import React, { FC, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useFormikContext } from 'formik';
import { useParams } from 'react-router-dom';
import { FormattedNumber } from 'react-intl';
import styled from 'styled-components';

import { getBeneficiaryTableWizard } from '../../../../actions/clustersActions';
import { IFormData } from '../../../../entities/IDeclaration';
import { useOrganization } from '../../../../tools/organizationHooks';
import { ColoredDatagrid } from '../../../Common/ColoredDatagrid/ColoredDatagrid';
import { EColumnType } from '../../../../entities/IDatagrid';
import { useIntlMessage } from '../../../Common/IntlMessage';
import { EColumnSubTypes } from '../../../../tools/dataGridTools';
import { EContextList, EProfileType, EReferentials, IReferencial } from '../../../../entities/IGlobal';
import { ESpinnerSize, Spinner } from '../../../Common/Spinner/Spinner';
import { TranslateReferentials } from '../../../../tools/referentials';
import { ISingleCluster } from '../../../../entities/IClusters';
import { Alert } from '../../../Common/Alert/Alert';
import { EAlertType, EAlertTypeSchema } from '../../../../entities/IAlert';
import { EPropertieKey, IClusterSingleDynamicStake } from '../../../../entities/IClusterStake';
import { getReferentials } from '../../../../actions/globalActions';

type GetBeneficiaryTableWizard = ReturnType<typeof getBeneficiaryTableWizard>;
type GetReferentials = ReturnType<typeof getReferentials>;

const Wrapper = styled.div`
    width: 100%;
    height: 90%;
`;

interface IProps {
    setCumulatedObject: (cumulatedObject: ISingleCluster) => void;
}

const DonorTable: FC<IProps> = props => {
    const dispatch = useDispatch();
    const { intlFormatMessage } = useIntlMessage();
    const { organization } = useParams<{ organization: string }>();
    const { currentOrganization } = useOrganization(organization);
    const { values } = useFormikContext<IFormData>();
    const [tableData, setTableData] = useState<IClusterSingleDynamicStake[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [allUnits, setAllUnits] = useState<IReferencial[]>([]);
    const didMountRef = useRef(false);
    const cumulatedMessages = useMemo(() => (tableData?.map((elem) => elem.properties.find(element => element.key === 'non-registered-user-messages').value).filter(elem => !!elem)), [tableData]);

    useEffect(() => {
        setLoading(true);
        dispatch<GetBeneficiaryTableWizard>(getBeneficiaryTableWizard(currentOrganization.id, values)).then(res => {
            props.setCumulatedObject(res);
            setTableData(res.dynamicStakes.find(elem => elem.stakeFamilyKey === 'Parties').stakeListItems.find(elem => (elem.key === EPropertieKey.ReceivingGiftDonors || elem.key === EPropertieKey.ReceivingInvitationDonors)).clusterStakes);
        });
        dispatch<GetReferentials>(getReferentials('', EReferentials.Currency, EContextList.Platform)).then(res => {
            setAllUnits(res);
        });
    }, []);

    useEffect(() => {
        if (didMountRef.current) {
            setLoading(false);
        }
        didMountRef.current = true;
    }, [tableData]);

    const getData = async () => {
        let finalData = [];
        finalData = tableData.map((elem, index) => ({
            order: index + 1,
            person: {
                childId: elem.properties.find(element => element.key === 'non-registered-user-third-party').value?.id,
                firstLine: `${elem.properties.find(element => element.key === 'non-registered-user-third-party').value?.firstName} ${elem.properties.find(element => element.key === 'non-registered-user-third-party').value.lastName}`,
                picture: elem.properties.find(element => element.key === 'non-registered-user-third-party').value?.picture,
                principalType: EProfileType.Personal,
                secondLine: elem.properties.find(element => element.key === 'non-registered-user-third-party').value?.jobTitle
            },
            sensitive: elem.properties.find(element => element.key === 'non-registered-user-sensitive-person').value,
            thisGift: <>{elem.properties.find(element => element.key === 'non-registered-user-amount-per-gift').value?.number ? (
                <>
                    <FormattedNumber value={elem.properties.find(element => element.key === 'non-registered-user-amount-per-gift').value?.number} maximumFractionDigits={2} /> <TranslateReferentials type={EReferentials.Currency} context={EContextList.Platform} uniqueKey={elem.properties.find(element => element.key === 'non-registered-user-amount-per-gift').value?.unit?.uniqueKey} />
                </>
            ) : ''}</>,
            cumulated: {
                bgColor: elem.properties.find(element => element.key === 'non-registered-user-cumulated-amount').value?.color,
                value: <>{elem.properties.find(element => element.key === 'non-registered-user-cumulated-amount').value?.number ? (
                    <>
                        <FormattedNumber value={elem.properties.find(element => element.key === 'non-registered-user-cumulated-amount').value?.number} maximumFractionDigits={2} /> <TranslateReferentials type={EReferentials.Currency} context={EContextList.Platform} uniqueKey={elem.properties.find(element => element.key === 'non-registered-user-cumulated-amount').value?.unit?.uniqueKey} />
                    </>
                ) : ''}
                </>
            },
            entity: {
                childId: elem.properties.find(element => element.key === 'non-registered-user-legal-entity').value?.id,
                firstLine: elem.properties.find(element => element.key === 'non-registered-user-legal-entity').value?.name,
                picture: elem.properties.find(element => element.key === 'non-registered-user-legal-entity').value?.picture,
                principalType: EProfileType.Company,
                secondLine: ''
            },
            relationship: elem.properties.find(element => element.key === 'non-registered-user-relationship-nature')?.value?.value || ''
        }));
        return finalData;
    };

    const getSettings = async () => ({
        columnsSettings: [{
            field: 'order',
            headerText: intlFormatMessage({ id: 'giftinvitation.beneficiaryTable.columnName.order' }),
            isVisible: true,
            type: EColumnType.number,
            subType: EColumnSubTypes.Order
        }, {
            field: 'person',
            headerText: intlFormatMessage({ id: 'giftinvitation.beneficiaryTable.columnName.person' }),
            isVisible: true,
            type: EColumnType.extendedAvatar
        }, {
            field: 'sensitive',
            headerText: intlFormatMessage({ id: 'giftinvitation.beneficiaryTable.columnName.sensitive' }),
            isVisible: true,
            type: EColumnType.exclamationMark
        }, {
            field: 'entity',
            headerText: intlFormatMessage({ id: 'giftinvitation.beneficiaryTable.columnName.entity' }),
            isVisible: true,
            type: EColumnType.extendedAvatar
        }, {
            field: 'thisGift',
            headerText: intlFormatMessage({ id: 'giftinvitation.beneficiaryTable.columnName.thisGift' }),
            isVisible: true,
            type: EColumnType.number
        }, {
            field: 'cumulated',
            headerText: intlFormatMessage({ id: 'giftinvitation.beneficiaryTable.columnName.cumulated' }),
            isVisible: true,
            type: EColumnType.number,
            subType: EColumnSubTypes.Color
        }, {
            field: 'relationship',
            headerText: intlFormatMessage({ id: 'giftinvitation.beneficiaryTable.columnName.relationship' }),
            isVisible: true,
            type: EColumnType.text
        }
        ],
        exportable: false
    });

    return (
        <Wrapper>
            {loading ? (
                <Spinner className="spinner" size={ESpinnerSize.LARGE} label={intlFormatMessage({ id: 'giftinvitation.beneficiaryTable.loader.label' })} />
            ) : (
                <>
                    <Alert
                        type={EAlertType.INFO}
                        typeSchema={EAlertTypeSchema.SECONDARY}
                        alertId="1"
                        visible
                        withoutProgress
                        withoutClose
                        title={intlFormatMessage({ id: 'giftinvitation.beneficiaryTable.message.subject' })}
                    >
                        <ul>
                            {cumulatedMessages?.map((elem, index) => (
                                <>
                                    {elem.map((message) => (
                                        <li
                                            key={message.id}
                                            dangerouslySetInnerHTML={{
                                                __html: intlFormatMessage({ id: `giftinvitation.beneficiaryTable.message.${message.key}` }, {
                                                    limit: `${message.dataForTranslations.limit.amount} ${allUnits?.find(unit => unit.Key === message?.dataForTranslations?.limit?.currency?.uniqueKey)?.name}`,
                                                    person: `${message.dataForTranslations.person.firstName} ${message.dataForTranslations.person.lastName}`
                                                })
                                            }}
                                        />
                                    ))}
                                </>
                            ))}
                            {cumulatedMessages?.length === 0 &&
                                <li
                                    dangerouslySetInnerHTML={{
                                        __html: intlFormatMessage({ id: `giftinvitation.beneficiaryTable.message.nothingToNotify` })
                                    }}
                                />
                            }
                        </ul>
                    </Alert>
                    <br />
                    <ColoredDatagrid
                        whiteHeader
                        withoutMinHeight
                        getData={getData}
                        getSettings={getSettings}
                    />
                </>
            )}
        </Wrapper>
    );
};

export default DonorTable;