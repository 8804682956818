import React from 'react';
import { Column } from '@syncfusion/ej2-react-grids';
import moment from 'moment';
import { IntlShape } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import { ELcid } from '../entities/ILanguage';
import { EColumnType, IHyperlinkDataType } from '../entities/IDatagrid';
import { IColumnSettings } from '../entities/IClusters';
import { getTextFromDraft } from '../tools/draftJS';
import {
    Template,
    SeeMore,
    DateContainer,
    Tooltip,
    TopSection,
    NameButton
} from '../components/Common/GridStyles/Components';
import { MultiStakeTemplate } from '../components/Common/GridStyles/ColumnTemplates/MultiStakeTemplate';

export enum EColumnSubTypes {
    Id = '#',
    MultiStake = 'MultiStake',
    Messages = 'messages',
    Documents = 'documents',
    Datetime = 'datetime',
    Date = 'date',
    Relative = 'relativeDate',
    organization = 'organization',
    cluster = 'cluster',
    Order = 'order',
    Status = 'status',
    Color = 'Color'
}

export interface IAccess {
    [key: string]: (field: string, props: any, column: Column) => string;
}

export interface ITemplate {
    [key: string]: (field: string, props: any) => JSX.Element;
}

export interface IColumnField {
    field: string;
    fieldName: string;
    type: EColumnType;
}

export interface IGridSourceRow {
    [key: string]: any;
}

interface ICellValidator {
    [field: string]: {
        definedValue: unknown;
        operator: EFilterOperators;
        stylesClass: string;
        overwrittenValue?: unknown;
        crossCell?: ICellValidator;
    };
}

interface IOptions {
    typeCheck?: any;
    toExclude?: string[];
    shadeOverflow?: boolean;
    showDateTooltip?: boolean;
    cellValidator?: ICellValidator;
    intlFormatMessage: any;
    onClick?(text: string, clusterName: string, id: string, columnName: string);
    onSogeStructureClick?(
        textPopupValue: React.ReactNode,
        clusterName: string,
        versionId: string,
        structureType: number,
        elementId: string,
        columnName: string
    );
}

interface IToExtract {
    field: string;
    subType?: EColumnSubTypes;
}

export interface IFilterValue {
    operator: EFilterOperators;
    text?: string;
    predicate?: EPredicate;
}

export interface IDateFilterValue extends IFilterValue {
    extension?: IDateFilterValue;
    daysCorrection?: number;
    isWithin?: boolean;
    customValue?: number;
    isMultipleInput?: boolean;
}
export interface INumberFilterValue extends IFilterValue {
    isMultipleInput?: boolean;
    extension?: INumberFilterValue;
}

export enum EPredicate {
    and = 'and',
    or = 'or'
}

export enum EFilterOperators {
    contains = 'contains',
    endsWith = 'endswith',
    equal = 'equal',
    greaterThan = 'greaterthan',
    greaterThanOrEqual = 'greaterthanorequal',
    lessThan = 'lessthan',
    lessThanOrEqual = 'lessthanorequal',
    notEqual = 'notequal',
    startsWith = 'startswith'
}

export enum ESortOperators {
    Ascending = 'Ascending',
    Descending = 'Descending'
}

const checkValuesByOperator = (a: unknown, b: unknown, operator: EFilterOperators): boolean => {
    switch (operator) {
        case EFilterOperators.equal:
            return a === b;
        case EFilterOperators.greaterThan:
            return a > b;
        default:
            return false;
    }
};

const assignClassNameBasedOnValidator = (validator: ICellValidator, field: string, data: any): string => {
    const crossFieldName = Object.keys(validator[field]?.crossCell || {})?.[0];
    const crossCellValidator = validator[field]?.crossCell?.[crossFieldName];
    if (
        !!crossFieldName &&
        checkValuesByOperator(crossCellValidator.definedValue, data[crossFieldName], crossCellValidator.operator)
    ) {
        return checkValuesByOperator(data[field], crossCellValidator.overwrittenValue, validator[field].operator)
            ? crossCellValidator.stylesClass
            : '';
    } else {
        return checkValuesByOperator(data[field], validator[field].definedValue, validator[field].operator)
            ? validator[field].stylesClass
            : '';
    }
};

export const getTemplates = (toAssign: ITemplate, toExtract: IToExtract[], options: IOptions) => {
    const { typeCheck, intlFormatMessage, shadeOverflow, onClick, onSogeStructureClick, showDateTooltip } = options;
    const autoGeneratedTemplates: ITemplate = {};

    toExtract.forEach(facet => {
        if (!toAssign[facet.field] && !options.toExclude?.includes(facet.field)) {
            if (facet.subType === EColumnSubTypes.MultiStake) {
                autoGeneratedTemplates[facet.field] = (field: string, props: any) => (
                    <MultiStakeTemplate props={props} field={field} />
                );
            } else {
                autoGeneratedTemplates[facet.field] = (field: string, props: any) => {
                    const isDate =
                        typeCheck?.[`${field}Properties`] === 'date' || props[`${field}Properties`] instanceof Date;
                    const cellWidth =
                        typeof props.column.width === 'string'
                            ? Math.floor(Number(props.column.width.split('px')[0]))
                            : Math.floor(props.column.width);
                    const styles = [];
                    let length = 0;
                    if (shadeOverflow) {
                        styles.push(Number(props.index) % 2 === 0 ? 'custom-cell-even' : 'custom-cell-odd');
                    }
                    if (!!options?.cellValidator?.[`${field}Properties`]) {
                        styles.push(
                            assignClassNameBasedOnValidator(options.cellValidator, `${field}Properties`, props)
                        );
                    }
                    if (!isDate && typeof props[`${field}Properties`] === 'string') {
                        length = getTextFromDraft(props[`${field}Properties`])?.length;
                    }
                    const getPopupValue = () => {
                        if (!!options.onSogeStructureClick) {
                            onSogeStructureClick(
                                getTextFromDraft(props[`${field}Properties`]),
                                props.fullName,
                                props.versionId,
                                props.typeBase,
                                props.elementId,
                                props.column.headerText
                            );
                        } else {
                            onClick(
                                getTextFromDraft(props[`${field}Properties`]),
                                props.name || props.entityName,
                                props.id,
                                props.column.headerText
                            );
                        }
                    };
                    return (
                        <Template length={length} className={styles.join(' ')}>
                            {isDate ? (
                                <DateContainer>
                                    {moment(props[`${field}Properties`]).format('DD MMM YYYY')}
                                    {showDateTooltip && (
                                        <Tooltip className="date-tooltip">
                                            <TopSection>
                                                {moment(props[`${field}Properties`]).format('DD MMM YYYY')}
                                            </TopSection>
                                        </Tooltip>
                                    )}
                                </DateContainer>
                            ) : (
                                <span>
                                    {typeof props[`${field}Properties`] === 'string' &&
                                        getTextFromDraft(props[`${field}Properties`])}
                                    {typeof props[`${field}Properties`] === 'object' &&
                                        props[`${field}Properties`]?.map(elem => elem?.name)?.join(', ')}
                                    {typeof props[`${field}Properties`] === 'number' &&
                                        `${String(props[`${field}Properties`])} ${props?.extension || ''}`}
                                </span>
                            )}
                            {shadeOverflow && length && cellWidth - length * 6.4 < 60 ? (
                                <SeeMore onClick={getPopupValue}>
                                    <span>{(intlFormatMessage?.formatMessage || intlFormatMessage)({ id: 'datagrid.seeMore' })}</span>
                                </SeeMore>
                            ) : (
                                <span />
                            )}
                        </Template>
                    );
                };
            }
        }
    });
    return { ...toAssign, ...autoGeneratedTemplates };
};

export const getAccesses = (toAssign: IAccess, toExtract: IToExtract[], options: IOptions) => {
    const { typeCheck } = options;
    const autoGeneratedAccess: IAccess = {};
    toExtract.forEach(facet => {
        if (!toAssign[facet.field] && !options.toExclude?.includes(facet.field)) {
            autoGeneratedAccess[facet.field] = (field: string, props: any, column: Column) => {
                const isDate = typeCheck?.[field] === 'date';
                return isDate
                    ? moment(props?.[field]).format('DD MMM YYYY')
                    : typeof (['string', 'number']).includes(props?.[field])
                    ? props?.[field]
                    : undefined;
            };
        }
    });
    return { ...toAssign, ...autoGeneratedAccess };
};

export const getCaptionTemplate = (props): JSX.Element => {
    let groupName = `No ${props.headerText.toLowerCase()}`;
    const groupDate = new Date(props.key);
    if (props.key) {
        if (typeof props.key === 'string') groupName = props.key;
        else if (props.key?.firstName || props.key?.lastName)
            groupName = `${props.key?.firstName || ''} ${props.key?.lastName || ''}`;
        else if (props.key.length && props.key[0].firstName)
            groupName = props.key.map(elem => `${elem.firstName} ${elem.lastName}`)?.join(', ') || '';
        else if (props.key.length && props.key[0].name) groupName = props.key.map(elem => elem.name)?.join(', ') || '';
        else if (props.key.length) groupName = props.key?.join(', ') || '';
    }
    return (
        <span>
            {props.headerText}:{' '}
            <strong>
                {groupDate instanceof Date && !isNaN(groupDate.valueOf()) && props.key
                    ? props.key.split('T')[0]
                    : groupName}
            </strong>
        </span>
    );
};

export const getCurrentDateFormat = (language: ELcid) => {
    const currentDateFormat = {
        [ELcid.En]: 'M/d/y',
        [ELcid.Fr]: 'dd/MM/y'
    };

    return currentDateFormat[language];
};

export enum ECellHeight {
    Small = 36,
    Medium = 56,
    Tall = 76,
    ExtraTall = 111
}

export const getCellHeightClass = (height: ECellHeight) => {
    const cellStyles = {
        [ECellHeight.Small]: 'cell-height-1',
        [ECellHeight.Medium]: 'cell-height-2',
        [ECellHeight.Tall]: 'cell-height-3',
        [ECellHeight.ExtraTall]: 'cell-height-4'
    };
    return cellStyles?.[height] || cellStyles[ECellHeight.Small];
};

export const displayUsersByCellHeight = (height: ECellHeight) => {
    const usersPerHeight = {
        [ECellHeight.Small]: 1,
        [ECellHeight.Medium]: 1,
        [ECellHeight.Tall]: 2,
        [ECellHeight.ExtraTall]: 3
    };
    return usersPerHeight[height];
};

export const getTranslatedPredicateOptions = (intlFormatMessage: any) => [
    {
        text: intlFormatMessage({ id: 'global.where' }),
        key: EPredicate.and
    },
    {
        text: intlFormatMessage({ id: 'global.or' }),
        key: EPredicate.or
    }
];

const getBooleanFilters = (intlFormatMessage: any) => [
    {
        text: intlFormatMessage({ id: 'cluster.filterPicker.equal' }),
        operator: EFilterOperators.equal
    },
    {
        text: intlFormatMessage({ id: 'cluster.filterPicker.notEqual' }),
        operator: EFilterOperators.notEqual
    }
];

const getNumberFilters = (intlFormatMessage: any) => [
    {
        text: '=',
        operator: EFilterOperators.equal
    },
    {
        text: '≠',
        operator: EFilterOperators.notEqual
    },
    {
        text: '>',
        operator: EFilterOperators.greaterThan
    },
    {
        text: '≥',
        operator: EFilterOperators.greaterThanOrEqual
    },
    {
        text: '<',
        operator: EFilterOperators.lessThan
    },
    {
        text: '≤',
        operator: EFilterOperators.lessThanOrEqual
    },
    {
        text: intlFormatMessage({ id: 'cluster.filterPicker.between' }),
        operator: EFilterOperators.greaterThanOrEqual,
        predicate: 'and',
        isMultipleInput: true,
        extension: {
            operator: EFilterOperators.lessThanOrEqual
        }
    }
];

const getStringFilters = (intlFormatMessage: any) => [
    ...getBooleanFilters(intlFormatMessage),
    {
        text: intlFormatMessage({ id: 'cluster.filterPicker.contains' }),
        operator: EFilterOperators.contains
    },
    {
        text: intlFormatMessage({ id: 'cluster.filterPicker.startsWith' }),
        operator: EFilterOperators.startsWith
    },
    {
        text: intlFormatMessage({ id: 'cluster.filterPicker.lessThan' }),
        operator: EFilterOperators.endsWith
    }
];

export const getFiltersByDataType = (dataType: EColumnType, intlFormatMessage: any) => {
    const filterValues = {
        [EColumnType.date]: [
            ...getBooleanFilters(intlFormatMessage),
            {
                text: intlFormatMessage({ id: 'cluster.filterPicker.greaterThan' }),
                operator: EFilterOperators.greaterThan
            },
            {
                text: intlFormatMessage({ id: 'cluster.filterPicker.greaterThanOrEqualTo' }),
                operator: EFilterOperators.greaterThanOrEqual
            },
            {
                text: intlFormatMessage({ id: 'cluster.filterPicker.lessThan' }),
                operator: EFilterOperators.lessThan
            },
            {
                text: intlFormatMessage({ id: 'cluster.filterPicker.lessOrEqualTo' }),
                operator: EFilterOperators.lessThanOrEqual
            },
            {
                text: intlFormatMessage({ id: 'cluster.filterPicker.between' }),
                operator: EFilterOperators.greaterThanOrEqual,
                predicate: 'or',
                isMultipleInput: true,
                extension: {
                    operator: EFilterOperators.lessThanOrEqual
                }
            },
            {
                text: intlFormatMessage({ id: 'cluster.filterPicker.pastWeek' }),
                operator: EFilterOperators.greaterThanOrEqual,
                isWithin: true,
                daysCorrection: -7,
                predicate: 'or',
                extension: {
                    operator: EFilterOperators.lessThanOrEqual
                }
            },
            {
                text: intlFormatMessage({ id: 'cluster.filterPicker.pastMonth' }),
                operator: EFilterOperators.greaterThanOrEqual,
                isWithin: true,
                daysCorrection: -30,
                predicate: 'or',
                extension: {
                    operator: EFilterOperators.lessThanOrEqual
                }
            },
            {
                text: intlFormatMessage({ id: 'cluster.filterPicker.pastYear' }),
                operator: EFilterOperators.greaterThanOrEqual,
                isWithin: true,
                daysCorrection: -365,
                predicate: 'or',
                extension: {
                    operator: EFilterOperators.lessThanOrEqual
                }
            },
            {
                text: intlFormatMessage({ id: 'cluster.filterPicker.nextWeek' }),
                operator: EFilterOperators.greaterThanOrEqual,
                isWithin: true,
                predicate: 'or',
                extension: {
                    operator: EFilterOperators.lessThanOrEqual,
                    daysCorrection: 7
                }
            },
            {
                text: intlFormatMessage({ id: 'cluster.filterPicker.nextMonth' }),
                operator: EFilterOperators.greaterThanOrEqual,
                isWithin: true,
                predicate: 'or',
                extension: {
                    operator: EFilterOperators.lessThanOrEqual,
                    daysCorrection: 30
                }
            },
            {
                text: intlFormatMessage({ id: 'cluster.filterPicker.nextYear' }),
                operator: EFilterOperators.greaterThanOrEqual,
                isWithin: true,
                predicate: 'or',
                extension: {
                    operator: EFilterOperators.lessThanOrEqual,
                    daysCorrection: 365
                }
            },
            {
                text: intlFormatMessage({ id: 'cluster.filterPicker.pastNumberOfDays' }),
                operator: EFilterOperators.greaterThanOrEqual,
                isWithin: true,
                customValue: -1,
                predicate: 'or',
                extension: {
                    operator: EFilterOperators.lessThanOrEqual
                }
            },
            {
                text: intlFormatMessage({ id: 'cluster.filterPicker.nextNumberOfDays' }),
                operator: EFilterOperators.greaterThanOrEqual,
                isWithin: true,
                predicate: 'or',
                extension: {
                    operator: EFilterOperators.lessThanOrEqual,
                    customValue: 1
                }
            }
        ],
        [EColumnType.number]: getNumberFilters(intlFormatMessage),
        [EColumnType.STRING]: getStringFilters(intlFormatMessage),
        [EColumnType.boolean]: getBooleanFilters(intlFormatMessage)
    };
    return filterValues[dataType] || [];
};

export const columnDataFromSettings = (settings: IColumnSettings[]): IColumnField[] => {
    const mappedSettings: IColumnField[] = [];
    (settings || []).forEach(column => {
        if (column.properties?.length > 0) {
            column.properties.forEach(childNode =>
                mappedSettings.push({
                    fieldName: `${column.headerText} > ${childNode.headerText}`,
                    field: childNode.field,
                    type: childNode.type || EColumnType.STRING
                })
            );
        } else {
            mappedSettings.push({
                fieldName: column.headerText,
                field: column.field,
                type: column.type || EColumnType.STRING
            });
        }
    });
    return mappedSettings || [];
};

export const hyperLinkDataSwitch = (field: string, props: IHyperlinkDataType, orgUrlName: string, navigate: ReturnType<typeof useNavigate>) => {
    switch (props[`${field}Properties`]?.routeName || '') {
        // tslint:disable-next-line:no-string-literal
        case 'Campaign':
            return (
                <NameButton
                    onClick={() =>
                        props[`${field}Properties`]?.text &&
                        navigate(
                            `/orgs/${orgUrlName}/campaign/${
                                // tslint:disable-next-line:no-string-literal
                                props[`${field}Properties`]?.hyperlinkParameters['campaignId']
                            }`
                        )
                    }
                >
                    {props[`${field}Properties`]?.text}
                </NameButton>
            );
        // tslint:disable-next-line:no-string-literal
        case 'DraftCampaign':
            return (
                <NameButton
                    onClick={() =>
                        props[`${field}Properties`]?.text &&
                        navigate(
                            `/orgs/${orgUrlName}/campaign/edit/${
                                // tslint:disable-next-line:no-string-literal
                                props[`${field}Properties`]?.hyperlinkParameters['campaignId']
                            }`
                        )
                    }
                >
                    {props[`${field}Properties`]?.text}
                </NameButton>
            );
        default:
            return () => {
                return <span />;
            };
    }
};
