import * as actionTypes from '../actions/actionTypes/workspaceTypes';
import { ICategoriesData } from '../components/Organization/Settings/Tabs/Categories';

export interface IWorkspaceReducer {
    workspaceCategories: ICategoriesData[];
}

const defaultState = (): IWorkspaceReducer => ({
    workspaceCategories: []
});

export default (state = defaultState(), action): IWorkspaceReducer => {
    switch (action.type) {
        case actionTypes.SET_CATEGORIES: {
            const payload: actionTypes.IWorkspaceTypes['SET_CATEGORIES'] = action;
            return {
                ...state,
                workspaceCategories: payload.categories
            };
        }
        default: {
            return state;
        }
    }
};