import { IconDefinition } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC, useState } from 'react';
import styled, { css } from 'styled-components';

import { colorStack } from '../../../../styleHelpers/colors';
import { EIconButtonColorSchema, EIconButtonTypeSchema, IconButton } from '../../Buttons/IconButton';
import { Button, EButtonColorSchema, EButtonTypeSchema } from '../../Buttons/NewButton';
import { TextWithEllipsis } from '../../TextWithEllipsis/TextWithEllipsis';

const Wrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    text-align: center;
    height: 100%;
`;

const ButtonContainer = styled.div<{backgroundColor: string, disabled: boolean, fontColor: string}>`
    background-color: ${colorStack.white};
    padding: 0 1rem;
    width: 100%;
    ${({ backgroundColor, disabled, fontColor }) => backgroundColor && css`
        background-color: ${backgroundColor};
        height: 100%;
        color: ${disabled ? colorStack.disabled : (fontColor || colorStack.white)};
        > * {
            width: 100%;
            height: 100%;
        }
    `};
    > svg {
        font-size: 1.5rem;
    };
    &[aria-type='Button'] {
        width: auto;
    };
`;

const IconWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 4px;
    > svg {
        font-size: 1.5rem;
    }
`;

export interface ISingleRowButtonProps {
    data: {
        name?: string;
        icon?: IconDefinition;
        type: 'Button' | 'IconButton' | 'Icon';
        data: any;
        typeSchema?: EButtonTypeSchema | EIconButtonTypeSchema;
        colorSchema?: EButtonColorSchema | EIconButtonColorSchema;
        color?: string;
        fontColor?: string;
        hidden?: boolean;
        disabled?: boolean;
        backgroundColor: string;
        action(data: any): Promise<any>;
    }[];
}

export const ButtonType: FC<React.PropsWithChildren<ISingleRowButtonProps>> = props => {
    const [disableButtons, setDisableButtons] = useState<boolean>(false);

    const onClick = async (elem) => {
        setDisableButtons(true);
        elem.action(elem).then(() => {
            setDisableButtons(false);
        });
    };

    return (
        <Wrapper>
            {props.data?.map((elem, index) => (
                <ButtonContainer aria-type={elem.type} backgroundColor={!elem.hidden && elem.backgroundColor} disabled={elem.disabled} key={index} fontColor={elem.fontColor}>
                    {elem.type === 'Button' && (
                        elem.hidden ? (
                            <div></div>
                        ) : (
                            <Button
                                onClick={() => onClick(elem)}
                                colorSchema={elem.colorSchema as EButtonColorSchema}
                                typeSchema={elem.typeSchema as EButtonTypeSchema}
                                leftIco={elem.icon}
                                disabled={elem.disabled || disableButtons}
                                className={'type-button'}
                            >
                                {elem.name}
                            </Button>
                        )
                    )}
                    {elem.type === 'IconButton' && (
                        elem.hidden ? (
                            <div></div>
                        ) : (
                            <IconButton
                                icon={elem.icon}
                                colorSchema={elem.colorSchema as EIconButtonColorSchema}
                                typeSchema={elem.typeSchema as EIconButtonTypeSchema}
                                onClick={() => onClick(elem)}
                                disabled={disableButtons}
                                className={'type-icon-button'}
                            />
                        )
                    )}
                    {elem.type === 'Icon' && (
                        elem.hidden ? (
                            <div></div>
                        ) : (
                            <IconWrapper className={'type-icon'} onClick={!(elem.disabled || disableButtons) ? () => onClick(elem) : undefined}>
                                <FontAwesomeIcon
                                    color={!elem.disabled ? elem.color : colorStack.disabled}
                                    icon={elem.icon}
                                />
                                {elem?.name &&
                                    <TextWithEllipsis>{elem.name}</TextWithEllipsis>
                                }
                            </IconWrapper>
                        )
                    )}
                </ButtonContainer>
            ))}
        </Wrapper>
    );
};