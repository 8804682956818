import { getAllCookies, getCookie, removeCookie, setCookie } from './cookies';

export const setSessionCookie = (cname: string, cvalue: string) => {
    const expires = `expires=${-1}`;
    document.cookie = `${cname}=${cvalue};${expires};path=/`;
};

const createStorage = (): Storage => ({
    ...(getAllCookies()),
    setItem(key: string, value: string) {
        this[key] = value;
        setSessionCookie(key, value);
    },
    getItem(key: string) {
        return getCookie(key);
    },
    removeItem(key: string) {
        delete this[key];
        removeCookie(key);
    }
} as Storage);

export let sessionStorage: Storage = window.sessionStorage;

try {
    if (!window.localStorage || !window.sessionStorage) throw 'exception';
    window.sessionStorage.setItem('storage_test', '1');
    window.sessionStorage.removeItem('storage_test');
} catch (e) {
    sessionStorage = createStorage();

    (window as any).sessionStorage = sessionStorage;
    window.sessionStorage.__proto__ = sessionStorage;
}

interface IExpStorage {
    value: string;
    expiry: number;
}

export const sessionWithExpiry = (key: string, value: string, ttl: number) => {
    const now = new Date();

    const item: IExpStorage = {
        value,
        expiry: now.getTime() + ttl // ttl in miliseconds
    };

    sessionStorage.setItem(key, JSON.stringify(item));
};

export const getSessionWithExpiry = (key: string, returnLastValue?: boolean) => {
    try {
        const json = sessionStorage.getItem(key);
        const item = JSON.parse(json) as IExpStorage;
        const now = new Date();

        if (now.getTime() > item.expiry) {
            sessionStorage.removeItem(key);
            return returnLastValue ? item.value : undefined;
        }

        return item.value;
    } catch (e) {
        return undefined;
    }
};
