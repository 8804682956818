import React from 'react';

import { useIntlMessage } from '../../../Common/IntlMessage';

interface SuccessfulSubmissionProps {

}

export const SuccessfulSubmission = (props: SuccessfulSubmissionProps) => {
    const { intlFormatMessage } = useIntlMessage();
    return (
        <div>
            <span dangerouslySetInnerHTML={{
                __html: intlFormatMessage({ id: 'giftinvitation.success.great' })
            }} />
            <span dangerouslySetInnerHTML={{
                __html: intlFormatMessage({ id: 'giftinvitation.success.paragraph' })
            }} />
        </div>
    );
};