import React, { FC } from 'react';
import { Form, useFormikContext } from 'formik';
import styled, { css } from 'styled-components';

import { media } from '../../../../styleHelpers/breakpoint';
import { NewClusterFormValues } from '../../../../tools/clusterTools';
import { TextComponent } from '../../../Common/Inputs/TextComponent';
import { Privacy } from '../../../Common/Privacy/Privacy';
import { LabelWithEllipsis } from '../../../Common/Label/Label';
import { IValue } from '../../../../entities/IPickers';
import IntlMessage from '../../../Common/IntlMessage';

const FormField = styled.div<{ half?: boolean }>`
    width: 100%;
    padding-bottom: 18px;

    ${(props) => props.half && css`
        ${media.tabletSm`
            width: 50%;
        `}
    `}
`;

const Wrapper = styled(Form)`
    width: 100%;
`;

export const Step1: FC<React.PropsWithChildren<unknown>> = () => {
    const { values, handleBlur, handleChange } = useFormikContext<NewClusterFormValues>();

    return (
        <Wrapper>
            <FormField>
                <LabelWithEllipsis required>
                    <IntlMessage id="dispute.wizard.step1.title2" />
                </LabelWithEllipsis>
                <TextComponent
                    name="name"
                    value={values.name}
                    onBlur={handleBlur}
                    onChange={(value: string, selectedCurrency: IValue, e?: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => handleChange(e)}
                    error={values.name?.length > 100}
                    errorMsg={<IntlMessage id="dispute.wizard.step1.title.error" />}
                />
            </FormField>
            <FormField>
                <Privacy field="privacy" />
            </FormField>
        </Wrapper>
    );
};
