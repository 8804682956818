import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { faTag } from '@fortawesome/pro-regular-svg-icons';

import { getSogeArticleUrl } from '../../../../tools/legalDocTools/sogeTools';
import { getErrorMessage } from '../../../../tools/errorTools';
import { ISogeArticle, ISogeOtherArticleVersion } from '../../../../entities/ISoge';
import { EVersionStatus } from '../../../../entities/LegalDoc/INormativeDocumentationVersion';
import { getArticleVersions } from '../../../../actions/sogeActions';
import { IState } from '../../../../reducers';
import { ISogeReducer } from '../../../../reducers/sogeReducer';
import { fontSize } from '../../../../styleHelpers/fontSizes';
import { Popup } from '../../../Common/Popup/Popup';
import { dateStandardFormatString } from '../../../Common/Date/DateStandardFormat';
import { LegalDocLink, LegalDocLinks } from './SharedComponents';
import { Loader } from '../../../Common/Loader/Loader';
import IntlMessage, { useIntlMessage } from '../../../Common/IntlMessage';

type GetArticleVersions = ReturnType<typeof getArticleVersions>;

const CurrentVersion = styled.div`
    font-size: ${fontSize[13]};
`;

const CurrentVersionDetails = styled.span`
    font-weight: 500;
    span {
        font-weight: 400;
        margin: 0 5px 0 0;
    }
`;

const LegalDocLinksTitle = styled.div`
    font-weight: 500;
    margin: 10px 0 10px 0;
    font-size: ${fontSize[13]};
`;

interface IArticleHistoryPopupProps {
    article: ISogeArticle;
    organizationUrlName: string;
    structureId: string;
    versionId: string;
    isConsultation: boolean;
    onClose?(): void;
}

export const ArticleHistoryPopup: FC<React.PropsWithChildren<IArticleHistoryPopupProps>> = ({ article, organizationUrlName, structureId, versionId, isConsultation, onClose }) => {
    const dispatch = useDispatch();
    const { intlFormatMessage } = useIntlMessage();
    const { languageData, documentationLcid } = useSelector<IState, ISogeReducer>(state => state.soge);
    const [articleVersions, setArticleVersions] = useState<ISogeOtherArticleVersion[]>(undefined);
    const [errorMessage, setErrorMessage] = useState<string>(undefined);

    useEffect(() => {
        if (versionId) {
            dispatch<GetArticleVersions>(getArticleVersions(article.architextLastVersion.articleVersion.normativeDocumentationId, versionId, article.commonArticleId, documentationLcid))
                .then(response => {
                    const currentVersionStatus = languageData?.[versionId]?.[documentationLcid]?.status;
                    setArticleVersions(response.filter(version => (isConsultation && currentVersionStatus !== EVersionStatus.New) ? languageData?.[version.versionId]?.[documentationLcid]?.status !== EVersionStatus.New && version.id !== article.id : true));
                })
                .catch(err => {
                    setArticleVersions([]);
                    const errorCode: number = err?.data?.errorCode;
                    errorCode !== 4010 && setErrorMessage(getErrorMessage(err));
                });
        }
    }, [versionId, isConsultation, documentationLcid, article?.commonArticleId]);

    return (
        <Popup
            noButtons
            titleBorder
            disableMaxWidth
            isVisible
            title={<IntlMessage id="global.history" />}
            cancelAction={onClose}
            ico={faTag}
        >
            <Loader loading={!articleVersions}>
                {!!article?.architextLastVersion?.articleVersion && !!articleVersions?.length && (
                    <CurrentVersion>
                        <CurrentVersionDetails>
                            <Link to={getSogeArticleUrl(organizationUrlName, article.id, structureId, article.architextLastVersion.articleVersion?.normativeDocumentationInstanceVersionId)}>
                                <IntlMessage id="norms.selectedVersion" />
                                {`${article.architextLastVersion.articleVersion?.documentVersionName || ''}${article.architextLastVersion.articleVersion?.publicationDate ? ` - ${dateStandardFormatString({ date: article.architextLastVersion.articleVersion.publicationDate, context: intlFormatMessage, showNumeric: true })}` : ''}`}
                            </Link>
                        </CurrentVersionDetails>
                    </CurrentVersion>
                )}
                {!!articleVersions?.length && (
                    <LegalDocLinks>
                        <LegalDocLinksTitle>
                            <IntlMessage id="norms.otherVersions" />
                        </LegalDocLinksTitle>
                        <ul>
                            {(articleVersions || []).map(version => (
                                <LegalDocLink key={version.id}>
                                    <Link to={getSogeArticleUrl(organizationUrlName, version.id, structureId, version.versionId)}>
                                        {`${version.documentVersionName}${version?.versionDate ? ` - ${dateStandardFormatString({ date: version.versionDate, context: intlFormatMessage, showNumeric: true })}` : ''} - ${version.articleName || 'Article'}`}
                                    </Link>
                                </LegalDocLink>
                            ))}
                        </ul>
                    </LegalDocLinks>
                )}
                {articleVersions?.length === 0 && (
                    <div>
                        {errorMessage ? (
                            <span>{errorMessage}</span>
                        ) : (
                            <IntlMessage id="norms.noHistoricalVersions" />
                        )}
                    </div>
                )}
            </Loader>
        </Popup>
    );
};
