import React, { FC } from 'react';

import { EApprovalRequestStatus, ERequestStatus, EBranchStatus } from '../../../entities/ISoge';
import { EVersionStatus } from '../../../entities/LegalDoc/INormativeDocumentationVersion';
import { Tag } from '../../Common/Tag/Tag';
import IntlMessage from '../../Common/IntlMessage';

interface IStatusTagProps {
    status?: EVersionStatus | EApprovalRequestStatus | ERequestStatus;
    branchStatus?: EBranchStatus;
    className?: string;
    noBreak?: boolean;
}

export const StatusTag: FC<React.PropsWithChildren<IStatusTagProps>> = ({ status, branchStatus, noBreak, className = '' }) => {
    return (
        <>
            {status === EVersionStatus.Current && (
                <Tag className={className} color="successGreen" noBorder nomargin sm noBreak={noBreak}>
                    <div>
                        <IntlMessage id="norms.label.current" />
                    </div>
                </Tag>
            )}
            {status === EVersionStatus.Archived && (
                <Tag className={className} color="red" noBorder nomargin sm noBreak={noBreak}>
                    <div>
                        <IntlMessage id="norms.status.archived" />
                    </div>
                </Tag>
            )}
            {status === EVersionStatus.New && (
                <Tag className={className} color="gray" noBorder nomargin sm noBreak={noBreak}>
                    <div>
                        <IntlMessage id="norms.status.new" />
                    </div>
                </Tag>
            )}
            {status === EVersionStatus.WorkInProgress && (
                <Tag className={className} color="activeBlue" noBorder nomargin sm noBreak={noBreak}>
                    <div>
                        <IntlMessage id="norms.status.workInProgress" />
                    </div>
                </Tag>
            )}
            {(status === EApprovalRequestStatus.Active || status === ERequestStatus.Active) && (
                <Tag className={className} color="activeBlue" noBorder nomargin sm noBreak={noBreak}>
                    <div>
                        <IntlMessage id="norms.status.active" />
                    </div>
                </Tag>
            )}
            {(status === EApprovalRequestStatus.Abandoned || status === ERequestStatus.Abandoned) && (
                <Tag className={className} color="red" noBorder nomargin sm noBreak={noBreak}>
                    <div>
                        <IntlMessage id="norms.status.rejected" />
                    </div>
                </Tag>
            )}
            {(status === EApprovalRequestStatus.Completed || status === ERequestStatus.Completed) && (
                <Tag className={className} color="successGreen" noBorder nomargin sm noBreak={noBreak}>
                    <div>
                        <IntlMessage id="norms.status.completed" />
                    </div>
                </Tag>
            )}
            {(status === EApprovalRequestStatus.WaitingForApproval || status === ERequestStatus.WaitingForApproval) && (
                <Tag className={className} color="activeBlue" noBorder nomargin sm noBreak={noBreak}>
                    <div>
                        <IntlMessage id="norms.status.waitingForApproval" />
                    </div>
                </Tag>
            )}
            {branchStatus === EBranchStatus.Active && (
                <Tag color="activeBlue" noBorder nomargin sm noBreak={noBreak}>
                    <IntlMessage id="norms.status.active" />
                </Tag>
            )}
            {branchStatus === EBranchStatus.Approved && (
                <Tag color="successGreen" noBorder nomargin sm noBreak={noBreak}>
                    <IntlMessage id="norms.status.approved" />
                </Tag>
            )}
            {branchStatus === EBranchStatus.Rejected && (
                <Tag color="red" noBorder nomargin sm noBreak={noBreak}>
                    <IntlMessage id="norms.status.rejected" />
                </Tag>
            )}
            {branchStatus === EBranchStatus.WaitingForApproval && (
                <Tag color="brown" noBorder nomargin sm noBreak={noBreak}>
                    <IntlMessage id="norms.status.waitingForApproval" />
                </Tag>
            )}
        </>
    );
};
