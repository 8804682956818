import React, { FC, useCallback, useEffect } from 'react';
import styled from 'styled-components';
import { useFormikContext } from 'formik';
import { useSelector } from 'react-redux';

import { IFormData } from '../../../../../entities/IDeclaration';
import { ISponsoringData } from '../../../../../entities/ActionData/ISponsoringData';
import { fontSize } from '../../../../../styleHelpers/fontSizes';
import { colorStack } from '../../../../../styleHelpers/colors';
import { Checkbox } from '../../../../Common/Inputs/Checkbox';
import { IState } from '../../../../../reducers';
import { IProfileReducer } from '../../../../../reducers/profileReducer';
import IntlMessage, { useIntlMessage } from '../../../../Common/IntlMessage';

const SWrapper = styled.div`
    color: ${colorStack.content};
    font-size: ${fontSize[16]};
    display: flex;
    flex-direction: column;
    margin: 0 2rem 0 2rem;
    ul {
        list-style-type: disc;
        background-color: white;
        border-radius: 8px;

        li {
            margin: 1rem 1.6rem 1rem 3rem;
            font-size: ${fontSize[16]};
        }
        margin-bottom: 1rem;
        margin-top: 1rem;
    }
    span {
        margin: 0.5rem;
        & a {
            color: ${colorStack.middleBlue};
        }
        & span {
            margin: 0;
        }
        &:nth-child(1) {
            max-width: 611px;
            overflow: hidden;
            text-overflow: ellipsis;
            width: 98%;
        }
    }
`;

export const Step2: FC<React.PropsWithChildren<unknown>> = () => {
    const { intlFormatMessage } = useIntlMessage();
    const { setFieldValue, values } = useFormikContext<IFormData>();
    const formikContext = useFormikContext<ISponsoringData>();
    const { currentUserProfile } = useSelector<IState, IProfileReducer>((state) => state.profile);

    useEffect(() => {
        formikContext.setFieldValue('step', 2);
    }, []);

    const changeCheckbox = useCallback((checked: boolean) => {
        setFieldValue('readPolicy', checked);
    }, [setFieldValue]);

    return (
        <SWrapper>
            <span><IntlMessage id="sponsoring.popup.step2.text1" values={{ UserFirstName: currentUserProfile.firstName }} /></span>
            <span dangerouslySetInnerHTML={{
                __html: intlFormatMessage({ id: 'sponsoring.popup.step2.text2' })
            }} />
            <span dangerouslySetInnerHTML={{
                __html: intlFormatMessage({ id: 'sponsoring.popup.step2.text7' })
            }} />
            <br />
            <Checkbox
                onChange={changeCheckbox}
                checked={values.readPolicy}
                label={intlFormatMessage({
                    id: 'sponsoring.popup.step2.checkbox'
                })}
            />
        </SWrapper>
    );
};
