import { useIntlMessage } from '../IntlMessage';

export const customTranslations = () => {
    const { intlFormatMessage } = useIntlMessage();

    return {
        'PdfViewer': {
            'PdfViewer': intlFormatMessage({ id: 'pdfViewer.pdfViewer' }),
            'Cancel': intlFormatMessage({ id: 'pdfViewer.cancel' }),
            'Download file': intlFormatMessage({ id: 'pdfViewer.downloadFile' }),
            'Download': intlFormatMessage({ id: 'pdfViewer.download' }),
            'Enter Password': intlFormatMessage({ id: 'pdfViewer.enterPassword' }),
            'File Corrupted': intlFormatMessage({ id: 'pdfViewer.fileCorrupted' }),
            'File Corrupted Content': intlFormatMessage({ id: 'pdfViewer.fileCorruptedContent' }),
            'Fit Page': intlFormatMessage({ id: 'pdfViewer.fitPage' }),
            'Fit Width': intlFormatMessage({ id: 'pdfViewer.fitWidth' }),
            'Automatic': intlFormatMessage({ id: 'pdfViewer.automatic' }),
            'Go To First Page': intlFormatMessage({ id: 'pdfViewer.goToFirstPage' }),
            'Invalid Password': intlFormatMessage({ id: 'pdfViewer.invalidPassword' }),
            'Next Page': intlFormatMessage({ id: 'pdfViewer.nextPage' }),
            'OK': intlFormatMessage({ id: 'pdfViewer.ok' }),
            'Open': intlFormatMessage({ id: 'pdfViewer.open' }),
            'Page Number': intlFormatMessage({ id: 'pdfViewer.pageNumber' }),
            'Previous Page': intlFormatMessage({ id: 'pdfViewer.previousPage' }),
            'Go To Last Page': intlFormatMessage({ id: 'pdfViewer.goToLastPage' }),
            'Zoom': intlFormatMessage({ id: 'pdfViewer.zoom' }),
            'Zoom In': intlFormatMessage({ id: 'pdfViewer.zoomIn' }),
            'Zoom Out': intlFormatMessage({ id: 'pdfViewer.zoomOut' }),
            'Page Thumbnails': intlFormatMessage({ id: 'pdfViewer.pageThumbnails' }),
            'Bookmarks': intlFormatMessage({ id: 'pdfViewer.bookmarks' }),
            'Print': intlFormatMessage({ id: 'pdfViewer.print' }),
            'Password Protected': intlFormatMessage({ id: 'pdfViewer.passwordProtected' }),
            'Copy': intlFormatMessage({ id: 'pdfViewer.copy' }),
            'Text Selection': intlFormatMessage({ id: 'pdfViewer.textSelection' }),
            'Panning': intlFormatMessage({ id: 'pdfViewer.panning' }),
            'Text Search': intlFormatMessage({ id: 'pdfViewer.textSearch' }),
            'Find in document': intlFormatMessage({ id: 'pdfViewer.findInDocument' }),
            'Match case': intlFormatMessage({ id: 'pdfViewer.matchCase' }),
            'Apply': intlFormatMessage({ id: 'pdfViewer.apply' }),
            'GoToPage': intlFormatMessage({ id: 'pdfViewer.goToPage' }),
            'No matches': intlFormatMessage({ id: 'pdfViewer.noMatches' }),
            'No Text Found': intlFormatMessage({ id: 'pdfViewer.noTextFound' }),
            'Undo': intlFormatMessage({ id: 'pdfViewer.undo' }),
            'Redo': intlFormatMessage({ id: 'pdfViewer.redo' }),
            'Annotation': intlFormatMessage({ id: 'pdfViewer.annotation' }),
            'Highlight': intlFormatMessage({ id: 'pdfViewer.highlight' }),
            'Underline': intlFormatMessage({ id: 'pdfViewer.Underline' }),
            'Strikethrough': intlFormatMessage({ id: 'pdfViewer.strikethrough' }),
            'Delete': intlFormatMessage({ id: 'pdfViewer.delete' }),
            'Opacity': intlFormatMessage({ id: 'pdfViewer.opacity' }),
            'Color edit': intlFormatMessage({ id: 'pdfViewer.colorEdit' }),
            'Opacity edit': intlFormatMessage({ id: 'pdfViewer.opacityEdit' }),
            'highlight': intlFormatMessage({ id: 'pdfViewer.highlight' }),
            'underline': intlFormatMessage({ id: 'pdfViewer.underline' }),
            'strikethrough': intlFormatMessage({ id: 'pdfViewer.strikethrough' }),
            'Server error': intlFormatMessage({ id: 'pdfViewer.serverError' }),
            'Open text': intlFormatMessage({ id: 'pdfViewer.openText' }),
            'First text': intlFormatMessage({ id: 'pdfViewer.firstText' }),
            'Previous text': intlFormatMessage({ id: 'pdfViewer.previousText' }),
            'Next text': intlFormatMessage({ id: 'pdfViewer.nextText' }),
            'Last text': intlFormatMessage({ id: 'pdfViewer.lastText' }),
            'Zoom in text': intlFormatMessage({ id: 'pdfViewer.zoomInText' }),
            'Zoom out text': intlFormatMessage({ id: 'pdfViewer.zoomOutText' }),
            'Selection text': intlFormatMessage({ id: 'pdfViewer.selectionText' }),
            'Pan text': intlFormatMessage({ id: 'pdfViewer.panText' }),
            'Print text': intlFormatMessage({ id: 'pdfViewer.printText' }),
            'Seach text': intlFormatMessage({ id: 'pdfViewer.seachText' }),
            'Annotation Edit text': intlFormatMessage({ id: 'pdfViewer.annotationEditText' }),
            'Line Thickness': intlFormatMessage({ id: 'pdfViewer.lineThickness' }),
            'Line Properties': intlFormatMessage({ id: 'pdfViewer.lineProperties' }),
            'Start Arrow': intlFormatMessage({ id: 'pdfViewer.startArrow' }),
            'End Arrow': intlFormatMessage({ id: 'pdfViewer.endArrow' }),
            'Line Style': intlFormatMessage({ id: 'pdfViewer.lineStyle' }),
            'Fill Color': intlFormatMessage({ id: 'pdfViewer.fillColor' }),
            'Line Color': intlFormatMessage({ id: 'pdfViewer.lineColor' }),
            'None': intlFormatMessage({ id: 'pdfViewer.none' }),
            'Open Arrow': intlFormatMessage({ id: 'pdfViewer.openArrow' }),
            'Closed Arrow': intlFormatMessage({ id: 'pdfViewer.closedArrow' }),
            'Round Arrow': intlFormatMessage({ id: 'pdfViewer.roundArrow' }),
            'Square Arrow': intlFormatMessage({ id: 'pdfViewer.squareArrow' }),
            'Diamond Arrow': intlFormatMessage({ id: 'pdfViewer.diamondArrow' }),
            'Cut': intlFormatMessage({ id: 'pdfViewer.cut' }),
            'Paste': intlFormatMessage({ id: 'pdfViewer.paste' }),
            'Delete Context': intlFormatMessage({ id: 'pdfViewer.deleteContext' }),
            'Properties': intlFormatMessage({ id: 'pdfViewer.properties' }),
            'Add Stamp': intlFormatMessage({ id: 'pdfViewer.addStamp' }),
            'Add Shapes': intlFormatMessage({ id: 'pdfViewer.addShapes' }),
            'Stroke edit': intlFormatMessage({ id: 'pdfViewer.strokeEdit' }),
            'Change thickness': intlFormatMessage({ id: 'pdfViewer.changeThickness' }),
            'Add line': intlFormatMessage({ id: 'pdfViewer.addLine' }),
            'Add arrow': intlFormatMessage({ id: 'pdfViewer.addArrow' }),
            'Add rectangle': intlFormatMessage({ id: 'pdfViewer.addRectangle' }),
            'Add circle': intlFormatMessage({ id: 'pdfViewer.addCircle' }),
            'Add polygon': intlFormatMessage({ id: 'pdfViewer.addPolygon' }),
            'Add Comments': intlFormatMessage({ id: 'pdfViewer.addComments' }),
            'Comments': intlFormatMessage({ id: 'pdfViewer.comments' }),
            'No Comments Yet': intlFormatMessage({ id: 'pdfViewer.noCommentsYet' }),
            'Accepted': intlFormatMessage({ id: 'pdfViewer.accepted' }),
            'Completed': intlFormatMessage({ id: 'pdfViewer.completed' }),
            'Cancelled': intlFormatMessage({ id: 'pdfViewer.cancelled' }),
            'Rejected': intlFormatMessage({ id: 'pdfViewer.rejected' }),
            'Leader Length': intlFormatMessage({ id: 'pdfViewer.leaderLength' }),
            'Scale Ratio': intlFormatMessage({ id: 'pdfViewer.scaleRatio' }),
            'Calibrate': intlFormatMessage({ id: 'pdfViewer.calibrate' }),
            'Calibrate Distance': intlFormatMessage({ id: 'pdfViewer.calibrateDistance' }),
            'Calibrate Perimeter': intlFormatMessage({ id: 'pdfViewer.calibratePerimeter' }),
            'Calibrate Area': intlFormatMessage({ id: 'pdfViewer.calibrateArea' }),
            'Calibrate Radius': intlFormatMessage({ id: 'pdfViewer.calibrateRadius' }),
            'Calibrate Volume': intlFormatMessage({ id: 'pdfViewer.calibrateVolume' }),
            'Depth': intlFormatMessage({ id: 'pdfViewer.depth' }),
            'Closed': intlFormatMessage({ id: 'pdfViewer.closed' }),
            'Round': intlFormatMessage({ id: 'pdfViewer.round' }),
            'Square': intlFormatMessage({ id: 'pdfViewer.square' }),
            'Diamond': intlFormatMessage({ id: 'pdfViewer.diamond' }),
            'Edit': intlFormatMessage({ id: 'pdfViewer.edit' }),
            'Comment': intlFormatMessage({ id: 'pdfViewer.comment' }),
            'Comment Panel': intlFormatMessage({ id: 'pdfViewer.commentPanel' }),
            'Set Status': intlFormatMessage({ id: 'pdfViewer.setStatus' }),
            'Post': intlFormatMessage({ id: 'pdfViewer.post' }),
            'Page': intlFormatMessage({ id: 'pdfViewer.page' }),
            'Add a comment': intlFormatMessage({ id: 'pdfViewer.addComment' }),
            'Add a reply': intlFormatMessage({ id: 'pdfViewer.addReply' }),
            'Import Annotations': intlFormatMessage({ id: 'pdfViewer.importAnnotations' }),
            'Export Annotations': intlFormatMessage({ id: 'pdfViewer.exportAnnotations' }),
            'Add': intlFormatMessage({ id: 'pdfViewer.add' }),
            'Clear': intlFormatMessage({ id: 'pdfViewer.clear' }),
            'Bold': intlFormatMessage({ id: 'pdfViewer.bold' }),
            'Italic': intlFormatMessage({ id: 'pdfViewer.italic' }),
            'Strikethroughs': intlFormatMessage({ id: 'pdfViewer.strikethroughs' }),
            'Underlines': intlFormatMessage({ id: 'pdfViewer.underlines' }),
            'Superscript': intlFormatMessage({ id: 'pdfViewer.superscript' }),
            'Subscript': intlFormatMessage({ id: 'pdfViewer.subscript' }),
            'Align left': intlFormatMessage({ id: 'pdfViewer.alignLeft' }),
            'Align right': intlFormatMessage({ id: 'pdfViewer.alignRight' }),
            'Center': intlFormatMessage({ id: 'pdfViewer.center' }),
            'Justify': intlFormatMessage({ id: 'pdfViewer.justify' }),
            'Font color': intlFormatMessage({ id: 'pdfViewer.fontColor' }),
            'Text Align': intlFormatMessage({ id: 'pdfViewer.textAlign' }),
            'Text Properties': intlFormatMessage({ id: 'pdfViewer.textProperties' }),
            'Draw Signature': intlFormatMessage({ id: 'pdfViewer.drawSignature' }),
            'Create': intlFormatMessage({ id: 'pdfViewer.create' }),
            'Font family': intlFormatMessage({ id: 'pdfViewer.fontFamily' }),
            'Font size': intlFormatMessage({ id: 'pdfViewer.fontSize' }),
            'Free Text': intlFormatMessage({ id: 'pdfViewer.freeText' }),
            'Import Failed': intlFormatMessage({ id: 'pdfViewer.importFailed' }),
            'File not found': intlFormatMessage({ id: 'pdfViewer.fileNotFound' }),
            'Export Failed': intlFormatMessage({ id: 'pdfViewer.exportFailed' }),
            'Dynamic': intlFormatMessage({ id: 'pdfViewer.dynamic' }),
            'Standard Business': intlFormatMessage({ id: 'pdfViewer.standardBusiness' }),
            'Sign Here': intlFormatMessage({ id: 'pdfViewer.signHere' }),
            'Custom Stamp': intlFormatMessage({ id: 'pdfViewer.customStamp' }),
            'InitialFieldDialogHeaderText': intlFormatMessage({ id: 'pdfViewer.initialFieldDialogHeaderText' }),
            'HandwrittenInitialDialogHeaderText': intlFormatMessage({ id: 'pdfViewer.handwrittenInitialDialogHeaderText' }),
            'SignatureFieldDialogHeaderText': intlFormatMessage({ id: 'pdfViewer.signatureFieldDialogHeaderText' }),
            'HandwrittenSignatureDialogHeaderText': intlFormatMessage({ id: 'pdfViewer.handwrittenSignatureDialogHeaderText' }),
            'Draw-hand Signature': intlFormatMessage({ id: 'pdfViewer.drawHandSignature' }),
            'Type Signature': intlFormatMessage({ id: 'pdfViewer.typeSignature' }),
            'Upload Signature': intlFormatMessage({ id: 'pdfViewer.uploadSignature' }),
            'Browse Signature Image': intlFormatMessage({ id: 'pdfViewer.browseSignatureImage' }),
            'Save Signature': intlFormatMessage({ id: 'pdfViewer.saveSignature' }),
            'Save Initial': intlFormatMessage({ id: 'pdfViewer.saveInitial' }),
            'Highlight context': intlFormatMessage({ id: 'pdfViewer.highlightContext' }),
            'Underline context': intlFormatMessage({ id: 'pdfViewer.underlineContext' }),
            'Strikethrough context': intlFormatMessage({ id: 'pdfViewer.strikethroughContext' }),
            'FormDesigner': intlFormatMessage({ id: 'pdfViewer.formDesigner' }),
            'SubmitForm': intlFormatMessage({ id: 'pdfViewer.submitForm' }),
            'Search text': intlFormatMessage({ id: 'pdfViewer.searchText' }),
            'Draw Ink': intlFormatMessage({ id: 'pdfViewer.drawInk' }),
            'Revised': intlFormatMessage({ id: 'pdfViewer.revised' }),
            'Reviewed': intlFormatMessage({ id: 'pdfViewer.reviewed' }),
            'Received': intlFormatMessage({ id: 'pdfViewer.received' }),
            'Confidential': intlFormatMessage({ id: 'pdfViewer.confidential' }),
            'Approved': intlFormatMessage({ id: 'pdfViewer.approved' }),
            'Not Approved': intlFormatMessage({ id: 'pdfViewer.notApproved' }),
            'Witness': intlFormatMessage({ id: 'pdfViewer.witness' }),
            'Initial Here': intlFormatMessage({ id: 'pdfViewer.initialHere' }),
            'Draft': intlFormatMessage({ id: 'pdfViewer.draft' }),
            'Final': intlFormatMessage({ id: 'pdfViewer.final' }),
            'For Public Release': intlFormatMessage({ id: 'pdfViewer.forPublicRelease' }),
            'Not For Public Release': intlFormatMessage({ id: 'pdfViewer.notForPublicRelease' }),
            'For Comment': intlFormatMessage({ id: 'pdfViewer.forComment' }),
            'Void': intlFormatMessage({ id: 'pdfViewer.void' }),
            'Preliminary Results': intlFormatMessage({ id: 'pdfViewer.preliminaryResults' }),
            'Information Only': intlFormatMessage({ id: 'pdfViewer.informationOnly' }),
            'Enter Signature as Name': intlFormatMessage({ id: 'pdfViewer.enterSignatureAsName' }),
            'Textbox': intlFormatMessage({ id: 'pdfViewer.textbox' }),
            'Password': intlFormatMessage({ id: 'pdfViewer.password' }),
            'Check Box': intlFormatMessage({ id: 'pdfViewer.checkBox' }),
            'Radio Button': intlFormatMessage({ id: 'pdfViewer.radioButton' }),
            'Dropdown': intlFormatMessage({ id: 'pdfViewer.dropdown' }),
            'List Box': intlFormatMessage({ id: 'pdfViewer.listBox' }),
            'Signature': intlFormatMessage({ id: 'pdfViewer.signature' }),
            'Delete FormField': intlFormatMessage({ id: 'pdfViewer.deleteFormField' }),
            'FormDesigner Edit text': intlFormatMessage({ id: 'pdfViewer.formDesignerEditText' }),
            'in': intlFormatMessage({ id: 'pdfViewer.in' }),
            'm': intlFormatMessage({ id: 'pdfViewer.m' }),
            'ft_in': intlFormatMessage({ id: 'pdfViewer.ftIn' }),
            'ft': intlFormatMessage({ id: 'pdfViewer.ft' }),
            'p': intlFormatMessage({ id: 'pdfViewer.p' }),
            'cm': intlFormatMessage({ id: 'pdfViewer.cm' }),
            'mm': intlFormatMessage({ id: 'pdfViewer.mm' }),
            'pt': intlFormatMessage({ id: 'pdfViewer.pt' }),
            'cu': intlFormatMessage({ id: 'pdfViewer.cu' }),
            'sq': intlFormatMessage({ id: 'pdfViewer.sq' }),
            'General': intlFormatMessage({ id: 'pdfViewer.general' }),
            'Appearance': intlFormatMessage({ id: 'pdfViewer.appearance' }),
            'Options': intlFormatMessage({ id: 'pdfViewer.options' }),
            'Textbox Properties': intlFormatMessage({ id: 'pdfViewer.textboxProperties' }),
            'Name': intlFormatMessage({ id: 'pdfViewer.name' }),
            'Tooltip': intlFormatMessage({ id: 'pdfViewer.tooltip' }),
            'Value': intlFormatMessage({ id: 'pdfViewer.value' }),
            'Form Field Visibility': intlFormatMessage({ id: 'pdfViewer.formFieldVisibility' }),
            'Read Only': intlFormatMessage({ id: 'pdfViewer.readOnly' }),
            'Required': intlFormatMessage({ id: 'pdfViewer.required' }),
            'Checked': intlFormatMessage({ id: 'pdfViewer.checked' }),
            'Show Printing': intlFormatMessage({ id: 'pdfViewer.showPrinting' }),
            'Formatting': intlFormatMessage({ id: 'pdfViewer.formatting' }),
            'Fill': intlFormatMessage({ id: 'pdfViewer.fill' }),
            'Border': intlFormatMessage({ id: 'pdfViewer.border' }),
            'Border Color': intlFormatMessage({ id: 'pdfViewer.borderColor' }),
            'Thickness': intlFormatMessage({ id: 'pdfViewer.thickness' }),
            'Max Length': intlFormatMessage({ id: 'pdfViewer.maxLength' }),
            'List Item': intlFormatMessage({ id: 'pdfViewer.listItem' }),
            'Export Value': intlFormatMessage({ id: 'pdfViewer.exportValue' }),
            'Dropdown Item List': intlFormatMessage({ id: 'pdfViewer.dropdownItemList' }),
            'List Box Item List': intlFormatMessage({ id: 'pdfViewer.listBoxItemList' }),
            'Delete Item': intlFormatMessage({ id: 'pdfViewer.deleteItem' }),
            'Up': intlFormatMessage({ id: 'pdfViewer.up' }),
            'Down': intlFormatMessage({ id: 'pdfViewer.down' }),
            'Multiline': intlFormatMessage({ id: 'pdfViewer.multiline' }),
            'Initial': intlFormatMessage({ id: 'pdfViewer.initial' }),
            'Export XFDF': intlFormatMessage({ id: 'pdfViewer.exportXFDF' }),
            'Import XFDF': intlFormatMessage({ id: 'pdfViewer.importXFDF' })
        }
    };
};