import React, { useEffect, useState, useCallback, FC } from 'react';
import styled, { css } from 'styled-components';
import { useDispatch } from 'react-redux';

import { IClusterStakeProperty, EStakeDetailsSubType } from '../../../../../entities/IClusterStake';
import { getReferentials } from '../../../../../actions/globalActions';
import { colorStack } from '../../../../../styleHelpers/colors';
import { fontSize } from '../../../../../styleHelpers/fontSizes';
import { SingleRadioItem } from '../../../../Common/Buttons/SingleRadioItem';
import { SelectInput } from '../../../../Common/SelectInput/SelectInput';
import { IValue } from '../../../../../entities/IPickers';
import { EDropDownType } from '../../../../Common/DropDown/DropDown';

const Wrapper = styled.div<{ radioSelect: boolean }>`
    ${props => props.radioSelect ? css`
        display: flex;
    ` : css`
        > span {
            font-size: ${fontSize[13]};
            color: ${colorStack.darkBlue};
        }
    `}
`;

type GetReferentials = ReturnType<typeof getReferentials>;

interface ISingleSelectContentProps {
    data: IClusterStakeProperty;
    propertieId: string;
    error: string;
    editStake: boolean;
    setEditingData(propertie: IClusterStakeProperty);
}

export const SingleSelectContent: FC<React.PropsWithChildren<ISingleSelectContentProps>> = ({ error, data, editStake, propertieId, setEditingData }) => {
    const dispatch = useDispatch();
    const [selectContent, setSelectContent] = useState<{ Name: string, id: string, Key: string }[]>(undefined);
    const [selectValue, setSelectValue] = useState<string>(data?.value?.text);
    const [selectedValue, setSelectedValue] = useState<IValue>(data?.value);
    const referentialIdentity = data.referentialIdentity;
    const referentialContext = data.configuration?.referentialContext;
    const referentialType = data.configuration?.referentialType;

    const changeSelect = useCallback((dataObj: IValue, option?: any) => {
        if (dataObj) {
            setSelectValue(dataObj.data?.name || dataObj.data?.Name);
            setSelectedValue(dataObj);
            setEditingData({
                ...data,
                // tslint:disable-next-line:no-null-keyword
                value: dataObj.data.id !== 'empty' ? referentialIdentity === 'Custom' ? ({ id: dataObj.data.id, Name: dataObj.data.Name, IsDeleted: false }) : ({ key: dataObj.data.id, value: dataObj.data.Name, uniqueKey: dataObj.data.Key }) : null,
                id: propertieId
            });
        }
    }, [selectContent, data, setEditingData, referentialIdentity, referentialContext, referentialType]);

    useEffect(() => {
        setSelectValue(data?.value?.text || data?.configuration?.value?.Value || data?.configuration?.value?.value);
        if (editStake) {
            const context = data.configuration?.referentialContext || (data.configuration as any)?.ReferentialContext;
            const type = data.configuration?.referentialType || (data.configuration as any)?.ReferentialType;
            setSelectedValue(data?.value || data?.configuration?.value || data?.configuration?.value);
            if (context && type) {
                dispatch<GetReferentials>(getReferentials('', type, context)).then(response => {
                    if (!data.value && (data.configuration?.value?.Name && !data.configuration?.value?.id)) {
                        const defaultValue = response.find(elem => elem.name === data.configuration.value.Name);
                        if (defaultValue) {
                            changeSelect({key: defaultValue.id, text: defaultValue.name, data: defaultValue });
                        }
                    }
                    setSelectContent(response.map(elem => ({
                        Name: elem.name,
                        id: elem.id,
                        Key: elem.Key
                    })));
                });
            }
        }
    }, [editStake]);

    return (
        <Wrapper radioSelect={data.subType === EStakeDetailsSubType.Radio}>
            {editStake ? (
                <>
                    {(selectContent && !data.subType) &&
                        <SelectInput
                            value={selectedValue?.key ? [selectedValue] : undefined}
                            errorMessage={error}
                            onChange={(option: IValue) => changeSelect(option, option)}
                            options={selectContent?.map(elem => ({
                                key: elem.id,
                                text: elem.Name,
                                data: {
                                    ...elem
                                }
                            }))}
                            type={EDropDownType.SEARCH}
                        />
                    }
                    {(selectContent && data.subType === EStakeDetailsSubType.Radio) &&
                        selectContent?.map(item => (
                            <SingleRadioItem
                                key={item.id}
                                id={item.id}
                                name={item.Name}
                                selectedItemId={selectedValue.data.Key}
                                onSelect={(res) => changeSelect({key: res.id, text: res.Name, data: res})}
                            />
                        ))
                    }
                </>
            ) : (
                <span>{selectValue}</span>
            )}
        </Wrapper>
    );
};
