import React, { FC, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { useFormikContext } from 'formik';
import { useDispatch } from 'react-redux';

import { ISponsoringData } from '../../../../../entities/ActionData/ISponsoringData';
import { TextComponent } from '../../../../Common/Inputs/TextComponent';
import { LabelWithEllipsis } from '../../../../Common/Label/Label';
import { MandatoryFieldStar } from '../../../../Common/MandatoryFieldStar/MandatoryFieldStar';
import { colorStack } from '../../../../../styleHelpers/colors';
import { fontSize } from '../../../../../styleHelpers/fontSizes';
import { inputsShadow } from '../../../../../styleHelpers/mixins/shadow';
import { RequiredInformation } from '../../../../Common/RequiredInformation/RequiredInformation';
import { getClusterTemplates } from '../../../../../actions/clustersActions';
import { IClusterTemplate } from '../../../../../entities/IClusters';
import { NewRT } from '../../../../Common/RichText/NewRT';
import IntlMessage from '../../../../Common/IntlMessage';

type GetClusterTemplates = ReturnType<typeof getClusterTemplates>;

const Wrapper = styled.div`
    width: 100%;
`;

const Row = styled.div`
    display: flex;
    margin: 0 0 1rem 0;
    > div {
        display: flex;
        flex-direction: column;
        width: 280px;
        margin: 0 2rem 0 0;
        &:nth-child(2) {
            width: auto;
        }
    }
`;

const FakeInput = styled.div<{ color?: string }>`
    ${inputsShadow()};
    border-radius: 4px;
    padding: 9px 1rem;
    font-size: ${fontSize[13]};
    background: ${colorStack.ligthGrey};
    ${(props) =>
        props.color &&
        css`
            background: ${props.color};
            text-align: center;
        `}
`;

const ProjectTitle = styled.div`
    margin: 0 0 1rem 0;
`;

interface IProps {
    organizationId: string;
}

export const Step3: FC<React.PropsWithChildren<IProps>> = props => {
    const dispatch = useDispatch();
    const formikContext = useFormikContext<ISponsoringData>();
    const { setFieldValue, values } = useFormikContext<ISponsoringData>();
    const [clusterTemplates, setClusterTemplates] = useState<IClusterTemplate[]>([]);

    useEffect(() => {
        formikContext.setFieldValue('step', 3);
        dispatch<GetClusterTemplates>(getClusterTemplates(props.organizationId))
            .then(response => {
                setClusterTemplates(response || []);
            });
    }, []);

    return (
        <Wrapper>
            <Row>
                <div>
                    <LabelWithEllipsis>
                        <IntlMessage id="sponsoring.popup.step3.text1" />
                        <MandatoryFieldStar />
                    </LabelWithEllipsis>
                    <TextComponent
                        value={values.formData.declarationName}
                        onChange={(value: string) => setFieldValue('formData.declarationName', value)}
                        error={values.formData.declarationName?.length > 100}
                        errorMsg={<IntlMessage id="sponsoring.popup.step3.title.error" />}
                    />
                </div>
                <div>
                    <LabelWithEllipsis>
                        <IntlMessage id="sponsoring.popup.step3.text2" />
                        <MandatoryFieldStar />
                    </LabelWithEllipsis>
                    <FakeInput color={clusterTemplates?.find(template => template.templateKey === values.formData.templateKey)?.color}>
                        {clusterTemplates?.find(template => template.templateKey === values.formData.templateKey)?.templateName}
                    </FakeInput>
                </div>
            </Row>
            <ProjectTitle>
                <IntlMessage id="sponsoring.popup.step3.text3" />
            </ProjectTitle>
            <LabelWithEllipsis>
                <IntlMessage id="sponsoring.popup.step3.text4" />
            </LabelWithEllipsis>
            <NewRT
                content={values.formData.description}
                onChange={(text) => setFieldValue('formData.description', text)}
            />
            <RequiredInformation
                topTextId="sponsoring.requiredInfo"
            />
        </Wrapper>
    );
};
