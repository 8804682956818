import React, { useCallback, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { useFormikContext } from 'formik';
import { useDispatch } from 'react-redux';

import { getProfileById } from '../../../../actions/profileActions';
import { getCompanyById } from '../../../../actions/legalEntitiesActions';
import { EProfileType, IUserBrief } from '../../../../entities/IGlobal';
import { ISIngleLegalEntity } from '../../../../entities/ILegalEntities';
import { TextComponent } from '../../../Common/Inputs/TextComponent';
import { RequiredInformation } from '../../../Common/RequiredInformation/RequiredInformation';
import { UsersPicker } from '../../../Common/Pickers/UsersPicker';
import { LegalEntitiesPicker } from '../../../Common/Pickers/LegalEntitiesPicker';
import { IWizardValues } from '../../../../entities/ILegalSupport';
import { LabelWithEllipsis } from '../../../Common/Label/Label';
import { EAlertType, EAlertTypeSchema } from '../../../../entities/IAlert';
import { Alert } from '../../../Common/Alert/Alert';
import IntlMessage, { useIntlMessage } from '../../../Common/IntlMessage';

type GetCompanyById = ReturnType<typeof getCompanyById>;

const Wrapper = styled.div`
    width: 100%;
    display: block;
    margin: 0 auto;
`;

const InnerWrapper = styled.div`
    position: relative;
    background: white;
    border-radius: 4px;
    margin-bottom: 26px;
    display: flex;
    flex-direction: column;
`;

const Row = styled.div`
    display: flex;
    flex-direction: row;
`;

const InputSection = styled.div<{ row?: boolean; text?: boolean; radio?: boolean }>`
    padding: 20px 28px;
    width: 50%;
    ${props => props.row && css`
        display: flex;
        flex-direction: row;
    `}
    ${props => props.text && css`
        display: inline-flex;
        align-items: center;
    `}
    ${props => props.radio && css`
        label {
            margin-right: 29px;
        }
    `}
`;

interface IProps {
    legalEntityId: string;
}

export const YourInformation = (props: IProps) => {
    const dispatch = useDispatch();
    const { intlFormatMessage } = useIntlMessage();
    const { setFieldValue, values, errors } = useFormikContext<IWizardValues>();

    useEffect(() => {
        setFieldValue('step', 3);
        if (values.formData.legalEntity === undefined) {
            props.legalEntityId && dispatch<GetCompanyById>(getCompanyById(props.legalEntityId)).then(res => {
                setFieldValue('formData.legalEntity', {
                    key: res.id,
                    text: res.name,
                    data: {...res, type: EProfileType.Company}
                });
            });
        }
    }, []);

    const fillUserInformation = useCallback(async (user: IUserBrief, manager?: boolean) => {
        if (user || (user && manager)) {
            const result = await dispatch(getProfileById(user?.id));
            setFieldValue(`formData.legalEntity`, undefined);
            setFieldValue(`formData.manager`, undefined);
            const entityInfo: ISIngleLegalEntity = result.cabinet ? await dispatch(getCompanyById(result.cabinet?.id)) : undefined;
            if (!manager) {
                setFieldValue(`formData.user`, result ? {
                    key: result.id,
                    text: `${result.firstName} ${result.lastName}`,
                    data: result
                    // tslint:disable-next-line:no-null-keyword
                } : null);
                setFieldValue(`formData.legalEntity`, entityInfo ? {
                    key: entityInfo.id,
                    text: entityInfo.name,
                    data: {...entityInfo, type: EProfileType.Company}
                    // tslint:disable-next-line:no-null-keyword
                } : null);
                setFieldValue(`formData.manager`, (user && result.manager) ? {
                    key: result.manager?.id,
                    text: `${result.manager?.firstName} ${result.manager?.lastName}`,
                    data: result.manager
                    // tslint:disable-next-line:no-null-keyword
                } : null);
            } else {
                setFieldValue(`formData.manager`, (user && result) ? {
                    key: user?.id,
                    text: `${user?.firstName} ${user?.lastName}`,
                    data: user
                    // tslint:disable-next-line:no-null-keyword
                } : null);
            }
        } else {
            !manager
                // tslint:disable-next-line:no-null-keyword
                ? setFieldValue(`formData.user`, null)
                :
                // tslint:disable-next-line:no-null-keyword
                setFieldValue(`formData.manager`, null);
        }
    }, [values.formData]);

    const fillLegalEntityInformation = useCallback((data: ISIngleLegalEntity) => {
        setFieldValue(`formData.legalEntity`, data ? ({
            key: data.id,
            text: data.name,
            data: {...data, type: EProfileType.Company}
            // tslint:disable-next-line:no-null-keyword
        }) : null);
    }, [values.formData.legalEntity]);

    const onChangeSelectedEntity = useCallback((element: { key: string; text: string; data: ISIngleLegalEntity; }[]) => {
        fillLegalEntityInformation(element?.[0]?.data);
    }, []);

    const fillNameInputOrUserInformation = useCallback((user: IUserBrief) => {
        // tslint:disable-next-line:no-null-keyword
        fillUserInformation(user);
    }, [values.formData]);

    const fillManagerInformation = useCallback((user: IUserBrief) => {
        // tslint:disable-next-line:no-null-keyword
        fillUserInformation(user, true);
    }, [values.formData]);

    const onChangeSelected = useCallback(async (element: { key: string; text: string; data: IUserBrief; }[]) => {
        fillNameInputOrUserInformation(element?.[0]?.data);
    }, []);

    const onChangeManagerSelected = useCallback((element: { key: string; text: string; data: IUserBrief; }[]) => {
        fillManagerInformation(element?.length > 0 ? element?.[0]?.data : undefined);
    }, []);

    return (
        <Wrapper>
            <InnerWrapper>
                <Row>
                    <InputSection>
                        <LabelWithEllipsis required>
                            <IntlMessage id="legalSupport.beneficiary.fullName" />
                        </LabelWithEllipsis>
                        <UsersPicker
                            value={values.formData.user ? [values.formData.user] : undefined}
                            onSelectElement={onChangeSelected}
                        />
                    </InputSection>
                    <InputSection>
                        <LabelWithEllipsis required>
                            <IntlMessage id="legalSupport.beneficiary.jobTitle" />
                        </LabelWithEllipsis>
                        <TextComponent
                            value={values.formData.jobTitle}
                            onChange={(value: string) => setFieldValue('formData.jobTitle', value)}
                        />
                    </InputSection>
                </Row>
                <Row>
                    <InputSection>
                        <LabelWithEllipsis required>
                            <IntlMessage id="legalSupport.beneficiary.legalEntity" />
                        </LabelWithEllipsis>
                        <LegalEntitiesPicker
                            value={values.formData.legalEntity ? [values.formData.legalEntity] : undefined}
                            onSelectElement={onChangeSelectedEntity}
                            filteredCompanies
                            hideButtons
                        />
                    </InputSection>
                    <InputSection>
                        <LabelWithEllipsis required>
                            <IntlMessage id="legalSupport.beneficiary.manager" />
                        </LabelWithEllipsis>
                        <UsersPicker
                            value={values.formData.manager ? [values.formData.manager] : undefined}
                            onSelectElement={onChangeManagerSelected}
                        />
                    </InputSection>
                </Row>
            </InnerWrapper>
            <RequiredInformation
                topTextId="legalSupport.requiredInfo"
            />
            <Alert
                withoutClose
                visible
                alertId=""
                withoutProgress
                typeSchema={EAlertTypeSchema.SECONDARY}
                type={EAlertType.INFO}
            >
                <span dangerouslySetInnerHTML={{__html: intlFormatMessage({id: 'legalSupport.requiredInfo.text'})}}/>
            </Alert>
        </Wrapper>
    );
};
