import React, { FC } from 'react';
import styled from 'styled-components';

import { INotification, ENotificationChannel, ILegalDocNotificationData } from '../../../entities/INotification';
import { Popup } from '../../Common/Popup/Popup';
import { colorStack } from '../../../styleHelpers/colors';
import IntlMessage from '../../Common/IntlMessage';

const Wrapper = styled.div`
    color: ${colorStack.darkBlue};
    cursor: initial;

    p {
        font-weight: normal;
        text-align: left;
        margin: 0 0 1rem 0;
    }
`;

interface IProps {
    notification: INotification;
    onClose();
}

export const LegalDocNotificationPopup: FC<React.PropsWithChildren<IProps>> = ({ onClose, notification }) => {
    const isLegalDoc = notification.channelId === ENotificationChannel.LegalDoc;
    const data = isLegalDoc && (notification?.data || {}) as ILegalDocNotificationData;

    return (
        <Wrapper>
            {isLegalDoc && (
                <Popup
                    isVisible
                    cancelAction={onClose}
                    title={notification.title}
                    cancelButtonText={<IntlMessage id="global.close" />}
                >
                    <p>{data.SystemInfo}</p>
                </Popup>
            )}
        </Wrapper>
    );
};
