import React, { CSSProperties, HTMLAttributes, InputHTMLAttributes, lazy, useCallback, useRef, useMemo, useState } from 'react';
import styled, { css } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconDefinition } from '@fortawesome/pro-light-svg-icons';
import { Link, useParams } from 'react-router-dom';

import { ESpinnerSize, Spinner } from '../Spinner/Spinner';
import { colorStack } from '../../../styleHelpers/colors';
import { fontSizeAndHeight } from '../../../styleHelpers/fontSizes';
import { isFontAwesomeIcon } from '../../../tools/generalTools';
import { useDropdown } from '../../../tools/hooks';
import { ContextMenu } from '../ContextMenu/ContextMenu';
import { StepsPopup } from '../StepsPopup/StepsPopup';
import { EPopupSizes } from '../../../entities/IGlobal';
import { ISelectedRows, IDirectoryContent, EFilesystemItemType } from '../../../entities/IClustersFilesystem';
import lazyBlock from '../../../tools/lazyBlock';
import { Button } from './NewButton';
import IntlMessage from '../IntlMessage';

const FileManager = lazyBlock(lazy(() => import('../../Organization/ClustersV2/ClusterDocuments/FileManager/FileManager'))) as any;

const setMargin = (margin?: string) => margin !== undefined && margin !== '' ?
    { '--button-margin': margin } as CSSProperties : null; // tslint:disable-line: no-null-keyword

const Wrapper = styled.button<{ bgColor: string, fontColor: string, borderColor: string, leftPadding: boolean, rightPadding: boolean, noContent: boolean, size: ButtonSize }>`
    border-radius: 8px;
    padding: 0 1rem;
    font-weight: 500;
    ${fontSizeAndHeight[16]};
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin: var(--button-margin, 0 4px);
    background: ${props => props.bgColor};
    color: ${props => props.fontColor};
    border: 2px solid ${props => props.borderColor};
    ${props => props.size && css`
        height: ${`${props.size}px`};
    `}
    ${props => props.rightPadding && css`
        padding-right: 4px;
    `}
    ${props => props.leftPadding && css`
        padding-left: 4px;
    `}
    ${props => props.noContent && css`
        padding: 0;
    `}

    > a, > span {
        color: inherit;
        width: 100%;
        text-decoration:none;
    }
    > a {
        display: flex;
    }
    &:disabled {
        background: ${colorStack.whiteGrey};
        border-color: ${colorStack.whiteGrey};
        color: ${colorStack.darkGrey};
        cursor: default;
    }
`;

const ContentBox = styled.div<{ leftMargin: boolean, rightMargin: boolean }>`
    position: relative;
    white-space: nowrap;
    ${props => props.rightMargin && css`
        margin-right: .5rem;
    `}
    ${props => props.leftMargin && css`
        margin-left: .5rem;
    `}
    > div {
        top: 32px;
        right: 0;
    }
`;

const IcoBox = styled.div`
    padding: 0 8px 0 8px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

interface IButtonIconProps {
    ico?: React.ReactNode | IconDefinition;
    loading?: boolean;
}

type ButtonSize = 16 | 24 | 32 | 40 | 48 | 56 | 64 | 72 | 80 | 88 | 96 | 104 | 112 | 120 | 128 | 136 | 144 | 152 | 160 | 168 | 176 | 184 | 192 | 200;

const ButtonIcon = ({ ico, loading }: IButtonIconProps) => (ico || loading) ?
    <IcoBox>
        {isFontAwesomeIcon(ico) ? <FontAwesomeIcon icon={ico} /> : (loading ? <Spinner size={ESpinnerSize.XSMALL} /> : ico)}
    </IcoBox> : null; // tslint:disable-line: no-null-keyword

interface IStyledButtonProps {
    margin?: string;
    bgColor?: string;
    fontColor?: string;
    borderColor?: string;
    size?: ButtonSize;
}

interface ILinkButtonProps extends IStyledButtonProps, HTMLAttributes<HTMLDivElement> {
    ico?: React.ReactNode | IconDefinition;
    rightIco?: React.ReactNode | IconDefinition;
    href?: string;
    externalLink?: boolean;
    to?: string;
    dataLc?: string;
    newWindow?: boolean;
    size?: ButtonSize;
    onClick?(e: React.MouseEvent<HTMLElement>); // overrides div onClick to make it compatible with Link onClick
}

export const LinkButton = ({ ico, rightIco, dataLc, margin = '0 4px', bgColor = colorStack.blue, fontColor = colorStack.white, borderColor = 'transparent', ...props }: ILinkButtonProps) => {
    const icon = useMemo(() => <ButtonIcon ico={ico} />, [ico, props.children]);
    const righticon = useMemo(() => <ButtonIcon ico={rightIco} />, [ico, props.children]);

    return (
        <Wrapper
            as="div"
            data-lc={dataLc}
            {...props}
            size={props.size || 32}
            leftPadding={!!ico}
            rightPadding={!!rightIco}
            bgColor={bgColor}
            noContent={!props.children}
            fontColor={fontColor}
            borderColor={borderColor}
            style={setMargin(margin)}
        >
            {props.externalLink ? (
                <a href={props.to || props.href} target="_blank" rel="noopener noreferrer">
                    {icon}
                    {props.children &&
                        <ContentBox leftMargin={!!ico} rightMargin={!!rightIco}>
                            {props.children}
                        </ContentBox>
                    }
                    {righticon}
                </a>) :
                props.href ? (
                    <a href={props.href} target={props.newWindow && '_blank'} >
                        {icon}
                        {props.children &&
                            <ContentBox leftMargin={!!ico} rightMargin={!!rightIco}>
                                {props.children}
                            </ContentBox>
                        }
                        {righticon}
                    </a>) :
                    props.to ? (
                        <Link to={props.to} onClick={props.onClick}>
                            {icon}
                            {props.children &&
                                <ContentBox leftMargin={!!ico} rightMargin={!!rightIco}>
                                    {props.children}
                                </ContentBox>
                            }
                            {righticon}
                        </Link>) : (
                        <a>
                            {icon}
                            {props.children &&
                                <ContentBox leftMargin={!!ico} rightMargin={!!rightIco}>
                                    {props.children}
                                </ContentBox>
                            }
                            {righticon}
                        </a>
                    )}
        </Wrapper>
    );
};

const BrowseWrapper = styled.div`
    position: relative;
`;

const UploadFromComputer = styled.div`
    overflow: hidden;
    position: relative;
    color: ${colorStack.content};
    ${fontSizeAndHeight[16]};
    padding: .5rem;
    font-weight: 500;
    width: 100%;
    text-align: left;
    transition: all .3s;
    cursor: pointer;
    margin: 0!important;
    span {
        white-space: nowrap;
    }
    &:hover {
        background: ${colorStack.whiteBlue};
    }
    input[type="file"] {
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
    }
`;

interface IFileButtonProps extends InputHTMLAttributes<HTMLInputElement> {
    currentClusterName?: string;
    dataLc?: string;
    importFile?(file: IDirectoryContent, closeImportPopup: () => void, path: string[]);
}

export const FileButton = ({ importFile, dataLc, children, ...props }: IFileButtonProps) => {
    const [wrapperRef, dropdownOpen, toggleDropdown, closeDropdown] = useDropdown();
    const [openClusterDocument, setOpenClusterDocument] = useState<boolean>(false);
    const [selectedFile, setSelectedFile] = useState<IDirectoryContent>(undefined);
    const [isValidated, setIsValidated] = useState<boolean>(false);
    const [path, setPath] = useState<string[]>(undefined);
    const inputRef = useRef<HTMLInputElement>();
    const { id } = useParams<{ id: string }>();

    const onChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        props.onChange?.(e);
        inputRef?.current && (inputRef.current.value = '');
        closeDropdown();
    }, [props.onChange]);

    const saveHandler = useCallback((): Promise<boolean> => {
        return new Promise((resolve) => {
            importFile(selectedFile, () => setOpenClusterDocument(false), path).then(() => {
                resolve(true);
                closeDropdown();
            });
        });
    }, [selectedFile, importFile, path]);

    const openClusterDocPopup = useCallback(() => {
        setOpenClusterDocument(state => !state);
        closeDropdown();
    }, []);

    const getFileToImport = useCallback((selectedRows: ISelectedRows<IDirectoryContent>, pathArray: string[]) => {
        const selected = Object.values(selectedRows || {}).filter(item => !!item);
        setIsValidated(selected?.length < 2 && selected?.[0]?.item.type === EFilesystemItemType.File);
        setPath(pathArray);
        setSelectedFile(selected?.[0]?.item);
    }, [setSelectedFile]);

    const openContextMenu = useCallback(() => {
        if (!dropdownOpen) {
            toggleDropdown();
        }
    }, [dropdownOpen]);

    return (
        <BrowseWrapper ref={wrapperRef} onClick={openContextMenu} data-lc={dataLc || 'js-lc-browse-button'}>
            <Button>
                {children || <IntlMessage id="global.browse" />}
            </Button>
            {dropdownOpen &&
                <ContextMenu
                    links={[{
                        name: <IntlMessage id="clusterFilesystem.uploadFromMyComputer" />,
                        customRender: <UploadFromComputer>
                            <IntlMessage id="clusterFilesystem.uploadFromMyComputer" tagName="span" />
                            <input ref={inputRef} type="file" id="attachment-from-button" {...props} onChange={onChange} />
                        </UploadFromComputer>,
                        action: closeDropdown,
                        visible: true
                    }, {
                        name: <IntlMessage id="clusterFilesystem.uploadFromMyCluster" tagName="span" />,
                        visible: !!importFile,
                        action: openClusterDocPopup
                    }]}
                />
            }
            <StepsPopup
                showPopup={openClusterDocument}
                size={EPopupSizes.MONSTER}
                title={<IntlMessage id="clusterFilesystem.importDocumentToOverview" />}
                confirmDisabled={false}
                isFormValid={!!selectedFile && isValidated}
                finishHandler={openClusterDocPopup}
                confirmHandler={saveHandler}
                sendButtonText={<IntlMessage id="global.import" />}
                lastThanksScreen={1}
                sendButtonType="button"
                steps={[
                    { content: <FileManager withOutTopMenu getFile={getFileToImport} onThePopup outerCluster={{ id: id, name: props.currentClusterName }} /> }
                ]}
            />
        </BrowseWrapper>
    );
};
