import React, { FC, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import { getFilteredCompanies } from '../../../actions/adminActions';
import { searchLegalEntities } from '../../../actions/clustersActions';
import { getAllDirectoryLegalEntities, getAllLegalEntities } from '../../../actions/legalEntitiesActions';
import { ECompanyGroupKey } from '../../../entities/ICompany';
import { EProfileType } from '../../../entities/IGlobal';
import { useOrganization } from '../../../tools/organizationHooks';
import { IPickerProps, CorePicker, EButtonsTypes } from './CorePicker';

type SearchLegalEntities = ReturnType<typeof searchLegalEntities>;
type GetFilteredCompanies = ReturnType<typeof getFilteredCompanies>;
type GetAllLegalEntities = ReturnType<typeof getAllLegalEntities>;
type GetAllDirectoryLegalEntities = ReturnType<typeof getAllDirectoryLegalEntities>;

type IProps = IPickerProps & {
    orgId?: string;
    filteredCompanies?: boolean;
    allCompanies?: boolean;
    filterForDirectory?: string;
};

const Wrapper = styled.div``;

export const LegalEntitiesPicker: FC<React.PropsWithChildren<IProps>> = props => {
    const dispatch = useDispatch();
    const { organization } = useParams<{ organization: string }>();
    const { currentOrganization } = useOrganization(organization);

    const getData = useCallback((query: string) => {
        if (props.filteredCompanies) {
            return dispatch<GetFilteredCompanies>(getFilteredCompanies(query, 100, ECompanyGroupKey.OurGroup)).then(data => {
                return data?.map(entity => ({
                    key: entity.id,
                    text: entity.name,
                    data: { ...entity, type: EProfileType.Company }
                }));
            }).catch(() => []);
        } else if (props.allCompanies) {
            return dispatch<GetAllLegalEntities>(getAllLegalEntities(query, undefined, 100, false, props.orgId)).then(res => {
                return res.items.map(elem => ({
                    key: elem.id,
                    data: { ...elem, type: EProfileType.Company },
                    text: elem.name
                }));
            }).catch(() => []);
        } else if (props.filterForDirectory) {
            return dispatch<GetAllDirectoryLegalEntities>(getAllDirectoryLegalEntities(query, { TypeOfLegalEntity: [props.filterForDirectory] }, 100)).then(data => {
                return data?.items?.map(entity => ({
                    key: entity.id,
                    text: entity.name,
                    data: { ...entity, type: EProfileType.Company }
                }));
            });
        } else {
            return dispatch<SearchLegalEntities>(searchLegalEntities(currentOrganization?.id || props.orgId, query, 50)).then(res => {
                return res.items.map(elem => ({
                    key: elem.id,
                    data: { ...elem, type: EProfileType.Company },
                    text: elem.name
                }));
            }).catch(() => []);
        }
    }, [props.orgId, props.filterForDirectory, props.filteredCompanies, props.allCompanies]);

    return (
        <Wrapper>
            <CorePicker {...props} getData={getData} buttonsType={props.buttonsType || EButtonsTypes.Company} />
        </Wrapper>
    );
};