import { Dispatch } from 'redux';
import queryString from 'query-string-for-all';

import * as actionTypes from './actionTypes/organizationsTypes';
import * as actionTypess from '../actions/actionTypes/exploreTypes';
import * as contextActions from './contextActions';
import {
    IOrganizationMember, EOrganizationRole, IOrganization, IOrganizationMembers, IOrganizationAccount, IOrganizationLink, ILatestActivitiy, IOrgModuleVisibility,
    IOrganizationOwner, IOrganizationModule, IOrganizationTag, IOrganizationType, IOrganizationOption, ICallToAtion, ETypeOfOrganization, IOrganizationDataTable, ELegalDocType
} from '../entities/IOrganization';
import { IGroupsAndPeople } from '../entities/IAdmin';
import { IOrganizationContext } from '../entities/IContext';
import { IRoleManagerUser, IRoleManagerEmailInvitation } from '../entities/IRoleManager';
import { Http } from '../tools/http';
import { action, IDispatch } from '../tools/reduxTools';
import { IState } from '../reducers';
import { ISearch } from '../entities/ISearch';
import { IModuleMember, OrganizationPermissions } from '../entities/IPermissions';
import { ISearchLawyer } from '../entities/ISearchLawyer';
import { CampaignDto, ICampaignListElem, ISingleCampaign } from '../entities/ICampaigns';
import { ISearchResults } from '../entities/IFilters';
import { IReferencial, IViewReferential } from '../entities/IGlobal';
import { IValue } from '../entities/IPickers';
import { ELcid } from '../entities/ILanguage';
import { IApprovalDatatable, ITemplateDataGridSettings } from '../entities/IClusters';
import { IFacetsSearchResponse } from '../entities/IEntity';
import { formDataEncodeAndAppendFile } from '../tools/files';

export type IOrganizationSearchResult = {
    emailWithoutAtSign: string;
    id: string;
    name: string;
    picture: string;
    type: string;
};

export const organizationContactsPickerSearch = (query: string = '') => action<Promise<IOrganizationSearchResult[]>>((dispatch, getState: () => IState, http: Http) => {

    return http.permissionsApiPost(`/groups/search-items?size=10&query=${query}`, {}, undefined, 15000).then((results: IGroupsAndPeople) => {
        return results.items;
    });
});

export const getUsersFromLc = (Search: string) => ((dispatch, getState, http: Http) => {
    const { pageContext } = getState().context;
    const { userLanguage } = getState().language;
    const digitalWorkplaceUrl = pageContext.web.absoluteUrl;
    const body = {
        Search,
        isMarketplaceSearch: true,
        IsGlobalOrNetworkDirectory: true,
        Selection: [],
        Orderby: 'LegalScore desc, search.score() desc, LastName asc, FirstName asc',
        Top: 50,
        Skip: 0,
        RelationIds: [],
        ExpertisesIds: [],
        SpecialitiesIds: [],
        CountriesIds: [],
        RoleFunctionListIds: [],
        CompaniesIds: [],
        BusinessSectorsIds: [],
        BarAdmissionIds: [],
        LanguageIds: [],
        IsPerfectMatch: true,
        UserIds: [],
        HideCurrentUser: false
    };
    const url = `/users/azure-search?digitalWorkplaceUrl=${encodeURIComponent(digitalWorkplaceUrl)}&lcid=${userLanguage}`;
    return http.apiPost(url, body)
        .then((searchResult: any) => {
            return {
                persons: ((searchResult && searchResult.value) || []).map((profile: ISearchLawyer) => {
                    const persona = {
                        text: `${profile?.FirstName} ${profile?.LastName}`,
                        imageInitials: `${profile?.FirstName.slice(0, 1)}${profile?.LastName.slice(0, 1)}`,
                        imageUrl: profile?.Picture,
                        secondaryText: [profile?.JobTitle, profile?.Cabinet].filter(val => val).join(' - '),
                        id: profile?.id
                    };
                    return persona;
                }), searchResult: searchResult.value
            };
        }).catch(err => {
            throw new Error(err);
        });
}) as any;

export const getOrganizationMembersSearch = (organizationId: string, query: string = '', size = '50') => action<Promise<IOrganizationMember[]>>((dispatch: Dispatch, getState, http: Http) => {
    const params = queryString.stringify({
        size: size,
        query: query,
        PrincipalType: 'Personal'
    });
    return http.organizationsApiGet(`/organizations/${organizationId}/members?${params}`).then((response) => {
        const members = ((response && response.items) || []) as IOrganizationMember[];
        return members;
    });
});

export const getOrganizationMembers = (organizationId: string) => action<Promise<IOrganizationMember[]>>((dispatch: Dispatch, getState, http: Http) => {
    return http.organizationsApiGet(`/organizations/${organizationId}/members?size=9999`).then((response) => {
        return (response && response.items) || [];
    });
});

export const addMemberToOrganization = (organizationId: string, message: string, member?: IRoleManagerUser, email?: IRoleManagerEmailInvitation) => action<Promise<any>>((dispatch, getState: () => IState, http: Http) => {
    return http.organizationsApiPost(`/organizations/${organizationId}/members`, {
        ...(member || email),
        message
    });
});

export const removeMemberFromOrganization = (organizationId: string, memberId: string) => action<Promise<any>>((dispatch, getState: () => IState, http: Http) => {
    return http.organizationsApiDelete(`/organizations/${organizationId}/members/${memberId}`);
});

export const setOrganizationsName = (name: string) => ({
    type: actionTypes.SET_ORGANIZATIONS_NAME,
    name
});

export const clearOrgsLastMembers = () => ({
    type: actionTypes.CLEAR_ORGS_LAST_MEMBERS
});

export const acceptOrDeclineInvitationToOrganization = (organizationId: string, accepted: boolean) => action<Promise<any>>((dispatch, getState: () => IState, http: Http) => {
    return http.organizationsApiPost(`/organizations/${organizationId}/invitation?isAccepted=${accepted}`);
});

export const uploadOrganizationsLogo = (file: File) => action<Promise<string>>((dispatch, getState: () => IState, http: Http) => {
    const form = new FormData();
    formDataEncodeAndAppendFile(form, 'file', file);

    return http.organizationsApiPost(`/Organizations/upload-logo`, form).then((url: string) => {
        return url;
    });
});

export const updateOrganizations = (updatedOrganization: IOrganizationContext) => action<Promise<any>>((dispatch, getState: () => IState, http: Http) => {
    const body = {
        name: updatedOrganization.details.name,
        description: updatedOrganization.details.description,
        isLawFirm: updatedOrganization.details.isLawFirm,
        logo: updatedOrganization.details.logo,
        businessSectors: updatedOrganization.details.businessSectors,
        company: updatedOrganization.details.company,
        urlName: updatedOrganization.details.urlName,
        clientCrmId: updatedOrganization.clientCrmId,
        tenantId: updatedOrganization.details.tenantId,
        defaultCurrency: updatedOrganization.details.defaultCurrency
    } as IOrganization;

    return http.organizationsApiPut(`/Organizations/${updatedOrganization.id}`, body);
});

export const orgsSearch = (organizationId: string, search: string, searchArrays: any, page: number, size: number) => action<Promise<undefined>>((dispatch, getState: () => IState, http: Http) => {
    const { userLanguage } = getState().language;
    const query = queryString.stringify({
        page: page,
        size: size,
        lcid: userLanguage
    });
    const body = {
        search,
        departments: searchArrays.departments,
        teams: searchArrays.teams,
        languages: searchArrays.languages,
        barAdmissions: searchArrays.barAdmissions,
        expertises: searchArrays.expertises,
        competences: searchArrays.competences,
        businessSectors: searchArrays.businessSectors,
        countries: searchArrays.countries,
        talents: searchArrays.talents,
        roleFunctions: searchArrays.roleFunctions,
        roleCategories: searchArrays.roleCategories,
        firstReferentials: searchArrays.firstReferentials,
        secondReferentials: searchArrays.secondReferentials,
        thirdReferentials: searchArrays.thirdReferentials
    };

    return http.profilesApiPost(`/organization/${organizationId}/users/search?${query}`, body).then((result: ISearch) => {
        dispatch({
            type: actionTypes.GET_ORGS_SEARCH,
            orgsSearch: result
        });
    });
});

export const getCurrentPOAHolders = (organizationId: string, search?: string, searchArrays?: any, page: number = 0, size?: number) => action<Promise<ISearch>>((dispatch: Dispatch, getState, http: Http) => {
    const { userLanguage } = getState().language;
    const query = queryString.stringify({
        lcid: userLanguage,
        page: page,
        size: 9999
    });
    const body = {
        search,
        legalEntities: searchArrays?.legalEntities,
        areaOfResponsibilities: searchArrays?.areaOfResponsibilities,
        items: searchArrays?.items,
        powers: searchArrays?.powers,
        financialLimit: searchArrays?.financialLimits?.toString(),
        levels: searchArrays?.levels?.map(elem => parseInt(elem, 10)),
        canSubdelegate: searchArrays?.canSubdelegate?.[0],
        firstReferentials: searchArrays?.firstReferentials,
        secondReferentials: searchArrays?.secondReferentials,
        thirdReferentials: searchArrays?.thirdReferentials,
        referenceItemStatuses: searchArrays?.referenceItemStatuses,
        delegates: searchArrays?.delegates,
        referenceItemPrivacies: searchArrays?.referenceItemPrivacies,
        delegators: searchArrays?.delegators,
        referenceItemPeopleInCharge: searchArrays?.referenceItemPeopleInCharge
    };

    return http.profilesApiPost(`/organizations/${organizationId}/poa-mandates/holders/search?${query}`, body)
        .then((response: ISearch) => {
            dispatch({
                type: actionTypes.GET_ORGS_SEARCH,
                orgsSearch: response
            });
            return response;
        });
});

export const getExploreOrgDirectoryUsers = (organisationId: string, page: number = 0, size: number = 48, body, loadSingleData: boolean) => action<Promise<ISearchResults>>((dispatch, getState, http) => {
    const { userLanguage } = getState().language;
    const query = queryString.stringify({
        page: page,
        size: size,
        lcid: userLanguage
    });
    return http.profilesApiPost(`/organization/${organisationId}/users/search?${query}`, body);
});

export const getLastOrgsMembers = (organizationId: string) => action<Promise<IOrganizationMembers>>((dispatch, getState: () => IState, http: Http) => {
    return http.organizationsApiGet(`/organizations/${organizationId}/members/last?size=9999`).then((members: IOrganizationMembers) => {
        dispatch({
            type: actionTypes.GET_LAST_ORGS_MEMBERS,
            members: members.items
        });
        return members;
    });
});

export const getAllOrganizations = () => action<Promise<IOrganizationContext[]>>(async (dispatch, getState: () => IState, http: Http) => {
    const { userLanguage } = getState().language;
    const response = await http.organizationsApiGet(`/Organizations?size=99999&lcid=${userLanguage}`);
    return (response && response.items) || [];
});

export const getOrganizationById = (orgId: string) => action<Promise<IOrganizationContext>>(async (dispatch, getState: () => IState, http: Http) => {
    return http.organizationsApiGet(`/Organizations/${orgId}/as-admin`);
});

interface IFilters {
    dataRange?: [];
    organizationType?: ETypeOfOrganization,
    ownerIds?: string[];
    query: string;
}

export const getOrganizationsDatatable = (filters: IFilters, page?: number) => action<Promise<{ items: IOrganizationDataTable[], facets: Record<string, any[]>, count: number }>>(async (dispatch, getState, http) => {
    const { userLanguage } = getState().language;
    return http.organizationsApiPost(`/organizations/as-datatable?lcid=${userLanguage}&page=${page || 0}`, { ...filters, query: filters.query?.[0] });
});

export const createOrganization = (name: string, urlName: string, owners: IValue[], organizationType: IOrganizationType, defaultCurrency: IViewReferential, logo: string, description: string, clientName?: string, legalDocType?: ELegalDocType, clientCrmId?: string) => action<Promise<any>>((dispatch, getState: () => IState, http: Http) => new Promise((resolve, reject) => {
    http.organizationsApiPost('/Organizations', {
        organization: {
            name,
            logo,
            businessSectors: [],
            urlName,
            clientCrmId,
            description,
            tags: [],
            organizationType,
            defaultCurrency
        },
        clientName,
        legalDocType,
        owners: owners.map((owner) => ({
            id: owner.data.id,
            picture: owner.data.picture,
            firstName: owner.text.split(' ')[0],
            lastName: owner.text.split(' ')[1],
            position: owner.data.position,
            role: EOrganizationRole.Owner
        }))
    }).then(response => {
        dispatch(contextActions.getCurrentUserContextOrganizations());
        resolve(response);
    }).catch(error => {
        reject(error);
    });
}));

export const updateOrganization = (organizationId: string, organization: IOrganizationContext) => action<Promise<undefined>>(async (dispatch, getState, http) => {
    await http.organizationsApiPut(`/Organizations/${organizationId}/as-admin`, {
        ...organization.details,
        clientCrmId: organization.clientCrmId
    });
});

export const getAllOrganizationAccounts = (organizationId: string) => action<Promise<IOrganizationAccount[]>>(async (dispatch, getState, http) => {
    const accounts = await http.organizationsApiGet(`/organizations/${organizationId}/accounts`);
    return accounts || [];
});

export const createNewAccountForOrganization = (organizationId: string, name: string) => action<Promise<undefined>>(async (dispatch, getState, http) => {
    await http.organizationsApiPost(`/organizations/${organizationId}/accounts?newAccountName=${name}`);
});

export const removeAccountFromOrganization = (organizationId: string, accountId: string) => action<Promise<undefined>>(async (dispatch, getState, http) => {
    await http.organizationsApiDelete(`/organizations/${organizationId}/accounts?removedAccountId=${accountId}`);
});

export const getAllTags = (organizationId: string) => action<Promise<IOrganizationTag[]>>(async (dispatch, getState, http) => {
    const orgTags = await http.organizationsApiGet(`/organizations/${organizationId}/tags`);
    return orgTags || [];
});

export const addNewtag = (organizationId: string, newTag: string) => action<Promise<undefined>>(async (dispatch, getState, http) => {
    await http.organizationsApiPost(`/organizations/${organizationId}/tags?newTag=${newTag}`);
});

export const removeUsefulLink = (organizationId: string, id: string) => action<Promise<undefined>>(async (dispatch, getState, http) => {
    await http.organizationsApiDelete(`/organizations/${organizationId}/links/${id}`);
});

export const updateUsefulLink = (organizationId: string, id: string, name: string, url: string) => action<Promise<undefined>>(async (dispatch, getState, http) => {
    const body = {
        name,
        url
    };
    await http.organizationsApiPut(`/organizations/${organizationId}/links/${id}`, body);
});

export const getUsefulLinks = (organizationId: string) => action<Promise<undefined>>(async (dispatch: Dispatch, getState, http) => {
    await http.organizationsApiGet(`/organizations/${organizationId}/links`).then((response: { links: IOrganizationLink[], permissions: string[] }) => {
        dispatch({
            type: actionTypes.GET_USEFUL_LINKS,
            usefulLinks: response.links
        });
        dispatch<IDispatch<actionTypes.IOrganizationsTypes['UPDATE_ORGANIZATION_PERMISSIONS']>>({
            type: actionTypes.UPDATE_ORGANIZATION_PERMISSIONS,
            permissions: response.permissions as OrganizationPermissions[],
            organizationId
        });
    });
});

export const addUsefulLink = (organizationId: string, name: string, url: string) => action<Promise<undefined>>(async (dispatch, getState, http) => {
    const body = [{
        name,
        url
    }];
    await http.organizationsApiPost(`/organizations/${organizationId}/links/add`, body);
});

export const getOrganizationActivities = (organizationId: string) => action<Promise<ILatestActivitiy[]>>(async (dispatch, getState, http) => {
    const response = await http.messagingApiGet(`/organizations/${organizationId}/activities?size=10`);
    return (response && response.items || []);
});

export const markActivityAsRead = (organizationId: string, activityId: string) => action<Promise<undefined>>(async (dispatch, getState, http) => {
    await http.messagingApiPut(`/organizations/${organizationId}/activities/${activityId}/mark-as-read`);
});

export const markAllActivitiesAsRead = (organizationId: string) => action<Promise<undefined>>(async (dispatch, getState, http) => {
    await http.messagingApiPut(`/organizations/${organizationId}/activities/mark-as-read`);
});

export const getVisibleModulesForOrganization = (organizationId: string) => action<Promise<IOrgModuleVisibility[]>>((dispatch, getState, http) => {
    return http.organizationsApiGet(`/organizations/${organizationId}/modules`);
});

export const getVisibleOptionsForOrganization = (organizationId: string) => action<Promise<IOrganizationOption[]>>(async (dispatch, getState, http) => {
    return http.organizationsApiGet(`/organizations/${organizationId}/options`);
});

export const updateModuleVisibility = (organizationId: string, moduleId: string, visibility: boolean) => action<Promise<undefined>>(async (dispatch, getState, http) => {
    await http.organizationsApiPut(`/organizations/${organizationId}/modules/${moduleId}`, { visibility });
});

export const updateOptionVisibility = (organizationId: string, organizationOptionKey: number, value: boolean) => action<Promise<undefined>>(async (dispatch, getState, http) => {
    await http.organizationsApiPut(`/organizations/${organizationId}/options`, { organizationId, organizationOptionKey, value });
});

export const getOrganizationOwners = (organizationId: string) => action<Promise<IOrganizationOwner[]>>((dispatch, getState, http) => {
    return http.organizationsApiPost(`/organizations/${organizationId}/owners`);
});

export const addOrganizationOwner = (organizationId: string, member: IOrganizationMember) => action<Promise<undefined>>(async (dispatch, getState, http) => {
    await http.organizationsApiPost(`/organizations/${organizationId}/owners/add`, member);
});

export const deleteOrganizationOwner = (organizationId: string, ownerId: string) => action<Promise<undefined>>(async (dispatch, getState, http) => {
    await http.organizationsApiDelete(`/organizations/${organizationId}/owners/${ownerId}`);
});

export const getOrganizationModules = (organizationId: string) => action<Promise<IOrganizationModule[]>>(async (dispatch, getState, http) => {
    const response = await http.organizationsApiGet(`/organizations/${organizationId}/modules`);
    return response instanceof Array ? response : [];
});

export const addMemberToOrgModule = (organizationId: string, moduleId: string, member: IModuleMember) => action<Promise<void>>((dispatch, getState, http) => {
    return http.organizationsApiPost(`/organizations/${organizationId}/modules/${moduleId}/members`, member);
});

export const updateOrgModuleMemberRole = (organizationId: string, moduleId: string, memberId: string, role: number | string) => action<Promise<void>>(async (dispatch, getState, http) => {
    await http.organizationsApiPut(`/organizations/${organizationId}/modules/${moduleId}/members/${memberId}?role=${role}`);
});

export const removeOrgModuleMember = (organizationId: string, moduleId: string, memberId: string) => action<Promise<void>>(async (dispatch, getState, http) => {
    await http.organizationsApiDelete(`/organizations/${organizationId}/modules/${moduleId}/members/${memberId}`);
});

export const getOrganizationByTenant = (tenantId: string) => action<Promise<IOrganizationContext>>((dispatch: Dispatch, getState, http: Http) => {
    return http.organizationsApiGet(`/organizations/by-tenant/${tenantId}`).then((response) => {
        return response;
    });
});

export const getOrganizationTypes = () => action<Promise<IOrganizationType[]>>((dispatch: Dispatch, getState, http: Http) => {
    const { userLanguage } = getState().language;
    return http.organizationsApiGet(`/organizations/types?lcid=${userLanguage}`);
});

export const updateOrganizationPermissions = (permissions: OrganizationPermissions[], organizationId: string): IDispatch<actionTypes.IOrganizationsTypes['UPDATE_ORGANIZATION_PERMISSIONS']> => ({
    type: actionTypes.UPDATE_ORGANIZATION_PERMISSIONS,
    permissions,
    organizationId
});

export const getListOfMembersOfOrganization = (organizationId: string, query?: string, size?: number, page?: number) => action<Promise<IOrganizationMembers>>((dispatch, getState, http) => {
    return http.organizationsApiGet(`/organizations/${organizationId}/members/with-email?query=${query}&size=99999&page=0`);
});

export const getAvailableCurrencies = () => action<Promise<IReferencial[]>>((dispatch, getState, http) => {
    return http.apiGet(`/available-currencies`);
});

export const getAllCallToActions = (orgId: string) => action<Promise<ICallToAtion[]>>((dispatch, getState, http) => {
    return http.organizationsApiGet(`/Organizations/${orgId}/call-to-actions`);
});

export const clearSearchResult = () => ({
    type: actionTypes.CLEAR_SEARCH_RESULT
});

export const deleteCTA = (organizationId: string, callToActionId: string) => action<Promise<ICallToAtion[]>>((dispatch, getState, http) => {
    const body = {
        organizationId,
        callToActionId
    };
    return http.organizationsApiPost(`/Organizations/${organizationId}/call-to-actions/delete`, body);
});

export const updateCTA = (organizationId: string, callToAction: ICallToAtion) => action<Promise<ICallToAtion[]>>((dispatch, getState, http) => {
    const body = {
        organizationId,
        callToAction
    };
    return http.organizationsApiPost(`/Organizations/${organizationId}/call-to-actions/update`, body);
});

export const newCTA = (organizationId: string, callToAction: ICallToAtion) => action<Promise<ICallToAtion[]>>((dispatch, getState, http) => {
    const body = {
        organizationId,
        callToAction
    };
    return http.organizationsApiPost(`/Organizations/${organizationId}/call-to-actions/new`, body);
});

export const getCampaignsList = (organizationId: string) => action<Promise<ICampaignListElem[]>>((dispatch, getState, http) => {
    return http.organizationsApiGet(`/organizations/${organizationId}/campaigns`);
});

export const getCampaignsRecipients = (organizationId: string, campaignId: string) => action<Promise<ISingleCampaign[]>>((dispatch, getState, http) => {
    return http.organizationsApiGet(`/organizations/${organizationId}/campaigns/${campaignId}/recipients`);
});

export const reorderCTA = (organizationId: string, callToActionId: string, callToActionOrder: number) => action<Promise<ISingleCampaign[]>>((dispatch, getState, http) => {
    const body = {
        callToActionId,
        callToActionOrder,
        organizationId
    };

    return http.organizationsApiPost(`/Organizations/${organizationId}/call-to-actions/reorder`, body);
});

export const resendCampaign = (organizationId: string, campaignId: string) => action<Promise<any>>((dispatch, getState, http) => {
    return http.organizationsApiPost(`/organizations/${organizationId}/campaigns/${campaignId}/resend`, []);
});

export const closeCampaign = (organizationId: string, campaignId: string) => action<Promise<CampaignDto>>((dispatch, getState, http) => {
    return http.organizationsApiPost(`/organizations/${organizationId}/campaigns/${campaignId}/close`, []);
});

export const searchParams = (params: Record<string, string | number | null | undefined>): URLSearchParams => new URLSearchParams(
    Object.entries(params)
        .filter(([_key, value]) => value !== null && value !== undefined)
        .map(([key, value]) => [key, value.toString()])
);

export const getExploreData = (organizationId?: string, page: number = 0, size: number = 20, body?, timeout?: number) => action<Promise<ISearchResults>>((dispatch, getState, http) => {
    const { userLanguage: lcid } = getState().language;
    return http.clustersApiPost(`/search-v2?${searchParams({ organizationId, lcid, page, size })}`, body, undefined, timeout);
});

export const getExploreDataGrid = (organizationId?: string, page: number = 0, size: number = 20, filters: any = {}) => action<Promise<ISearchResults>>((_dispatch, getState, http) => {
    const { userLanguage } = getState().language;
    const { mine, query, ...otherfilters } = filters;
    return http.clustersApiPost(`/search-explore?${searchParams({ organizationId, lcid: userLanguage, page, size })}`, {
        ...otherfilters, mine: mine?.[0], query: query?.[0]
    });
});

export const clearExploreItems = () => ({
    type: actionTypess.CLEAR_EXPLORE_ELEMENTS
});

export const addRecipientsToCampaign = (organizationId: string, campaignId: string, body: { principalsToAdd: { principalChildId: string, principalType: number }[] }) => action<Promise<CampaignDto>>((dispatch, getState, http) => {
    return http.organizationsApiPost(`/organizations/${organizationId}/campaigns/${campaignId}/recipients`, body);
});

export const deleteRecipientFromCampaign = (organizationId: string, campaignId: string, body: string[]) => action<Promise<CampaignDto>>((dispatch, getState, http) => {
    return http.organizationsApiDelete(`/organizations/${organizationId}/campaigns/${campaignId}`, body);
});

export const deleteOrganization = (organizationId: string) => action<Promise<void>>((dispatch, getState, http) => {
    return http.organizationsApiDelete(`/organizations/${organizationId}`, []);
});

export const getSettingsApprovalDatatable = (organizationId: string) => action<Promise<ITemplateDataGridSettings>>(async (dispatch: Dispatch, getState: () => IState, http: Http) => {
    const { language } = getState();
    return http.clustersApiGet(`/organizations/${organizationId}/custom-datagrid-by-key/approvals/settings?lcid=${language.userLanguage || ELcid.En}`);
});

export const getApprovalDatatableData = (organizationId: string, filters, page?: number) => action<Promise<IFacetsSearchResponse<IApprovalDatatable, Record<string, any[]>>>>(async (dispatch, getState, http) => {
    return http.clustersApiPost(`/organizations/${organizationId}/datatable/approvals`, {...filters, query: filters.query?.[0]});
});
