import React, { FC, useCallback } from 'react';
import styled from 'styled-components';
import { useFormikContext } from 'formik';

import { colorStack } from '../../../../../../styleHelpers/colors';
import { fontSize } from '../../../../../../styleHelpers/fontSizes';
import { Checkbox } from '../../../../../Common/Inputs/Checkbox';
import { ICOIData } from '../../../../../../entities/ActionData/ICOIData';
import { useIntlMessage } from '../../../../../Common/IntlMessage';

const SWrapper = styled.div`
    color: ${colorStack.content};
    font-size: ${fontSize[16]};
    display: flex;
    flex-direction: column;
    margin: 0 2rem 0 2rem;
`;

const PrinciplesContainer = styled.div`
    margin-bottom: 1rem;
    ul {
        list-style-type: disc;
    }
    ol {
        list-style-type: decimal;
    }
`;

export const NoLinkOfInterest: FC<React.PropsWithChildren<unknown>> = () => {
    const { intlFormatMessage } = useIntlMessage();
    const { setFieldValue, values } = useFormikContext<ICOIData>();

    const changeCheckbox = useCallback((checked: boolean) => {
        setFieldValue('noLinkPolicy', checked);
    }, []);

    return (
        <SWrapper>
            <PrinciplesContainer>
                <div dangerouslySetInnerHTML={{
                    __html: intlFormatMessage({ id: 'coi.popup.noLinkOfInterest.textList' })
                }} />
            </PrinciplesContainer>
            <div>
                <Checkbox onChange={changeCheckbox} checked={values.noLinkPolicy} label={intlFormatMessage({ id: 'coi.popup.noLink.policyCheckbox' })} />
            </div>
        </SWrapper>
    );
};