import React, { FC, useEffect, useCallback, useMemo, useState } from 'react';
import styled, { css } from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { rgba } from 'polished';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBookmark } from '@fortawesome/pro-light-svg-icons';
import { faCaretDown, faCaretUp } from '@fortawesome/pro-solid-svg-icons';

import { IState } from '../../../reducers';
import { ISogeReducer } from '../../../reducers/sogeReducer';
import { getVersions, setUniqueVersionId, getNormsConfiguration } from '../../../actions/sogeActions';
import { useDropdown } from '../../../tools/hooks';
import { ITableOfContentsVersion } from '../../../entities/ISoge';
import { EVersionStatus } from '../../../entities/LegalDoc/INormativeDocumentationVersion';
import { SgAlignTo } from '../../../entities/LegalDoc/ISogeStyles';
import { colorStack } from '../../../styleHelpers/colors';
import { media } from '../../../styleHelpers/breakpoint';
import { Button, EButtonTypeSchema } from '../../Common/Buttons/NewButton';
import { StatusTag } from '../Components/StatusTag';
import { ELcid } from '../../../entities/ILanguage';
import { languageIcons } from '../../../tools/languageTools';
import { fontSize } from '../../../styleHelpers/fontSizes';
import IntlMessage from '../../Common/IntlMessage';

const DropdownWrapper = styled.div`
    position: relative;
    ${media.tabletSm`
        margin-right: 1rem;
    `}
`;

const VersionDropdown = styled.div<{ alignTo?: SgAlignTo }>`
    position: absolute;
    left: 0;
    z-index: 9;
    background: ${colorStack.white};
    box-shadow: 0 2px 4px 0 ${rgba(colorStack.darkBlue, 0.10)};
    border-radius: 3px;
    ${media.tablet`
        right: 0;
        left: auto;
        ${({ alignTo }) => alignTo === 'left' && css`
            left: 0;
            right: auto;
        `}
    `}
`;

const VersionButton = styled.button`
    display: flex;
    justify-content: flex-start;
    padding: 0.5rem 0.6rem;
    min-width: 100px;
    cursor: pointer;
    outline: 0;
    white-space: nowrap;
    align-items: center;
`;

const VersionLcid = styled.img`
    width: 1rem;
    margin: 0 0.5rem;
`;

const VersionName = styled.div`
    margin: 0 8px 0 0;
    white-space: nowrap;
    color: ${colorStack.darkGrey};
`;

const VersionLabel = styled.div`
    display: flex;
    align-items: center;
    color: ${colorStack.middleGrey};
    font-size: ${fontSize[13]};
    svg {
        margin-right: .5rem;
    }
    span {
        display: none;
    }
    ${media.tablet`
        margin-right: .5rem;
        span {
            display: inline;
        }
    `}
`;

const DropdownCaret = styled.span`
    display: none;
    ${media.tablet`
        display: inline;
    `}
`;

const SelectedVersion = styled.span`
    display: flex;
    align-items: center;
    justify-content: flex-start;
`;

const VersionTag = styled(StatusTag)`
    white-space: nowrap;
    span {
        font-weight: 400;
    }
`;

const DropdownButton = styled(Button)`
    > span {
        font-size: ${fontSize[13]};
        font-family: 'Roboto', sans-serif;
        font-weight: 400;
    }
`;

interface IVersionOptionProps {
    name: string;
    id: string;
    uniqueVersionId: string;
    status?: EVersionStatus;
    lcid?: ELcid;
    onClick(id: string, uniqueVersionId: string);
}

const VersionOption: FC<React.PropsWithChildren<IVersionOptionProps>> = props => {
    const onClick = useCallback(() => {
        props.onClick(props.id, props.uniqueVersionId);
    }, [props.id, props.onClick, props.uniqueVersionId]);

    return (
        <VersionButton onClick={onClick}>
            <VersionName>{props.name}</VersionName>
            {props.lcid && (
                <VersionLcid src={languageIcons[props.lcid]} />
            )}
            <VersionTag status={props.status}/>
        </VersionButton>
    );
};

interface IVersionSelectProps {
    versions?: ITableOfContentsVersion[];
    currentVersionId?: string;
    disabled?: boolean;
    isGlobalVersionSelect?: boolean;
    isOwner?: boolean;
    labelHidden?: boolean;
    alignTo?: SgAlignTo;
    lcidVisible?: boolean;
    bgColor?: string;
    onVersionChange?(versionId: string, status: EVersionStatus, uniqueVersionId: string, lcid: ELcid);
    setLimitedAccess?(isLimited: boolean);
}

export const VersionSelect: FC<React.PropsWithChildren<IVersionSelectProps>> = props => {
    const dispatch = useDispatch();
    const [tocWrapperRef, tocDropdownOpen, tocToggle, tocCloseDropdown] = useDropdown();
    const { documentationId, documentationLcid } = useSelector<IState, ISogeReducer>(state => state.soge);
    const [versions, setVersions] = useState<ITableOfContentsVersion[]>([]);

    useEffect(() => {
        !props.versions && documentationId && documentationLcid && dispatch(getVersions(documentationId, documentationLcid)).then(allVersions => {
            setVersions(props.isGlobalVersionSelect
                ? allVersions?.filter(version => version.documentVersion?.status === EVersionStatus.Current || version.documentVersion?.status === EVersionStatus.Archived || (props.isOwner && version.documentVersion?.status === EVersionStatus.New))
                : allVersions
            );
            dispatch(getNormsConfiguration(documentationId));
        });
    }, [documentationId, documentationLcid, props.currentVersionId]);

    const onVersionChange = useCallback((id: string, uniqueVersionId: string) => {
        if (props.currentVersionId !== id) {
            const uniqueVersion = (props.versions || versions).find(version => version.id === uniqueVersionId);
            props.onVersionChange?.(id, uniqueVersion?.documentVersion?.status, uniqueVersion?.id, uniqueVersion?.lcid);
            dispatch(setUniqueVersionId(uniqueVersionId));
        }
        tocCloseDropdown();
    }, [props.currentVersionId, props.onVersionChange, tocCloseDropdown, props.versions, versions]);

    const currentVersion = useMemo(() => {
        const selectedVersion = (props.versions || versions).find(version => version.versionId === props.currentVersionId || version.id === props.currentVersionId || version[0]?.versionId);
        props.currentVersionId && props.isGlobalVersionSelect && documentationId && documentationLcid && (props.versions?.length || versions.length) && props.setLimitedAccess?.(!selectedVersion);
        return selectedVersion;
    }, [props.currentVersionId, versions, props.versions, props.setLimitedAccess, props.isGlobalVersionSelect, documentationId, documentationLcid]);

    return (
        <>
            {!props.labelHidden && (
                <VersionLabel>
                    <FontAwesomeIcon icon={faBookmark} />
                    <IntlMessage id="norms.versions" />
                </VersionLabel>
            )}
            <DropdownWrapper ref={tocWrapperRef}>
                <DropdownButton typeSchema={EButtonTypeSchema.TERTIARY} outlined onClick={tocToggle} disabled={props.disabled}>
                    <SelectedVersion>
                        {props.lcidVisible && (!!currentVersion?.lcid || !!versions[0]?.lcid) && (
                            <VersionLcid src={languageIcons[currentVersion.lcid] || languageIcons[versions[0].lcid]} />
                        )}
                        <VersionTag status={currentVersion?.documentVersion?.status || versions[0]?.documentVersion?.status} />
                        <VersionName>{(currentVersion?.documentVersion?.name || versions[0]?.documentVersion?.name)  || props.children}</VersionName>
                        <DropdownCaret>
                            {tocDropdownOpen ? (
                                <FontAwesomeIcon icon={faCaretUp} size="lg" color={colorStack.darkGrey} />
                            ) : (
                                <FontAwesomeIcon icon={faCaretDown} size="lg" color={colorStack.darkGrey} />
                            )}
                        </DropdownCaret>
                    </SelectedVersion>
                </DropdownButton>
                {tocDropdownOpen && (
                    <VersionDropdown alignTo={props.alignTo}>
                        {(props.versions || versions || []).map(version => (
                            <VersionOption
                                key={version.id || version.versionId}
                                id={version.versionId}
                                uniqueVersionId={version.id}
                                name={version.documentVersion?.name}
                                onClick={onVersionChange}
                                status={version.documentVersion?.status}
                                lcid={props.lcidVisible && version.lcid}
                            />
                        ))}
                    </VersionDropdown>
                )}
            </DropdownWrapper>
        </>
    );
};
