import React, { FC, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { useFormikContext } from 'formik';
import { useDispatch } from 'react-redux';

import { LabelWithEllipsis } from '../../../Common/Label/Label';
import { ICreateCorporateCluster, IClusterTemplate } from '../../../../entities/IClusters';
import { getClusterTemplates } from '../../../../actions/clustersActions';
import { colorStack } from '../../../../styleHelpers/colors';
import { inputsShadow } from '../../../../styleHelpers/mixins/shadow';
import { Privacy } from '../../../Common/Privacy/Privacy';
import IntlMessage from '../../../Common/IntlMessage';

type GetClusterTemplates = ReturnType<typeof getClusterTemplates>;

const Wrapper = styled.div`
    width: 100%;
`;

const Row = styled.div`
    display: flex;
    margin: 0 0 1rem 0;
    > div {
        display: flex;
        flex-direction: column;
        width: 280px;
        margin: 0 2rem 0 0;
        &:nth-child(2) {
            width: auto;
        }
    }
`;

const FakeInput = styled.div<{color?: string}>`
    ${inputsShadow()};
    border-radius: 4px;
    padding: 12px 1rem;
    background: ${colorStack.ligthGrey};
    ${props => props.color && css`
        background: ${props.color};
        text-align: center;
    `}
`;

interface IProps {
    organizationId: string;
}

export const Step3: FC<React.PropsWithChildren<IProps>> = props => {
    const dispatch = useDispatch();
    const formikContext = useFormikContext<ICreateCorporateCluster>();

    const [template, setTemplate] = useState<IClusterTemplate>(undefined);

    useEffect(() => {
        formikContext.setFieldValue('step', 3);
        dispatch<GetClusterTemplates>(getClusterTemplates(props.organizationId, true)).then(clusterTemplates => {
            setTemplate(clusterTemplates.find(elem => elem.id === formikContext.values.templateId));
        });
    }, []);

    return (
        <Wrapper>
            <Row>
                <div>
                    <LabelWithEllipsis>
                        <IntlMessage id="corporate.popop.step3.label.title" />
                    </LabelWithEllipsis>
                    <FakeInput>{formikContext?.values?.legalEntity?.name}</FakeInput>
                </div>
                <div>
                    <LabelWithEllipsis>
                        <IntlMessage id="corporate.popop.step3.label.legal-form" />
                    </LabelWithEllipsis>
                    <FakeInput color={template?.color}>{template?.templateName}</FakeInput>
                </div>
            </Row>
                <Privacy field="privacy" />
        </Wrapper>
    );
};
