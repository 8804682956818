import React, { FC, useCallback } from 'react';
import styled from 'styled-components';

import { IRelatedRequest, ERequestStatus } from '../../../entities/ISoge';
import { fontSize } from '../../../styleHelpers/fontSizes';
import { DateStandardFormat } from '../../Common/Date/DateStandardFormat';
import { Tag } from '../../Common/Tag/Tag';
import { Avatar, EAvatarSize } from '../../Common/Avatar/Avatar';
import { Button } from '../../Common/Buttons/NewButton';
import IntlMessage from '../../Common/IntlMessage';

const SingleValidatorWrapper = styled.div`
    display: flex;
    flex-direction: column;
    font-size: ${fontSize[13]};
    padding: 0 .5rem;
    span {
        font-weight: 500;
    }
    transition: background-color .2s ease;
`;

const ValidatorContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: .4rem 0;
`;

interface ISingleRequestorProps {
    requestor: IRelatedRequest;
    onRedirect?(requestId: string);
}

export const SingleRequestor: FC<React.PropsWithChildren<ISingleRequestorProps>> = ({ requestor, onRedirect }) => {
    const { createdByUser, creationDate, status, id } = requestor;

    const redirectToRequest = useCallback(() => {
        onRedirect(id);
    }, [id, onRedirect]);

    return (
        <SingleValidatorWrapper>
            <ValidatorContainer>
                <Avatar
                    picture={createdByUser.picture}
                    firstName={createdByUser.firstName}
                    lastName={createdByUser.lastName}
                    id={createdByUser.id}
                    preventRedirection={true}
                    extended
                    size={EAvatarSize.TINY}
                />
                {status === ERequestStatus.Active &&
                    <Tag color="activeBlue" noBorder nomargin sm><IntlMessage id="norms.status.active" /></Tag>
                }
                {status === ERequestStatus.Completed &&
                    <Tag color="successGreen" noBorder nomargin sm><IntlMessage id="norms.status.completed" /></Tag>
                }
                {status === ERequestStatus.Abandoned &&
                    <Tag color="red" noBorder nomargin sm><IntlMessage id="norms.status.rejected" /></Tag>
                }
                <DateStandardFormat date={creationDate} />
                <Button onClick={redirectToRequest}>
                    <IntlMessage id="global.open" />
                </Button>
            </ValidatorContainer>
        </SingleValidatorWrapper>
    );
};
