import React from 'react';
import styled, { css } from 'styled-components';

import { IValue } from '../../../entities/IPickers';
import { colorStack } from '../../../styleHelpers/colors';
import { fontSize } from '../../../styleHelpers/fontSizes';
import { ContextMenu } from '../ContextMenu/ContextMenu';
import IntlMessage from '../IntlMessage';

const CustomElem = styled.div<{ active: boolean }>`
    color: ${colorStack.content};
    font-size: ${fontSize[16]};
    padding: .8rem 1rem;
    font-weight: 400;
    text-align: left;
    transition: all .3s;
    cursor: pointer;
    display: flex;
    &:hover {
        background: ${colorStack.lightBlue};
        font-weight: 500;
    }
    ${({ active }) => active && css`
    background: ${colorStack.lightBlue};
    font-weight: 500;
    `}
`;

interface IProps {
    options: IValue[];
    value: IValue[];
    onChange(item: IValue);
}

export const DefaultMenu = (props: IProps) => {
    return (
        <ContextMenu links={props.options?.map(elem => ({
            customRender:
                <CustomElem onClick={() => props.onChange(elem)} active={props.value?.map(valueElem => valueElem.key).includes(elem.key)}>
                    {elem.text}
                </CustomElem>,
            visible: true
        }))} />
    );
};