import React, { FC } from 'react';
import styled from 'styled-components';

import { fontSize } from '../../../styleHelpers/fontSizes';
import { colorStack } from '../../../styleHelpers/colors';
import { media } from '../../../styleHelpers/breakpoint';
import IntlMessage from '../../Common/IntlMessage';

const Wrapper = styled.div`
    font-size: ${fontSize[13]};
    color: ${colorStack.darkBlue};
    svg {
        margin-right: .5rem;
    }
`;

const DisclaimerWrapper = styled(Wrapper)`
    display: flex;
    align-items: center;
    width: 100%;
    order: 5;
    margin-top: .5rem;
    ${media.tablet`
        width: auto;
        order: 0;
        margin-top: 0;
    `}
`;

interface IDisclaimerProps {
    editMode?: boolean;
}

export const Disclaimer: FC<React.PropsWithChildren<IDisclaimerProps>> = ({ editMode }) => (
    <>
        <DisclaimerWrapper>
            <IntlMessage id="norms.codeDisclaimer"  />
        </DisclaimerWrapper>
    </>
);
