import React, { FC, ReactNode } from 'react';
import styled, { css } from 'styled-components';
import { Link, LinkProps } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCodeBranch, faBookmark } from '@fortawesome/pro-light-svg-icons';

import { colorStack } from '../../../styleHelpers/colors';
import { fontSize } from '../../../styleHelpers/fontSizes';
import { IFullBranch } from '../../../entities/ISoge';
import { getBranchUrl } from '../../../tools/legalDocTools/sogeTools';
import IntlMessage from '../../Common/IntlMessage';

const Wrapper = styled.div`
    margin-bottom: 1rem;
    display: flex;
    align-items: center;
`;

const BranchName = styled.span<{ multipleBranches?: boolean } & LinkProps>`
    ${({ multipleBranches }) => multipleBranches ? css`
        display: block;
    ` : css`
        margin: 0 .5rem;
    `}
    svg {
        margin-right: .5rem;
    }
`;

const VersionWrapper = styled.span`
    margin: 0 1rem;
    font-size: ${fontSize[13]};
    color: ${colorStack.middleGrey};
    svg {
        margin-right: .5rem;
    }
`;

const VersionName = styled.span`
    color: ${colorStack.darkBlue};
    margin-left: .8rem;
`;

const RightCol = styled.div`
    margin-left: auto;
`;

interface IApprovalFormHeaderProps {
    branches: IFullBranch[];
    versionName: ReactNode;
    rightCol?: ReactNode;
    organizationUrlName: string;
}

export const ApprovalFormHeader: FC<React.PropsWithChildren<IApprovalFormHeaderProps>> = ({ branches, versionName, rightCol, organizationUrlName }) => {
    const multipleBranches = branches?.length > 1;
    return (
        <Wrapper>
            <IntlMessage
                id="soge.approval.listOfApprovers"
                values={{
                    branch: (
                        <>
                            {(branches || []).map((branch => (
                                <BranchName
                                    as={Link}
                                    key={branch.id}
                                    multipleBranches={multipleBranches}
                                    to={getBranchUrl(organizationUrlName, branch.normativeDocumentationVersionId, branch.relatedItemCrossId, branch.id)}
                                >
                                    <FontAwesomeIcon icon={faCodeBranch} />
                                    <strong>{branch.name}</strong>
                                </BranchName>
                            )))}
                        </>
                    ),
                    version: (
                        <VersionWrapper>
                            <FontAwesomeIcon icon={faBookmark} />
                            Version
                            <VersionName>{versionName}</VersionName>
                        </VersionWrapper>
                    )
                }}
            />
            {rightCol && (
                <RightCol>
                    {rightCol}
                </RightCol>
            )}
        </Wrapper>
    );
};