import React, { FC, useCallback, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { faBell } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';

import * as Components from '../Components';
import NavMenuNotification from '../NavMenuNotification/NavMenuNotification';
import { NoNotificationInfo } from './Components';
import ScrollBox from '../../../Common/ScrollBox/ScrollBox';
import { NotificatinHook } from './NotificationHook';
import { EAlertType } from '../../../../entities/IAlert';
import { INotification, ENotificationStatus, ENotificationChannel, ENotificationDocumentExportType } from '../../../../entities/INotification';
import { EExportType } from '../../../../entities/ISoge';
import { useDropdown, useResize, useAlert } from '../../../../tools/hooks';
import { notificationRedirect } from '../../../../tools/notificationRedirect';
import { IContextReducer } from '../../../../reducers/contextReducer';
import { IState } from '../../../../reducers';
import { INotificationReducer } from '../../../../reducers/notificationReducer';
import {
    setNotificationsVisibilityFlag, marNavMenuNotificationAsRead, updateNavMenuNotifications, acceptDeclineInvitations,
    changeNotificationStatus, markAllAsNotNew, getAllNotifications
} from '../../../../actions/notificationsActions';
import { acceptOrDeclineInvitationToOrganization } from '../../../../actions/organizationActions';
import { getCurrentUserContextOrganizations } from '../../../../actions/contextActions';
import { downloadExportedPdfDocument, downloadExportedWordDocument, downloadExportedArticlePdfDocument, downloadExportedArticleWordDocument } from '../../../../actions/sogeActions';
import { getErrorMessage } from '../../../../tools/errorTools';
import { Badge, EBadgeColor, EBadgeSize } from '../../../Common/Badge/Badge';
import { colorStack } from '../../../../styleHelpers/colors';
import { LegalDocNotificationPopup } from '../../../Routes/Notifications/LegalDocNotificationPopup';
import IntlMessage from '../../../Common/IntlMessage';

type SetNotificationsVisibilityFlag = ReturnType<typeof setNotificationsVisibilityFlag>;
type MarkNotificationAsRead = ReturnType<typeof marNavMenuNotificationAsRead>;
type UpdateNavMenuNotifications = ReturnType<typeof updateNavMenuNotifications>;
type AcceptDeclineInvitations = ReturnType<typeof acceptDeclineInvitations>;
type ChangeNotificationStatus = ReturnType<typeof changeNotificationStatus>;
type AcceptOrDeclineInvitationToOrganization = ReturnType<typeof acceptOrDeclineInvitationToOrganization>;
type GetCurrentUserContextOrganizations = ReturnType<typeof getCurrentUserContextOrganizations>;
type GetAllNotifications = ReturnType<typeof getAllNotifications>;

const NotificationBox = styled.div`
    position: relative;
    margin-right: -10px;
    display: flex;
    align-items: center;
    > svg {
        color: ${colorStack.darkGrey};
        font-size: 24px;
    }
    > div {
        position: relative;
        top: -4px;
        left: -10px;
    }
`;

const NotificationList: FC<React.PropsWithChildren<unknown>> = () => {
    const dispatch = useDispatch();
    const { innerWidth } = useResize();
    const addAlert = useAlert();
    const [selectedNotificationsIds, setSelectedNotificationsIds] = useState<string[]>([]);
    const [wrapperRef, dropdownOpen, toggleDropdown] = useDropdown();
    const [legaldocPopup, setLegaldocPopup] = useState<INotification>(undefined);
    const { newNotificationCount, navMenuNotifications, notificationsWithButtons, contextOrganizations } = useSelector<IState, INotificationReducer & IContextReducer>(state => ({
        ...state.notifications,
        ...state.context
    }));

    const loadMoreNotifications = useCallback(async (page: number) => {
        return await dispatch<GetAllNotifications>(getAllNotifications(page));
    }, []);

    const updateNotificationsHandler = useCallback((notifications) => {
        dispatch<UpdateNavMenuNotifications>(updateNavMenuNotifications(notifications));
    }, []);

    const { onScrollResults, hasMore, currentPage } = NotificatinHook({
        showNotificationList: dropdownOpen,
        notificationsCount: newNotificationCount,
        markAllAsNotNew,
        loadNotifications: loadMoreNotifications,
        updateNavMenuNotifications: updateNotificationsHandler,
        navMenuNotifications
    });

    const notificationHandler = useCallback((notification?: INotification) => {
        dispatch<SetNotificationsVisibilityFlag>(setNotificationsVisibilityFlag(dropdownOpen));
        toggleDropdown();
        if (notification?.data?.RedirectUri || notification?.data?.RedirectUrl) {
            window.location.assign(notification.data.RedirectUri || notification.data.RedirectUrl);
        }
    }, [dropdownOpen]);

    const marNotificationAsReadHandler = useCallback((id: string) => {
        dispatch<MarkNotificationAsRead>(marNavMenuNotificationAsRead(id));
    }, []);

    const updateNavMenuNotificationsHandler = useCallback((notifications: INotification[]) => {
        dispatch<UpdateNavMenuNotifications>(updateNavMenuNotifications(notifications));
    }, []);

    const setNotificationActionHandler = useCallback((notification: INotification) => {
        updateNavMenuNotificationsHandler(navMenuNotifications.map(navMenuNotification => navMenuNotification.id === notification.id ? {
            ...notification,
            notificationStatus: ENotificationStatus.Archived,
            data: {
                ...(notification.data || {}),
                ActionHandled: true
            }
        } : navMenuNotification));
    }, [navMenuNotifications]);

    const getOrganisationName = (notification: INotification) => {
        const orgId = notification.data.ResourceParentId;
        return contextOrganizations?.byId[orgId]?.details?.urlName;
    };

    const openNotification = (notification: INotification) => {
        const orgName = getOrganisationName(notification);
        notificationRedirect(notification, orgName);
        if (!notificationsWithButtons[notification.key]) {
            dispatch<ChangeNotificationStatus>(changeNotificationStatus(notification.id));
        }
        if (notification?.channelId === ENotificationChannel.LegalDoc && !(notification?.data?.UrlToBranch || notification?.data?.PathToBranch)) {
            setLegaldocPopup(notification);
        }
        if (notification?.data?.fileId) {
            setLegaldocPopup(undefined);
            try {
                switch (notification?.key) {
                    case ENotificationDocumentExportType?.SearchAndExportReferenceTexts:
                        notification?.data?.exportType === EExportType?.Pdf
                        ? dispatch(downloadExportedPdfDocument(notification?.data?.normativeDocumentationId, notification?.data?.normativeDocumentationVersionId, 'NormsExport', notification?.data?.fileId))
                        : dispatch(downloadExportedWordDocument(notification?.data?.normativeDocumentationId, notification?.data?.normativeDocumentationVersionId, 'NormsExport', notification?.data?.fileId));
                        break;
                    case ENotificationDocumentExportType?.SearchAndExportArticles:
                        notification?.data?.exportType === EExportType?.Pdf
                        ? dispatch(downloadExportedArticlePdfDocument(notification?.data?.normativeDocumentationId, notification?.data?.normativeDocumentationVersionId, 'NormsExport', notification?.data?.fileId))
                        : dispatch(downloadExportedArticleWordDocument(notification?.data?.normativeDocumentationId, notification?.data?.normativeDocumentationVersionId, 'NormsExport', notification?.data?.fileId));
                        break;
                    default:
                        break;
                }
            } catch (err) {
                addAlert(getErrorMessage(err), EAlertType.ERROR);
            }
        }
    };

    const acceptDeclineInvitationsHandler = useCallback((accept: boolean, notification: INotification) => {
        setSelectedNotificationsIds(currentState => [...currentState, notification.receiverId]);
        dispatch<AcceptDeclineInvitations>(acceptDeclineInvitations(accept, notification?.receiverId, notification?.senderDisplayDetails?.userId, notification.id))
            .then(() => {
                setNotificationActionHandler(notification);
            })
            .catch(() => {
                const newSelectedNotificationsIds = selectedNotificationsIds;
                const index = newSelectedNotificationsIds.indexOf(notification?.receiverId);
                index > -1 && newSelectedNotificationsIds.splice(index, 1);
                setSelectedNotificationsIds(newSelectedNotificationsIds);
            });
    }, [selectedNotificationsIds, setNotificationActionHandler]);

    const acceptOrDeclineInvitationToOrganizationHandler = useCallback((notificationId: string, organizationId: string, accepted: boolean) => {
        dispatch<AcceptOrDeclineInvitationToOrganization>(acceptOrDeclineInvitationToOrganization(organizationId, accepted)).then(() => {
            dispatch<GetCurrentUserContextOrganizations>(getCurrentUserContextOrganizations());
        });
        updateNavMenuNotifications(navMenuNotifications.map((notification: INotification) => notification.id === notificationId ? ({
            ...notification,
            data: {
                ...notification.data,
                ActionHandled: true
            }
        }) : notification));
    }, []);

    const notificationReadHandler = useCallback(() => {
        toggleDropdown();
    }, [toggleDropdown]);

    return (
        <Components.CounterBox ref={wrapperRef}>
            <div onClick={notificationReadHandler} className="pointer">
                <NotificationBox>
                    <FontAwesomeIcon icon={faBell} />
                    {newNotificationCount > 0 && <Badge outlined placeholder={newNotificationCount.toString()} color={EBadgeColor.RED} size={EBadgeSize.XSMALL}/>}
                </NotificationBox>
            </div>
            {dropdownOpen && (
                <Components.NotificationsList width={innerWidth}>
                    <Components.NotificationsListInner>
                        {(navMenuNotifications.length > 0) ? (
                            <ScrollBox hasMore={hasMore} onScrollResults={onScrollResults} heigth="415px" pageStart={currentPage}>
                                {navMenuNotifications.map((elem: INotification) =>
                                    <NavMenuNotification
                                        notification={elem}
                                        key={elem.id}
                                        handleP2PAction={acceptDeclineInvitationsHandler}
                                        openNotification={openNotification}
                                        isSelectedNotification={selectedNotificationsIds.indexOf(elem.id) > -1}
                                        onNotificationClick={() => notificationHandler(elem)}
                                        acceptOrDeclineInvitationToOrganization={acceptOrDeclineInvitationToOrganizationHandler}
                                        marNotificationAsRead={marNotificationAsReadHandler}
                                    />
                                )}
                            </ScrollBox>
                        ) : (
                                <IntlMessage id="topnav.notification.nonotifications" >
                                    {txt => <NoNotificationInfo>{txt}</NoNotificationInfo>}
                                </IntlMessage>
                            )}
                        <NavLink to="/notifications" title="Notifications" onClick={() => notificationHandler()}>
                            <Components.NotificationsShowAll><IntlMessage id="topnav.notification.seeall" /></Components.NotificationsShowAll>
                        </NavLink>
                    </Components.NotificationsListInner>
                </Components.NotificationsList>
            )}
            {legaldocPopup && (
                <LegalDocNotificationPopup
                    notification={legaldocPopup}
                    onClose={() => setLegaldocPopup(undefined)}
                />
            )}
        </Components.CounterBox>
    );
};

export default NotificationList;
