import React, { FC } from 'react';
import styled from 'styled-components';

import { colorStack } from '../../../../styleHelpers/colors';

const Bubble = styled.div`
    width: 24px;
    height: 24px;
    background: ${colorStack.darkRed};
    color: ${colorStack.white};
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    padding: 0.5rem;
    margin: 0 auto;
`;

interface IProps {
    value: boolean;
}

const ExclamationMarkType: FC<IProps> = props => {
    return (
        <>
            {props.value && <Bubble>!</Bubble>}
        </>
    );
};

export default ExclamationMarkType;