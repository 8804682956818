import React, { useState, useCallback, FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { Formik } from 'formik';

import { IState } from '../../../../reducers';
import { IProfileReducer } from '../../../../reducers/profileReducer';
import { StepsPopup } from '../../../Common/StepsPopup/StepsPopup';
import { Step4 } from './Steps/Step4';
import { Confirm } from './Steps/Confirm';
import { initialFormValues } from './InitialFormValues';
import { EPopupSizes } from '../../../../entities/IGlobal';
import { postTradeAssociationWizard } from '../../../../actions/clustersActions';
import { Step1 } from './Steps/Step1';
import { ETemplateTypes } from '../../../../entities/IClusters';
import { Step2 } from './Steps/Step2';
import { Step3 } from './Steps/Step3';
import { ITradeAssociationData } from '../../../../entities/ActionData/ITradeAssociationData';
import { EPanelUrlParams } from '../../../Common/Panel/PanelContainer';
import { useIntlMessage } from '../../../Common/IntlMessage';

type PostTradeAssociationWizard = ReturnType<typeof postTradeAssociationWizard>;

interface IProps {
    organizationId: string;
    organizationUrlName: string;
    isPopupShown: boolean;
    isHome?: boolean;
    setShowPopup(giftInvitationPopupOn: boolean);
}

export const TradeAssociationWizard: FC<React.PropsWithChildren<IProps>> = ({ isPopupShown, isHome, organizationId, organizationUrlName, setShowPopup }) => {
    const dispatch = useDispatch();
    const { intlFormatMessage } = useIntlMessage();
    const history = useNavigate();
    const { currentUserProfile } = useSelector<IState, IProfileReducer>(state => state.profile);
    const [stepFinished, setStepFinished] = useState<boolean>(false);
    const [isConfirmButtonDisabled, setIsConfirmButtonDisabled] = useState<boolean>(true);
    const [isFormFinnished, setIsFormFinnished] = useState<boolean>(false);

    const closePopup = useCallback((resetForm) => {
        setShowPopup(false);
        setStepFinished(false);
        resetForm();
        if (isFormFinnished) {
            history(`/orgs/${organizationUrlName}/dashboard?${EPanelUrlParams.REFRESH}=true`);
            setIsFormFinnished(false);
        } else if (isHome) {
            history('/');
        }
    }, [organizationId, organizationUrlName, isFormFinnished, isHome]);

    const submitFormData = useCallback((form) => {
        setIsConfirmButtonDisabled(true);
        return dispatch<PostTradeAssociationWizard>(postTradeAssociationWizard(organizationId, form)).then(() => {
            setIsConfirmButtonDisabled(false);
            setStepFinished(true);
            setIsFormFinnished(true);
        }).catch(reason => {
            console.log('TradeAssociation not send: ', reason);
        });
    }, [organizationId, dispatch, setIsConfirmButtonDisabled]);

    const clearForm = useCallback((resetForm) => {
        resetForm();
        setStepFinished(false);
    }, []);

    return (
        <Formik<ITradeAssociationData>
            initialValues={initialFormValues(currentUserProfile)}
            validationSchema={() => Yup.lazy((values: any) => {
                const stepNr = values.step;
                return Yup.object().shape({
                    readPolicy: Yup.boolean().oneOf([true]),
                    noLinkPolicy: stepNr === 3 && Yup.boolean().oneOf([true]),
                    formData: Yup.object().shape({
                        tradeAssociationDeclaration: Yup.object({
                            description: stepNr === 2 && Yup.string().required(),
                            startingDate: stepNr === 2 && Yup.string().required(),
                            thirdParty: Yup.object({
                                id: stepNr === 2 && Yup.string().required()
                            })
                        }),
                        tradeAssociationInformation: Yup.object({
                            jobTitle: stepNr === 4 && Yup.string().required(),
                            legalEntity: stepNr === 4 && Yup.object({
                                id: Yup.string().required()
                            }),
                            manager: Yup.object({
                                firstName: stepNr === 4 && Yup.string().required()
                            })
                        })
                    })
                });
            })}
            onSubmit={submitFormData}
        >
            {({ isValid, dirty, submitForm, resetForm }) => (
                <StepsPopup
                    showPopup={isPopupShown}
                    size={EPopupSizes.BIG}
                    title={intlFormatMessage({ id: 'tradeAssociation.wizard.title' })}
                    confirmDisabled={isConfirmButtonDisabled}
                    isFormValid={isValid}
                    dirty={dirty}
                    finishHandler={() => closePopup(resetForm)}
                    preventClosingOnClickingOutside
                    confirmFinished={stepFinished}
                    confirmHandler={submitForm}
                    showDeclareNew={true}
                    clearForm={() => clearForm(resetForm)}
                    wizardType={ETemplateTypes.TradeAssociations}
                    steps={[
                        {
                            content: <Step1 userFirstName={currentUserProfile?.firstName} />
                        },
                        {
                            title: intlFormatMessage({ id: `${'tradeAssociation.wizard.step2.title'}` }),
                            content: <Step2 />
                        },
                        {
                            title: intlFormatMessage({ id: `${'tradeAssociation.wizard.step3.title'}` }),
                            content: <Step3 />
                        },
                        {
                            title: intlFormatMessage({ id: `${'tradeAssociation.wizard.step4.title'}` }),
                            content: <Step4 />
                        },
                        {
                            content: <Confirm />
                        }
                    ]}
                />
            )}
        </Formik>
    );
};
