import React, { FC, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import { getUserProfileByQuery } from '../../../actions/profileActions';
import { EProfileType } from '../../../entities/IGlobal';
import { IPickerProps, CorePicker, EButtonsTypes } from './CorePicker';

type GetUserProfileByQuery = ReturnType<typeof getUserProfileByQuery>;

const Wrapper = styled.div``;

type IProps = IPickerProps & {
    filter?: string[];
};

export const UsersPicker: FC<React.PropsWithChildren<IProps>> = props => {
    const dispatch = useDispatch();

    const getData = useCallback((search: string) => {
        const body = {
            search,
            hideCurrentUser: false
        };
        return dispatch<GetUserProfileByQuery>(getUserProfileByQuery(body, 0, 100)).then(res => {
            return res.items.filter((elem) => !props.filter?.includes(elem.id)).map(person => ({
                key: person.id,
                data: {...person, type: EProfileType.Personal},
                text: `${person.firstName} ${person.lastName}`
            }));
        }).catch(() => []);
    }, []);

    return (
        <Wrapper>
            <CorePicker {...props} value={props.value?.map(elem => ({...elem, data: {...elem.data, type: 'Personal'}}))} getData={getData} buttonsType={props.buttonsType || EButtonsTypes.Person} />
        </Wrapper>
    );
};