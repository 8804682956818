import React, { FC, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { useFormikContext } from 'formik';

import { getClusterTemplates } from '../../../../actions/clustersActions';
import { SelectCard } from '../../../Common/SelectCard/SelectCard';
import { IClusterTemplate, ICreateCorporateCluster, ETemplateKeys } from '../../../../entities/IClusters';
import IntlMessage from '../../../Common/IntlMessage';

const Wrapper = styled.div`
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    > div {
        margin: 0 0 1rem 0;
    }
`;

type GetClusterTemplates = ReturnType<typeof getClusterTemplates>;

interface IProps {
    organizationId: string;
}
export const Step2: FC<React.PropsWithChildren<IProps>> = props => {
    const formikContext = useFormikContext<ICreateCorporateCluster>();
    const dispatch = useDispatch();

    const [templates, setTemplates] = useState<IClusterTemplate[]>(undefined);

    useEffect(() => {
        formikContext.setFieldValue('step', 2);
        dispatch<GetClusterTemplates>(getClusterTemplates(props.organizationId, true)).then(clusterTemplates => {
            setTemplates(clusterTemplates);
        });
    }, []);

    const areTemplatesAvailable = useMemo(() => {
        return templates?.find((elem) => elem.templateKey === ETemplateKeys.CorporateForeignMfBrands || elem.templateKey === ETemplateKeys.CorporateSaBoard
        || elem.templateKey === ETemplateKeys.CorporateSarl || elem.templateKey === ETemplateKeys.CorporateSas || elem.templateKey === ETemplateKeys.CorporateSc
        || elem.templateKey === ETemplateKeys.CorporateSimpleSas || elem.templateKey === ETemplateKeys.CorporateSaSupervisory); }, [templates]);

    return (
        <Wrapper>
            {areTemplatesAvailable ?
                templates?.map(elem =>
                    <SelectCard
                        key={elem.id}
                        field="templateId"
                        value={elem.id}
                        imageKey={elem.templateKey}
                        name={`corporate.popup.step2.name.${elem.templateKey}`}
                        description={`corporate.popup.step2.${elem.templateKey}`}
                    />
                )
            : <IntlMessage id="global.wizard.noTemplates"  />}
        </Wrapper>
    );
};