import React, { FC } from 'react';
import styled from 'styled-components';
import moment from 'moment';

import { TextComponent } from '../Inputs/TextComponent';
import { LabelWithEllipsis } from '../Label/Label';
import { MandatoryFieldStar } from '../MandatoryFieldStar/MandatoryFieldStar';
import { CustomCalendar } from '../CustomCalendar/CustomCalendar';

const InnerWrapper = styled.div`
    position: relative;
    .custom-calendar {
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    }
`;

interface IProps {
    value: Date;
    disabled?: boolean;
    placeholder?: string;
    minDate?: Date;
    maxDate?: Date;
    label?: string | React.ReactNode;
    required?: boolean;
    error?: boolean;
    errorMsg?: string;
    format?: string;
    onChange(date: Date);
}

export const DatePickerWrapper: FC<React.PropsWithChildren<IProps>> = props => {
    return (
        <div>
            {props.label &&
                <LabelWithEllipsis>
                    {props.label}
                    {props.required &&
                        <MandatoryFieldStar />
                    }
                </LabelWithEllipsis>
            }
            <InnerWrapper>
                <TextComponent
                    value={props.value ? moment.utc(props.value).local().format(props.format || 'DD MMM YYYY') : ''}
                    placeholder={props.placeholder}
                    error={props.error}
                    errorMsg={props.errorMsg}
                    disabled={props.disabled}
                />
                <CustomCalendar
                    disabled={props.disabled}
                    minDate={props.minDate}
                    maxDate={props.maxDate}
                    onChange={props.onChange}
                    value={props.value}
                />
            </InnerWrapper>
        </div>
    );
};