import React, { useState, useCallback, FC } from 'react';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { Formik } from 'formik';

import { StepsPopup } from '../../../Common/StepsPopup/StepsPopup';
import { EPopupSizes } from '../../../../entities/IGlobal';
import { ICreateCorporateCluster, EClusterPrivacy, ISingleCluster } from '../../../../entities/IClusters';
import { createCorporateCluster } from '../../../../actions/clustersActions';
import { EAlertType } from '../../../../entities/IAlert';
import { useAlert } from '../../../../tools/hooks';
import { Step1 } from './Step1';
import { Step2 } from './Step2';
import { Step3 } from './Step3';
import { Confirm } from './Confirm';
import { useIntlMessage } from '../../../Common/IntlMessage';

type CreateCorporateCluster = ReturnType<typeof createCorporateCluster>;

interface IProps {
    organizationId: string;
    organizationUrlName: string;
    organizationName: string;
    isPopupShown: boolean;
    setShowCorporatePopup(showCorporatePopup: boolean);
}

const initialValues: ICreateCorporateCluster = {
    templateId: undefined,
    legalEntity: undefined,
    privacy: EClusterPrivacy.Private,
    organizationName: undefined,
    organizationUrlName: undefined,
    step: 1
};

export const CorporatePopup: FC<React.PropsWithChildren<IProps>> = props => {
    const dispatch = useDispatch();
    const { intlFormatMessage } = useIntlMessage();
    const history = useNavigate();
    const addAlert = useAlert();
    const [isConfirmButtonDisabled, setIsConfirmButtonDisabled] = useState<boolean>(true);
    const [stepFinished, setStepFinished] = useState<boolean>(false);
    const [clusterData, setClusterData] = useState<ISingleCluster>(undefined);

    const closePopup = useCallback((resetForm) => {
        props.setShowCorporatePopup(false);
        setStepFinished(false);
        resetForm();
        clusterData?.id && history(`/orgs/${props.organizationUrlName}/cluster/${clusterData.id}/overview`);
    }, [props.organizationUrlName, clusterData]);

    const submitFormData = useCallback((values: ICreateCorporateCluster) => {
        setIsConfirmButtonDisabled(true);
        return dispatch<CreateCorporateCluster>(createCorporateCluster(props.organizationId, {
            ...values,
            organizationUrlName: props.organizationUrlName,
            organizationName: props.organizationName
        })).then(res => {
            setStepFinished(true);
            setClusterData(res);
            setIsConfirmButtonDisabled(false);
        }).catch(err => {
            addAlert(<span>{err?.data?.aggregatedErrors?.[0].message}</span>, EAlertType.ERROR);
            setIsConfirmButtonDisabled(false);
        });
    }, [props.organizationUrlName, props.organizationName, props.organizationId]);

    return (
        <Formik<any>
            initialValues={initialValues}
            onSubmit={submitFormData}
            enableReinitialize
            validationSchema={() => Yup.lazy((values: any) => {
                const stepNr = values.step;
                return Yup.object().shape({
                    templateId: stepNr === 2 && Yup.string().required(),
                    legalEntity: Yup.object().required(),
                    privacy: stepNr === 3 && Yup.string().required()
                });
            })}
        >
            {({ values, isValid, dirty, submitForm, resetForm }) => (
                <StepsPopup
                    showPopup={props.isPopupShown}
                    size={EPopupSizes.MEDIUM}
                    title={intlFormatMessage({ id: 'corporate.popup.title' })}
                    confirmDisabled={isConfirmButtonDisabled}
                    isFormValid={isValid}
                    preventClosingOnClickingOutside
                    dirty={dirty}
                    finishHandler={() => closePopup(resetForm)}
                    confirmFinished={stepFinished}
                    confirmHandler={submitForm}
                    steps={[
                        {
                            title: intlFormatMessage({ id: 'corporate.popup.step1.title' }),
                            content: <Step1 />
                        },
                        {
                            title: intlFormatMessage({ id: 'corporate.popup.step2.title' }),
                            content: <Step2 organizationId={props.organizationId} />
                        },
                        {
                            title: intlFormatMessage({ id: 'corporate.popup.step3.title' }),
                            content: <Step3 organizationId={props.organizationId} />
                        },
                        {
                            content: <Confirm clusterName={clusterData?.presentation?.name} />
                        }
                    ]}
                />
            )}
        </Formik>
    );
};