import React, { FC } from 'react';
import styled from 'styled-components';

import { useIntlMessage } from '../../../Common/IntlMessage';

const Wrapper = styled.div`
    width: 100%;
`;
export const Confirm: FC = () => {
    const { intlFormatMessage } = useIntlMessage();
    return (
        <Wrapper>
            <div dangerouslySetInnerHTML={{
                __html: intlFormatMessage({ id: `eApproval.wizard.confirm` })
            }} />
        </Wrapper>
    );
};