import React, { FC, useState, useCallback, useMemo, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { rgba } from 'polished';

import { IFullBranch, BranchId } from '../../../entities/ISoge';
import { removeArrDuplicates, arrayToObject } from '../../../tools/arrayTools';
import { removeKeyFromObject } from '../../../tools/objectTools';
import { useEscKey } from '../../../tools/keyboardTools';
import { colorStack } from '../../../styleHelpers/colors';
import { Button } from '../../Common/Buttons/NewButton';
import { WhiteBox } from '../../Common/WhiteBox/WhiteBox';
import { BranchRow } from '../Amendments/Steps/Branches/BranchRow';
import { Loader } from '../../Common/Loader/Loader';
import IntlMessage from '../../Common/IntlMessage';

const CommitWrapper = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    background: ${rgba(colorStack.darkBlue, .1)};
    z-index: 999;
`;

const BranchSelectWrapper = styled.div`
    margin-bottom: 2rem;
`;

const ConfirmCommitModal = styled(WhiteBox)`
    max-width: 650px;
    width: 100%;
`;

const BranchesWrapper = styled.div`
    margin-bottom: 2rem;
    border: 1px solid ${colorStack.ligthGrey};
    border-radius: 4px;
    max-width: 600px;
    overflow: auto;
    box-shadow: inset 0px 1px 2px ${rgba(colorStack.black, 0.25)};
    margin-top: 0.7rem;
    padding: .5rem 0;
`;

const Buttons = styled.div`
    margin-top: 1rem;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    button + button {
        margin-left: 0.5rem;
    }
`;

const CheckStructureWrapper = styled.div`
    display: flex;
`;

const CheckStructureMessage = styled.div`
    padding: 0 .5rem 0 0 ;
`;

const UnmatchedElementsWrapper = styled.div`
    margin: .2rem .5rem;
`;

const CheckConsistencyResult = styled.div<{isCorrectMatch: boolean}>`
    display: inline;
    ${props => props?.isCorrectMatch && css`
        color: ${colorStack.middleGreen};
    `}
    ${props => !props?.isCorrectMatch && css`
        color: ${colorStack.middleRed};
    `}
`;

interface IBranchPickerProps {
    availableBranches: IFullBranch[];
    loading: boolean;
    currentBranchId: string;
    checkStructure?: boolean;
    isCheckMessageVisible?: boolean;
    consistencyFullMatch?: boolean;
    isLanguageConsistencyOn?: boolean;
    onCancel(): void;
    onSelect(selectedBranches: string[]);
}

export const BranchPicker: FC<React.PropsWithChildren<IBranchPickerProps>> = ({ onCancel, onSelect, loading, availableBranches, currentBranchId, checkStructure, isCheckMessageVisible, consistencyFullMatch, isLanguageConsistencyOn }) => {
    useEscKey(onCancel);

    const [branchesIds, setBranchesIds] = useState<Record<BranchId, boolean>>({});

    const getSameGroupBranchesIds = useCallback((branchId: BranchId) => {
        const branchInGroup = availableBranches.find(branch => branch.id === branchId);
        const groupId = branchInGroup?.branchGroupId || branchInGroup?.languageConsistencyGroupId;
        return groupId && availableBranches.filter(branch => branch.branchGroupId === groupId || branch.languageConsistencyGroupId === groupId).map(branch => branch.id);
    }, [availableBranches]);

    const onSelectBranch = useCallback((branchId: BranchId, wasSelected: boolean) => {
        const sameGroupBranchesIds = getSameGroupBranchesIds(branchId);

        if (wasSelected) {
            setBranchesIds(removeKeyFromObject(branchesIds, sameGroupBranchesIds || branchId));
        } else {
            setBranchesIds({
                ...branchesIds,
                ...arrayToObject(sameGroupBranchesIds || [branchId], key => key, () => true)
            });
        }
    }, [getSameGroupBranchesIds, availableBranches, branchesIds]);

    useEffect(() => {
        const sameGroupBranchesIds = getSameGroupBranchesIds(currentBranchId);
        sameGroupBranchesIds?.length && setBranchesIds(arrayToObject(sameGroupBranchesIds, key => key, () => true));
    }, [currentBranchId, getSameGroupBranchesIds]);

    const selectedBranchIds = useMemo(() => {
        return Object.keys(branchesIds);
    }, [branchesIds]);

    const onStartApproval = useCallback(() => {
        onSelect(removeArrDuplicates(selectedBranchIds));
    }, [selectedBranchIds]);

    return (
        <CommitWrapper>
            <ConfirmCommitModal>
                <BranchSelectWrapper>
                    <div><IntlMessage id="tempid" /></div>
                    <Loader loading={loading}>
                        <BranchesWrapper>
                            {availableBranches.map(branch => (
                                <BranchRow
                                    key={branch.id}
                                    branchId={branch.id}
                                    branchName={branch.name}
                                    isSelected={branchesIds[branch.id]}
                                    onSelect={onSelectBranch}
                                />
                            ))}
                        </BranchesWrapper>
                    </Loader>
                </BranchSelectWrapper>
                {checkStructure ? (
                    <CheckStructureWrapper>
                        <CheckStructureMessage>
                            <IntlMessage id="norms.checkingStructureBetweenLanguages" />
                        </CheckStructureMessage>
                        <Loader loading={true}></Loader>
                    </CheckStructureWrapper>
                ) : isCheckMessageVisible && (
                    <CheckStructureMessage>
                        <IntlMessage id="norms.checkingStructureConsistencyFinished" />&nbsp;
                        {consistencyFullMatch ? (
                            <CheckConsistencyResult isCorrectMatch={consistencyFullMatch}>
                                <IntlMessage id="norms.consistencyMatched" />
                            </CheckConsistencyResult>
                        ) : (
                            <CheckConsistencyResult isCorrectMatch={consistencyFullMatch}>
                                <IntlMessage id="norms.consistencyNotMatched" />
                            </CheckConsistencyResult>
                        )}
                    </CheckStructureMessage>
                )}
                <Buttons>
                    <Button onClick={onCancel} disabled={loading}>
                        <IntlMessage id="global.cancel"  />
                    </Button>
                    <Button onClick={onStartApproval} disabled={(isLanguageConsistencyOn && (checkStructure || !consistencyFullMatch)) || loading || !selectedBranchIds.length}>
                        <IntlMessage id="global.continue"  />
                    </Button>
                </Buttons>
            </ConfirmCommitModal>
        </CommitWrapper>
    );
};
