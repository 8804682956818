import React, { FC, useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';

import { EApprovalContext, EApprovalTypes, IEApprovalContext, IMainStepProps } from '..';
import { Row } from '../../../../Common/Row/Row';
import { ETextComponentSubType, TextComponent } from '../../../../Common/Inputs/TextComponent';
import { IValue } from '../../../../../entities/IPickers';
import { DEFAULT_CURRENCY_KEY, EContextList, EReferentials, IReferencial } from '../../../../../entities/IGlobal';
import { NewRT } from '../../../../Common/RichText/NewRT';
import { DatePickerWrapper } from '../../../../Common/DatePicker/DatePicker';
import { getReferentials } from '../../../../../actions/globalActions';
import { useIntlMessage } from '../../../../Common/IntlMessage';

type GetReferentials = ReturnType<typeof getReferentials>;

const Wrapper = styled.div`
    width: 100%;
    > div {
        margin-bottom: 1rem;
    }
    > :last-child {
        margin: 0;
    }
`;

export const GroupingStep2: FC<IMainStepProps> = props => {
    const { intlFormatMessage } = useIntlMessage();
    const dispatch = useDispatch();
    const eApprovalContext = useContext<IEApprovalContext>(EApprovalContext);
    const [unitItems, setUnitItems] = useState<IReferencial[]>(undefined);

    useEffect(() => {
        eApprovalContext.modifyData(undefined, undefined, props.context, props.stepName, props.stepNumber);
        dispatch<GetReferentials>(getReferentials('', EReferentials.Currency, EContextList.Platform)).then(response => {
            setUnitItems(response);
        });
    }, []);

    useEffect(() => {
        if (!eApprovalContext.data?.[EApprovalTypes.Grouping]?.[props.stepName] && unitItems) {
            const defaultCurrency = unitItems?.find((elem) => elem.Key === DEFAULT_CURRENCY_KEY);
            eApprovalContext.modifyData({
                number: undefined,
                // tslint:disable-next-line:no-null-keyword
                baseNumber: null,
                unit: {
                    key: defaultCurrency?.id,
                    parentId: defaultCurrency?.referentialIdentityId,
                    uniqueKey: defaultCurrency?.Key
                }
            }, 'amountAllocated', props.context, props.stepName, props.stepNumber);
            eApprovalContext.modifyData({
                number: undefined,
                // tslint:disable-next-line:no-null-keyword
                baseNumber: null,
                unit: {
                    key: defaultCurrency?.id,
                    parentId: defaultCurrency?.referentialIdentityId,
                    uniqueKey: defaultCurrency?.Key
                }
            }, 'contractValue', props.context, props.stepName, props.stepNumber);
        }
    }, [unitItems]);

    const onTextChange = (field: string, value: string) => {
        eApprovalContext.modifyData(value, field, props.context, props.stepName, props.stepNumber);
    };

    const onRichTextChange = (value: string) => {
        if (value !== eApprovalContext.data?.[EApprovalTypes.Grouping]?.[props.stepName]?.reasonForPartnerShip) {
            const span = document.createElement('span');
            span.innerHTML = value;
            if (span.innerText?.trim()?.length > 0) {
                eApprovalContext.modifyData(value, 'reasonForPartnerShip', props.context, props.stepName, props.stepNumber);
            } else if (span.innerText?.trim()?.length === 0) {
                eApprovalContext.modifyData('', 'reasonForPartnerShip', props.context, props.stepName, props.stepNumber);
            }
        }
    };

    const onCurrencyChange = (field: string, valueText: string, unit: IValue) => {
        const value = valueText.replace(',', '.').replaceAll(' ', '');
        if (/^\d+\.?\d{0,6}$/.test(value) || value === '') {
            eApprovalContext.modifyData({
                number: valueText,
                // tslint:disable-next-line:no-null-keyword
                baseNumber: null,
                unit: {
                    key: unit?.data.key,
                    parentId: unit?.data.parentId || unit?.data.referentialIdentityId,
                    uniqueKey: unit?.data.uniqueKey || unit?.data.Key
                }
            }, field, props.context, props.stepName, props.stepNumber);
        }
    };

    const onDateChange = (field: string, endAt: Date) => {
        const changeHour = endAt ? new Date(new Date(endAt).setHours(7)) : undefined;
        eApprovalContext.modifyData(changeHour, field, props.context, props.stepName, props.stepNumber);
    };

    return (
        <Wrapper>
            <Row numberOfElements={1} noBorder>
                <TextComponent
                    value={eApprovalContext.data?.[EApprovalTypes.Grouping]?.[props.stepName]?.nameOfProject}
                    label={intlFormatMessage({ id: `eApproval.wizard.step2.${[EApprovalTypes.Grouping]}.nameOfProject` })}
                    onChange={(value) => onTextChange('nameOfProject', value)}
                    required
                />
            </Row>
            <Row numberOfElements={2} noBorder>
                {unitItems?.length > 0 &&
                    <TextComponent
                        label={intlFormatMessage({ id: `eApproval.wizard.step2.${[EApprovalTypes.Grouping]}.contractValue` })}
                        required
                        value={eApprovalContext.data?.[EApprovalTypes.Grouping]?.[props.stepName]?.contractValue?.number}
                        onChange={(valueText, unit) => onCurrencyChange('contractValue', valueText, unit)}
                        subType={ETextComponentSubType.CURRENCY}
                        currencies={unitItems?.sort((a, b) => a.name.localeCompare(b.name))}
                        selectedCurrency={(eApprovalContext.data?.[EApprovalTypes.Grouping]?.[props.stepName]?.contractValue?.unit?.uniqueKey && unitItems) ? {
                            key: eApprovalContext.data?.[EApprovalTypes.Grouping]?.[props.stepName]?.contractValue?.unit?.uniqueKey,
                            text: unitItems?.find(elem => elem.Key === eApprovalContext.data?.[EApprovalTypes.Grouping]?.[props.stepName]?.contractValue?.unit?.uniqueKey)?.name,
                            data: eApprovalContext.data?.[EApprovalTypes.Grouping]?.[props.stepName]?.contractValue?.unit
                        } : undefined}
                    />
                }
                {unitItems?.length > 0 &&
                    <TextComponent
                        label={intlFormatMessage({ id: `eApproval.wizard.step2.${[EApprovalTypes.Grouping]}.amountAllocated` })}
                        required
                        value={eApprovalContext.data?.[EApprovalTypes.Grouping]?.[props.stepName]?.amountAllocated?.number}
                        onChange={(valueText, unit) => onCurrencyChange('amountAllocated', valueText, unit)}
                        subType={ETextComponentSubType.CURRENCY}
                        currencies={unitItems?.sort((a, b) => a.name.localeCompare(b.name))}
                        selectedCurrency={(eApprovalContext.data?.[EApprovalTypes.Grouping]?.[props.stepName]?.amountAllocated?.unit?.uniqueKey && unitItems) ? {
                            key: eApprovalContext.data?.[EApprovalTypes.Grouping]?.[props.stepName]?.amountAllocated?.unit?.uniqueKey,
                            text: unitItems?.find(elem => elem.Key === eApprovalContext.data?.[EApprovalTypes.Grouping]?.[props.stepName]?.amountAllocated?.unit?.uniqueKey)?.name,
                            data: eApprovalContext.data?.[EApprovalTypes.Grouping]?.[props.stepName]?.amountAllocated?.unit
                        } : undefined}
                    />
                }
            </Row>
            <Row numberOfElements={2} noBorder>
                <DatePickerWrapper
                    required
                    label={intlFormatMessage({ id: `eApproval.wizard.step2.${[EApprovalTypes.Grouping]}.date` })}
                    value={eApprovalContext.data?.[EApprovalTypes.Grouping]?.[props.stepName]?.date}
                    onChange={(val) => onDateChange('date', val)}
                />
                <div></div>
            </Row>
            <Row numberOfElements={1} noBorder>
                <div>
                    <NewRT
                        label={intlFormatMessage({ id: `eApproval.wizard.step2.${[EApprovalTypes.Grouping]}.reasonForPartnerShip` })}
                        required
                        content={eApprovalContext.data?.[EApprovalTypes.Grouping]?.[props.stepName]?.reasonForPartnerShip}
                        onChange={onRichTextChange}
                    />
                </div>
            </Row>
        </Wrapper>
    );
};
