import React, { FC, Fragment, useEffect, useMemo, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { Value } from 'react-calendar/dist/cjs/shared/types';
import Scrollbars from 'react-custom-scrollbars';
import moment from 'moment';
import { faSearch, faPlusCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { colorStack } from '../../../styleHelpers/colors';
import { Button, EButtonSize, EButtonTypeSchema } from '../Buttons/NewButton';
import { IItem } from '../../../entities/IFilters';
import { fontSizeAndHeight } from '../../../styleHelpers/fontSizes';
import { Avatar, EAvatarSize } from '../Avatar/Avatar';
import { AllStatusEnums, EProfileType, EFilterType } from '../../../entities/IGlobal';
import { ESpinnerSize, Spinner } from '../Spinner/Spinner';
import { StatusDot } from '../StatusDot/StatusDot';
import { CustomCalendar } from '../CustomCalendar/CustomCalendar';
import { getStatusText } from '../../../tools/statusHelper';
import { TextComponent } from '../Inputs/TextComponent';
import { Checkbox } from '../Inputs/Checkbox';
import { RadioButton } from '../Inputs/RadioButton';
import { EButtonsTypes } from '../Pickers/CorePicker';
import { removeArrDuplicates } from '../../../tools/arrayTools';
import { MultilineTextWithEllipsis } from '../TextWithEllipsis/MultilineTextWithEllipsis';
import ScrollBox from '../ScrollBox/ScrollBox';
import { IValue } from '../../../entities/IPickers';
import IntlMessage, { useIntlMessage } from '../IntlMessage';

const Wrapper = styled.div`
    border-radius: 8px;
    width: 346px;
    overflow: hidden;
    background: ${colorStack.white};
    box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.16);
`;

const SearchBox = styled.div`
    padding: .5rem;
    background: ${colorStack.whiteGrey};
    input {
        background: ${colorStack.white};
    }
`;

const Results = styled.div`
`;

const ButtonsWrapper = styled.div`
    display: flex;
    padding: .5rem;
    background: ${colorStack.whiteGrey};
    align-items: center;
    justify-content: flex-end;
    gap: .5rem;
`;

const TooltipWrapper = styled.div`
    position: absolute;
    z-index: 99;
    bottom: -30px;
    width: 90%;
    > div {
        max-width: 100%;
        white-space: break-spaces;
        word-wrap: break-word;
    }
`;

const SingleElem = styled.div<{ active?: boolean }>`
    font-weight: ${({active}) => active ? 500 : 400};
    padding: 12px 1rem;
    min-height: 46px;
    justify-content: space-between;
    background: ${colorStack.white};
    transition: all .3s;
    cursor: pointer;
    position: relative;
    display: flex;
    align-items: center;
    &:hover {
        background: ${colorStack.lightBlue};
    }
    ${props => props.active && css`
        background: ${colorStack.lightBlue};
    `}
    > div:first-child {
        width: calc(100% - .5rem - 34px);
    }
    > div:nth-child(2) {
        width: calc(100% - 35px);
    }
`;

const NoResult = styled.div`
    height: 62px;
    display: flex;
    align-items: center;
    justify-content: center;
    ${fontSizeAndHeight[16]};
    color: ${colorStack.content};
`;

const ShadowLayer = styled.div`
    width: 97%!important;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 999;
`;

const AddingButton = styled.div`
    color: ${colorStack.middleBlue};
    ${fontSizeAndHeight[16]};
    font-weight: 500;
    height: 48px;
    padding: 0.5rem 1rem;
    display: flex;
    align-items: center;
    position: relative;
    margin: 0 auto;
    z-index: 9;
    cursor:pointer;
    svg {
        margin: 0 0.5rem 0 0;
    }
`;

const DoubleCreationWrapper = styled(AddingButton as any)`
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
    height: 70px;
    &:hover {
        background: unset;
    }
`;

const SingleCreateWrapper = styled.div`
    width: 100%;
    text-align: center;
    position: relative;
    z-index: 9;
    cursor: pointer;
`;

interface ISingleResultElement {
    elem: IItem;
    active?: boolean;
    type: string;
    translationKey?: string;
    multiSelect?: boolean;
    selectItem(elem: IItem);
}

export const SingleResultElement: FC<React.PropsWithChildren<ISingleResultElement>> = props => {
    const { intlFormatMessage } = useIntlMessage();

    const selectItem = (e?: React.MouseEvent<HTMLDivElement>) => {
        e?.stopPropagation();
        e?.preventDefault();
        props.selectItem({
            ...props.elem, text: props.type === 'status' ? getStatusText(props.elem.text as AllStatusEnums, intlFormatMessage) : props.elem.text
        });
    };

    return (
        <SingleElem key={props.elem.id} active={props.active}>
            <ShadowLayer onClick={selectItem} />
            {(props.type === EProfileType.Personal || props.type === EProfileType.Company || props.type === EProfileType.Group || props.type === EProfileType.Cluster) &&
                <Avatar
                    extended
                    firstName={props.elem.text}
                    lastName=""
                    type={props.type as EProfileType}
                    id={props.elem.id}
                    metaInfo={props.elem.data?.emailContact}
                    size={EAvatarSize.SMALL}
                    picture={props.elem.data.picture}
                    preventRedirection
                />
            }
            {props.type === 'default' &&
                <MultilineTextWithEllipsis numberOfLines={2}>
                    {props.translationKey ? <IntlMessage id={`${props.translationKey}.${props.elem.data.key || props.elem.data}`} /> : props.elem.text}
                </MultilineTextWithEllipsis>
            }
            {props.type === 'status' &&
                <StatusDot status={props.elem.text as AllStatusEnums} extended size={16} />
            }
            {props.multiSelect ? (
                <Checkbox checked={props.active} />
            ) : (
                <RadioButton checked={props.active} />
            )}
        </SingleElem>
    );
};

interface IProps {
    items: IItem[];
    selectedItems: IItem[];
    dateSelectedItems?: {
        fromDate: Date;
        toDate: Date;
    };
    placeholder?: string;
    type: EFilterType;
    translationKey: string;
    multiSelect?: boolean;
    hideButtons?: boolean;
    loading?: boolean;
    buttonsType?: EButtonsTypes;
    className?: string;
    onSelectItem(item: IItem);
    onClear();
    onSave();
    getData?(text: string);
    userFormHandler?();
    companyFormHandler?();
    outsideGetSearchValue?(value: string);
}

let resultsDebounce: ReturnType<typeof setTimeout>;
let debounceTimout: ReturnType<typeof setTimeout> = undefined;

export const ResultBox: FC<React.PropsWithChildren<IProps>> = props => {
    const [searchValue, setSearchValue] = useState<string>('');
    const [page, setPage] = useState<number>(0);
    const [loading, setLoading] = useState<boolean>(false);
    const [dateValue, setDateValue] = useState<Value>([undefined, undefined]);
    const [displayedElements, setDisplayedElements] = useState<IItem[]>([]);
    const scrollBarsRef = useRef<Scrollbars>();

    useEffect(() => {
        setLoading(props.loading);
    }, [props.loading]);

    const changeHandler = (value: string, item: IValue, e: React.ChangeEvent<HTMLInputElement>) => {
        setPage(0);
        setLoading(true);
        clearTimeout(resultsDebounce);
        e.stopPropagation();
        setSearchValue(value);
        props.outsideGetSearchValue?.(value);
        clearTimeout(debounceTimout);
        resultsDebounce = setTimeout(async () => {
            props.getData?.(value);
        }, 1000);
        if (!props.getData) {
            setTimeout(() => {
                setLoading(false);
            }, 500);
        }
    };

    useEffect(() => {
        if (props.dateSelectedItems) {
            setDateValue([props.dateSelectedItems?.fromDate, props.dateSelectedItems?.toDate]);
        }
    }, [props.dateSelectedItems]);

    const selectItem = (elem: IItem) => {
        props.onSelectItem(elem);
    };

    const onClick = (e) => {
        e.stopPropagation();
    };

    const dateChangeHandler = (value: Value) => {
        setDateValue(value);

        const dateRanges = {
            text: `${moment(moment.utc(value[0])).local().format('DD MMM YYYY')} - ${moment(moment.utc(value[1])).local().format('DD MMM YYYY')}`,
            id: 'dateRanges',
            visible: true,
            data: {
                isDate: true,
                fromDate: value[0],
                toDate: value[1]
            },
            translationKey: undefined
        };
        selectItem(dateRanges);
    };

    const clearHandler = () => {
        setSearchValue('');
        setPage(0);
        props.outsideGetSearchValue?.('');
        setLoading(true);
        clearTimeout(debounceTimout);
        props.getData?.('');
        debounceTimout = setTimeout(() => {
            setLoading(false);
        }, 500);
    };

    const getRef = (scrollRef: Scrollbars) => {
        scrollBarsRef.current = scrollRef;
    };

    const filteredElements = useMemo(() => {
        const nameFilter = props.items?.filter(elem => elem?.text?.toLowerCase()?.includes(searchValue.toLowerCase())) || [];
        const emailFilter = props.items?.filter(elem => elem?.data?.emailContact?.toLowerCase()?.includes(searchValue.toLowerCase())) || [];
        return removeArrDuplicates([...nameFilter, ...emailFilter]);
    }, [props.items, searchValue]);

    const getDisplayedElements = (pageNumber) => {
        const newDisplayedElements = [...filteredElements];
        setDisplayedElements(newDisplayedElements.slice(0, 50 + pageNumber * 50));
    };

    useEffect(() => {
        getDisplayedElements(page);
    }, [page, filteredElements.length]);

    const getMoreResults = () => {
        setPage((prevState) => prevState + 1);
    };

    return (
        <Wrapper className={props.className}>
            {props.type !== EFilterType.date &&
                <SearchBox>
                    <TextComponent
                        value={searchValue}
                        autoFocus
                        placeholder={props.placeholder}
                        onClearClick={clearHandler}
                        onClick={onClick}
                        onChange={changeHandler}
                        leftIco={faSearch}
                    />
                </SearchBox>
            }
            <Results>
                {props.type === EFilterType.date ? (
                    <CustomCalendar
                        rangePicker
                        onChange={dateChangeHandler}
                        value={dateValue}
                    />
                ) : (
                    <ScrollBox
                        hasMore={filteredElements.length > 0 && (filteredElements?.length > displayedElements?.length)}
                        getRef={getRef}
                        withoutChildrenCount
                        onScrollResults={getMoreResults}
                        autoHeightMax={230}
                        autoHeight
                    >
                        {
                            <>
                                {loading ? (
                                    <>
                                        <br />
                                        <Spinner size={ESpinnerSize.SMALL} />
                                        <br />
                                    </>
                                ) : (
                                    <div>
                                        {displayedElements?.length > 0 ? (
                                            <>
                                                {displayedElements?.map(elem =>
                                                    <Fragment key={elem.id}>
                                                        <SingleResultElement
                                                            elem={elem}
                                                            multiSelect={props.multiSelect}
                                                            active={!!props.selectedItems?.find(selectedItem => selectedItem.id === elem.id)}
                                                            selectItem={selectItem}
                                                            type={elem.data?.type || props.type.toLocaleLowerCase()}
                                                            translationKey={props.translationKey}
                                                        />
                                                    </Fragment>
                                                )}
                                            </>
                                        ) : (
                                            <NoResult>
                                                <IntlMessage id="global.noResultsFound" />
                                            </NoResult>
                                        )}
                                    </div>
                                )}
                            </>
                        }
                    </ScrollBox>
                )}
            </Results>
            <ButtonsWrapper>
                {(!props.hideButtons && !loading && filteredElements?.length === 0) &&
                    <>
                        {props.buttonsType === EButtonsTypes.All
                            ? <DoubleCreationWrapper>
                                <SingleCreateWrapper onClick={props.userFormHandler}>
                                    <FontAwesomeIcon icon={faPlusCircle} />
                                    <IntlMessage id="searchInput.button.createPerson" />
                                </SingleCreateWrapper>
                                <SingleCreateWrapper onClick={props.companyFormHandler}>
                                    <FontAwesomeIcon icon={faPlusCircle} />
                                    <IntlMessage id="searchInput.button.createLegalEntity" />
                                </SingleCreateWrapper>
                            </DoubleCreationWrapper>
                            :
                            <>
                                {props.buttonsType === EButtonsTypes.Person &&
                                    <AddingButton onClick={props.userFormHandler}>
                                        <FontAwesomeIcon icon={faPlusCircle} />
                                        <IntlMessage id="searchInput.button.createPerson" />
                                    </AddingButton>
                                }
                                {(props.buttonsType === EButtonsTypes.Company) &&
                                    <AddingButton onClick={props.companyFormHandler}>
                                        <FontAwesomeIcon icon={faPlusCircle} />
                                        <IntlMessage id="searchInput.button.createLegalEntity" />
                                    </AddingButton>
                                }
                            </>
                        }
                    </>
                }
                {(filteredElements?.length > 0 || props.type === EFilterType.date) &&
                    <>
                        <Button
                            size={EButtonSize.SMALL}
                            typeSchema={EButtonTypeSchema.TERTIARY}
                            onClick={props.onClear}
                            disabled={props.type === EFilterType.date ? !dateValue[1] : (!props.selectedItems || props.selectedItems?.length === 0)}
                        >
                            <IntlMessage id="filters.clear" />
                        </Button>
                        <Button
                            size={EButtonSize.SMALL}
                            onClick={props.onSave}
                            disabled={props.type === EFilterType.date ? !dateValue[1] : (!props.selectedItems || props.selectedItems?.length === 0)}
                        >
                            <IntlMessage id="filters.apply" />
                        </Button>
                    </>
                }
            </ButtonsWrapper>
        </Wrapper>
    );
};