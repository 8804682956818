import React, { FC, useCallback, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { useFormikContext } from 'formik';

import { fontSize } from '../../../../../styleHelpers/fontSizes';
import { ISIngleLegalEntity } from '../../../../../entities/ILegalEntities';
import * as PopupStyles from '../../../Common/Styles';
import { TextComponent } from '../../../../Common/Inputs/TextComponent';
import { MandatoryFieldStar } from '../../../../Common/MandatoryFieldStar/MandatoryFieldStar';
import { DatePickerWrapper } from '../../../../Common/DatePicker/DatePicker';
import { LegalEntitiesPicker } from '../../../../Common/Pickers/LegalEntitiesPicker';
import { NewRT } from '../../../../Common/RichText/NewRT';
import { LabelWithEllipsis } from '../../../../Common/Label/Label';
import { ITradeAssociationData } from '../../../../../entities/ActionData/ITradeAssociationData';
import IntlMessage from '../../../../Common/IntlMessage';

const Wrapper = styled.div`
    width: 100%;
    display: block;
    margin: 0 auto;
`;

const InnerWrapper = styled.div`
    position: relative;
    border-radius: 4px;
    margin-bottom: 26px;
    display: flex;
    flex-direction: column;
`;

const Row = styled.div<{ alignEnd?: boolean }>`
    display: flex;
    flex-direction: row;
    ${props => props.alignEnd && css`
        align-items: end;
    `};
`;

const InputSection = styled.div<{ fullWidth?: boolean, fullHeight?: boolean }>`
    padding: 20px 28px;
    width: 50%;
    ${props => props.fullWidth && css`
        width: 100%;
    `}

    > p {
        margin-bottom: 20px;
        font-size: ${fontSize[20]};
        text-align: left;
    }
`;
const MarginWrapper = styled.div`
    margin-bottom: .5rem;
`;

export const Step2: FC<React.PropsWithChildren<unknown>> = props => {
    const { setFieldValue, values } = useFormikContext<ITradeAssociationData>();

    useEffect(() => {
        setFieldValue('step', 2);
    }, []);

    const fillLegalEntityInformation = useCallback((data: ISIngleLegalEntity) => {
        setFieldValue(`formData.tradeAssociationDeclaration.thirdParty`, data ? {
            name: data?.name,
            picture: data?.picture,
            id: data?.id,
            type: data.type
        } : undefined);
    }, []);

    const onChangeSelectedEntity = useCallback((element: { key: string; text: string; data: ISIngleLegalEntity; }[]) => {
        fillLegalEntityInformation(element?.[0]?.data);
    }, []);

    const changeStartDate = useCallback((target: string, endAt: Date) => {
        const changeHour = endAt ? new Date(new Date(endAt).setHours(7)) : undefined;
        setFieldValue(target, changeHour);
    }, []);

    return (
        <Wrapper>
            <InnerWrapper>
                <Row>
                    <InputSection fullWidth>
                        <MarginWrapper>
                            <IntlMessage id={'tradeAssociation.wizard.step2.text1'} />
                        </MarginWrapper>
                        <LabelWithEllipsis required>
                            <IntlMessage id={'tradeAssociation.wizard.step2.text2'} />
                        </LabelWithEllipsis>
                        <TextComponent
                            value={values.formData.tradeAssociationDeclaration.description}
                            onChange={(value: string) => setFieldValue('formData.tradeAssociationDeclaration.description', value)}
                        />
                    </InputSection>
                </Row>
                <Row>
                    <InputSection fullWidth>
                        <MarginWrapper>
                            <IntlMessage id={'tradeAssociation.wizard.step2.text3'} />
                        </MarginWrapper>
                        <LabelWithEllipsis required>
                            <IntlMessage id={'tradeAssociation.wizard.step2.text4'} />
                        </LabelWithEllipsis>
                        <LegalEntitiesPicker
                            value={values.formData?.tradeAssociationDeclaration?.thirdParty ? [{
                                key: values.formData?.tradeAssociationDeclaration?.thirdParty?.id,
                                text: values.formData?.tradeAssociationDeclaration?.thirdParty?.name || values.formData.tradeAssociationDeclaration.thirdParty?.name,
                                data: values.formData?.tradeAssociationDeclaration?.thirdParty
                            }] : undefined}
                            onSelectElement={onChangeSelectedEntity}
                            allCompanies
                        />
                    </InputSection>
                </Row>
                <Row alignEnd>
                    <InputSection>
                        <MarginWrapper>
                            <IntlMessage id={'tradeAssociation.wizard.step2.text5'} />
                        </MarginWrapper>
                        <LabelWithEllipsis required>
                            <IntlMessage id={'tradeAssociation.wizard.step2.text6'} />
                        </LabelWithEllipsis>
                        <DatePickerWrapper
                            value={values.formData.tradeAssociationDeclaration.startingDate}
                            onChange={(val) => changeStartDate('formData.tradeAssociationDeclaration.startingDate', val)}
                        />
                    </InputSection>
                    <InputSection>
                        <LabelWithEllipsis>
                            <IntlMessage id={'tradeAssociation.wizard.step2.text7'} />
                        </LabelWithEllipsis>
                        <DatePickerWrapper
                            value={values.formData.tradeAssociationDeclaration.endingDate}
                            onChange={(val) => changeStartDate('formData.tradeAssociationDeclaration.endingDate', val)}
                        />
                    </InputSection>
                </Row>
                <Row>
                    <InputSection fullHeight fullWidth>
                        <LabelWithEllipsis><IntlMessage id={'tradeAssociation.wizard.step2.text8'} /></LabelWithEllipsis>
                        <NewRT
                            content={values.formData.tradeAssociationDeclaration.context}
                            onChange={(text) => setFieldValue('formData.tradeAssociationDeclaration.context', text)}
                        />
                    </InputSection>
                </Row>
            </InnerWrapper>
            <PopupStyles.RequiredInformation>
                <PopupStyles.Title>
                    <MandatoryFieldStar />
                    <IntlMessage id="tradeAssociation.wizard.requiredInfo" />
                </PopupStyles.Title>
            </PopupStyles.RequiredInformation>
        </Wrapper>
    );

};