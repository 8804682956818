import React, { useMemo, useCallback, useEffect, useState, FC } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { IState } from '../../../../../reducers';
import { ILanguageReducer } from '../../../../../reducers/languageReducer';
import { EStakeDetailsSubType, IClusterStakeProperty, ITranslationItem } from '../../../../../entities/IClusterStake';
import { ErrorText } from './StakeStyles';
import { SelectInput } from '../../../../Common/SelectInput/SelectInput';
import { StatusDot } from '../../../../Common/StatusDot/StatusDot';
import { AllStatusEnums, EStatus } from '../../../../../entities/IGlobal';
import { IValue } from '../../../../../entities/IPickers';
import { EDropDownType } from '../../../../Common/DropDown/DropDown';
import { getStatusColor } from '../../../../../tools/statusHelper';
import { useIntlMessage } from '../../../../Common/IntlMessage';

const Wrapper = styled.div``;

interface IEnumSelectContent {
    data: IClusterStakeProperty;
    propertieId: string;
    error: string;
    editStake: boolean;
    setEditingData(propertie: IClusterStakeProperty);
}

export const EnumSelectContent: FC<React.PropsWithChildren<IEnumSelectContent>> = props => {
    const { intlFormatMessage } = useIntlMessage();
    const { userLanguage, translations } = useSelector<IState, ILanguageReducer>(state => state.language);
    const placeholder = useMemo(() => intlFormatMessage({ id: 'global.selectValue' }), []);
    const emptyItem: ITranslationItem = { enumElement: 'empty', translationKey: 'global.selectValue' };
    const options = useMemo(() => [
        { key: emptyItem.enumElement, text: placeholder, data: emptyItem },
        ...(props.data?.configuration?.items || []).map(item => ({ key: item.enumElement, text: translations[userLanguage][item.translationKey], data: {...item, color: getStatusColor(item.enumElement as AllStatusEnums)} }))
    ], [emptyItem, placeholder, translations, userLanguage, props.data.configuration.items]);
    // tslint:disable-next-line:no-null-keyword
    const [selectedItem, setSelectedItem] = useState<ITranslationItem>(null);

    useEffect(() => {
        // tslint:disable-next-line:no-null-keyword
        setSelectedItem(props.data?.value || null);
    }, [props.data, props.editStake]);

    const changeHandler = useCallback((dataObj: ITranslationItem) => {
        // tslint:disable-next-line:no-null-keyword
        props.setEditingData({ ...props.data, value: dataObj?.enumElement !== 'empty' ? dataObj : null });
    }, [props.data, props.setEditingData]);

    return (
        <Wrapper>
            {props.editStake ? (
                <>
                    <SelectInput
                        value={selectedItem ? [{key: selectedItem?.enumElement, text: translations[userLanguage][selectedItem?.translationKey], data: selectedItem}] : undefined}
                        onChange={(option: IValue) => changeHandler(option?.data)}
                        options={options}
                        type={props.data.subType === EStakeDetailsSubType.Status ? EDropDownType.STATUS : EDropDownType.DEFAULT}
                    />
                    {props.error && <ErrorText>{props.error}</ErrorText>}
                </>
            ) : (
                <>
                    {selectedItem && <StatusDot status={selectedItem.enumElement as EStatus} extended />}
                </>
            )}
        </Wrapper>
    );
};
