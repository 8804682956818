import React, { useState, useEffect, FC } from 'react';
import { useFormikContext } from 'formik';
import styled, { css } from 'styled-components';
import { useDispatch } from 'react-redux';

import { EClusterPrivacy, EClusterVisibility, EContextTypes } from '../../../entities/IClusters';
import { EReferentials, EProfileType, EContextList, EClusterStatus } from '../../../entities/IGlobal';
import { getReferentials, getReferentialsIdentities } from '../../../actions/globalActions';
import { IPresentation, PresentationEditField } from '../../../entities/CLE/ICLE';
import { EStakeDetailsType } from '../../../entities/IClusterStake';
import { fontSizeAndHeight } from '../../../styleHelpers/fontSizes';
import { colorStack } from '../../../styleHelpers/colors';
import { media } from '../../../styleHelpers/breakpoint';
import { WhiteCollapsedSection } from '../WhiteCollapsedSection/WhiteCollapsedSection';
import { PresentationSection } from '../../Organization/ClustersV2/ClusterOverview/PresentationComponents';
import { SetPropertiesIcon } from '../../Organization/ClustersV2/ClusterOverview/StakeComponents/SetPropertiesIcon';
import { ProfilesSummary } from '../ProfilesSummary/ProfilesSummary';
import { clusterPrivacyTranslation, clusterVisibilityTranslation } from '../../../tools/translationTools';
import { EProfileDataType } from '../../../tools/profileConstants';
import { SelectInput } from '../../Common/SelectInput/SelectInput';
import { convertPascalCaseKeyToCamelCaseKey } from '../../../tools/string';
import { getStatusText } from '../../../tools/statusHelper';
import { NewRtView } from '../RichText/NewRtView';
import { IValue } from '../../../entities/IPickers';
import { EDropDownType } from '../DropDown/DropDown';
import { UsersPicker } from '../Pickers/UsersPicker';
import { StaticDataPicker } from '../Pickers/StaticDataPicker';
import { Pill } from '../Pill/Pill';
import IntlMessage, { useIntlMessage } from '../IntlMessage';

type GetReferentialsIdentities = ReturnType<typeof getReferentialsIdentities>;
type GetReferentials = ReturnType<typeof getReferentials>;

const Wrapper = styled.div<{ pageBreakOn?: boolean; }>`
    ${props => props.pageBreakOn && css`
        page-break-inside:avoid;
    `}
`;

const PillWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: .5rem;
`;

const RowLeft = styled.div`
    ${fontSizeAndHeight[13]};
    color: ${colorStack.disabled};
    width: 100%;
    font-weight: 500;
    margin: 0 0 1rem 0;
    ${media.desktop`
        width: 25%;
        margin: 0;
    `};
    svg, img {
        margin: 0 1rem 0 0;
        width: 16px!important;
    }
`;

const RowRight = styled.div`
    width: 100%;
    ${media.desktop`
        width: 75%;
    `}
`;

interface IPresentationProps {
    editMode: boolean;
    data: IPresentation;
    context: 'Cluster' | 'PLE';
    pageBreakOn?: boolean;
}

export const Presentation: FC<React.PropsWithChildren<IPresentationProps>> = props => {
    const { intlFormatMessage } = useIntlMessage();
    const visibilityObject = [{ key: '0', text: 'Visible', data: 'Visible' }, { key: '1', text: 'Hidden', data: 'Hidden' }];
    const dispatch = useDispatch();
    const formikContext = useFormikContext<any>();
    const [referentialsName, setReferentialsName] = useState<{ name: string, type: string, key: string }[]>(undefined);

    useEffect(() => {
        dispatch<GetReferentialsIdentities>(getReferentialsIdentities('')).then(res => {
            const ref1 = res.filter(elem => elem.referentialType === EProfileDataType.FirstReferential)[0];
            const ref2 = res.filter(elem => elem.referentialType === EProfileDataType.SecondReferential)[0];
            const ref3 = res.filter(elem => elem.referentialType === EProfileDataType.ThirdReferential)[0];
            setReferentialsName([{ name: ref1.name, type: ref1.referentialType, key: ref1.key }, { name: ref2.name, type: ref2.referentialType, key: ref2.key }, { name: ref3.name, type: ref3.referentialType, key: ref3.key }]);
        });
    }, [props.data?.organizationId]);

    const resolveReferentials = async (type: EReferentials, query: string): Promise<IValue[]> => {
        const referentials = await dispatch<GetReferentials>(getReferentials(query, type, EContextList.Platform));
        return referentials.map(ref => ({
            key: ref.Key || ref.id,
            text: ref.name,
            data: ref
        }));
    };

    const onSelectElement = (data: IValue[]) => {
        if (data?.length) {
            formikContext.setFieldValue(PresentationEditField[props.context].peopleInCharge, [data[0]?.data]);
        } else {
            formikContext.setFieldValue(PresentationEditField[props.context].peopleInCharge, undefined);
        }
    };

    return (
        <Wrapper pageBreakOn>
            <a data-scrollId="10" data-scrollName={intlFormatMessage({ id: 'cluster.label.presentation' })} />
            {props.data && (!props.editMode ? (
                <WhiteCollapsedSection
                    title={<IntlMessage id="cluster.label.presentation" />}
                    editMode={props.editMode}
                >
                    {!!PresentationEditField[props.context].description && !!props.data?.description?.length && (
                        <PresentationSection>
                            <RowLeft>
                                <SetPropertiesIcon type={EStakeDetailsType.Text} />
                                <IntlMessage id="cluster.label.description" />
                            </RowLeft>
                            {!!props.data?.description && (
                                <RowRight>
                                    <NewRtView content={props.data.description || ''} />
                                </RowRight>
                            )}
                        </PresentationSection>
                    )}
                    {!!PresentationEditField[props.context].peopleInCharge && !!props.data?.peopleInCharge?.length && (
                        <PresentationSection>
                            <RowLeft>
                                <SetPropertiesIcon type={EStakeDetailsType.Person} />
                                <IntlMessage id="cluster.label.peopleincharge" />
                            </RowLeft>
                            {!!props.data?.peopleInCharge?.length && (
                                <RowRight>
                                    {(props.data.peopleInCharge || []).map(profile => (
                                        <ProfilesSummary key={profile.id} type={EProfileType.Personal} profile={profile} />
                                    ))}
                                </RowRight>
                            )}
                        </PresentationSection>
                    )}
                    {(!!props.data?.firstReferential && !!PresentationEditField[props.context].firstReferential && (!!props.data.firstReferential?.referentials?.length || !!props.data.firstReferential?.length)) && (
                        <PresentationSection>
                            <RowLeft>
                                <SetPropertiesIcon type={EStakeDetailsType.Reference} />
                                <span>
                                    {referentialsName?.[0]?.key !== undefined ?
                                        <IntlMessage id={convertPascalCaseKeyToCamelCaseKey(referentialsName?.[0]?.key?.replace('referentials.', 'referentialIdentities.'))} />
                                        : referentialsName?.[0]?.name
                                    }
                                </span>
                            </RowLeft>
                            <RowRight>
                                <PillWrapper>
                                    {(props.data.firstReferential?.referentials || props.data.firstReferential || []).map(referential => (
                                        <Pill
                                            text={referential.name || referential.Name}
                                            id={referential.id}
                                            key={referential.id}
                                        />
                                    ))}
                                </PillWrapper>
                            </RowRight>
                        </PresentationSection>
                    )}
                    {(!!props.data?.secondReferential && !!PresentationEditField[props.context].secondReferential && !!props.data.secondReferential?.referentials?.length || !!props.data.secondReferential?.length) && (
                        <PresentationSection>
                            <RowLeft>
                                <SetPropertiesIcon type={EStakeDetailsType.Reference} />
                                <span>
                                    {referentialsName?.[1]?.key !== undefined ?
                                        <IntlMessage id={convertPascalCaseKeyToCamelCaseKey(referentialsName?.[1]?.key?.replace('referentials.', 'referentialIdentities.'))} />
                                        : referentialsName?.[1]?.name
                                    }
                                </span>
                            </RowLeft>
                            <RowRight>
                                <PillWrapper>
                                    {(props.data.secondReferential?.referentials || props.data.secondReferential || []).map(referential => (
                                        <Pill
                                            text={referential.name || referential.Name}
                                            id={referential.id}
                                            key={referential.id}
                                        />
                                    ))}
                                </PillWrapper>
                            </RowRight>
                        </PresentationSection>
                    )}
                    {(!!props.data?.thirdReferential && !!PresentationEditField[props.context].thirdReferential && !!props.data.thirdReferential?.referentials?.length || !!props.data.thirdReferential?.length) && (
                        <PresentationSection>
                            <RowLeft>
                                <SetPropertiesIcon type={EStakeDetailsType.Reference} />
                                <span>
                                    {referentialsName?.[2]?.key !== undefined ?
                                        <IntlMessage id={convertPascalCaseKeyToCamelCaseKey(referentialsName?.[2]?.key?.replace('referentials.', 'referentialIdentities.'))} />
                                        : referentialsName?.[2]?.name
                                    }
                                </span>
                            </RowLeft>
                            <RowRight>
                                <PillWrapper>
                                    {(props.data.thirdReferential?.referentials || props.data.thirdReferential || []).map(referential => (
                                        <Pill
                                            text={referential.name || referential.Name}
                                            id={referential.id}
                                            key={referential.id}
                                        />
                                    ))}
                                </PillWrapper>
                            </RowRight>
                        </PresentationSection>
                    )}
                </WhiteCollapsedSection>
            ) : (
                <>
                    <WhiteCollapsedSection
                        title={<IntlMessage id="cluster.label.presentation" />}
                    >
                        {!!PresentationEditField[props.context].peopleInCharge &&
                            <PresentationSection>
                                <RowLeft>
                                    <SetPropertiesIcon type={EStakeDetailsType.Person} />
                                    <IntlMessage id="cluster.label.peopleincharge" />
                                </RowLeft>
                                <RowRight>
                                    <UsersPicker
                                        value={props.data?.peopleInCharge ? props.data?.peopleInCharge?.map(elem => ({ key: elem.id, text: `${elem.firstName} ${elem.lastName}`, data: elem })) : undefined}
                                        onSelectElement={onSelectElement}
                                    />
                                </RowRight>
                            </PresentationSection>
                        }
                        {!!PresentationEditField.PLE.visibility && props.context === EContextTypes.PLE &&
                            <PresentationSection>
                                <RowLeft>
                                    <SetPropertiesIcon type={EStakeDetailsType.Reference} />
                                    <IntlMessage id="cluster.label.visibility" />
                                </RowLeft>
                                <RowRight>
                                    <SelectInput
                                        value={props.data?.visibility ? [{ key: props.data?.visibility?.toString(), text: visibilityObject.find(elem => elem.key === props.data?.visibility.toString()).text, data: props.data?.visibility }] : undefined}
                                        onChange={(value: IValue) => formikContext.setFieldValue(PresentationEditField.PLE.visibility, value.key)}
                                        options={visibilityObject.map(elem => ({
                                            key: elem.key,
                                            text: clusterVisibilityTranslation(EClusterVisibility[elem.text], intlFormatMessage),
                                            data: {
                                                ...elem
                                            }
                                        }))}
                                        type={EDropDownType.DEFAULT}
                                    />
                                </RowRight>
                            </PresentationSection>
                        }
                        {!!PresentationEditField[props.context].status &&
                            <PresentationSection>
                                <RowLeft>
                                    <SetPropertiesIcon type={EStakeDetailsType.Reference} />
                                    <IntlMessage id="cluster.label.status" />
                                </RowLeft>
                                <RowRight>
                                    <SelectInput
                                        value={[{ key: props.data?.status, text: props.data?.status, data: props.data?.status }]}
                                        onChange={(value: IValue) => formikContext.setFieldValue(PresentationEditField[props.context].status, value.key)}
                                        options={[EClusterStatus.Active, EClusterStatus.Closed, EClusterStatus.Archived].map(key => ({
                                            key, text: getStatusText(key, intlFormatMessage), data: {
                                                key,
                                                simpleText: true
                                            }
                                        }))}
                                        type={EDropDownType.STATUS}
                                    />
                                </RowRight>
                            </PresentationSection>
                        }
                        {!!PresentationEditField[props.context].privacy &&
                            <PresentationSection>
                                <RowLeft>
                                    <SetPropertiesIcon type={EStakeDetailsType.Reference} />
                                    <IntlMessage id="cluster.label.privacy" />
                                </RowLeft>
                                <RowRight>
                                    <SelectInput
                                        value={[{ key: props.data?.privacy, text: props.data?.privacy, data: props.data?.privacy }]}
                                        onChange={(value: IValue) => formikContext.setFieldValue(PresentationEditField[props.context].privacy, value?.key)}
                                        options={Object.keys(EClusterPrivacy).map(key => ({
                                            key, text: clusterPrivacyTranslation(EClusterPrivacy[key], intlFormatMessage), data: {
                                                key
                                            }
                                        }))}
                                        type={EDropDownType.DEFAULT}
                                    />
                                </RowRight>
                            </PresentationSection>
                        }
                        {!!PresentationEditField[props.context].firstReferential &&
                            <PresentationSection>
                                <RowLeft>
                                    <SetPropertiesIcon type={EStakeDetailsType.Reference} />
                                    <span>
                                        {referentialsName?.[0]?.key !== undefined ?
                                            <IntlMessage id={convertPascalCaseKeyToCamelCaseKey(referentialsName?.[0]?.key?.replace('referentials.', 'referentialIdentities.'))} />
                                            : referentialsName?.[0]?.name
                                        }
                                    </span>
                                </RowLeft>
                                <RowRight>
                                    <StaticDataPicker
                                        getData={query => resolveReferentials(EReferentials.FirstReferential, query)}
                                        multiSelect
                                        onSelectElementMulti={tags => formikContext.setFieldValue(PresentationEditField[props.context].firstReferential, props.context === 'PLE' ? tags.map(tag => ({
                                            ...tag,
                                            Name: tag.data.Name || tag.data.name
                                        })) : ({
                                            name: referentialsName?.[0]?.name || '',
                                            type: referentialsName?.[0]?.type || '',
                                            referentials: tags.map(tag => ({
                                                ...tag,
                                                id: tag.data.id,
                                                name: tag.data.Name || tag.data.name
                                            }))
                                        }))}
                                        value={(props.data?.firstReferential?.referentials || props.data?.firstReferential || []).map(referential => ({
                                            key: referential.Key || referential.id,
                                            text: referential.Name || referential.name,
                                            data: referential
                                        }))}
                                    />
                                </RowRight>
                            </PresentationSection>
                        }

                        {!!PresentationEditField[props.context].secondReferential &&
                            <PresentationSection>
                                <RowLeft>
                                    <SetPropertiesIcon type={EStakeDetailsType.Reference} />
                                    <span>
                                        {referentialsName?.[1]?.key !== undefined ?
                                            <IntlMessage id={convertPascalCaseKeyToCamelCaseKey(referentialsName?.[1]?.key?.replace('referentials.', 'referentialIdentities.'))} />
                                            : referentialsName?.[1]?.name
                                        }
                                    </span>
                                </RowLeft>
                                <RowRight>
                                    <StaticDataPicker
                                        getData={query => resolveReferentials(EReferentials.SecondReferential, query)}
                                        multiSelect
                                        onSelectElementMulti={tags => formikContext.setFieldValue(PresentationEditField[props.context].secondReferential, props.context === 'PLE' ? tags.map(tag => ({
                                            ...tag,
                                            Name: tag.data.Name || tag.data.name
                                        })) : ({
                                            name: referentialsName?.[0]?.name || '',
                                            type: referentialsName?.[0]?.type || '',
                                            referentials: tags.map(tag => ({
                                                ...tag,
                                                id: tag.data.id,
                                                name: tag.data.Name || tag.data.name
                                            }))
                                        }))}
                                        value={(props.data?.secondReferential?.referentials || props.data?.secondReferential || []).map(referential => ({
                                            key: referential.Key || referential.id,
                                            text: referential.Name || referential.name,
                                            data: referential
                                        }))}
                                    />
                                </RowRight>
                            </PresentationSection>
                        }
                        {!!PresentationEditField[props.context].thirdReferential &&
                            <PresentationSection>
                                <RowLeft>
                                    <SetPropertiesIcon type={EStakeDetailsType.Reference} />
                                    <span>
                                        {referentialsName?.[2]?.key !== undefined ?
                                            <IntlMessage id={convertPascalCaseKeyToCamelCaseKey(referentialsName?.[2]?.key?.replace('referentials.', 'referentialIdentities.'))} />
                                            : referentialsName?.[2]?.name
                                        }
                                    </span>
                                </RowLeft>
                                <RowRight>
                                    <StaticDataPicker
                                        getData={query => resolveReferentials(EReferentials.ThirdReferential, query)}
                                        multiSelect
                                        onSelectElementMulti={tags => formikContext.setFieldValue(PresentationEditField[props.context].thirdReferential, props.context === 'PLE' ? tags.map(tag => ({
                                            ...tag,
                                            Name: tag.data.Name || tag.data.name
                                        })) : ({
                                            name: referentialsName?.[0]?.name || '',
                                            type: referentialsName?.[0]?.type || '',
                                            referentials: tags.map(tag => ({
                                                ...tag,
                                                id: tag.data.id,
                                                name: tag.data.Name || tag.data.name
                                            }))
                                        }))}
                                        value={(props.data?.thirdReferential?.referentials || props.data?.thirdReferential || []).map(referential => ({
                                            key: referential.Key || referential.id,
                                            text: referential.Name || referential.name,
                                            data: referential
                                        }))}
                                    />
                                </RowRight>
                            </PresentationSection>
                        }
                    </WhiteCollapsedSection>
                </>
            ))}
        </Wrapper>
    );
};
