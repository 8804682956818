import React, { useEffect, FC, useState, useMemo } from 'react';
import styled, { css } from 'styled-components';
import { useFormikContext } from 'formik';
import { useDispatch } from 'react-redux';

import { ICOIData } from '../../../../entities/ActionData/ICOIData';
import { SelectCard } from '../../../Common/SelectCard/SelectCard';
import { IClusterTemplate, ETemplateKeys } from '../../../../entities/IClusters';
import { getClusterTemplates } from '../../../../actions/clustersActions';
import IntlMessage from '../../../Common/IntlMessage';

type GetClusterTemplates = ReturnType<typeof getClusterTemplates>;

const SWrapper = styled.div<{areTemplates: boolean}>`
    width: 100%;
    display: flex;
    grid-gap: 37px;
    flex-wrap: wrap;
    ${props => !props.areTemplates && css`
        justify-content: center;
    `}
    input[type="radio"] {
        display: none;
    }
    img {
        display: block;
        margin: auto;
    }
`;

interface IProps {
    organizationId: string;
}

export const DeclarationType: FC<React.PropsWithChildren<IProps>> = (props) => {
    const formikContext = useFormikContext<ICOIData>();
    const [templates, setTemplates] = useState<IClusterTemplate[]>(undefined);
    const dispatch = useDispatch();

    useEffect(() => {
        formikContext.setFieldValue('step', 2);
        dispatch<GetClusterTemplates>(getClusterTemplates(props.organizationId, true)).then(clusterTemplates => {
            setTemplates(clusterTemplates);
        });
    }, []);

    const noLinkCoiCreate = useMemo(() => { return templates?.find((elem) => elem.templateKey === ETemplateKeys.NoLinkCoiCreate); }, [templates]);
    const CoiCreate = useMemo(() => { return templates?.find((elem) => elem.templateKey === ETemplateKeys.CoiCreate); }, [templates]);

    return (
        <SWrapper areTemplates={!(noLinkCoiCreate || CoiCreate)}>
            {noLinkCoiCreate || CoiCreate ?
                <>
                    {noLinkCoiCreate &&
                        <SelectCard
                            field="formData.coiDeclaration.type"
                            value="CoiNoLink"
                            imageKey="no-link"
                            name="coi.popup.noLinkOfInterest"
                            description="coi.popup.noLinkOfInterest.description"
                        />
                    }
                    {CoiCreate &&
                        <>
                            <SelectCard
                                field="formData.coiDeclaration.type"
                                value="CoiElectedPosition"
                                imageKey="elected_office"
                                name="coi.popup.electedOffice"
                                description="coi.popup.electedOffice.description"
                            />
                            <SelectCard
                                field="formData.coiDeclaration.type"
                                value="CoiFamilyInterest"
                                imageKey="family"
                                name="coi.popup.family"
                                description="coi.popup.family.description"
                            />
                            <SelectCard
                                field="formData.coiDeclaration.type"
                                value="CoiFinance"
                                imageKey="financial"
                                name="coi.popup.financialParticipation"
                                description="coi.popup.financialParticipation.description"
                            />
                            <SelectCard
                                field="formData.coiDeclaration.type"
                                value="CoiAssociationMandate"
                                imageKey="association"
                                name="coi.popup.accociationMandate"
                                description="coi.popup.accociationMandate.description"
                            />
                            <SelectCard
                                field="formData.coiDeclaration.type"
                                value="CoiOtherLink"
                                imageKey="other"
                                name="coi.popup.other"
                                description="coi.popup.other.description"
                            />
                        </>
                    }
                </>
            : <IntlMessage id="global.wizard.noTemplates"  />}
        </SWrapper>
    );
};
