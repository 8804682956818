import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC, useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { faXmark, IconDefinition } from '@fortawesome/pro-regular-svg-icons';
import styled, { css } from 'styled-components';

import { colorStack } from '../../../styleHelpers/colors';
import { Avatar, EAvatarSize } from '../Avatar/Avatar';
import { fontSizeAndHeight } from '../../../styleHelpers/fontSizes';
import { EProfileType } from '../../../entities/IGlobal';
import { ISingleGroupUser } from '../../../entities/IAdmin';
import { TextWithEllipsis } from '../TextWithEllipsis/TextWithEllipsis';
import { ConfirmPopup } from '../../Common/ConfirmPopup/ConfirmPopup';
import * as adminActions from '../../../actions/adminActions';
import IntlMessage from '../IntlMessage';

type GetSingleGroup = ReturnType<typeof adminActions.getSingleGroup>;

export enum EPillColorSchema {
    BLUE = 'BLUE',
    RED = 'RED',
    GRAY = 'GRAY',
    GREEN = 'GREEN',
    PURPLE = 'PURPLE',
    YELLOW = 'YELLOW',
    WHITE = 'WHITE',
    TRANSPARENT = 'TRANSPARENT'
}

export enum EPillShape {
    CIRCLE = 'CIRCLE',
    SQUARE = 'SQUARE'
}

const Wrapper = styled.div<{ colorSchema: EPillColorSchema, shape: EPillShape, leftIco: boolean, duoMode: boolean, status: boolean }>`
    padding: 0 1rem;
    display: inline-flex;
    white-space: nowrap;
    max-width: 100%;
    border-radius: 50px;
    align-items: center;
    background: ${colorStack.whiteBlue};
    color: ${colorStack.content};
    ${fontSizeAndHeight[16]};
    height: 32px;
    ${({ status }) => status && css`
        padding-left: 8px;
    `};
    ${({ leftIco }) => leftIco && css`
        padding-left: 4px;
    `};
    ${({ shape }) => shape === EPillShape.SQUARE && css`
        border-radius: 8px;
    `}
    ${({ colorSchema }) => colorSchema === EPillColorSchema.RED && css`
        background: ${colorStack.whiteRed};
    `};
    ${({ colorSchema }) => colorSchema === EPillColorSchema.GRAY && css`
        background: ${colorStack.middleGrey};
        color: ${colorStack.disabled};
    `};
    ${({ colorSchema }) => colorSchema === EPillColorSchema.GREEN && css`
        background: ${colorStack.whiteGreen};
    `};
    ${({ colorSchema }) => colorSchema === EPillColorSchema.PURPLE && css`
        background: ${colorStack.whitePurple};
    `};
    ${({ colorSchema }) => colorSchema === EPillColorSchema.YELLOW && css`
        background: ${colorStack.whiteOrange};
    `};
    ${({ colorSchema }) => colorSchema === EPillColorSchema.WHITE && css`
        background: ${colorStack.white};
    `};
    ${({ colorSchema }) => colorSchema === EPillColorSchema.TRANSPARENT && css`
        background: transparent;
    `};
    ${({ duoMode }) => duoMode && css`
        padding-left: 0;
    `};
`;

const Text = styled.span <{ maxWidth?: number; rightIco: boolean }>`
    ${fontSizeAndHeight[16]};
    width: 100%;
    ${props => props.rightIco && css`
        width: calc(100% - 18px);
    `}
    ${props => props.maxWidth && css`
        max-width: ${props.maxWidth}px;
    `}
`;

const GroupElement = styled.div`
    cursor: pointer;
`;

const GroupPopupContainer = styled.div`
    display: grid;
    grid-template-columns: 10rem 10rem 10rem;
    grid-column-gap: 8px;
`;

const GroupPopupElement = styled.div`
    padding-bottom: .8rem;
`;

const CloseBox = styled.div`
    margin-left: .5rem;
    display: flex;
    align-items: center;
    color: ${colorStack.darkGrey};
    justify-content: center;
    cursor: pointer;
`;

const LeftIcoBox = styled.div`
    margin-right: .5rem;
    width: 24px;
    height: 24px;
    border-radius: 100%;
    background: ${colorStack.white};
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${colorStack.darkGrey};
`;

const RightIcoBox = styled.div`
    margin-left: .5rem;
    color: ${colorStack.darkGrey};
`;

const RightButtons = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    > div {
        border-right: 2px solid ${colorStack.white};
        padding-right: .5rem;
        &:last-child {
            padding: 0;
            border: none;
        }
    }
`;

const DuoBox = styled.div`
    display: flex;
    align-items: center;
    height: 32px;
`;

const LeftSide = styled.div<{ comboGreyColor: boolean }>`
    height: 100%;
    display: flex;
    border-radius: 50px 0 0 50px;
    align-items: center;
    background: ${colorStack.white};
    padding: 0 .5rem 0 1rem;
    ${fontSizeAndHeight[16]};
    ${({ comboGreyColor }) => comboGreyColor && css`
        background: ${colorStack.whiteGrey};
    `};
`;

const RightSide = styled.div`
    height: 100%;
    padding: 0 0 0 1rem;
    display: flex;
    ${fontSizeAndHeight[16]};
    align-items: center;
`;

const StatusDot = styled.div<{ colorSchema: EPillColorSchema }>`
    border-radius: 100%;
    width: 16px;
    height: 16px;
    margin-right: .5rem;
    background: ${colorStack.blue};
    ${({ colorSchema }) => colorSchema === EPillColorSchema.RED && css`
        background: ${colorStack.red};
    `};
    ${({ colorSchema }) => colorSchema === EPillColorSchema.GRAY && css`
        background: ${colorStack.disabled};
    `};
    ${({ colorSchema }) => colorSchema === EPillColorSchema.GREEN && css`
        background: ${colorStack.green};
    `};
    ${({ colorSchema }) => colorSchema === EPillColorSchema.PURPLE && css`
        background: ${colorStack.purple};
    `};
    ${({ colorSchema }) => colorSchema === EPillColorSchema.YELLOW && css`
        background: ${colorStack.orange};
    `};
`;

const AvatarBox = styled.div`
    width: 96%;
    > div {
        width: 100%;
    }
`;

interface IProps {
    id: string;
    text: string;
    colorSchema?: EPillColorSchema;
    shape?: EPillShape;
    translationKey?: string;
    link?: string;
    rightIco?: IconDefinition;
    leftIco?: IconDefinition;
    value?: string;
    status?: boolean;
    picture?: string;
    profileType?: EProfileType;
    comboGreyColor?: boolean;
    darkMode?: boolean;
    maxTextWidth?: number;
    preventRedirection?: boolean;
    onClick?(elemId: string);
}

export const Pill: FC<React.PropsWithChildren<IProps>> = props => {

    const dispatch = useDispatch();
    const [isGroupPopupVisible, setIsGroupPopupVisible] = useState<boolean>(false);
    const [groupData, setGroupData] = useState<ISingleGroupUser[]>(undefined);

    const clickHandler = () => {
        props.onClick(props.id);
    };

    const clickGroupHandler = useCallback((id: string) => {
        dispatch<GetSingleGroup>(adminActions.getSingleGroup(id)).then(response => {
            setGroupData(response?.groupUsers);
            setIsGroupPopupVisible(state => !state);
        });
    }, []);

    return (
        <Wrapper
            colorSchema={props.colorSchema || EPillColorSchema.BLUE}
            shape={props.shape || EPillShape.CIRCLE}
            leftIco={!!props.leftIco || !!props.picture || !!props.profileType}
            status={props.status}
            duoMode={!!props.value}
        >
            {(props.value && props.text) &&
                <DuoBox>
                    <LeftSide comboGreyColor={props.comboGreyColor}>{props.text}</LeftSide>
                    <RightSide >
                        <span>{props.value}</span>
                        <RightButtons>
                            {props.rightIco &&
                                <RightIcoBox>
                                    <FontAwesomeIcon icon={props.rightIco} />
                                </RightIcoBox>
                            }
                            {props.onClick &&
                                <CloseBox onClick={clickHandler}>
                                    <FontAwesomeIcon icon={faXmark} />
                                </CloseBox>
                            }
                        </RightButtons>
                    </RightSide>
                </DuoBox>
            }
            {props.status &&
                <StatusDot colorSchema={props.colorSchema || EPillColorSchema.BLUE} />
            }
            {props.leftIco &&
                <LeftIcoBox>
                    <FontAwesomeIcon icon={props.leftIco} />
                </LeftIcoBox>
            }
            {(((props.profileType && props.profileType !== EProfileType.Personal && props.profileType !== EProfileType.LegalEntity && props.profileType !== EProfileType.Company && props.profileType !== EProfileType.Group && props.profileType !== EProfileType.Cluster) || !props.profileType) && !props.value) &&
                <>
                    {props.link ? (
                        <a href={props.link} target="_blank">
                            {<Text data-nonclick maxWidth={props.maxTextWidth} rightIco={!!props.onClick}>
                                <TextWithEllipsis data-nonclick>{props.translationKey ? <IntlMessage id={`${props.translationKey}.${props.text}`} /> : props.text}</TextWithEllipsis></Text>}
                        </a>
                    ) : (
                        <>
                            {<Text data-nonclick maxWidth={props.maxTextWidth} rightIco={!!props.onClick}>
                                <TextWithEllipsis data-nonclick>{props.translationKey ? <IntlMessage id={`${props.translationKey}.${props.text}`} /> : props.text}</TextWithEllipsis></Text>}
                        </>
                    )}
                </>
            }
            {(props.profileType && props.profileType !== EProfileType.Group) &&
                <AvatarBox>
                    <Avatar
                        firstName={props.text}
                        lastName=""
                        type={props.profileType as EProfileType}
                        id={props.id}
                        preventRedirection={props.preventRedirection}
                        picture={props.picture}
                        darkMode={props.darkMode}
                        extended
                        size={EAvatarSize.TINY}
                    />
                </AvatarBox>
            }
            {props.profileType === EProfileType.Group && (
                props.preventRedirection ? (
                    <Avatar
                        firstName={props.text}
                        lastName=""
                        type={props.profileType as EProfileType}
                        id={props.id}
                        picture={props.picture}
                        darkMode={props.darkMode}
                        extended
                        preventRedirection
                        size={EAvatarSize.TINY}
                    />
                ) : (
                    <GroupElement onClick={() => clickGroupHandler(props?.id)}>
                        <Avatar
                            firstName={props.text}
                            lastName=""
                            type={props.profileType as EProfileType}
                            id={props.id}
                            picture={props.picture}
                            darkMode={props.darkMode}
                            extended
                            preventRedirection
                            size={EAvatarSize.TINY}
                        />
                    </GroupElement>
                )
            )}
            {((props.rightIco || props.onClick) && !props.value) &&
                <RightButtons>
                    {props.rightIco &&
                        <RightIcoBox>
                            {props.link ?
                                <a href={props.link} target="_blank">
                                    <FontAwesomeIcon icon={props.rightIco} />
                                </a>
                                : <FontAwesomeIcon icon={props.rightIco} />
                            }
                        </RightIcoBox>
                    }
                    {props.onClick &&
                        <CloseBox onClick={clickHandler}>
                            <FontAwesomeIcon icon={faXmark} />
                        </CloseBox>
                    }
                </RightButtons>
            }
            <ConfirmPopup
                showPopup={isGroupPopupVisible}
                noHandler={() => setIsGroupPopupVisible(false)}
                noButtonText={<IntlMessage id="global.close" />}
            >
                <GroupPopupContainer>
                    {groupData && groupData?.map(el => (
                        <GroupPopupElement key={el?.id}>
                            <Avatar
                                firstName={el.name}
                                lastName={''}
                                type={EProfileType?.Personal}
                                id={el.id}
                                picture={el.picture}
                                extended
                                size={EAvatarSize.TINY}
                            />
                        </GroupPopupElement>
                    ))}
                </GroupPopupContainer>
            </ConfirmPopup>
        </Wrapper>
    );
};
