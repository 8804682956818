import React, { FC, useContext, useEffect } from 'react';
import styled from 'styled-components';

import { EPOAContext, IPOAContext } from '.';
import { useIntlMessage } from '../../../Common/IntlMessage';

const Wrapper = styled.div``;

export const Step1: FC = () => {
    const { intlFormatMessage } = useIntlMessage();
    const POAContext = useContext<IPOAContext>(EPOAContext);

    useEffect(() => {
        POAContext.modifyData(undefined, undefined, 1);
    }, []);

    return (
        <Wrapper>
            <div dangerouslySetInnerHTML={{__html: intlFormatMessage({id: 'poa.wizard.step1.content'})}} />
        </Wrapper>
    );
};