import React, { FC, useEffect, useCallback, useMemo, useRef } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { faCheck, faXmark } from '@fortawesome/pro-solid-svg-icons';
import moment from 'moment';
import { useLocation, useNavigate } from 'react-router-dom';

import { EValidationStatus } from '../../../../../entities/IClusters';
import { EStatusesTypes, EValidatorType, ValidationResponse } from '../../../../../entities/IValidation';
import { EValidationRequestStageStatus } from '../../../../../entities/IGlobal';
import { IProfileReducer } from '../../../../../reducers/profileReducer';
import { IState } from '../../../../../reducers';
import { IApprovalReducer } from '../../../../../reducers/approvalReducer';
import { EAlertType, EAlertTypeSchema } from '../../../../../entities/IAlert';
import { EPanelType, EPanelUrlParams } from '../../../../Common/Panel/PanelContainer';
import { changeValidationStatus, getSingleLastValidation } from '../../../../../actions/validationActions';
import { ColoredDatagrid } from '../../../../Common/ColoredDatagrid/ColoredDatagrid';
import { EColumnSubTypes } from '../../../../../tools/dataGridTools';
import { EColoredDatagridField, EColumnType } from '../../../../../entities/IDatagrid';
import { getSingleCluster } from '../../../../../actions/clustersActions';
import { useAlert } from '../../../../../tools/hooks';
import { colorStack } from '../../../../../styleHelpers/colors';
import { Alert } from '../../../../Common/Alert/Alert';
import IntlMessage, { useIntlMessage } from '../../../../Common/IntlMessage';

type GetSingleLastValidation = ReturnType<typeof getSingleLastValidation>;
type ChangeValidationStatus = ReturnType<typeof changeValidationStatus>;
type GetSingleCluster = ReturnType<typeof getSingleCluster>;

const ApproverWrapper = styled.div`
    margin-bottom: 1rem;
`;

const Wrapper = styled.div<{ validationStatus: EValidationStatus }>`
`;

export enum EApprovalTypes {
    Other = 'Other',
    ClusterOverview = 'ClusterOverview',
    ClusterDocument = 'ClusterDocument'
}

export const EApprovalStatusAlertId = {
    [EStatusesTypes.Acknowledged]: 'validation.alert.acknowledged',
    [EStatusesTypes.Approved]: 'validation.alert.approved',
    [EStatusesTypes.Rejected]: 'validation.alert.reject',
    [EStatusesTypes.Favorable]: 'validation.alert.favorable',
    [EStatusesTypes.Unfavorable]: 'validation.alert.unfavorable'
};

interface IProps {
    validationStatus: EValidationStatus;
    updateDate: Date;
    currentClusterId: string;
    setOverviewResizeRefresh();
}

export const ApprovalMessage: FC<IProps> = ({ validationStatus, updateDate, currentClusterId, setOverviewResizeRefresh }) => {
    const { currentUserProfile, approvalOverviewInformations } = useSelector<IState, IProfileReducer & IApprovalReducer>(state => ({
        ...state.profile,
        ...state.approval
    }));
    const dispatch = useDispatch();
    const history = useNavigate();
    const { intlFormatMessage } = useIntlMessage();
    const addAlert = useAlert();
    const didMountRef = useRef(false);
    const { search } = useLocation();
    const params = new URLSearchParams(search);

    const getActiveValidator = useCallback((data: ValidationResponse) => {
        if (data?.stages) {
            const stages = [...data.stages];
            const activeStage = stages?.find(
                elem => elem.status === EValidationRequestStageStatus.InProgress && validationStatus !== EValidationStatus.Rejected
            );
            const activeValidatorsInStage = activeStage?.validators?.filter((elem) => !elem.status);
            const currentUserActiveValidators = activeValidatorsInStage?.filter((elem => elem.principal.childId === currentUserProfile.id));
            return {
                currentUserActiveValidators: currentUserActiveValidators,
                activeStage: activeStage?.stageId
            };
        }
        return undefined;
    }, []);

    useEffect(() => {
        if (!params.get('validationId')) {
            dispatch<GetSingleLastValidation>(getSingleLastValidation(currentClusterId, EApprovalTypes.ClusterOverview));
        }
    }, [currentClusterId, validationStatus, params.get('validationId')]);

    const activeApproverData = useMemo(() => {
        if (!approvalOverviewInformations) {
            return;
        }
        return getActiveValidator(approvalOverviewInformations);
    }, [currentUserProfile, approvalOverviewInformations]);

    const onValidationStatusClick = () => {
        history({
            search: `${EPanelUrlParams.PANELTYPE}=${EPanelType.APPROVAL}&${EPanelUrlParams.VALIDATIONID}=${approvalOverviewInformations?.id}`,
            hash: window.location.hash
        });
    };

    useEffect(() => {
        if (didMountRef.current) {
            setOverviewResizeRefresh();
        } else {
            didMountRef.current = true;
        }
    }, [activeApproverData?.currentUserActiveValidators?.length]);

    useEffect(() => {
        if (didMountRef.current) {
            setOverviewResizeRefresh();
        } else {
            didMountRef.current = true;
        }
        return () => {
            setOverviewResizeRefresh();
        };
    }, []);

    const getAllertType = (type: EValidationStatus): EAlertType => {
        switch (type) {
            case EValidationStatus.Active:
                return EAlertType.WARNING;
            case EValidationStatus.Completed:
                return EAlertType.SUCCESS;
            case EValidationStatus.Rejected:
                return EAlertType.ERROR;
            case EValidationStatus.Disabled:
                return EAlertType.INFO;
            case EValidationStatus.Done:
                return EAlertType.DONE;
            default: return EAlertType.INFO;
        }
    };

    const getSettings = async () => ({
        columnsSettings: [{
            field: 'order',
            headerText: intlFormatMessage({ id: 'rolemanager.columnName.order' }),
            isVisible: true,
            type: EColumnType.number,
            subType: EColumnSubTypes.Order
        }, {
            field: 'principal',
            headerText: intlFormatMessage({ id: 'rolemanager.columnName.authority' }),
            isVisible: true,
            type: EColumnType.extendedAvatar
        }, {
            field: 'type',
            headerText: intlFormatMessage({ id: 'rolemanager.columnName.type' }),
            isVisible: true,
            type: EColumnType.text
        }, {
            field: 'status',
            subType: EColumnSubTypes.Status,
            headerText: intlFormatMessage({ id: 'rolemanager.columnName.status' }),
            isVisible: true,
            type: EColumnType.enum
        }, {
            field: 'buttonProps',
            headerText: intlFormatMessage({ id: 'rolemanager.columnName.actionTaken' }),
            isVisible: true,
            exportable: false,
            type: EColumnType.button
        }
    ],
        exportable: false
    });

    const getApprovalAlert = (status: EStatusesTypes) => {
        if (EApprovalStatusAlertId[status]) {
            return addAlert(<IntlMessage id={EApprovalStatusAlertId[status]} />, EAlertType.SUCCESS);
        }
    };
    const changeStatus = (status: EStatusesTypes) => {
        return new Promise<void>((resolve, reject) => {
            dispatch<ChangeValidationStatus>(changeValidationStatus(currentClusterId, approvalOverviewInformations.id, status)).then(() => {
                getApprovalAlert(status);
                if (params.get('clusterId')) {
                    history({
                        search: `${EPanelUrlParams.REFRESH}=true`,
                        hash: window.location.hash
                    });
                }
                return dispatch<GetSingleCluster>(getSingleCluster(currentClusterId));
            }).then(() => resolve()).catch((error) => reject(error));
        });
    };

    const approvalActionHandler = (status: EStatusesTypes) => {
        return new Promise<void>((resolve, reject) => {
            changeStatus(status).then(() => {
                resolve();
            }).catch((error) => {
                reject(error);
            });
        });
    };

    const getActionType = (validatorType: EValidatorType, deleteAction?: boolean) => {
        switch (validatorType) {
            case EValidatorType.Approval:
               if (!deleteAction) {
                    return EStatusesTypes.Approved;
               }
               return EStatusesTypes.Rejected;
            case EValidatorType.Opinion:
                if (!deleteAction) {
                    return EStatusesTypes.Favorable;
                }
                return EStatusesTypes.Unfavorable;
            case EValidatorType.Acknowledgement:
                if (!deleteAction) {
                    return EStatusesTypes.Acknowledged;
                }
                return;
            default:
                return;
        }
    };

    const getData = async () => {
        const finalData = activeApproverData?.currentUserActiveValidators.map(user => ({
            principal: {
                firstLine: user.principal.childName,
                secondLine: user.principal?.jobTitle,
                picture: user.principal.picture,
                principalType: user.principal.type,
                childId: user.principal.childId
            },
            quality: user?.role?.name,
            type: user.validatorType,
            order: activeApproverData.activeStage + 1,
            status: {
                translationKey: EValidationRequestStageStatus.InProgress,
                text: intlFormatMessage({ id: `approval.status.${EValidationRequestStageStatus.InProgress}` })
            },
            buttonProps: {
                data: [{
                    type: 'Icon',
                    icon: faCheck,
                    name: intlFormatMessage({ id: `approval.validatorType.button.${getActionType(user.validatorType)}` }),
                    color: colorStack.white,
                    backgroundColor: colorStack.middleBlue,
                    action: () => approvalActionHandler(getActionType(user.validatorType))
                }, {
                    type: 'Icon',
                    name: intlFormatMessage({ id: `approval.validatorType.button.${getActionType(user.validatorType, true)}` }),
                    icon: faXmark,
                    color: colorStack.white,
                    backgroundColor: colorStack.middleRed,
                    hidden: user.validatorType === EValidatorType.Acknowledgement,
                    action: () => approvalActionHandler(getActionType(user.validatorType, true))
                }]
            }
        }));
        return finalData;
    };

    return (
        <>
            {approvalOverviewInformations &&
                <>
                    <Wrapper validationStatus={validationStatus} onClick={onValidationStatusClick}>
                        <Alert withoutProgress withoutClose alertId="1" visible typeSchema={EAlertTypeSchema.SECONDARY} type={getAllertType(validationStatus)}>
                            {validationStatus === EValidationStatus.Active &&
                                <div>
                                    <IntlMessage values={{ date: moment(approvalOverviewInformations?.createdAt || new Date()).format('DD MMM YYYY') }} id="cluster.overviewValidation.active" />
                                    <br />
                                    <IntlMessage id="cluster.overviewValidation.active.secondText" />
                                </div>
                            }
                            {validationStatus === EValidationStatus.Completed &&
                                <span dangerouslySetInnerHTML={{
                                    __html: intlFormatMessage({ id: 'cluster.overviewValidation.completed' }, {
                                        date: moment(updateDate || new Date()).format('DD MMM YYYY')
                                    })
                                }} />
                            }
                            {validationStatus === EValidationStatus.Rejected &&
                                <span dangerouslySetInnerHTML={{
                                    __html: intlFormatMessage({ id: 'cluster.overviewValidation.rejected' }, {
                                        date: moment(updateDate || new Date()).format('DD MMM YYYY')
                                    })
                                }} />
                            }
                            {validationStatus === EValidationStatus.Done &&
                                <span dangerouslySetInnerHTML={{
                                    __html: intlFormatMessage({ id: 'cluster.overviewValidation.done' }, {
                                        date: moment(updateDate || new Date()).format('DD MMM YYYY')
                                    })
                                }} />
                            }
                        </Alert>
                    </Wrapper>
                    <ApproverWrapper>
                        {activeApproverData?.currentUserActiveValidators?.length > 0 &&
                            <ColoredDatagrid
                                resolveTopicFromField={topic => topic}
                                getData={getData}
                                coloredFieldName={EColoredDatagridField.Status}
                                getSettings={getSettings}
                                orgContext
                                withoutMinHeight
                            />
                        }
                    </ApproverWrapper>
                </>
            }
        </>
    );
};
