import React, { FC, useEffect, useState, useCallback } from 'react';
import styled, { css } from 'styled-components';

import { IClusterStakeProperty } from '../../../../../entities/IClusterStake';
import { colorStack } from '../../../../../styleHelpers/colors';
import { fontSize } from '../../../../../styleHelpers/fontSizes';
import { ErrorText } from './StakeStyles';
import { useNewDesignContext } from '../../../../NewDesignCommon/NewDesignSwitch/NewDesignSwitch';
import { newDesignThemeSwitch } from '../../../../NewDesignCommon/NewDesignSwitch/newDesignTheme';
import { GroupButton } from '../../../../Common/Buttons/GroupButton';
import IntlMessage, { useIntlMessage } from '../../../../Common/IntlMessage';

const Wrapper = styled.div`
    span {
        font-size: ${fontSize[13]};
        color: ${colorStack.darkBlue};
    }
    ${(props) => newDesignThemeSwitch(props, () =>
    css`
            span {
                font-size: ${fontSize[13]};
                font-family: ${props.theme.font.primary};
                font-weight: 400;
                color: ${colorStack.content};
            }
        `
)}
`;

const RadioGroup = styled.div`
    padding-top: 12px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    & > :first-child {
        margin-right: 60px;
    }
`;

interface IBooleanContentProps {
    data: IClusterStakeProperty;
    editStake: boolean;
    propertieId: string;
    error: string;
    setEditingData(propertie: IClusterStakeProperty);
}

export const BooleanContent: FC<React.PropsWithChildren<IBooleanContentProps>> = ({ error, data, editStake, propertieId, setEditingData }) => {
    const { intlFormatMessage } = useIntlMessage();
    // tslint:disable-next-line:no-null-keyword
    const [content, setContent] = useState<boolean | null>(null);
    const newDesign = useNewDesignContext();

    useEffect(() => {
        setContent(data.value);
    }, [data, editStake]);

    const changeState = useCallback((value) => {
        // tslint:disable-next-line:no-null-keyword
        setContent(value);
        setEditingData({
            ...data,
            // tslint:disable-next-line:no-null-keyword
            value: value,
            id: propertieId
        });
    }, [data, propertieId, setEditingData, content]);

    if (newDesign.active) {

        return (
            <Wrapper>
                {editStake ? (
                    <>
                        <RadioGroup>
                            <GroupButton
                                buttons={[{
                                    text: intlFormatMessage({ id: 'global.yes' }),
                                    active: content === true,
                                    action: () => changeState(true)
                                }, {
                                    text: intlFormatMessage({ id: 'global.no' }),
                                    active: content === false,
                                    action: () => changeState(false)
                                }, {
                                    text: intlFormatMessage({ id: 'global.blank' }),
                                    active: content === null,
                                    // tslint:disable-next-line:no-null-keyword
                                    action: () => changeState(null)
                                }]}
                            />
                        </RadioGroup>
                        {error && <ErrorText>{error}</ErrorText>}
                    </>
                ) : (
                    <>
                        {content === true && <IntlMessage id="global.yes" />}
                        {content === false && <IntlMessage id="global.no" />}
                        {content === null && <IntlMessage id="global.blank" />}
                    </>
                )}
            </Wrapper>
        );
    }

    return (
        <Wrapper>
            {editStake ? (
                <>
                    <GroupButton
                        buttons={[{
                            text: intlFormatMessage({ id: 'global.yes' }),
                            active: content === true,
                            action: () => changeState(true)
                        }, {
                            text: intlFormatMessage({ id: 'global.no' }),
                            active: content === false,
                            action: () => changeState(false)
                        }, {
                            text: intlFormatMessage({ id: 'global.blank' }),
                            active: content === null,
                            // tslint:disable-next-line:no-null-keyword
                            action: () => changeState(null)
                        }]}
                    />
                    {error &&
                        <ErrorText>{error}</ErrorText>
                    }
                </>
            ) : (
                <>
                    {content === true && <IntlMessage id="global.yes" />}
                    {content === false && <IntlMessage id="global.no" />}
                    {content === null && <IntlMessage id="global.blank" />}
                </>
            )}
        </Wrapper>
    );
};