import React, { FC, useEffect, useState, Fragment, useMemo } from 'react';
import { useFormikContext } from 'formik';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import { getClusterTemplates } from '../../../../../actions/clustersActions';
import { IClusterTemplate, ETemplateKeys } from '../../../../../entities/IClusters';
import { SelectCard } from '../../../../Common/SelectCard/SelectCard';
import { IContractData } from '../IContractData';
import IntlMessage from '../../../../Common/IntlMessage';

const Wrapper = styled.div`
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    > div {
        margin: 0 0 1rem 0;
    }
`;
type GetClusterTemplates = ReturnType<typeof getClusterTemplates>;

interface IProps {
    organizationId: string;
}

export const Step1: FC<React.PropsWithChildren<IProps>> = (props) => {
    const formikContext = useFormikContext<IContractData>();
    const [templates, setTemplates] = useState<IClusterTemplate[]>(undefined);
    const dispatch = useDispatch();

    useEffect(() => {
        formikContext.setFieldValue('step', 1);
        dispatch<GetClusterTemplates>(getClusterTemplates(props.organizationId, true)).then(clusterTemplates => {
            setTemplates(clusterTemplates);
        });
    }, []);

    const areTemplatesAvailable = useMemo(() => {
        return templates?.find((elem) => elem.templateKey === ETemplateKeys.KnowledgeManagement ||
            elem.templateKey === ETemplateKeys.DistributionMfBrand ||
            elem.templateKey === ETemplateKeys.FranchiseContract ||
            elem.templateKey === ETemplateKeys.SupplyContract ||
            elem.templateKey === ETemplateKeys.contractSupplierItSepro ||
            elem.templateKey === ETemplateKeys.contractMaintenanceSepro ||
            elem.templateKey === ETemplateKeys.contractDistributionSepro ||
            elem.templateKey === ETemplateKeys.contractLeaseSepro ||
            elem.templateKey === ETemplateKeys.contractKeyAccountsSepro ||
            elem.templateKey === ETemplateKeys.contractSaleRepresentativeSepro ||
            elem.templateKey === ETemplateKeys.contractSupplierSepro ||
            elem.templateKey === ETemplateKeys.contractLease ||
            elem.templateKey === ETemplateKeys.basicContract ||
            elem.templateKey === ETemplateKeys.CONTRACT_NDA
        );
    }, [templates]);

    return (
        <Wrapper>
            {areTemplatesAvailable ?
                templates?.map((elem) =>
                    <Fragment key={elem.id}>
                        <SelectCard
                            field="formData.cluster.clusterTemplateKey"
                            value={elem.templateKey}
                            imageKey={elem.templateKey}
                            name={`contract.popup.step2.name.${elem.templateKey}`}
                            description={`contract.popup.step2.${elem.templateKey}`}
                        />
                    </Fragment>
                )
                : <IntlMessage id="global.wizard.noTemplates" />}
        </Wrapper>
    );
};
