import React from 'react';

import { useIntlMessage } from '../../../Common/IntlMessage';

export const SuccessfulSubmission = () => {
    const { intlFormatMessage } = useIntlMessage();
    return (
        <div>
            <span dangerouslySetInnerHTML={{
                __html: intlFormatMessage({ id: 'coi.popup.success.great' })
            }} />
            <span dangerouslySetInnerHTML={{
                __html: intlFormatMessage({ id: 'coi.popup.success.confirmation' })
            }} />
            <span dangerouslySetInnerHTML={{
                __html: intlFormatMessage({ id: 'coi.popup.success.notificationInfo' })
            }} />
        </div>
    );
};