import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';

import { IClusterStakeProperty } from '../../../../../entities/IClusterStake';
import { colorStack } from '../../../../../styleHelpers/colors';
import { fontSize } from '../../../../../styleHelpers/fontSizes';
import { getReferentials } from '../../../../../actions/globalActions';
import { ErrorText } from './StakeStyles';
import { IValue } from '../../../../../entities/IPickers';
import { Pill } from '../../../../Common/Pill/Pill';
import { StaticDataPicker } from '../../../../Common/Pickers/StaticDataPicker';
import { IReferencial } from '../../../../../entities/IGlobal';

const Wrapper = styled.div`
`;

type GetReferentials = ReturnType<typeof getReferentials>;

interface IMultiSelectContentProps {
    data: IClusterStakeProperty;
    editStake: boolean;
    propertieId: string;
    error: string;
    setEditingData(propertie: IClusterStakeProperty);
}

export const MultiSelectContent: FC<React.PropsWithChildren<IMultiSelectContentProps>> = props => {
    const dispatch = useDispatch();
    const [selectContent, setSelectContent] = useState<IReferencial[]>(undefined);
    const [selectValue, setSelectValue] = useState<IValue[]>([]);

    useEffect(() => {
        if (props.editStake) {
            const context = props.data.configuration?.referentialContext || props.data.configuration?.ReferentialContext;
            const type = props.data.configuration?.referentialType || props.data.configuration?.ReferentialType;
            if (context && type) {
                dispatch<GetReferentials>(getReferentials('', type, context)).then(response => {
                    setSelectContent(response);
                });
            }
        }
        setSelectValue((props.data?.value || []).map(elem => ({ key: elem.key, text: elem.value, data: elem })));
    }, [props.editStake, props.data?.value]);

    const changeSelect = (items: IValue[]) => {
        props.setEditingData({
            ...props.data,
            value: items?.length > 0 ? items?.map(elem => ({
                key: elem.data.id,
                value: elem.data.name,
                uniqueKey: elem.data.Key
                // tslint:disable-next-line:no-null-keyword
            })) : null,
            id: props.propertieId
        });
    };

    return (
        <Wrapper>
            {props.editStake ? (
                <>
                    {selectContent && (
                        <StaticDataPicker
                            value={selectValue}
                            multiSelect
                            onSelectElementMulti={changeSelect}
                            staticData={selectContent.map(elem => ({
                                key: elem.Key || elem.key || elem.id,
                                data: {...elem},
                                text: elem.name
                            }))}
                        />
                    )}
                    {props.error &&
                        <ErrorText>{props.error}</ErrorText>
                    }
                </>
            ) : (
                <>
                    {(selectValue || []).map(tag => (
                        <Pill
                            key={tag.key}
                            id={tag.key}
                            text={tag.text}
                        />
                    ))}
                </>
            )}
        </Wrapper>
    );
};