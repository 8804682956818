import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import TagManager from 'react-gtm-module';

import { isPageWithoutAutorization } from '../constants/routes';
import Tracking from './Tracking/Tracking';
import { GlobalLoader } from './GlobalLoader';
import { checkFinalRegistrationRule, getCurrentUserProfileNew } from '../actions/profileActions';
import { getCurrentUserContextOrganizations } from '../actions/contextActions';
import { EAlertType } from '../entities/IAlert';
import { useAlert } from '../tools/hooks';
import { addRcivedNotification, getNotificationsActiveData } from '../actions/notificationsActions';
import { useNotification } from '../tools/signalRHook';
import { ISingleConversation, ISingleMessage } from '../entities/Messaging/IMessaging';
import { EClusterErrors, IClusterSingleMessage, IClusterValidationRequestStatusUpdateMessage, ISingleChannelMessage } from '../entities/IClusters';
import { IState } from '../reducers';
import { IClusterReducer } from '../reducers/clustersReducer';
import { activityCreatedEventOn, addReceiveAttachment, addUnreadConversation, clearCurrentMessages, newEventsHaveOccuredOn, removeTempConversation, setActiveConversation, updateSingleConversation, updateSingleMessage, userRemovedFromConversation } from '../actions/messagingActions';
import { deleteChannelThreadFromState, getChannelMessages, getClusterDataForRefreshApproval, getClusterDocuments, getSingleCluster, getTeamsWithChannels, receiveClusterMessage, receiveThreadMessage } from '../actions/clustersActions';
import { getSingleLastValidation } from '../actions/validationActions';
import { EApprovalTypes } from './Organization/ClustersV2/ClusterOverview/OverviewContent/ApprovalMessage';
import { IProfileReducer } from '../reducers/profileReducer';
import { IMessagingReducer } from '../reducers/messagingReducer';
import { ENotificationType } from '../entities/INotification';
import { EBranchNotificationType } from '../entities/ISoge';
import { IContextReducer } from '../reducers/contextReducer';
import { instanceConfig } from '../instance';
import { ICookiesReducer } from '../reducers/cookiesReducer';
import { ETrackingType } from '../entities/IAnalytics';
import { IAnalyticsReducer } from '../reducers/analyticsReducer';
import { ILanguageReducer } from '../reducers/languageReducer';
import { AlertContainer } from './Common/Alert/AlertContainer';
import Cookies from './Layout/Cookies/Cookies';
import { getAllReferentials } from '../actions/globalActions';
import { IGlobalReducer } from '../reducers/globalReducer';
import IntlMessage, { useIntlMessage } from './Common/IntlMessage';

type GetCurrentUserProfileNew = ReturnType<typeof getCurrentUserProfileNew>;
type CheckFinalRegistrationRule = ReturnType<typeof checkFinalRegistrationRule>;
type GetCurrentUserContextOrganizations = ReturnType<typeof getCurrentUserContextOrganizations>;
type GetNotificationsActiveData = ReturnType<typeof getNotificationsActiveData>;
type NewEventsHaveOccuredOn = ReturnType<typeof newEventsHaveOccuredOn>;
type ActivityCreatedEventOn = ReturnType<typeof activityCreatedEventOn>;
type ReceiveThreadMessage = ReturnType<typeof receiveThreadMessage>;
type GetClusterDocuments = ReturnType<typeof getClusterDocuments>;
type ReceiveClusterMessage = ReturnType<typeof receiveClusterMessage>;
type GetClusterDataForRefreshApproval = ReturnType<typeof getClusterDataForRefreshApproval>;
type GetSingleLastValidation = ReturnType<typeof getSingleLastValidation>;
type GetTeamsWithChannels = ReturnType<typeof getTeamsWithChannels>;
type GetChannelMessages = ReturnType<typeof getChannelMessages>;
type GetSingleCluster = ReturnType<typeof getSingleCluster>;
type DeleteChannelThreadFromState = ReturnType<typeof deleteChannelThreadFromState>;
type AddReceiveAttachment = ReturnType<typeof addReceiveAttachment>;
type AddUnreadConversation = ReturnType<typeof addUnreadConversation>;
type UpdateSingleConversation = ReturnType<typeof updateSingleConversation>;
type SetActiveConversation = ReturnType<typeof setActiveConversation>;
type UpdateSingleMessage = ReturnType<typeof updateSingleMessage>;
type RemoveTempConversation = ReturnType<typeof removeTempConversation>;
type AddRcivedNotification = ReturnType<typeof addRcivedNotification>;
type UserRemovedFromConversation = ReturnType<typeof userRemovedFromConversation>;
type ClearCurrentMessages = ReturnType<typeof clearCurrentMessages>;
type GetAllReferentials = ReturnType<typeof getAllReferentials>;

const scrollExceptions: (string | RegExp)[] = [/\/rfp/];

export const GlobalLayout: FC = props => {
    const dispatch = useDispatch();
    const { intlFormatMessage } = useIntlMessage();
    const addAlert = useAlert();
    const { clusterValidationRequestStatusUpdateMessageDto, conversationMemberRemoved, receiveNotification, receiveMessage, receiveChannelMessage, updateChannelMessage, assignedRoleCreated, assignToClusterCompleted, deleteChannelMessage, receiveConversation, attachmentUploaded } = useNotification();
    const { translations, tracker, userLanguage, cookieBlock, isShadowAuth, conversations, activeConversationId, showConversationMenu, currentClusterId, currentChannelId, currentClusterData, currentUserProfile } = useSelector<IState, IGlobalReducer & ICookiesReducer & IContextReducer & IMessagingReducer & IClusterReducer & IProfileReducer & IAnalyticsReducer & ILanguageReducer>(state => ({
        ...state.clusters,
        ...state.profile,
        ...state.context,
        ...state.messaging,
        ...state.cookies,
        ...state.analytics,
        ...state.language,
        ...state.global
    }));
    const paramsUrl = new URLSearchParams(window.location.search);
    const [loading, setLoading] = useState<boolean>(true);
    const [currentUrl, setCurrentUrl] = useState<string>('');
    const [initialOperations, setInitialOperations] = useState<boolean>(false);
    const [loginInfo, setLoginInfo] = useState<boolean>(false);
    const { gtm } = instanceConfig;

    useEffect(() => {
        if (!isShadowAuth && !initialOperations && !isPageWithoutAutorization() && userLanguage) {
            dispatch<GetAllReferentials>(getAllReferentials());
            Promise.all([
                dispatch<GetCurrentUserProfileNew>(getCurrentUserProfileNew()),
                dispatch<CheckFinalRegistrationRule>(checkFinalRegistrationRule()),
                dispatch<GetCurrentUserContextOrganizations>(getCurrentUserContextOrganizations())
            ]).then(response => {
                if (!response[0].isProfileValidated && response[1] && window.location.pathname !== '/registration-final-step') {
                    window.location.replace('/registration-final-step');
                } else {
                    setLoading(false);
                    !paramsUrl.get('token') && dispatch<GetNotificationsActiveData>(getNotificationsActiveData());
                }
            });
            if (cookieBlock === false) {
                const tagManagerArgs = {
                    gtmId: 'GTM-WF74HF6'
                };
                gtm && TagManager.initialize(tagManagerArgs);
            }
            setInitialOperations(true);
        } else if (isShadowAuth) {
            dispatch<GetCurrentUserContextOrganizations>(getCurrentUserContextOrganizations());
            setLoading(false);
        }
        if (isPageWithoutAutorization()) {
            setLoading(false);
        }
        if (Object.keys(translations)?.length > 0 && currentUserProfile && !loginInfo) {
            if (currentUserProfile.lastLoginDate && !window.location?.search?.includes('wopieItemId') && !IS_TEAMS_INSTANCE && window.location.pathname !== '/registration-final-step') {
                addAlert(<span dangerouslySetInnerHTML={{
                    __html: intlFormatMessage({ id: 'admin.lastLoginDate' }, {
                        userName: currentUserProfile.firstName,
                        lastLoginDate: moment.utc(currentUserProfile.lastLoginDate).local().format('DD MMM YYYY HH:mm:ss')
                    })
                }} />, EAlertType.INFO);
                setLoginInfo(true);
            }
        }
    }, [translations, userLanguage]);

    useEffect(() => {
        const currentLocation = window.location;
        const currentLocalUrl = `${currentLocation.pathname}${currentLocation.search || ''}`;
        setCurrentUrl(currentLocalUrl);
        if (currentUrl !== currentLocalUrl) {
            if (!scrollExceptions.find(exception => !!currentUrl.match(exception))) {
                window.scrollTo(0, 0);
            }

            if (tracker[ETrackingType.Smh]) {
                tracker[ETrackingType.Smh].setCustomUrl(currentUrl);
                tracker[ETrackingType.Smh].trackPageView();
            }

            if (tracker[ETrackingType.Sg]) {
                tracker[ETrackingType.Sg].setCustomUrl(currentUrl);
                tracker[ETrackingType.Sg].trackPageView();
            }
        }
    }, [window.location]);

    useEffect(() => {
        if (receiveChannelMessage) {
            receiveChannelMessageHandler(receiveChannelMessage);
        }
    }, [receiveChannelMessage]);

    useEffect(() => {
        if (updateChannelMessage) {
            updateChannelMessageHandler(updateChannelMessage);
        }
    }, [updateChannelMessage]);

    useEffect(() => {
        if (assignedRoleCreated) {
            assignedRoleCreatedHandler(assignedRoleCreated);
        }
    }, [assignedRoleCreated]);

    useEffect(() => {
        if (deleteChannelMessage) {
            deleteChannelMessageHandler(deleteChannelMessage);
        }
    }, [deleteChannelMessage]);

    useEffect(() => {
        if (receiveMessage) {
            receiveMessageHandler(receiveMessage);
        }
    }, [receiveMessage]);

    useEffect(() => {
        if (receiveNotification) {
            receiveNotificationHandler(receiveNotification);
        }
    }, [receiveNotification]);

    useEffect(() => {
        if (conversationMemberRemoved) {
            conversationMemberRemovedHandler(conversationMemberRemoved);
        }
    }, [conversationMemberRemoved]);

    useEffect(() => {
        if (clusterValidationRequestStatusUpdateMessageDto) {
            clusterValidationRequestStatusUpdateMessageDtoHandler(clusterValidationRequestStatusUpdateMessageDto);
        }
    }, [clusterValidationRequestStatusUpdateMessageDto]);

    useEffect(() => {
        if (receiveConversation) {
            dispatch<NewEventsHaveOccuredOn>(newEventsHaveOccuredOn());
        }
    }, [receiveConversation]);

    useEffect(() => {
        if (attachmentUploaded) {
            dispatch<NewEventsHaveOccuredOn>(newEventsHaveOccuredOn());
            dispatch<AddReceiveAttachment>(addReceiveAttachment(attachmentUploaded.id, attachmentUploaded.attachments[0]));
        }
    }, [attachmentUploaded]);

    useEffect(() => {
        if (assignToClusterCompleted) {
            if (currentClusterData?.errorCode === EClusterErrors.CreationInProgress) {
                dispatch<GetSingleCluster>(getSingleCluster(assignToClusterCompleted.id));
            }
        }
    }, [assignToClusterCompleted]);

    const clusterValidationRequestStatusUpdateMessageDtoHandler = (message: IClusterValidationRequestStatusUpdateMessage) => {
        if (message.additionalInformation.clusterId === currentClusterId) {
            dispatch<GetClusterDataForRefreshApproval>(getClusterDataForRefreshApproval(message.additionalInformation.clusterId));
            dispatch<GetSingleLastValidation>(getSingleLastValidation(message.additionalInformation.clusterId, EApprovalTypes.ClusterOverview));
        }
    };

    const conversationMemberRemovedHandler = (message) => {
        if (currentUserProfile.id === message.id) {
            dispatch<UserRemovedFromConversation>(userRemovedFromConversation(message.conversationId));

            if (conversations && conversations.length && activeConversationId && activeConversationId === message.conversationId) {
                const firstConv = conversations.filter((conversation: ISingleConversation) => conversation.id !== message.conversationId);
                const id = firstConv && firstConv[0] && firstConv[0].id;
                dispatch<SetActiveConversation>(setActiveConversation(id));
                dispatch<ClearCurrentMessages>(clearCurrentMessages());
                /* getMessages(); */
            }
        }
    };

    const receiveNotificationHandler = (message) => {
        const notificationTranslation = <IntlMessage
            id={`notificationsLabels.${message?.key}`}
            values={{
                ObjectName: message?.data?.ObjectName,
                LanguageCode: message?.data?.LanguageCode,
                BranchName: message?.data?.BranchName
            }}
        />;

        if (message?.data?.IsSuccess || message?.key === EBranchNotificationType?.AutomaticallyCreatedBranch) {
            addAlert(notificationTranslation, EAlertType.SUCCESS);
        } else if (message?.data?.IsSuccess === false) {
            addAlert(notificationTranslation, EAlertType.ERROR);
        }
        if (message?.key === ENotificationType.AddMemberToOrganization) {
            dispatch<GetCurrentUserContextOrganizations>(getCurrentUserContextOrganizations());
        }
        if (message?.key === ENotificationType.RemoveMemberFromOrganization) {
            dispatch<GetCurrentUserContextOrganizations>(getCurrentUserContextOrganizations());
        }
        if (message?.key === ENotificationType.SideLetterGenerateStarted) {
            addAlert(notificationTranslation, EAlertType.SUCCESS);
        }
        if (message?.key === ENotificationType.SideLetterGenerateCompleted) {
            addAlert(notificationTranslation, EAlertType.SUCCESS);
        }
        if (message?.key === ENotificationType.SideLetterGenerateFailed) {
            addAlert(notificationTranslation, EAlertType.ERROR);
        }
        dispatch<AddRcivedNotification>(addRcivedNotification(message));
    };

    const receiveMessageHandler = (message: ISingleMessage) => {
        const isMyMessage = message.senderUserId === currentUserProfile?.id;
        !isMyMessage && dispatch<AddUnreadConversation>(addUnreadConversation(message.conversationId));
        dispatch<NewEventsHaveOccuredOn>(newEventsHaveOccuredOn());
        if (!(activeConversationId || showConversationMenu)) {
            return;
        }
        const conversationElem = (conversations || []).find((conversation: ISingleConversation) => conversation.id === message.conversationId);
        if (!conversationElem) {
            /* TODO: waiting for api this.props.getConversation(message.conversationId).then(conversation => {
                this.updateConversations(message, conversation);
            }); */
        } else {
            updateConversations(message, conversationElem);
        }
    };

    const updateConversations = (message: ISingleMessage, conversationElem: ISingleConversation) => {

        const dontUpdateUnreadMessagesCount = message.conversationId === activeConversationId || message.senderUserId === currentUserProfile?.id;

        const newConversationData = {
            ...conversationElem,
            unreadMessagesCount: dontUpdateUnreadMessagesCount ? 0 : (conversationElem?.unreadMessagesCount || 0) + 1,
            lastMessage: {
                creationDate: message.createdDate,
                messageId: message.id,
                preview: message.content.slice(0, 15) || ''
            }
        };

        if (message.tempId && conversations?.find((conversation: ISingleConversation) => conversation.id === message.tempId)) {
            dispatch<RemoveTempConversation>(removeTempConversation(message.tempId));
        }

        if ((message.conversationId === activeConversationId || message.tempId === activeConversationId) && message.type !== 'SystemRemovingMessage') {
            dispatch<UpdateSingleConversation>(updateSingleConversation(newConversationData));
            dispatch<SetActiveConversation>(setActiveConversation(message.conversationId));
        } else if (message.type !== 'SystemRemovingMessage') {
            dispatch<UpdateSingleConversation>(updateSingleConversation(newConversationData));
            /* if (!activeConversationId?.includes('tempGroup-')) {
                this.props.getMessages();
            } */
        }

        if (message.type === 'SystemRemovingMessage') {
            dispatch<UpdateSingleMessage>(updateSingleMessage(message));
        }
    };

    const deleteChannelMessageHandler = (message: IClusterSingleMessage) => {
        if (!message.isThread) {
            dispatch<ReceiveThreadMessage>(receiveThreadMessage(message));
        } else {
            dispatch<DeleteChannelThreadFromState>(deleteChannelThreadFromState(message.threadId));
        }
    };

    const assignedRoleCreatedHandler = (message) => {
        if (message.clusterId === currentClusterId) {
            dispatch<NewEventsHaveOccuredOn>(newEventsHaveOccuredOn());
            if (message.resource.type === 'Cluster') {
                dispatch<GetTeamsWithChannels>(getTeamsWithChannels(message.resource.childId)).then(res => {
                    dispatch<GetChannelMessages>(getChannelMessages(0, 100, res[0].resourceChildId, res[0].teamChannels[0].teamId, res[0].teamChannels[0].id));
                });
            }
        }
    };

    const updateChannelMessageHandler = (message: IClusterSingleMessage) => {
        if (message.clusterId === currentClusterId) {
            dispatch<NewEventsHaveOccuredOn>(newEventsHaveOccuredOn());
        }
        const ValidationRequestPreview = 'ValidationRequestPreview';
        if (message.type === ValidationRequestPreview) {
            if (message.clusterId === currentClusterId) {
                dispatch<GetClusterDataForRefreshApproval>(getClusterDataForRefreshApproval(message.clusterId));
                dispatch<GetSingleLastValidation>(getSingleLastValidation(message.clusterId, EApprovalTypes.ClusterOverview));
            }
        }
    };

    const receiveChannelMessageHandler = (message: IClusterSingleMessage) => {
        const ValidationRequestPreview = 'ValidationRequestPreview';
        if (message.type === ValidationRequestPreview) {
            if (message.clusterId === currentClusterId) {
                dispatch<GetClusterDataForRefreshApproval>(getClusterDataForRefreshApproval(message.clusterId));
                dispatch<GetSingleLastValidation>(getSingleLastValidation(message.clusterId, EApprovalTypes.ClusterOverview));
            }
        }
        if (message.clusterId === currentClusterId) {
            dispatch<NewEventsHaveOccuredOn>(newEventsHaveOccuredOn());
            dispatch<ActivityCreatedEventOn>(activityCreatedEventOn());
        }
        if (currentChannelId === message.teamChannelId) {
            if (!message.isThread) {
                dispatch<ReceiveThreadMessage>(receiveThreadMessage(message));
            } else {
                const messageObj: ISingleChannelMessage = {
                    ...message,
                    threadMessages: []
                };
                dispatch<ReceiveClusterMessage>(receiveClusterMessage(messageObj));
                if (message.type === 'SystemDocumentsUploaded') {
                    dispatch<GetClusterDocuments>(getClusterDocuments());
                }
            }
        }
    };

    return (
        <>
            {!paramsUrl.get('token') &&
                <>
                    {Object.keys(translations)?.length > 0 &&
                        <AlertContainer />
                    }
                    <Tracking />
                </>
            }
            {props.children}
            {!paramsUrl.get('token') &&
                <Cookies />
            }
            <GlobalLoader isLoading={loading} />
        </>
    );
};