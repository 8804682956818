import React, { useCallback, useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { useFormikContext } from 'formik';

import { ISIngleLegalEntity } from '../../../../../entities/ILegalEntities';
import { EProfileType, IUserBrief } from '../../../../../entities/IGlobal';
import { TextComponent } from '../../../../Common/Inputs/TextComponent';
import { Avatar, EAvatarSize } from '../../../../Common/Avatar/Avatar';
import { IValue } from '../../../../../entities/IPickers';
import { LegalEntitiesPicker } from '../../../../Common/Pickers/LegalEntitiesPicker';
import { UsersPicker } from '../../../../Common/Pickers/UsersPicker';
import { LabelWithEllipsis } from '../../../../Common/Label/Label';
import { EAlertType, EAlertTypeSchema } from '../../../../../entities/IAlert';
import { MandatoryFieldStar } from '../../../../Common/MandatoryFieldStar/MandatoryFieldStar';
import { colorStack } from '../../../../../styleHelpers/colors';
import { ITradeAssociationData } from '../../../../../entities/ActionData/ITradeAssociationData';
import { Alert } from '../../../../Common/Alert/Alert';
import IntlMessage, { useIntlMessage } from '../../../../Common/IntlMessage';

const Wrapper = styled.div`
    width: 100%;
    display: block;
    margin: 0 auto;
`;

const InnerWrapper = styled.div`
    position: relative;
    background: white;
    border-radius: 4px;
    margin-bottom: 26px;
    display: flex;
    flex-direction: column;
`;

const Row = styled.div`
    display: flex;
    flex-direction: row;
`;

const InputSection = styled.div<{ row?: boolean; text?: boolean; radio?: boolean }>`
    padding: 20px 28px;
    width: 50%;
    ${props => props.row && css`
        display: flex;
        flex-direction: row;
    `}
    ${props => props.text && css`
        display: inline-flex;
        align-items: center;
    `}
    ${props => props.radio && css`
        label {
            margin-right: 29px;
        }
    `}
`;

const Title = styled.div`
    color: ${colorStack.content};
    margin: 0 0 2rem 0;
    sup {
        color: ${colorStack.red};
    }
`;

interface IFocusedElementTag {
    firstName: string;
    lastName: string;
    picture: string;
    id: string;
}

export const Step4 = () => {
    const { intlFormatMessage } = useIntlMessage();
    const { setFieldValue, values, errors } = useFormikContext<ITradeAssociationData>();
    const [focusedElementTag, setFocusedElementTag] = useState<IFocusedElementTag>();
    const [managerValue, setManagerValue] = useState<{ key: string; text: string; data: IUserBrief; }[]>(undefined);
    const [entityValue, setEntityValue] = useState<IValue[]>(undefined);
    const [inputBlur, setInputBlur] = useState({
        fullName: false,
        jobTitle: false,
        legalEntity: false,
        country: false,
        manager: false
    });

    useEffect(() => {
        setFieldValue('step', 4);
    }, []);

    const fillLegalEntityInformation = useCallback((data: ISIngleLegalEntity) => {
        setFieldValue(`formData.tradeAssociationInformation.legalEntity`, data ? {
            name: data?.name,
            picture: data?.picture,
            id: data?.id,
            type: data?.type
            // tslint:disable-next-line:no-null-keyword
        } : null);
    }, []);

    const onChangeSelectedEntity = useCallback((element: { key: string; text: string; data: ISIngleLegalEntity; }[]) => {
        setEntityValue(element);
        fillLegalEntityInformation(element?.[0]?.data);
    }, []);

    useEffect(() => {
        values.formData.tradeAssociationInformation.creator.firstName && (
            setFocusedElementTag({
                firstName: values.formData?.tradeAssociationInformation?.creator.firstName,
                lastName: values.formData?.tradeAssociationInformation?.creator.lastName,
                picture: values.formData?.tradeAssociationInformation?.creator.picture,
                id: values.formData?.tradeAssociationInformation?.creator.id
            })
        );
    }, []);

    useEffect(() => {
        values.formData.tradeAssociationInformation?.legalEntity && !entityValue && (
            setEntityValue(values.formData?.tradeAssociationInformation?.legalEntity?.id ? [{
                key: values.formData?.tradeAssociationInformation?.legalEntity?.id,
                text: values.formData?.tradeAssociationInformation?.legalEntity?.name,
                data: values.formData?.tradeAssociationInformation?.legalEntity
            }] : undefined)
        );
    }, []);

    useEffect(() => {
        if (!!values.formData.tradeAssociationInformation.manager?.id) {
            setManagerValue([{
                key: values.formData.tradeAssociationInformation.manager.id || '',
                text: '',
                data: {
                    firstName: values.formData.tradeAssociationInformation.manager.firstName || '',
                    lastName: values.formData.tradeAssociationInformation.manager.lastName || '',
                    jobTitle: values.formData.tradeAssociationInformation.manager.jobTitle || '',
                    picture: values.formData.tradeAssociationInformation.manager.picture || '',
                    cabinet: values.formData.tradeAssociationInformation.manager.cabinet || '',
                    id: values.formData.tradeAssociationInformation.manager.id || '',
                    type: EProfileType.Personal
                }
            }]);
        }
    }, []);

    const onChangeManagerSelected = useCallback((element: { key: string; text: string; data: IUserBrief; }[]) => {
        setManagerValue(element);
        setFieldValue(`formData.tradeAssociationInformation.manager`, {
            firstName: element?.[0]?.data?.firstName || '',
            lastName: element?.[0]?.data?.lastName || '',
            picture: element?.[0]?.data?.picture,
            id: element?.[0]?.data?.id
        });

    }, [values.formData.tradeAssociationInformation.manager?.firstName]);

    const handleBlur = useCallback((name: string) => {
        setInputBlur({
            ...inputBlur,
            [name]: true
        });
    }, [inputBlur]);

    return (
        <Wrapper>
            <InnerWrapper>
                <Row>
                    <InputSection>
                        <LabelWithEllipsis required>
                            <IntlMessage id="tradeAssociation.wizard.step4.fullName" />
                        </LabelWithEllipsis>
                        <Avatar
                            picture={focusedElementTag?.picture}
                            firstName={focusedElementTag?.firstName}
                            lastName={focusedElementTag?.lastName}
                            id={focusedElementTag?.id}
                            size={EAvatarSize.XSMALL}
                            extended
                        />
                    </InputSection>
                    <InputSection>
                        <LabelWithEllipsis required>
                            <IntlMessage id="tradeAssociation.wizard.step4.jobTitle" />
                        </LabelWithEllipsis>
                        <TextComponent
                            value={values.formData.tradeAssociationInformation.jobTitle}
                            error={inputBlur.jobTitle && !!errors?.formData?.tradeAssociationInformation?.jobTitle}
                            onChange={(value: string) => setFieldValue('formData.tradeAssociationInformation.jobTitle', value)}
                            onBlur={() => handleBlur('jobTitle')}
                        />
                    </InputSection>
                </Row>
                <Row>
                    <InputSection>
                        <LabelWithEllipsis required>
                            <IntlMessage id="tradeAssociation.wizard.step4.legalEntity" />
                        </LabelWithEllipsis>
                        <LegalEntitiesPicker
                            value={entityValue}
                            onSelectElement={onChangeSelectedEntity}
                            filteredCompanies
                            hideButtons
                        />
                    </InputSection>
                    <InputSection>
                        <LabelWithEllipsis required>
                            <IntlMessage id="tradeAssociation.wizard.step4.manager" />
                        </LabelWithEllipsis>
                        <UsersPicker
                            value={managerValue}
                            onSelectElement={onChangeManagerSelected}
                        />
                    </InputSection>
                </Row>
            </InnerWrapper>
            <Title>
                <MandatoryFieldStar />
                <IntlMessage id="tradeAssociation.wizard.step4.requiredInfo" />
            </Title>
            <Alert
                withoutClose
                visible
                alertId=""
                withoutProgress
                typeSchema={EAlertTypeSchema.SECONDARY}
                type={EAlertType.INFO}
            >
                <span dangerouslySetInnerHTML={{ __html: intlFormatMessage({ id: 'tradeAssociation.wizard.step4.requiredInfo.text' }) }} />
            </Alert>
            <Alert
                withoutClose
                visible
                alertId=""
                withoutProgress
                typeSchema={EAlertTypeSchema.SECONDARY}
                type={EAlertType.DEFAULT}
            >
                <span dangerouslySetInnerHTML={{ __html: intlFormatMessage({ id: 'tradeAssociation.wizard.step4.greybox' }) }} />
            </Alert>
        </Wrapper>
    );
};
