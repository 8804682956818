import React, { FC, useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useFormikContext } from 'formik';
import { useDispatch } from 'react-redux';

import { DatePickerWrapper } from '../../../Common/DatePicker/DatePicker';
import { LabelWithEllipsis } from '../../../Common/Label/Label';
import { SelectInput } from '../../../Common/SelectInput/SelectInput';
import { Dropzone } from '../../../NewDesignCommon/Dropzone/Dropzone';
import { IWizardValues } from '../../../../entities/ILegalSupport';
import { getReferentials } from '../../../../actions/globalActions';
import { EContextList, IReferencial, EReferentials } from '../../../../entities/IGlobal';
import { uploadAttachment } from '../../../../actions/clustersActions';
import { MandatoryFieldStar } from '../../../Common/MandatoryFieldStar/MandatoryFieldStar';
import { NewRT } from '../../../Common/RichText/NewRT';
import { IValue } from '../../../../entities/IPickers';
import { EDropDownType } from '../../../Common/DropDown/DropDown';
import { formDataEncodeAndAppendFile } from '../../../../tools/files';
import IntlMessage from '../../../Common/IntlMessage';

type GetReferentials = ReturnType<typeof getReferentials>;
type UploadAttachment = ReturnType<typeof uploadAttachment>;

const Wrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    `;

const Row = styled.div`
    margin: 1rem 0 1rem 0;
    flex-direction: column;
    display: flex;
    > div {
        margin: 0 1rem 0 0;
    }

`;

const TwoElems = styled.div`
    display: flex;
    justify-content: space-between;
    > div {
        width: 45%;
    }
`;

export const Step2: FC<React.PropsWithChildren<unknown>> = () => {
    const dispatch = useDispatch();
    const { setFieldValue, values } = useFormikContext<IWizardValues>();
    const [loadingDocument, setLoadingDocument] = useState<boolean>(false);
    const [subjectOptions, setSubjectOptions] = useState<IReferencial[]>(undefined);

    useEffect(() => {
        setFieldValue('step', 2);
    }, []);

    useEffect(() => {
        dispatch<GetReferentials>(getReferentials('', EReferentials.LegalSupportSubject, EContextList.LegalSupport)).then(response => {
            setSubjectOptions(response);
        });
    }, []);

    const changeSubject = useCallback((option?: IValue) => {
        setFieldValue('formData.subject', option?.data);
    }, []);

    const changeDatePicker = useCallback((date: Date) => {
        setFieldValue('formData.answerDate', date);
    }, []);

    const onMessageChange = useCallback((content: string) => {
        const contextLength = content ? new DOMParser().parseFromString(content?.replace(/[^\x20-\x7E]/gmi, '').trim(), 'text/html').documentElement.textContent.trim() : '';
        setFieldValue('formData.question', content);
        setFieldValue('formData.isRichText', !!contextLength);
    }, []);

    const changeDropzoneFiles = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        const files = e.target.files;
        if (files?.length ) {
            setLoadingDocument(true);
            const formData = new FormData();
            formDataEncodeAndAppendFile(formData, 'attachment', files[0]);
            dispatch<UploadAttachment>(uploadAttachment(formData)).then(res => {
                setFieldValue('formData.attachment', ({
                    name: res.name,
                    directUri: res.directUri,
                    size: res.size,
                    fileId: res.fileId
                }));
                setLoadingDocument(false);
            });
        }
    }, []);

    const removeFile = useCallback(() => {
        setFieldValue('formData.attachment', undefined);
    }, [values.formData.attachment]);

    return (
        <Wrapper>
            <Row>
                <TwoElems>
                    <SelectInput
                        value={values.formData.subject?.id ? [{key: values.formData.subject?.id, text: values.formData.subject?.name, data: values.formData.subject}] : undefined}
                        onChange={changeSubject}
                        inputLabel={<IntlMessage id="legalSupport.wizard.step2.subject" />}
                        required
                        options={subjectOptions?.map(elem => ({
                            key: elem.id,
                            text: elem.name,
                            data: {
                                ...elem
                            }
                        }))}
                        type={EDropDownType.DEFAULT}
                    />
                    <DatePickerWrapper
                        value={values.formData.answerDate}
                        onChange={changeDatePicker}
                        label={<IntlMessage id="legalSupport.wizard.step2.date" />}
                        required
                    />
                </TwoElems>
            </Row>
            <Row>
                <LabelWithEllipsis>
                    <IntlMessage id="legalSupport.wizard.step2.question" />
                    <MandatoryFieldStar />
                </LabelWithEllipsis>
                <NewRT
                    content={values.formData.question}
                    onChange={onMessageChange}
                />
            </Row>
            <Row>
                <LabelWithEllipsis><IntlMessage id="legalSupport.wizard.step2.attachment" /></LabelWithEllipsis>
                <Dropzone
                    onChange={changeDropzoneFiles}
                    loading={loadingDocument}
                    fileList={[values.formData.attachment]}
                    hideCopyUrl
                    canRemove
                    hideView
                    hideDownload
                    removeHandler={removeFile}
                />
            </Row>
        </Wrapper>
    );
};
