import React, { FC, useCallback } from 'react';
import styled from 'styled-components';
import { faDownload, faTimes } from '@fortawesome/pro-light-svg-icons';

import { ELcid } from '../../../entities/ILanguage';
import { ISgVersionLcid, ITableOfContentsVersion, ICompareVersion, IFeatureBranchCompare } from '../../../entities/ISoge';
import { Button } from '../../Common/Buttons/NewButton';
import { colorStack } from '../../../styleHelpers/colors';
import { fontSize } from '../../../styleHelpers/fontSizes';
import { media } from '../../../styleHelpers/breakpoint';
import { CompareVersionSelect } from './CompareVersionSelect';
import LangSelect from '../Components/LangSelect';
import { ESpinnerSize, Spinner } from '../../Common/Spinner/Spinner';
import { SgDownloadButton } from '../Components/SgDownloadButton';
import { EIconButtonTypeSchema, IconButton } from '../../Common/Buttons/IconButton';
import { Checkbox } from '../../Common/Inputs/Checkbox';
import IntlMessage from '../../Common/IntlMessage';

const VersionSelectBarWrapper = styled.div`
    display: flex;
    margin: 0 1rem;
    border-bottom: 1px solid ${colorStack.ligthGrey};
    align-items: center;
    padding-bottom: 0.5rem;
`;

const VersionSelectBar = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 10px 0;
    > span {
        margin: 0 1rem 0 0;
        font-size: ${fontSize[13]};
        font-weight: 500;
    }
`;

const DownloadingSpinner = styled(Spinner)`
    margin-right: 1rem;
`;

const ButtonWrapper = styled.div`
    margin-left: auto;
    display: flex;
    align-items: center;
`;

const ToolbarCheckbox = styled(Checkbox)`
    > label > span {
        font-size: ${fontSize[13]};
        font-weight: 500;
    }
`;

interface IContentToolbarProps {
    versionsLcid: ISgVersionLcid;
    versions: ITableOfContentsVersion[];
    comparedBranchVersion: ICompareVersion;
    downloadDisabled?: boolean;
    isDownloading?: boolean;
    highlightChanges: boolean;
    sideBySide: boolean;
    className?: string;
    withLangSelect?: boolean;
    featureBranchesResponse?: IFeatureBranchCompare;
    isBranchPreview?: boolean;
    onChangeCompareBranchVersion(targetLanguage: ELcid, targetVersionId: string, targetVersionName: string, featureBranchId?: string): void;
    onDownloadClick?(): Promise<void>;
    toggleHighlightChanges();
    toggleSideBySide();
    onCloseClick?();
}

export const ContentToolbar: FC<React.PropsWithChildren<IContentToolbarProps>> = ({ versionsLcid, versions, comparedBranchVersion, downloadDisabled, isDownloading, highlightChanges, sideBySide, className, withLangSelect, featureBranchesResponse, isBranchPreview, onChangeCompareBranchVersion, onDownloadClick, toggleHighlightChanges, toggleSideBySide, onCloseClick }) => {
    const onClose = useCallback(() => {
        onCloseClick?.();
        onChangeCompareBranchVersion?.(undefined, undefined, undefined, undefined);
    }, [onCloseClick, onChangeCompareBranchVersion]);

    return (
        <VersionSelectBarWrapper className={className || ''}>
            <VersionSelectBar>
                {withLangSelect && (
                    <>
                        <IntlMessage id="norms.compare.contentIn" />
                        <LangSelect />
                    </>
                )}
                <IntlMessage id="norms.compare.comparedWith" />
                <CompareVersionSelect
                    onChangeVersion={onChangeCompareBranchVersion}
                    selectedLanguage={comparedBranchVersion?.language}
                    selectedVersionName={comparedBranchVersion?.versionName}
                    versions={versions}
                    versionLcid={versionsLcid}
                    featureBranchesResponse={featureBranchesResponse}
                    isBranchPreview={isBranchPreview}
                />
                <ToolbarCheckbox checked={highlightChanges} onChange={toggleHighlightChanges} disabled={!comparedBranchVersion?.versionId}>
                    <IntlMessage id="norms.checkbox.highlightChanges" />
                </ToolbarCheckbox>
                {highlightChanges && !!comparedBranchVersion?.versionId && (
                    <ToolbarCheckbox checked={sideBySide} onChange={toggleSideBySide} disabled={!comparedBranchVersion?.versionId}>
                        <IntlMessage id="norms.sideBySide" />
                    </ToolbarCheckbox>
                )}
            </VersionSelectBar>
            <SgDownloadButton>
                {!!onDownloadClick && (
                    <ButtonWrapper>
                        {isDownloading && (
                            <DownloadingSpinner size={ESpinnerSize.SMALL} />
                        )}
                        <Button leftIco={faDownload} onClick={onDownloadClick} disabled={downloadDisabled || isDownloading}>
                            <IntlMessage id="norms.button.downloadElement" />
                        </Button>
                    </ButtonWrapper>
                )}
            </SgDownloadButton>
            {!!onCloseClick && (
                <ButtonWrapper>
                    <IconButton icon={faTimes} typeSchema={EIconButtonTypeSchema.TERTIARY} onClick={onClose} />
                </ButtonWrapper>
            )}
        </VersionSelectBarWrapper>
    );
};

export const ConsultationCompareToolbar = styled(ContentToolbar)`
    display: none;
    ${media.desktop`
        display: flex;
        margin: 0 auto;
        border: 0;
        max-width: 1000px;
    `}
    > div {
        margin: 0;
        button {
            background: transparent;
        }
    }
    > ${ButtonWrapper} {
        margin-left: auto;
    }
`;
