import React, { FC, useCallback, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { IconDefinition, faSync } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { cardsShadow } from '../../../styleHelpers/mixins/shadow';
import { colorStack } from '../../../styleHelpers/colors';
import { fontSizeAndHeight } from '../../../styleHelpers/fontSizes';
import IntlMessage from '../IntlMessage';

const Wrapper = styled.div`
    border-radius: 4px;
    height: 42px;
    width: 180px;
    display: flex;
    position: relative;
    ${cardsShadow()};
    cursor: pointer;
    justify-content: space-between;
`;

const NameSlide = styled.div<{state: boolean}>`
    position: absolute;
    left: 60px;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 120px;
    height: 100%;
    background: ${colorStack.disabled};
    color: ${colorStack.white};
    ${fontSizeAndHeight[13]};
    transition: all .3s;
    svg {
        margin: 0 10px 0 0;
    }
    ${props => props.state && css`
        background: ${colorStack.middleBlue};
        left: 0px;
    `}
`;

const OnOffSide = styled.div<{state: boolean}>`
    width: 68px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: ${colorStack.ligthGrey};
    ${fontSizeAndHeight[13]};
    color: ${colorStack.disabled};
    transition: all .3s;
    ${props => props.state && css`
        background: ${colorStack.white};
        color: ${colorStack.middleBlue};

    `}
`;

interface IProps {
    offText?: string;
    onText?: string;
    name?: string;
    icon?: IconDefinition;
    state?: boolean;
    getCurrentState?(state: boolean);
}

export const OnOff: FC<React.PropsWithChildren<IProps>> = props => {
    const [currentState, setState] = useState<boolean>(false);

    useEffect(() => {
        setState(props.state);
    }, [props.state]);

    const changeState = useCallback(() => {
        setState(state => !state);
        props.getCurrentState && props.getCurrentState(!currentState);
    }, [currentState]);
    return (
        <Wrapper onClick={changeState}>
            <OnOffSide state={currentState}><IntlMessage id={props.offText || 'global.off'} /></OnOffSide>
            <NameSlide state={currentState}>
                <FontAwesomeIcon icon={props.icon || faSync} />
                <IntlMessage id={props.name || 'global.periodicity'} />
            </NameSlide>
            <OnOffSide state={currentState}><IntlMessage id={props.onText || 'global.on'} /></OnOffSide>
        </Wrapper>
    );
};