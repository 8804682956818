import React, {FC, useEffect} from 'react';
import {useSelector} from 'react-redux';
import queryString from 'query-string-for-all';

import {GlobalLoader} from '../GlobalLoader';
import {IState} from '../../reducers';
import {IConfigReducer} from '../../reducers/configReducer';
import {AuthState, EAuthConstants, getContextUserId, uuidv4} from '../../tools/authTools';
import {getWithExpiry, storeWithExpiry} from '../../tools/storage';
import { sessionWithExpiry } from '../../tools/sessionStorage';

interface IAuthStartProps {
    passwordResetMode?: boolean;
}

export const AuthStart: FC<React.PropsWithChildren<IAuthStartProps>> = ({ passwordResetMode }) => {
    const { appConfig: { applicationConfig } } = useSelector<IState, IConfigReducer>(state => state.config);

    useEffect(() => {
        const query = queryString.parse(window.location.search);
        const nextRequestUrl = ((query.nextRequestUrl as string || '').match('auth-callback') ? '/' : query.nextRequestUrl as string) || '/';

        const userId = getContextUserId();
        const nonce = uuidv4();
        const ttlCsrf = getWithExpiry(EAuthConstants.CSRFState);
        const csrf = ttlCsrf || uuidv4();
        const stateObject: AuthState = { csrf, nextRequestUrl };
        const state = btoa(JSON.stringify(stateObject));
        const codeChallenge = btoa(uuidv4());

        sessionWithExpiry(EAuthConstants.CodeChallenge, codeChallenge, 1000 * 60 * 3); // 3 min

        !ttlCsrf && storeWithExpiry(EAuthConstants.CSRFState, csrf, 1000 * 60 * 3); // 3 min

        const queryParams = {
            client_id: applicationConfig.clientID,
            response_type: 'code',
            response_mode: 'fragment',
            scope: `${applicationConfig.b2cScopes[0]} openid profile offline_access`,
            redirect_uri: `${applicationConfig.redirectURI}${EAuthConstants.RequestAuthEndRoute}`,
            nonce,
            state,
            domain_hint: 'organizations',
            ...(!!userId ? { login_hint: userId.email } : {}),
            code_challenge: codeChallenge,
            code_challenge_method: 'plain'
        };

        const authority = passwordResetMode ? applicationConfig.authorityResetPassword : applicationConfig.authority;
        const authorizeEndpoint = `${authority}/oauth2/v2.0/authorize?${queryString.stringify(queryParams)}`;

        window.location.assign(authorizeEndpoint);
    }, []);

    return (
        <GlobalLoader isLoading />
    );
};
