import React, { FC, useContext, useEffect } from 'react';
import styled from 'styled-components';

import { EApprovalContext, EApprovalTypes, IEApprovalContext, IMainStepProps } from '..';
import { Row } from '../../../../Common/Row/Row';
import { EAlertType, EAlertTypeSchema } from '../../../../../entities/IAlert';
import { SubjectMessage } from '../../../../Common/Inputs/SubjectMessage';
import { Alert } from '../../../../Common/Alert/Alert';
import IntlMessage, { useIntlMessage } from '../../../../Common/IntlMessage';

const Wrapper = styled.div`
    width: 100%;
    > div {
        margin-bottom: 1rem;
    }
    > :last-child {
        margin: 0;
    }
`;

export const GroupingStep9: FC<IMainStepProps> = props => {
    const { intlFormatMessage } = useIntlMessage();
    const eApprovalContext = useContext<IEApprovalContext>(EApprovalContext);

    useEffect(() => {
        if (!eApprovalContext.data?.[EApprovalTypes.Grouping]?.approvalMessage) {
            eApprovalContext.modifyData(intlFormatMessage({id: 'eApproval.wizard.step6.eapproval-grouping.message'}, {lineBrake: '\r\n'}), 'body', props.context, props.stepName, props.stepNumber);
            eApprovalContext.modifyData(intlFormatMessage({id: 'eApproval.wizard.step6.eapproval-grouping.subject'}), 'subject', props.context, props.stepName, props.stepNumber);
        }
    }, []);

    const onBodyChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        const value = e.target.value;
        eApprovalContext.modifyData(value, 'body', props.context, props.stepName, props.stepNumber);
    };

    const onSubjectChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        eApprovalContext.modifyData(value, 'subject', props.context, props.stepName, props.stepNumber);
    };

    return (
        <Wrapper>
            <Row numberOfElements={1} noBorder>
                <Alert
                    transparentRight
                    type={EAlertType.ERROR}
                    typeSchema={EAlertTypeSchema.SECONDARY}
                    withoutClose
                    withoutProgress
                    visible
                    alertId="1"
                >
                    <IntlMessage id={`eApproval.wizard.step9.${[EApprovalTypes.Grouping]}.warning`} />
                </Alert>
            </Row>
            <Row numberOfElements={1} noBorder>
                <div>
                    <IntlMessage id={`eApproval.wizard.step6.${[EApprovalTypes.Grouping]}.context`} />
                    <SubjectMessage
                        inputValue={eApprovalContext.data?.[EApprovalTypes.Grouping]?.approvalMessage?.subject}
                        textareaValue={eApprovalContext.data?.[EApprovalTypes.Grouping]?.approvalMessage?.body}
                        inputChange={onSubjectChange}
                        textareaChange={onBodyChange}
                        mandatoryStarSubject
                    />
                </div>
            </Row>
        </Wrapper>
    );
};