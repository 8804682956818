import React, { useCallback, FC } from 'react';
import styled from 'styled-components';
import { faMinusCircle } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IRefferentialObj, IRegistrations } from '../../../entities/ILegalEntities';
import { IReferencial } from '../../../entities/IGlobal';
import { colorStack } from '../../../styleHelpers/colors';
import { SelectInput } from '../../Common/SelectInput/SelectInput';
import { TextComponent } from '../Inputs/TextComponent';
import { IValue } from '../../../entities/IPickers';
import { EDropDownType } from '../DropDown/DropDown';

const TableRow = styled.div`
    display: flex;
    align-items: center;
    > div {
        padding: .5rem;
        &:first-child {
            width: 220px;
            border-right: 1px solid ${colorStack.ligthGrey};
        }
        &:nth-child(2) {
            display: flex;
            align-items: center;
            width: calc(100% - 420px);
            border-right: 1px solid ${colorStack.ligthGrey};
            input {
                margin: 0 1rem 0 0;
            }
        }
        &:nth-child(3) {
            width: 232px;
            display: flex;
            justify-content: center;
            align-items: center;
            svg {
                color: ${colorStack.darkRed};
                cursor: pointer;
            }
        }
    }
`;

interface ISingleRegistrationRowProps {
    elem: IRegistrations;
    aliasType: IReferencial[];
    elemIndex: number;
    removeRow(elemIndex: number);
    aliasChange(elemIndex: number, aliasChange: IRefferentialObj);
    codeChange(elemIndex: number, value: string);
}

export const SingleRegistrationRow: FC<React.PropsWithChildren<ISingleRegistrationRowProps>> = props => {

    const aliasChange = useCallback((aliasType: IRefferentialObj) => {
        props.aliasChange(props.elemIndex, aliasType);
    }, [props.elemIndex, props.aliasChange]);

    const codeChange = useCallback((value: string) => {
        props.codeChange(props.elemIndex, value);
    }, [props.elemIndex, props.codeChange]);

    const removeRowCode = useCallback(() => {
        props.removeRow(props.elemIndex);
    }, [props.elemIndex, props.removeRow]);

    return (
        <TableRow>
            <div>
                {(props.aliasType && props.aliasType.length > 0) &&
                    <SelectInput
                        value={props.elem?.aliasType ? [{key: props.elem?.aliasType?.name, text: props.elem?.aliasType?.name, data: props.elem?.aliasType?.name}] : undefined}
                        onChange={(option: IValue) => aliasChange(option?.data)}
                        options={props.aliasType.map(elem => ({
                            key: elem.name,
                            text: elem.name,
                            data: {
                                ...elem
                            }
                        }))}
                        type={EDropDownType.SEARCH}
                    />
                }
            </div>
            <div>
                <TextComponent
                    value={props.elem.aliasValue} onChange={codeChange}
                />
            </div>
            <div>
                <FontAwesomeIcon icon={faMinusCircle} onClick={removeRowCode} />
            </div>
        </TableRow>
    );
};
