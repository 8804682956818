import React, { FC, useCallback, useEffect } from 'react';
import styled from 'styled-components';
import { useFormikContext } from 'formik';

import { Checkbox } from '../../../Common/Inputs/Checkbox';
import { ICOIData } from '../../../../entities/ActionData/ICOIData';
import { fontSize } from '../../../../styleHelpers/fontSizes';
import { colorStack } from '../../../../styleHelpers/colors';
import { useIntlMessage } from '../../../Common/IntlMessage';

const CBContainer = styled.div``;

const Container = styled.div`
    color: ${colorStack.darkBlue};
    font-size: ${fontSize[16]};
    display: flex;
    flex-direction: column;
    margin: 0 2rem 0 2rem;
`;

const PrinciplesContainer = styled.div`
    margin-bottom: 1rem;
    ul {
        list-style-type: disc;
    }
    ol {
        list-style-type: decimal;
    }
`;

interface IProps {
    userFirstName: string;
}

export const COIPrincipes: FC<React.PropsWithChildren<IProps>> = props => {
    const { intlFormatMessage } = useIntlMessage();
    const { setFieldValue, values } = useFormikContext<ICOIData>();

    useEffect(() => {
        setFieldValue('step', 1);
    }, []);

    const changeCheckbox = useCallback((checked: boolean) => {
        setFieldValue('readPolicy', checked);
    }, []);

    return (
        <Container>
            <PrinciplesContainer>
            <div dangerouslySetInnerHTML={{
                __html: intlFormatMessage({ id: 'coi.popup.principes' }, {
                    user: props.userFirstName
                })
            }} />
            </PrinciplesContainer>
            <CBContainer>
                <Checkbox onChange={changeCheckbox} checked={values.readPolicy} label={intlFormatMessage({ id: 'coi.popup.principes.policyCheckbox' })} />
            </CBContainer>
        </Container>
    );
};