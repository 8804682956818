import React, { useState, useCallback, FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

import { createLegalSupportCluster } from '../../../../actions/clustersActions';
import { StepsPopup } from '../../../Common/StepsPopup/StepsPopup';
import { EPopupSizes } from '../../../../entities/IGlobal';
import { Step1 } from './Step1';
import { ETemplateTypes, ISingleCluster, EClusterPrivacy } from '../../../../entities/IClusters';
import { IState } from '../../../../reducers';
import { IProfileReducer } from '../../../../reducers/profileReducer';
import { Step2 } from './Step2';
import { IWizardValues } from '../../../../entities/ILegalSupport';
import { initialFormValues } from './InitialValues';
import { LastStep } from './LastStep';
import { YourInformation } from './YourInformation';
import { IClusterReducer } from '../../../../reducers/clustersReducer';
import { useIntlMessage } from '../../../Common/IntlMessage';

type CreateLegalSupportCluster = ReturnType<typeof createLegalSupportCluster>;

export interface IFormikData {
    formData: {
        cluster: {
            clusterTemplateId: string;
            clusterTemplateName: string;
            clusterTemplateKey: string;
            clusterTemplateColor: string;
        };
        name: string;
        privacy: EClusterPrivacy;
    };
    steps: number;
}

interface IProps {
    organizationId: string;
    organizationUrlName: string;
    isPopupShown: boolean;
    isHome?: boolean;
    setShowPopup(showContract: boolean);
}

export const LegalSupportWizard: FC<React.PropsWithChildren<IProps>> = (props) => {
    const dispatch = useDispatch();
    const { intlFormatMessage } = useIntlMessage();
    const history = useNavigate();
    const { clusterTemplates, currentUserProfile } = useSelector<IState, IProfileReducer & IClusterReducer>(state => ({
        ...state.profile,
        ...state.clusters
    }));
    const [isConfirmButtonDisabled, setIsConfirmButtonDisabled] = useState<boolean>(true);
    const [stepFinished, setStepFinished] = useState<boolean>(false);
    const [newClusterData, setNewClusterData] = useState<ISingleCluster>(undefined);

    const closePopup = useCallback((resetForm) => {
        resetForm();
        props.setShowPopup(false);
        setStepFinished(false);
        if (props.isHome) {
            history('/');
        } else {
            newClusterData?.id && history(`/orgs/${props.organizationUrlName}/cluster/${newClusterData.id}`);
        }
    }, [props.organizationUrlName, newClusterData, props.isHome]);

    const onSubmit = useCallback((values: IWizardValues) => {
        setIsConfirmButtonDisabled(true);
        return dispatch<CreateLegalSupportCluster>(createLegalSupportCluster(props.organizationId, values)).then(response => {
            setNewClusterData(response);
            setIsConfirmButtonDisabled(false);
            setStepFinished(true);
        }).catch(() => {
            setIsConfirmButtonDisabled(false);
        });
    }, [dispatch, setIsConfirmButtonDisabled, props.organizationId, clusterTemplates]);

    const clearForm = useCallback((resetForm) => {
        resetForm();
        setStepFinished(false);
    }, []);

    return (
        <Formik<IWizardValues>
            initialValues={initialFormValues(currentUserProfile)}
            validationSchema={() => Yup.lazy((values: IWizardValues) => {
                const stepNr = values.step;
                return Yup.object().shape({
                    formData: Yup.object().shape({
                        readPolicy: stepNr === 1 && Yup.boolean().oneOf([true]).required(),
                        subject: stepNr === 2 && Yup.object().shape({
                            id: Yup.string().required(),
                            Key: Yup.string().nullable(),
                            logicalId: Yup.number().nullable(),
                            name: Yup.string()
                        }),
                        answerDate: stepNr === 2 && Yup.date().required(),
                        question: stepNr === 2 && Yup.string().required(),
                        isRichText: stepNr === 2 && Yup.boolean().oneOf([true]).required(),
                        attachment: stepNr === 2 && Yup.object().shape({
                            directUri: Yup.string(),
                            name: Yup.string()
                        }),
                        user: stepNr === 3 && Yup.object().shape({
                            key: Yup.string().required(),
                            text: Yup.string().required(),
                            data: Yup.object()
                        }),
                        jobTitle: stepNr === 3 && Yup.string().required(),
                        legalEntity: stepNr === 3 && Yup.object().shape({
                            key: Yup.string().required(),
                            text: Yup.string().required(),
                            data: Yup.object()
                        }),
                        manager: stepNr === 3 && Yup.object().shape({
                            key: Yup.string().required(),
                            text: Yup.string().required(),
                            data: Yup.object()
                        })
                    })
                });
            })}
            onSubmit={onSubmit}>
            {({ isValid, dirty, submitForm, resetForm, values }) => (
                <StepsPopup
                    showPopup={props.isPopupShown}
                    size={EPopupSizes.MEDIUM}
                    title={intlFormatMessage({ id: 'legalSupport.wizard.title' })}
                    isFormValid={isValid}
                    preventClosingOnClickingOutside
                    dirty={dirty}
                    confirmDisabled={isConfirmButtonDisabled}
                    finishHandler={() => closePopup(resetForm)}
                    confirmFinished={stepFinished}
                    wizardType={ETemplateTypes.Contract}
                    clearForm={() => clearForm(resetForm)}
                    confirmHandler={submitForm}
                    steps={[
                        {
                            content: <Step1 userFirstName={currentUserProfile?.firstName} />
                        },
                        {
                            content: <Step2 />
                        },
                        {
                            content: <YourInformation legalEntityId={currentUserProfile?.cabinet?.id} />
                        },
                        {
                            content: <LastStep />
                        }
                    ]}
                />
            )}
        </Formik>
    );
};