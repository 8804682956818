import React, { useEffect, FC, useState, useMemo } from 'react';
import styled from 'styled-components';
import { useFormikContext } from 'formik';
import { useDispatch } from 'react-redux';

import { IFormData } from '../../../../entities/IDeclaration';
import { SelectCard } from '../../../Common/SelectCard/SelectCard';
import { IClusterTemplate, ETemplateKeys } from '../../../../entities/IClusters';
import { getClusterTemplates } from '../../../../actions/clustersActions';
import IntlMessage from '../../../Common/IntlMessage';

type GetClusterTemplates = ReturnType<typeof getClusterTemplates>;

const SWrapper = styled.div`
    display: flex;
    grid-gap: 37px;
    width: 100%;
    justify-content: center;
    input[type="radio"] {
        display: none;
    }
    img {
        display: block;
        margin: auto;
    }
`;

interface IProps {
    organizationId: string;
}

export const DeclarationType: FC<React.PropsWithChildren<IProps>> = (props) => {
    const formikContext = useFormikContext<IFormData>();
    const dispatch = useDispatch();
    const [declarationType, setDeclarationType] = useState<string>(undefined);
    const [templates, setTemplates] = useState<IClusterTemplate[]>(undefined);

    useEffect(() => {
        formikContext.setFieldValue('step', 1);
        setDeclarationType(formikContext.values.formData.declarationType);
        dispatch<GetClusterTemplates>(getClusterTemplates(props.organizationId, true)).then(clusterTemplates => {
            setTemplates(clusterTemplates);
        });
    }, []);

    useEffect(() => {
        if (formikContext.values.formData.declarationType !== declarationType && declarationType) {
            const type = formikContext.values.formData.declarationType;
            formikContext.resetForm();
            formikContext.setFieldValue('formData.declarationType', type);
            setDeclarationType(type);
        }
    }, [formikContext.values.formData.declarationType]);

    const isGiftAvailable = useMemo(() => { return templates?.find((elem) => elem.templateKey === ETemplateKeys.GiftReceive || elem.templateKey === ETemplateKeys.GiftSend); }, [templates]);
    const isInvitationAvailable = useMemo(() => { return templates?.find((elem) => elem.templateKey === ETemplateKeys.InvitationReceive || elem.templateKey === ETemplateKeys.InvitationSend); }, [templates]);

    return (
        <SWrapper>
            {isGiftAvailable || isInvitationAvailable ?
                <>
                    {isInvitationAvailable &&
                        <SelectCard
                            field="formData.declarationType"
                            value="invitation"
                            imageKey="invitation"
                            name="giftinvitation.popup.invitation"
                            description="giftinvitation.card.invitation.description"
                            testingDataAttr="js-lc-card-invitation"
                        />
                    }
                    {isGiftAvailable &&
                        <SelectCard
                            field="formData.declarationType"
                            value="gift"
                            imageKey="gift"
                            name="giftinvitation.popup.gift"
                            description="giftinvitation.card.gift.description"
                            testingDataAttr="js-lc-card-gift"
                        />
                    }
                </>
            : <IntlMessage id="global.wizard.noTemplates"  />}
        </SWrapper>
    );
};
