import React, { useState, useCallback, FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { Formik } from 'formik';

import { IState } from '../../../../reducers';
import { IProfileReducer } from '../../../../reducers/profileReducer';
import { EDeclarationTypeEnum } from '../../../../entities/IDeclaration';
import { ICOIData } from '../../../../entities/ActionData/ICOIData';
import { StepsPopup } from '../../../Common/StepsPopup/StepsPopup';
import { DeclarationType } from './DeclarationType';
import { YourInformation } from './YourInformation';
import { SuccessfulSubmission } from './SuccessfulSubmission';
import { initialFormValues } from './InitialFormValues';
import { EPopupSizes } from '../../../../entities/IGlobal';
import { postCOIWizard } from '../../../../actions/clustersActions';
import { COIPrincipes } from './COIPrincipes';
import { Step3 } from './Step3/Step3';
import { ETemplateTypes } from '../../../../entities/IClusters';
import { EPanelUrlParams } from '../../../Common/Panel/PanelContainer';
import { useIntlMessage } from '../../../Common/IntlMessage';

type PostCOIWizard = ReturnType<typeof postCOIWizard>;

interface IProps {
    organizationId: string;
    organizationUrlName: string;
    isPopupShown: boolean;
    isHome?: boolean;
    setIsCOIPopupOn(giftInvitationPopupOn: boolean);
}

export const COIPopup: FC<React.PropsWithChildren<IProps>> = ({ isPopupShown, isHome, organizationId, organizationUrlName, setIsCOIPopupOn }) => {
    const dispatch = useDispatch();
    const { intlFormatMessage } = useIntlMessage();
    const history = useNavigate();
    const { currentUserProfile } = useSelector<IState, IProfileReducer>(state => state.profile);
    const [stepFinished, setStepFinished] = useState<boolean>(false);
    const [isConfirmButtonDisabled, setIsConfirmButtonDisabled] = useState<boolean>(true);
    const [isFormFinnished, setIsFormFinnished] = useState<boolean>(false);

    const closePopup = useCallback((resetForm) => {
        setIsCOIPopupOn(false);
        setStepFinished(false);
        resetForm();
        if (isFormFinnished) {
            history(`/orgs/${organizationUrlName}/dashboard?${EPanelUrlParams.REFRESH}=true`);
            setIsFormFinnished(false);
        } else if (isHome) {
            history('/');
        }
    }, [organizationId, organizationUrlName, isFormFinnished, isHome]);

    const submitFormData = useCallback((form) => {
        setIsConfirmButtonDisabled(true);
        return dispatch<PostCOIWizard>(postCOIWizard(organizationId, form)).then(() => {
            setIsConfirmButtonDisabled(false);
            setStepFinished(true);
            setIsFormFinnished(true);
        }).catch(reason => {
            console.log('COI not send: ', reason);
        });
    }, [organizationId, dispatch, setIsConfirmButtonDisabled]);

    const showNewDeclaration = useCallback((declarationType: string) => {
        return declarationType !== EDeclarationTypeEnum.CoiNoLink;
    }, []);

    const clearForm = useCallback((resetForm) => {
        resetForm();
        setStepFinished(false);
    }, []);

    return (
        <Formik<ICOIData>
            initialValues={initialFormValues(currentUserProfile)}
            validationSchema={() => Yup.lazy((values: any) => {
                const stepNr = values.step;
                const linkOfInterest = values.formData.coiDeclaration.type !== EDeclarationTypeEnum.CoiNoLink;
                return Yup.object().shape({
                    readPolicy: Yup.boolean().oneOf([true]),
                    contextLength: (stepNr === 3 && linkOfInterest) && Yup.boolean().oneOf([true]),
                    noLinkPolicy: (stepNr === 3 && !linkOfInterest) && Yup.boolean().oneOf([true]),
                    formData: Yup.object().shape({
                        coiDeclaration: Yup.object({
                            type: stepNr === 2 && Yup.string().required(),
                            description: stepNr === 3 && linkOfInterest && Yup.string().required(),
                            startingDate: stepNr === 3 && linkOfInterest && Yup.string().required(),
                            thirdParty: Yup.object({
                                id: stepNr === 3 && linkOfInterest && Yup.string().required()
                            })
                        }),
                        coiInformation: Yup.object({
                            jobTitle: stepNr === 4 && Yup.string(),
                            legalEntity: stepNr === 4 && Yup.object({
                                id: Yup.string().required()
                            }),
                            manager: Yup.object({
                                firstName: stepNr === 4 && Yup.string().required()
                            }),
                            creator: Yup.object({
                                firstName: stepNr === 4 && Yup.string().required()
                            })
                        })
                    })
                });
            })}
            onSubmit={submitFormData}
        >
            {({ values, isValid, dirty, submitForm, resetForm }) => (
                <StepsPopup
                    showPopup={isPopupShown}
                    size={EPopupSizes.BIG}
                    title={intlFormatMessage({ id: 'coi.popup.mainTitle' })}
                    confirmDisabled={isConfirmButtonDisabled}
                    isFormValid={isValid}
                    dirty={dirty}
                    preventClosingOnClickingOutside
                    finishHandler={() => closePopup(resetForm)}
                    confirmFinished={stepFinished}
                    confirmHandler={submitForm}
                    showDeclareNew={showNewDeclaration(values.formData.coiDeclaration.type)}
                    clearForm={() => clearForm(resetForm)}
                    wizardType={ETemplateTypes.COI}
                    steps={[
                        {
                            content: <COIPrincipes userFirstName={currentUserProfile?.firstName} />
                        },
                        {
                            title: intlFormatMessage({ id: 'coi.popup.declare' }),
                            content: <DeclarationType organizationId={organizationId}/>
                        },
                        {
                            title: values.formData.coiDeclaration.type === EDeclarationTypeEnum.CoiNoLink
                                ? intlFormatMessage({ id: `${'coi.popup.declarationOfNonLink'}` })
                                : '',
                            content: <Step3 declarationType={values.formData.coiDeclaration.type} />
                        },
                        {
                            title: intlFormatMessage({ id: `${'coi.popup.checkYourInformation'}` }),
                            content: <YourInformation />
                        },
                        {
                            content: <SuccessfulSubmission />
                        }
                    ]}
                />
            )}
        </Formik>
    );
};
