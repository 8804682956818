import React, { ReactNode, useEffect, useState, FC } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import queryString from 'query-string-for-all';
import styled from 'styled-components';
import { Link, useNavigate } from 'react-router-dom';

import { IState } from '../../reducers';
import { ILanguageReducer } from '../../reducers/languageReducer';
import { ISogeReducer } from '../../reducers/sogeReducer';
import { IOrganizationReducer } from '../../reducers/organizationReducer';
import { IContextReducer } from '../../reducers/contextReducer';
import { setSogeDocumentationLanguage, getTableOfContent, getNormativeDocumentations, getDocumentationStyles, getVersionDetails, setUniqueVersionId, getVersions, getSgGlobalReferentials, setCurrentVersionRealVersionId } from '../../actions/sogeActions';
import { setOrganizationsName } from '../../actions/organizationActions';
import { SGDocLanguageKey, ISogeParent } from '../../entities/ISoge';
import { EVersionStatus } from '../../entities/LegalDoc/INormativeDocumentationVersion';
import { localStorage } from '../../tools/storage';
import { getSogeCollectionsUrl, getNormsManagementUrl } from '../../tools/legalDocTools/sogeTools';
import { getErrorMessage, is404 } from '../../tools/errorTools';
import { isNumber } from '../../tools/generalTools';
import { useLatestVersionId } from '../../tools/legalDocTools/sogeHooks';
import { SogeHeader } from './SogeHeader/SogeHeader';
import { Loader } from '../Common/Loader/Loader';
import { Tag } from '../Common/Tag/Tag';
import { Popup } from '../Common/Popup/Popup';
import { Button } from '../Common/Buttons/NewButton';
import { ELcid } from '../../entities/ILanguage';
import { EOrganizationPermissionsBase } from '../../entities/IPermissions';
import IntlMessage from '../Common/IntlMessage';

type SetLang = ReturnType<typeof setSogeDocumentationLanguage>;
type GetTOC = ReturnType<typeof getTableOfContent>;
type SetOrgName = ReturnType<typeof setOrganizationsName>;
type GetNormativeId = ReturnType<typeof getNormativeDocumentations>;
type GetDocStyles = ReturnType<typeof getDocumentationStyles>;

const HeaderName = styled.div`
    display: flex;
    align-items: center;
`;

const HeaderTag = styled(Tag)`
    margin-left: 1rem;
`;

const PopupButton = styled.div`
    text-align: center;
    margin-top: 1rem;
`;

interface ISogePageWrapperProps {
    header?: ReactNode;
    versionId?: string;
    urlVersionId?: string;
    withToc?: boolean;
    currentStructureId?: string;
    hideHeader?: boolean;
    organizationUrlName: string;
    parents?: ISogeParent[];
    editMode?: boolean;
    editVersionId?: string;
    hideVersion?: boolean;
    hideLang?: boolean;
    hideManageNorm?: boolean;
    editPage?: 'versions' | 'amendements' | 'settings' | 'branches' | 'reporting';
    consultationPage?: 'collections' | 'allTexts' | 'search';
    editSubPage?: 'content' | 'branches' | 'approvals' | 'branch-datagrid';
    withSearch?: boolean;
    subMenu?: ReactNode;
    bookCommonLevelId?: string;
    nextVersionToUse?: string;
    onVersionChange?(versionId: string, status: EVersionStatus);
    setError?(error: string);
    redirectToCurrentVersion?(version: string): string;
}

type ISogeWrapperSelectors = ILanguageReducer & ISogeReducer & IOrganizationReducer & IContextReducer;

export const SogePageWrapper: FC<React.PropsWithChildren<ISogePageWrapperProps>> = ({ editPage, consultationPage, children, header, versionId, urlVersionId, withToc, currentStructureId, hideHeader, organizationUrlName, parents, editMode, hideLang, hideVersion, editVersionId, hideManageNorm, withSearch, subMenu, editSubPage, bookCommonLevelId, nextVersionToUse, onVersionChange, setError, redirectToCurrentVersion }) => {
    const dispatch = useDispatch();
    const history = useNavigate();
    const { languageLoaded, userLanguage, documentationLcid, documentationId, currentOrganizationUrlName, contextOrganizations, currentEditVersionId, currentEditVersion,
        sgTableOfContentsContainer, currentUniqueVersionId, sgStylesContainer, newDataLoading, languageData, normReferentials, allVersions, organizationPermissions, permissionsLoaded } = useSelector<IState, ISogeWrapperSelectors>(state => ({
            ...state.language,
            ...state.soge,
            ...state.organization,
            ...state.context
        }));
    const [limitedAccess, setLimitedAccess] = useState<boolean>(false);
    const [versionNotFound, setVersionNotFound] = useState<boolean>(false);
    const { latestVersionId } = useLatestVersionId(true);
    const [prevDocumentationId, setPrevDocumentationId] = useState<typeof documentationId>(undefined);

    const currentOrganization = contextOrganizations.byUrl?.[organizationUrlName];
    const [currentOrgId, setCurrentOrgId] = useState<string>('');

    useEffect(() => {
        dispatch(setUniqueVersionId(undefined));
        dispatch(setCurrentVersionRealVersionId(undefined));

        return () => {
            dispatch(setUniqueVersionId(undefined));
            dispatch(setCurrentVersionRealVersionId(undefined));
        };
    }, []);

    useEffect(() => {
        !normReferentials && dispatch(getSgGlobalReferentials());
    }, [normReferentials]);

    useEffect(() => {
        if (documentationId && currentUniqueVersionId && !sgStylesContainer?.[currentUniqueVersionId]?.styles) {
            dispatch<GetDocStyles>(getDocumentationStyles(documentationId, currentUniqueVersionId));
        }

        if (documentationId && documentationId !== prevDocumentationId) {
            dispatch(setUniqueVersionId(undefined));
            dispatch<GetTOC>(getTableOfContent(documentationId, versionId, documentationLcid));
        }

        if (documentationId !== prevDocumentationId) {
            setPrevDocumentationId(documentationId);
        }
    }, [documentationId, prevDocumentationId, documentationLcid, currentUniqueVersionId]);

    useEffect(() => {
        const query = queryString.parse(window.location.search);
        const queryLcid = query?.lcid && parseInt(query.lcid as string, 10);
        const availableLcid = Object.values(ELcid).filter(value => typeof value === 'number');

        if (!limitedAccess) {
            if (availableLcid.includes(documentationLcid)) {
                if ((languageLoaded && !documentationLcid) || (!!queryLcid && isNumber(queryLcid))) {
                    const storedLanguage = parseInt(localStorage.getItem(SGDocLanguageKey), 10);
                    dispatch<SetLang>(setSogeDocumentationLanguage(queryLcid || storedLanguage || userLanguage));
                }
            }

            if (!availableLcid.includes(documentationLcid)) {
                dispatch<SetLang>(setSogeDocumentationLanguage(userLanguage));
                localStorage.setItem(SGDocLanguageKey, `${userLanguage}`);
            }

            if ((contextOrganizations?.ids?.length && !documentationId) || (organizationUrlName !== currentOrgId)) {
                const orgId = contextOrganizations.byUrl?.[organizationUrlName]?.id;
                orgId && dispatch<GetNormativeId>(getNormativeDocumentations(orgId));
                setCurrentOrgId(organizationUrlName);
            }

            if (withToc && !versionId && !urlVersionId && documentationId && documentationLcid) {
                dispatch(getVersions(documentationId, documentationLcid))
                    .then(versions => {
                        const currentVersion = versions.find(version => version?.documentVersion?.status === EVersionStatus.Current) || versions?.[0];
                        if (currentVersion) {
                            dispatch(setUniqueVersionId(currentVersion.id));
                            dispatch(setCurrentVersionRealVersionId(currentVersion.versionId));
                            history(redirectToCurrentVersion?.('current') || getSogeCollectionsUrl(organizationUrlName, 'current'));
                        }
                    });
            }

            if (documentationId && languageLoaded && documentationLcid && !newDataLoading && versionId && nextVersionToUse) {
                const currentVersionId = languageData?.[versionId]?.[documentationLcid]?.uniqueVersionId;
                !sgTableOfContentsContainer?.[currentVersionId]?.tableOfContent && dispatch<GetTOC>(getTableOfContent(documentationId, versionId, documentationLcid))
                    .then(tableOfContents => {
                        setError && setError(undefined);
                        !!tableOfContents?.id && !sgStylesContainer?.[tableOfContents.id]?.styles && dispatch<GetDocStyles>(getDocumentationStyles(documentationId, tableOfContents.id));
                    })
                    .catch(error => {
                        setError && setError(getErrorMessage(error));
                    });
            }
        }
    }, [languageLoaded, userLanguage, documentationLcid, documentationId, versionId, contextOrganizations?.ids?.length, organizationUrlName, languageData, urlVersionId, redirectToCurrentVersion]);

    useEffect(() => {
        documentationId && !allVersions && dispatch(getVersions(documentationId));
    }, [allVersions, documentationId]);

    useEffect(() => {
        if (editVersionId && documentationId && editVersionId !== currentEditVersionId) {
            dispatch(getVersionDetails(documentationId, editVersionId))
                .catch(err => {
                    is404(err) && setVersionNotFound(true);
                });
        }
    }, [editVersionId, currentEditVersionId, documentationId, documentationLcid]);

    useEffect(() => {
        if (organizationUrlName !== currentOrganizationUrlName) {
            dispatch<SetOrgName>(setOrganizationsName(organizationUrlName));
        }
    }, [organizationUrlName, currentOrganizationUrlName]);

    useEffect(() => {
        if (!permissionsLoaded) return;
        const isEditing = editMode || !!editVersionId;
        if (isEditing && !organizationPermissions?.[currentOrganization.id]?.[EOrganizationPermissionsBase.ManageOrganization]) {
            history(getSogeCollectionsUrl(organizationUrlName));
        }
    }, [permissionsLoaded, organizationPermissions, currentOrganization, editMode, editVersionId]);

    const headerSuffix = (editVersionId && currentEditVersion) && `- ${currentEditVersion.normativeDocumentationVersionName}` || '';
    const headerTitle = header || <IntlMessage id="norms.normsManagement" />;

    const editBranchesPath = `/orgs/${organizationUrlName}/documentation/versions/${latestVersionId}/branches`;

    return (
        <>
            {!hideHeader &&
                <SogeHeader
                    header={(
                        <HeaderName>
                            {(editVersionId || editMode) ? (
                                <Link to={getNormsManagementUrl(organizationUrlName)}>{headerTitle}</Link>
                            ) : headerTitle}
                            <span>&nbsp;{headerSuffix}</span>
                            {editVersionId && currentEditVersion && (
                                <>
                                    {currentEditVersion.versionStatus === EVersionStatus.New && (
                                        <HeaderTag color="gray" noBorder nomargin sm>New</HeaderTag>
                                    )}
                                    {currentEditVersion.versionStatus === EVersionStatus.WorkInProgress && (
                                        <HeaderTag color="activeBlue" noBorder nomargin sm>Work in progress</HeaderTag>
                                    )}
                                    {currentEditVersion.versionStatus === EVersionStatus.Current && (
                                        <HeaderTag color="successGreen" noBorder nomargin sm>Current</HeaderTag>
                                    )}
                                    {currentEditVersion.versionStatus === EVersionStatus.Archived && (
                                        <HeaderTag color="red" noBorder nomargin sm>Archived</HeaderTag>
                                    )}
                                </>
                            )}
                        </HeaderName>
                    )}
                    breadCrumbsTitle={headerTitle}
                    tableOfContents={sgTableOfContentsContainer?.[currentUniqueVersionId]?.tableOfContent}
                    currentStructureId={currentStructureId}
                    organizationUrlName={organizationUrlName}
                    parents={parents}
                    editMode={editMode}
                    editVersionId={editVersionId}
                    hideLang={hideLang}
                    hideVersion={hideVersion}
                    hideManageNorm={hideManageNorm}
                    editPage={editPage}
                    editSubPage={editSubPage}
                    branchCount={currentEditVersion?.branchesCounter}
                    approvalCount={currentEditVersion?.approvalRequestCounter}
                    versionId={versionId}
                    urlVersionId={urlVersionId}
                    withSearch={withSearch}
                    onVersionChange={onVersionChange}
                    setLimitedAccess={setLimitedAccess}
                    editBranchesPath={editBranchesPath}
                    consultationPage={consultationPage}
                    subMenu={subMenu}
                />
            }
            {limitedAccess ? (
                <IntlMessage id="norms.limitedAccess" />
            ) : (
                <Loader loading={!(documentationLcid && documentationId) || !permissionsLoaded}>
                    {!!documentationLcid && children}
                </Loader>
            )}
            <Popup
                disableMaxWidth
                noScroller
                noButtons
                isVisible={versionNotFound}
            >
                <p><IntlMessage id="norms.error.versionNotFound"/></p>
                <PopupButton>
                    <Button onClick={() => history(getNormsManagementUrl(organizationUrlName))}>
                        <IntlMessage id="norms.button.backToManagementPanel" />
                    </Button>
                </PopupButton>
            </Popup>
        </>
    );
};
