import moment from 'moment';
import { IntlShape } from 'react-intl';

import { ELcid, IClusterMessageTranslation } from '../entities/ILanguage';
import { IClusterSingleMessage } from '../entities/IClusters';
import { getData } from './date';

export const languageList = {
    1031: 'de',
    1033: 'en',
    1034: 'es',
    1036: 'fr',
    1040: 'it',
    1043: 'nl',
    1045: 'pl',
    1046: 'pt',
    1049: 'ru',
    1058: 'uk'
};

export const languageKeyArray = ['en', 'fr', 'pl', 'es', 'it', 'ru', 'uk', 'de', 'pt', 'nl'];

export const languageIcons = {
    [ELcid.En]: require('../../wwwroot/assets/images/icons/languages/en.png'),
    [ELcid.Fr]: require('../../wwwroot/assets/images/icons/languages/fr.png'),
    [ELcid.Es]: require('../../wwwroot/assets/images/icons/languages/es.png'),
    [ELcid.Pt]: require('../../wwwroot/assets/images/icons/languages/pt.png'),
    [ELcid.Ru]: require('../../wwwroot/assets/images/icons/languages/ru.png'),
    [ELcid.Uk]: require('../../wwwroot/assets/images/icons/languages/uk.png'),
    [ELcid.De]: require('../../wwwroot/assets/images/icons/languages/de.png'),
    [ELcid.Pl]: require('../../wwwroot/assets/images/icons/languages/pl.png'),
    [ELcid.Nl]: require('../../wwwroot/assets/images/icons/languages/nl.png'),
    [ELcid.It]: require('../../wwwroot/assets/images/icons/languages/it.png'),
    [ELcid.Ms]: require('../../wwwroot/assets/images/icons/languages/ms.png'),
    [ELcid.Vi]: require('../../wwwroot/assets/images/icons/languages/vi.png'),
    [ELcid.Hr]: require('../../wwwroot/assets/images/icons/languages/hr.png'),
    [ELcid.Hu]: require('../../wwwroot/assets/images/icons/languages/hu.png'),
    [ELcid.Sk]: require('../../wwwroot/assets/images/icons/languages/sk.png'),
    [ELcid.Sl]: require('../../wwwroot/assets/images/icons/languages/sl.png'),
    [ELcid.Ar]: require('../../wwwroot/assets/images/icons/languages/ar.png'),
    [ELcid.Ro]: require('../../wwwroot/assets/images/icons/languages/ro.png'),
    [ELcid.Fil]: require('../../wwwroot/assets/images/icons/languages/fil.png'),
    [ELcid.Th]: require('../../wwwroot/assets/images/icons/languages/th.png'),
    [ELcid.Da]: require('../../wwwroot/assets/images/icons/languages/da.png'),
    [ELcid.Fi]: require('../../wwwroot/assets/images/icons/languages/fi.png'),
    [ELcid.Id]: require('../../wwwroot/assets/images/icons/languages/id.png'),
    [ELcid.Ja]: require('../../wwwroot/assets/images/icons/languages/ja.png'),
    [ELcid.Tr]: require('../../wwwroot/assets/images/icons/languages/tr.png'),
    [ELcid.Zh]: require('../../wwwroot/assets/images/icons/languages/zh.png')
};

export const languageLabels = {
    [ELcid.All]: {
        id: 'global.language.all'
    },
    [ELcid.En]: {
        id: 'global.language.en'
    },
    [ELcid.Fr]: {
        id: 'global.language.fr'
    },
    [ELcid.Es]: {
        id: 'global.language.es'
    },
    [ELcid.Pt]: {
        id: 'global.language.pt'
    },
    /******* */
    [ELcid.Ru]: {
        id: 'global.language.ru'
    },
    [ELcid.Uk]: {
        id: 'global.language.uk'
    },
    [ELcid.De]: {
        id: 'global.language.de'
    },
    [ELcid.Pl]: {
        id: 'global.language.pl'
    },
    [ELcid.Nl]: {
        id: 'global.language.nl'
    },
    [ELcid.It]: {
        id: 'global.language.it'
    },
    [ELcid.Ms]: {
        id: 'global.language.ms'
    },
    [ELcid.Vi]: {
        id: 'global.language.vi'
    },
    [ELcid.Hr]: {
        id: 'global.language.hr'
    },
    [ELcid.Hu]: {
        id: 'global.language.hu'
    },
    [ELcid.Sk]: {
        id: 'global.language.sk'
    },
    [ELcid.Sl]: {
        id: 'global.language.sl'
    },
    [ELcid.Ar]: {
        id: 'global.language.ar'
    },
    [ELcid.Ro]: {
        id: 'global.language.ro'
    },
    [ELcid.Fil]: {
        id: 'global.language.fil'
    },
    [ELcid.Th]: {
        id: 'global.language.th'
    },
    [ELcid.Da]: {
        id: 'global.language.da'
    },
    [ELcid.Fi]: {
        id: 'global.language.fi'
    },
    [ELcid.Id]: {
        id: 'global.language.id'
    },
    [ELcid.Ja]: {
        id: 'global.language.ja'
    },
    [ELcid.Tr]: {
        id: 'global.language.tr'
    },
    [ELcid.Zh]: {
        id: 'global.language.zh'
    }
};

export const getLanguageTranslation = (language: string, intlFormatMessage: any) => {
    return intlFormatMessage({ id: `global.language.${language}` });
};

export const getCountryFromLanguageString = (langStr: string) => {
    const split = langStr.split('-');
    return (split[1] || split[0]).toUpperCase();
};

export const activityTranslations = (message: IClusterSingleMessage, clusterActivityTranslation: IClusterMessageTranslation, intlFormatMessage?: any): string => {
    let originalMessage = clusterActivityTranslation?.[message.translationKey];
    const isTranslations = originalMessage?.includes('translations');
    if (isTranslations) {
        const extractedFielsdArray = originalMessage?.split('{').filter(elem => elem.includes('translations')).map(el => el.split('.')[1].split('}')[0]);
        for (let i = 0; i < extractedFielsdArray?.length; i++) {
            originalMessage = originalMessage?.replace(`{translations.${extractedFielsdArray[i]}}`, intlFormatMessage({ id: message?.additionalInformation.translations[extractedFielsdArray[i]] }));
        }
    }
    return originalMessage?.
        replace('{memberA.firstName}', message?.additionalInformation?.memberA?.firstName).
        replace('{memberA.lastName}', message?.additionalInformation?.memberA?.lastName).
        replace('{memberB.firstName}', message?.additionalInformation?.memberB?.firstName).
        replace('{memberB.lastName}', message?.additionalInformation?.memberB?.lastName).
        replace('{member.lastName}', message?.additionalInformation?.member?.lastName).
        replace('{member.firstName}', message?.additionalInformation?.member?.firstName).
        replace('{member.firstName}', message?.additionalInformation?.member?.firstName).
        replace('{firstName}', message?.sender?.firstName).
        replace('{lastName}', message?.sender?.lastName).
        replace('{sourcePath}', message?.additionalInformation?.sourcePath).
        replace('{folderName}', message?.additionalInformation?.folderName).
        replace('{fileName}', message?.additionalInformation?.sourceName ? message?.additionalInformation?.sourceName : message?.additionalInformation?.fileName).
        replace('{folder}', message?.additionalInformation?.isDeletedPermanently ? message?.additionalInformation?.path : message?.additionalInformation?.sourcePath
            ? message?.additionalInformation?.sourcePath : Array.isArray(message.additionalInformation) ? message?.additionalInformation?.[0]?.path
                : message?.additionalInformation?.folder ? message?.additionalInformation?.folder : message?.additionalInformation?.path).
        replace('{invoker.firstName}', message?.additionalInformation?.invoker?.firstName).
        replace('{invoker.lastName}', message?.additionalInformation?.invoker?.lastName).
        replace('{validator.firstName}', message?.additionalInformation?.validator?.firstName).
        replace('{validator.lastName}', message?.additionalInformation?.validator?.lastName).
        replace('{date}', moment.utc(message?.additionalInformation?.date).local().format('DD MMM YYYY HH:mm:ss')).
        replace('{newTitle}', getData(message?.additionalInformation?.newTitle)).
        replace('{involvedPartyName}', getData(message?.additionalInformation?.involvedPartyName)).
        replace('{involvedPartiesGroupName}', getData(message?.additionalInformation?.involvedPartiesGroupName)).
        replace('{timelineTitle}', getData(message?.additionalInformation?.timelineTitle)).
        replace('{legalStakeTitle}', getData(message?.additionalInformation?.legalStakeTitle)).
        replace('{economicStakeTitle}', getData(message?.additionalInformation?.economicStakeTitle)).
        replace('{connectedMember.lastName}', message?.additionalInformation?.connectedMember?.lastName).
        replace('{connectedMember.firstName}', message?.additionalInformation?.connectedMember?.firstName).
        replace('{command.validationContext.type}', getData(message?.additionalInformation?.command?.validationContext?.Type)).
        replace('{filesNames}', Array.isArray(message?.additionalInformation?.filesNames) ? message?.additionalInformation?.filesNames?.map((file) => file.sourceName)?.join(', ')
            : Array.isArray(message?.additionalInformation) ? message?.additionalInformation?.map(({ fileName }) => fileName)?.join(', ') : message?.additionalInformation?.filesNames?.join(', '));
};
