import { getAllCookies, getCookie, removeCookie, setCookie } from './cookies';

const createStorage = (): Storage => ({
    ...(getAllCookies()),
    setItem(key: string, value: string) {
        this[key] = value;
        setCookie(key, value);
    },
    getItem(key: string) {
        return getCookie(key);
    },
    removeItem(key: string) {
        delete this[key];
        removeCookie(key);
    }
} as Storage);

export let localStorage: Storage = window.localStorage;

try {
    if (!window.localStorage || !window.sessionStorage) throw 'exception';
    window.localStorage.setItem('storage_test', '1');
    window.localStorage.removeItem('storage_test');
} catch (e) {
    localStorage = createStorage();

    (window as any).localStorage = localStorage;
    window.localStorage.__proto__ = localStorage;
}

interface IExpStorage {
    value: string;
    expiry: number;
}

export const storeWithExpiry = (key: string, value: string, ttl: number) => {
    const now = new Date();

    const item: IExpStorage = {
        value,
        expiry: now.getTime() + ttl // ttl in miliseconds
    };

    localStorage.setItem(key, JSON.stringify(item));
};

export const getWithExpiry = (key: string, returnLastValue?: boolean) => {
    try {
        const json = localStorage.getItem(key);
        const item = JSON.parse(json) as IExpStorage;
        const now = new Date();

        if (now.getTime() > item.expiry) {
            localStorage.removeItem(key);
            return returnLastValue ? item.value : undefined;
        }

        return item.value;
    } catch (e) {
        return undefined;
    }
};
