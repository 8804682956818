import React, { FC } from 'react';
import styled from 'styled-components';

import { colorStack } from '../../../../styleHelpers/colors';
import { useIntlMessage } from '../../IntlMessage';

const BooleanWrapper = styled.span`
    color: ${colorStack.content};
`;

export const BooleanType: FC<React.PropsWithChildren<unknown>> = props => {
    const { intlFormatMessage } = useIntlMessage();
    return (
        <BooleanWrapper>{props.children === true ? intlFormatMessage({ id: 'global.yes' }) : (props.children === null ? intlFormatMessage({ id: 'global.blank' }) : intlFormatMessage({ id: 'global.no' }))}</BooleanWrapper>
    );
};