import { combineReducers } from 'redux';

import config from './configReducer';
import context from './contextReducer';
import rms from './rmsReducer';
import profile from './profileReducer';
import cookies from './cookiesReducer';
import company from './companyReducer';
import organization from './organizationReducer';
import clusters from './clustersReducer';
import onboarding from './onboardingReducer';
import expertise from './expertiseReducer';
import notifications from './notificationReducer';
import rfp from './rfpReducer';
import proposal from './proposalReducer';
import recommendation from './recommendationsReducer';
import messaging from './messagingReducer';
import searchHandle from './searchReducer';
import language from './languageReducer';
import admin from './adminReducer';
import panels from './panelsReducer';
import soge from './sogeReducer';
import alert from './alertReducer';
import legalEntities from './legalEntitiesReducer';
import stake from './stakeReducer';
import menu from './menuReducer';
import analytics from './analyticsReducer';
import avatar from './avatarReducer';
import work from './workReducer';
import explore from './exploreReducer';
import pdfOverlay from './pdfOverlayReducer';
import global from './globalReducer';
import approval from './approvalReducer';
import datatable from './datatableReducer';
import workspace from './workspaceReducer';

const store = combineReducers({
    admin,
    alert,
    analytics,
    approval,
    avatar,
    clusters,
    company,
    config,
    context,
    cookies,
    datatable,
    expertise,
    explore,
    global,
    language,
    legalEntities,
    menu,
    messaging,
    notifications,
    onboarding,
    organization,
    panels,
    pdfOverlay,
    profile,
    proposal,
    recommendation,
    rfp,
    rms,
    searchHandle,
    soge,
    stake,
    work,
    workspace
});

export type IState = ReturnType<typeof store>;

export default store;
