import React, { FC, useCallback, useEffect } from 'react';
import styled from 'styled-components';
import { useFormikContext } from 'formik';

import { Checkbox } from '../../../Common/Inputs/Checkbox';
import { fontSize } from '../../../../styleHelpers/fontSizes';
import { colorStack } from '../../../../styleHelpers/colors';
import { IWizardValues } from '../../../../entities/ILegalSupport';
import IntlMessage, { useIntlMessage } from '../../../Common/IntlMessage';

const CBContainer = styled.div``;

const Container = styled.div`
    color: ${colorStack.darkBlue};
    font-size: ${fontSize[16]};
    display: flex;
    flex-direction: column;
    margin: 0 2rem 0 2rem;
    width: 100%;
    span {
        margin: 1rem .5rem;
        & a {
            color: ${colorStack.middleBlue};
        }
        & span {
            margin: 0;
        }
    }
    ${CBContainer} > div > label > div {
        font-size: ${fontSize[16]};
        padding-top: 0;
    }
`;

const Box = styled.div`
    background: ${colorStack.white};
    padding: 2% 3%;
    margin: 1em 0 .7em;
`;

interface IProps {
    userFirstName: string;
}

export const Step1: FC<React.PropsWithChildren<IProps>> = props => {
    const { intlFormatMessage } = useIntlMessage();
    const { setFieldValue, values } = useFormikContext<IWizardValues>();

    useEffect(() => {
        setFieldValue('step', 1);
    }, []);

    const changeCheckbox = useCallback((checked: boolean) => {
        setFieldValue('formData.readPolicy', checked);
    }, []);

    return (
        <Container>
            <IntlMessage id="legalSupport.popup.dearUser" values={{ userFirstName: props.userFirstName }} />
            <IntlMessage id="legalSupport.popup.principes.firstEl" />
            <Box>
                <IntlMessage id="legalSupport.popup.principes.secondEl.box" />
            </Box>
            <CBContainer>
                <Checkbox onChange={changeCheckbox} checked={values.formData.readPolicy} label={intlFormatMessage({ id: 'legalSupport.popup.principes.policyCheckbox' })} />
            </CBContainer>
        </Container>
    );
};