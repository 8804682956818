import { AllStatusEnums, EValidatorStatus, StatusMapper, ValidationStatusMapper } from '../entities/IGlobal';

export const getStatus = (status: AllStatusEnums) => {
    return StatusMapper.find(value => value.key === status);
};

export const getStatusColor = (status: AllStatusEnums) => {
    return StatusMapper.find(value => value.key === status)?.data.color || '';
};

export const getStatusFontColor = (status: AllStatusEnums) => {
    return StatusMapper.find(value => value.key === status)?.data.fontColor || '';
};

export const getStatusText = (status: AllStatusEnums, intlFormatMessage: any) => {
    return StatusMapper.find(value => value.key === status)?.text ? (intlFormatMessage?.formatMessage || intlFormatMessage)({id: StatusMapper.find(value => value.key === status)?.text}) : '';
};

export const getValidationBackground = (status: EValidatorStatus) => {
    return ValidationStatusMapper.find(value => value.key === status)?.data.color || '';
};