import React, { FC, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { useFormikContext } from 'formik';
import { useDispatch } from 'react-redux';

import { TextComponent } from '../../../../Common/Inputs/TextComponent';
import { LabelWithEllipsis } from '../../../../Common/Label/Label';
import { colorStack } from '../../../../../styleHelpers/colors';
import { fontSize } from '../../../../../styleHelpers/fontSizes';
import { inputsShadow } from '../../../../../styleHelpers/mixins/shadow';
import { IContractData } from '../IContractData';
import { getClusterTemplates } from '../../../../../actions/clustersActions';
import { IClusterTemplate } from '../../../../../entities/IClusters';
import { Privacy } from '../../../../Common/Privacy/Privacy';
import IntlMessage, { useIntlMessage } from '../../../../Common/IntlMessage';

const Wrapper = styled.div`
    width: 100%;
`;

const Row = styled.div`
    margin: 1rem 0 1rem 0;
    display: flex;
    > div {
        margin: 0 1rem 0 0;
    }

`;

const FakeInput = styled.div<{ color?: string }>`
    ${inputsShadow()};
    border-radius: 4px;
    padding: 9px 1rem;
    font-size: ${fontSize[13]};
    background: ${colorStack.ligthGrey};
    ${(props) =>
        props.color &&
        css`
            background: ${props.color};
            text-align: center;
        `}
`;

type GetClusterTemplates = ReturnType<typeof getClusterTemplates>;

interface IProps {
    organizationId: string;
}

export const Step2: FC<React.PropsWithChildren<IProps>> = (props) => {
    const { intlFormatMessage } = useIntlMessage();
    const formikContext = useFormikContext<IContractData>();
    const [templates, setTemplates] = useState<IClusterTemplate[]>(undefined);
    const { setFieldValue, values } = useFormikContext<IContractData>();
    const dispatch = useDispatch();

    useEffect(() => {
        formikContext.setFieldValue('step', 2);
        dispatch<GetClusterTemplates>(getClusterTemplates(props.organizationId, true)).then(clusterTemplates => {
            setTemplates(clusterTemplates);
        });
    }, []);

    useEffect(() => {
        if (templates) {
            const result = templates.find(({ templateKey }) => templateKey === values.formData?.cluster?.clusterTemplateKey);
            setFieldValue('formData.cluster.clusterTemplateId', result?.id);
            setFieldValue('formData.cluster.clusterTemplateName', result?.templateName);
            setFieldValue('formData.cluster.clusterTemplateColor', result?.color);
        }

    }, [setTemplates, templates, values]);

    return (
        <Wrapper>
            <div>
                <TextComponent
                    value={values.formData?.name}
                    onChange={(value: string) => setFieldValue('formData.name', value)}
                    error={values.formData.name?.length > 100}
                    required
                    errorMsg={<IntlMessage id="contract.popup.step2.title.error" />}
                    label={intlFormatMessage({ id: 'contract.popup.step2.text1' })}
                />
            </div>
            <Row>
                <div>
                    <LabelWithEllipsis required>
                        <IntlMessage id="contract.popup.step2.text2" />
                    </LabelWithEllipsis>
                    <FakeInput color={values.formData?.cluster?.clusterTemplateColor}>
                        {values.formData?.cluster?.clusterTemplateName}
                    </FakeInput>
                </div>
            </Row>
                <Privacy field="formData.privacy" />
        </Wrapper>
    );
};
