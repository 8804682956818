import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Scrollbars from 'react-custom-scrollbars';
import { useDispatch } from 'react-redux';
import styled, { css } from 'styled-components';

import { getReferentials } from '../../../actions/globalActions';
import { EContextList, EReferentials } from '../../../entities/IGlobal';
import { IValue } from '../../../entities/IPickers';
import { colorStack } from '../../../styleHelpers/colors';
import { cardsShadow } from '../../../styleHelpers/mixins/shadow';
import { TextComponent } from '../Inputs/TextComponent';
import { NoData } from '../NoData/NoData';
import { ESpinnerSize, Spinner } from '../Spinner/Spinner';
import { MultilineTextWithEllipsis } from '../TextWithEllipsis/MultilineTextWithEllipsis';
import IntlMessage from '../IntlMessage';

type GetReferentials = ReturnType<typeof getReferentials>;

const OptionsWrapper = styled.div<{ documentHeightUnderElement: number }>`
    ${cardsShadow()};
    border-radius: 4px;
    background: ${colorStack.white};
    overflow: hidden;
    position: absolute;
    width: 100%;
    z-index: 99;
    margin: 3px 0 0 0;
    ${props => props.documentHeightUnderElement < 195 && css`
        bottom: 100%;
        top: auto !important;
    `}
    ${props => !props.documentHeightUnderElement && css`
        display: none;
    `}
`;

const TextWrapper = styled.div`
    padding: .5rem;
`;

const LoadingBox = styled.div`
    margin: 1rem 0;
`;

const ScrollBarsWrapper = styled.div<{ height: number }>`
    max-height: 130px;
    height: 42px;
    ${props => props.height > 0 && css`
        height: ${`${props.height}px`};
    `}
    margin: 1rem 0 0 0;
`;

const OptionsResults = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

const SingleOption = styled.div`
    background-color: ${colorStack.white};
    transition: background-color .3s;
    text-align: left;
    cursor: pointer;
    border: none;
    padding: .5rem;
    &:hover {
        background-color: ${colorStack.lightBlue};
        font-weight: 500;
    }
`;

interface IProps {
    loading?: boolean;
    options?: IValue[];
    refsOptions?: {
        context: EContextList;
        type: EReferentials;
    }
    value?: IValue[];
    onChange(item: IValue);
}

const heightOfSingleOption = 42;

export const SearchMenu = (props: IProps) => {
    const dispatch = useDispatch();
    const searchMenuRef = useRef<HTMLDivElement>(undefined);
    const [searchValue, setSearchValue] = useState<string>('');
    const [options, setOptions] = useState<IValue[]>([]);
    const [documentHeightUnderElement, setDocumentHeightUnderElement] = useState<number>(undefined);

    useEffect(() => {
        const element = searchMenuRef?.current?.parentNode as HTMLDivElement;
        const elementRect = element?.getBoundingClientRect();
        const elementHeight = elementRect?.height;
        const elementOffsetTop = elementRect?.top + window.scrollY;
        setDocumentHeightUnderElement(Math.max(document.documentElement.scrollHeight, document.body.scrollHeight) - (elementOffsetTop + elementHeight));
    }, []);

    useEffect(() => {
        if (props.refsOptions) {
            dispatch<GetReferentials>(getReferentials('', props.refsOptions.type, props.refsOptions.context)).then(ref => {
                setOptions(ref.map(elem => ({
                    key: elem.id,
                    text: elem.name,
                    data: {...elem }
                })));
            });
        } else {
            setOptions(props.options);
        }
    }, [props.options]);

    const changeSearchValue = useCallback((value: string) => {
        setSearchValue(value);
    }, []);

    const filteredOptions = useMemo(() => {
        return options?.filter(singleOption => props.value ? !props.value?.map(el => el.key).includes(singleOption.key) : singleOption).filter(elem => elem.text?.toLowerCase().includes(searchValue?.toLowerCase()));
    }, [options, props.value, searchValue]);

    const changeHandler = (option: IValue) => {
        props.onChange(option);
    };

    return (
        <OptionsWrapper documentHeightUnderElement={documentHeightUnderElement} ref={searchMenuRef}>
            <TextWrapper>
                <TextComponent
                    value={searchValue}
                    onChange={changeSearchValue}
                />
            </TextWrapper>
            {props.loading ? (
                <LoadingBox>
                    <Spinner size={ESpinnerSize.MEDIUM} />
                </LoadingBox>
            ) : (
                <ScrollBarsWrapper height={filteredOptions?.length * heightOfSingleOption}>
                    <Scrollbars>
                        <OptionsResults>
                            {filteredOptions?.length > 0 ? (filteredOptions.map((option, i, arr) =>
                                <SingleOption key={i} onClick={() => changeHandler(option)}>
                                    <MultilineTextWithEllipsis numberOfLines={1}>{option.text}</MultilineTextWithEllipsis>
                                </SingleOption>)) : <NoData><IntlMessage id="global.noResult" /></NoData>
                            }
                        </OptionsResults>
                    </Scrollbars>
                </ScrollBarsWrapper>
            )}
        </OptionsWrapper>
    );
};