export enum ENotificationChannel {
    LegalDoc = 'LegalDoc',
    BackgroudOperation = 'Background operation'
}

export enum ENotificationDocumentExportType {
    SearchAndExportReferenceTexts = 'Success.SearchAndExportReferenceTexts',
    SearchAndExportArticles = 'Success.SearchAndExportArticles'
}

export interface INotification {
    body: string;
    channelId: ENotificationChannel;
    creationDateTime: string;
    data?: {
        [key: string]: any;
    }
    dataType: string;
    id: string;
    status: ENotificationStatus;
    receipts: any[];
    receiverId: string;
    senderDisplayDetails: {
        firstName: string;
        lastName: string;
        picture: string;
        userId: string;
    }
    title: string;
    version: string;
    key: string;
    normativeDocumentationId: string;
    normativeDocumentationVersionId: string;
}

export interface INewNotificationResponse {
    notifications: INotification[];
    count: number;
}

export enum ENotificationStatus {
    Archived = 'Archived',
    Read = 'Read',
    NotRead = 'NotRead'
}

export enum ENotificationType {
    Profile = 'Profile',
    Clusters = 'Clusters',
    LegalscoreDetails = 'LegalscoreDetails',
    EvaluationPage = 'EvaluationPage',
    Equitis = 'Equitis',
    SharedElement = 'SharedElement',
    RfpCreated = 'RfpCreated',
    RfpDeadlineExpired = 'RfpDeadlineExpired',
    RfpChangedStatus = 'RfpChangedStatus',
    RfpClosed = 'RfpClosed',
    RfpUpdated = 'RfpUpdated',
    ProposalChangedStatus = 'ProposalChangedStatus',
    ProposalCreated = 'ProposalCreated',
    ProposalUpdated = 'ProposalUpdated',
    ConversationMembersAdded = 'ConversationMembersAdded',
    InvitationToOrganization = 'InvitationToOrganization',
    P2PInvitation = 'P2PInvitation',
    ChannelMessageMentioned = 'ChannelMessageMentioned',
    AddedToCluster = 'AddedToCluster',
    RequestAccessToCluster = 'RequestAccessToCluster',
    RequestAccessToResource = 'RequestAccessToResource',
    ChannelMessageCreated = 'ChannelMessageCreated',
    AddMemberToOrganization = 'AddMemberToOrganization',
    ReceivingGift = 'ReceivingGift',
    SendingGift = 'SendingGift',
    Information = 'Information',
    Exception = 'Exception',
    Warning = 'Warning',
    ValidationRequestCreated = 'ValidationRequestCreated',
    ClusterDateReminder = 'ClusterDateReminder',
    SignatureUpdated = 'SignatureUpdated',
    ValidationRequestCompleted = 'ValidationRequestCompleted',
    ValidationRequestRejected = 'ValidationRequestRejected',
    ValidationRequestWithdrawn = 'ValidationRequestWithdrawn',
    CampaignSubmitted = 'CampaignSubmitted',
    ValidationRequestNewStageStarted = 'ValidationRequestNewStageStarted',
    RemoveMemberFromOrganization = 'RemoveMemberFromOrganization',
    SideLetterGenerateStarted = 'SideLetterGenerateStarted',
    ValidationRequestClosedNotificationForCreatorUser = 'ValidationRequestClosedNotificationForCreatorUser',
    ValidatorVotedOnBehalf = 'ValidatorVotedOnBehalf',
    SideLetterGenerateCompleted = 'SideLetterGenerateCompleted',
    SideLetterGenerateFailed = 'SideLetterGenerateFailed',
}

export interface IUserNotificationSettings {
    id: string;
    userId: string;
    settings: {
        [key: string]: SingNotificationSetting;
    };
}

export interface SingNotificationSetting {
    email?: {
        value: boolean;
        IsDefault: boolean;
    },
    mobilePush?: {
        value: boolean;
        IsDefault: boolean;
    },
    webPush?: {
        value: boolean;
        IsDefault: boolean;
    }
}

export interface ILegalDocNotificationData {
    ElementCommonId?: string;
    SystemInfo?: string;
    UserFriendlyMessage?: string;
}
