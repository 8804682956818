import React, { createContext, FC, lazy, useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import lazyBlock from '../../../tools/lazyBlock';
import { ConfirmPopup } from '../ConfirmPopup/ConfirmPopup';
import { ProxyHistoryParams } from '../../../entities/AssetManagement/global';
import IntlMessage from '../IntlMessage';

export enum EPanelType {
    AM = 'AM',
    QA = 'QA',
    SL = 'SL',
    FAQ = 'FAQ',
    CLAUSIER = 'Clausier',
    APPROVAL = 'Approval',
    CLUSTER = 'Cluster',
    TALK = 'Talk',
    GROUP = 'Group',
    WORKSPACE = 'Workspace',
    LABELS = 'LABELS',
    CATEGORIES = 'CATEGORIES',
    LIMITS = 'LIMITS',
    PERIODS = 'PERIODS'
}

export enum EPanelUrlParams {
    PANELTYPE = 'panelType',
    COMMENTS = 'comments',
    THREADID = 'threadId',
    CLUSTERID = 'clusterId',
    ORGANIZATIONSID = 'organizationsId',
    GROUPID = 'groupId',
    MAINIDS = 'mainIds',
    SEARCHFAQ = 'searchFAQ',
    SEARCHCLAUSES = 'searchClauses',
    CREATEIFNOTEXISTS = 'createIfNotExists',
    PREVIOUSPANELTYPE = 'previousPanelType',
    EDITMODE = 'editMode',
    REFRESH = 'refresh',
    VALIDATIONID = 'validationId',
    LABEL = 'label',
    NAVIGATING = 'navigating',
    ORGCONTEXT = 'orgContext'
}

export interface IMainPanelContext {
    showPanel: boolean;
    showComments: boolean;
    editorOpened: string[];
    setShowComments(state: boolean);
    setShowPanel(state: boolean);
    setEditorOpened(state: string[]);
    historyWithWarnings(historyParams: ProxyHistoryParams);
}

const ContentSwitcher = {
    [EPanelType.TALK]: lazyBlock(lazy(() => import('./PanelTypes/Talk'))),
    [EPanelType.APPROVAL]: lazyBlock(lazy(() => import('./PanelTypes/Approval'))),
    [EPanelType.CLUSTER]: lazyBlock(lazy(() => import('./PanelTypes/Cluster'))),
    [EPanelType.AM]: lazyBlock(lazy(() => import('./PanelTypes/AM'))),
    [EPanelType.GROUP]: lazyBlock(lazy(() => import('./PanelTypes/Group'))),
    [EPanelType.WORKSPACE]: lazyBlock(lazy(() => import('./PanelTypes/Workspace'))),
    [EPanelType.SL]: lazyBlock(lazy(() => import('./PanelTypes/SL'))),
    [EPanelType.FAQ]: lazyBlock(lazy(() => import('./PanelTypes/FAQ'))),
    [EPanelType.CLAUSIER]: lazyBlock(lazy(() => import('./PanelTypes/Clausier'))),
    [EPanelType.QA]: lazyBlock(lazy(() => import('./PanelTypes/QA'))),
    [EPanelType.LABELS]: lazyBlock(lazy(() => import('./PanelTypes/Labels'))),
    [EPanelType.CATEGORIES]: lazyBlock(lazy(() => import('./PanelTypes/Categories'))),
    [EPanelType.LIMITS]: lazyBlock(lazy(() => import('./PanelTypes/Limits'))),
    [EPanelType.PERIODS]: lazyBlock(lazy(() => import('./PanelTypes/Periods')))
};

export const MainPanelContext = createContext<Partial<IMainPanelContext>>(undefined);

export const PanelContainer: FC = () => {
    const history = useNavigate();
    const { search } = useLocation();
    const [showPanel, setShowPanel] = useState<boolean>(false);
    const [showComments, setShowComments] = useState<boolean>(false);
    const [editorOpened, setEditorOpened] = useState<string[]>([]);
    const [panelType, setPanelType] = useState<EPanelType>(undefined);
    const [showLeavePanelConfirmPopup, setShowLeavePanelConfirmPopup] = useState<boolean>(false);
    const [historyParamsAfterConfirmPopup, setHistoryParamsAfterConfirmPopup] = useState<ProxyHistoryParams>(undefined);

    let Panel = ContentSwitcher[panelType];

    const params = new URLSearchParams(search);

    useEffect(() => {
        const bodyObject = document.getElementsByTagName('body')[0];
        if (showPanel) {
            bodyObject.style.overflow = 'hidden';
        } else {
            bodyObject.style.overflow = 'auto';
        }
    }, [showPanel]);

    // Define a proxy for intercepting history changes to ensure that when we leave the page some behaviours can be added
    // it triggers a confirm popup to show when leaving with some editor opened
    const historyWithWarnings = useCallback((historyParams: ProxyHistoryParams) => {
        if (editorOpened.length > 0) {
            setShowLeavePanelConfirmPopup(true);
            setHistoryParamsAfterConfirmPopup(historyParams);
        } else {
            if (historyParams.doneCallback) {
                historyParams.doneCallback();
            }

            history(historyParams);
        }
    }, [editorOpened, setShowLeavePanelConfirmPopup, setHistoryParamsAfterConfirmPopup]);

    const applyHistoryParams = useCallback((_historyParams?: ProxyHistoryParams) => {
        if (historyParamsAfterConfirmPopup) {
            if (historyParamsAfterConfirmPopup?.doneCallback) {
                historyParamsAfterConfirmPopup?.doneCallback();
            }

            history(historyParamsAfterConfirmPopup);
            setHistoryParamsAfterConfirmPopup(undefined);
        } else {
            console.log('No params to set history, skipping navigation'); // please leave for debugging purpose
        }
    }, [historyParamsAfterConfirmPopup, setHistoryParamsAfterConfirmPopup]);

    const initState: IMainPanelContext = useMemo(() => ({
        showPanel,
        showComments,
        editorOpened,
        setShowComments,
        setShowPanel,
        setEditorOpened,
        historyWithWarnings
    }), [showPanel, showComments, editorOpened, setShowComments, setShowPanel, setEditorOpened, historyWithWarnings]);

    useEffect(() => {
        const panelTypeParam = params.get(EPanelUrlParams.PANELTYPE);
        const commentsParam = params.get(EPanelUrlParams.COMMENTS);
        if (Object.values(EPanelType).includes(panelTypeParam as EPanelType)) {
            setPanelType(panelTypeParam as EPanelType);
            if (commentsParam === 'true') {
                setShowComments(true);
            } else {
                setShowComments(false);
            }
        } else {
            setShowPanel(false);
            setShowComments(false);
            setPanelType(undefined);
        }
    }, [params]);

    const leaveYesHandler = () => {
        applyHistoryParams();
        setEditorOpened([]);
        setShowLeavePanelConfirmPopup(false);
    };

    const leaveNoHeandler = () => {
        setHistoryParamsAfterConfirmPopup(undefined);
        setShowLeavePanelConfirmPopup(false);
    };

    return (
        <MainPanelContext.Provider value={initState}>
            {Panel &&
                <Panel />
            }
            <ConfirmPopup
                showPopup={showLeavePanelConfirmPopup}
                yesHandler={leaveYesHandler}
                noHandler={leaveNoHeandler}
            >
                <IntlMessage id="panel.confirm.unsavedChanges" />
            </ConfirmPopup>
        </MainPanelContext.Provider>
    );
};
