import React, { FC, useRef, ReactNode, useCallback } from 'react';
import styled from 'styled-components';
import { faClipboard } from '@fortawesome/pro-light-svg-icons';

import { useAlert, useCopyInputToClipboard } from '../../tools/hooks';
import { EAlertType } from '../../entities/IAlert';
import { Input } from './Input/Input';
import { Button } from './Buttons/NewButton';
import IntlMessage from './IntlMessage';

const CopyButton = styled(Button)`
    min-width: 100px;
`;

interface IUrlInputProps {
    labelColor?: string;
    label?: ReactNode;
    value: string;
    copyLabel?: ReactNode;
    doneCallback?(): void;
}

export const UrlInput: FC<React.PropsWithChildren<IUrlInputProps>> = ({ children, labelColor, label, value, copyLabel, doneCallback }) => {
    const inputRef = useRef<HTMLInputElement>();
    const [canCopyText, copyToClipboard, onFocus] = useCopyInputToClipboard(inputRef);
    const addAlert = useAlert();

    const onCopyClick = useCallback(() => {
        try {
            copyToClipboard();
            doneCallback?.();
            addAlert(<IntlMessage id="norms.linkHasBeenCopied" />);
        } catch (error) {
            addAlert(<IntlMessage id="norms.error.somethingWentWrong" values={{ error }} />, EAlertType.ERROR);
        }
    }, [value]);

    return (
        <Input
            labelColor={labelColor}
            label={label}
            value={value}
            ref={inputRef}
            onFocus={onFocus}
            button={canCopyText && (
                <CopyButton onClick={onCopyClick} leftIco={faClipboard}>
                    {copyLabel || <IntlMessage id="global.copy" />}
                </CopyButton>
            )}
        >
            {children}
        </Input>
    );
};
