import React, { FC, useCallback, useEffect, useReducer } from 'react';
import styled from 'styled-components';

import { IStakeCoupleCollection } from './StakeCoupleCollection';
import { IReferencial } from '../../../../../entities/IGlobal';
import { useReferentialsPicker } from '../../../../../tools/pickerHooks';
import { uuidv4 } from '../../../../../tools/authTools';
import { ITag } from '../../../../Common/TagPicker/TagPicker';
import { SpliceList } from '../../../../Common/SpliceList/SpliceList';
import { NewDesignStakeLayout } from './NewDesignStakeLayout';
import { ErrorText } from '../StakeComponents/StakeStyles';
import { StaticDataPicker } from '../../../../Common/Pickers/StaticDataPicker';
import { IValue } from '../../../../../entities/IPickers';
import { LabelWithEllipsis } from '../../../../Common/Label/Label';
import { Pill } from '../../../../Common/Pill/Pill';
import IntlMessage from '../../../../Common/IntlMessage';

const PillsBox = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: .5rem;
`;

interface IPropertyValue {
    key: string;
    value: string;
    parentId?: string;
}

interface IScopeItem {
    id: string;
    aorValue: IPropertyValue[];
    itemsValue: IPropertyValue[];
}

interface IState {
    aorTags: ITag<IReferencial>[];
    itemsTags: ITag<IReferencial>[];
    scopeItems: IScopeItem[];
    totalAorValue: IPropertyValue[];
    totalItemsValue: IPropertyValue[];
    changed: boolean;
}

const initialState = {
    aorTags: [],
    itemsTags: [],
    scopeItems: [],
    totalAorValue: [],
    totalItemsValue: [],
    changed: false
};

type TagKeys = 'aorTags' | 'itemsTags';

type Action =
    | { type: 'INIT', totalAorValue: IPropertyValue[], totalItemsValue?: IPropertyValue[] }
    | { type: 'ADD_ITEM', aorValue?: IPropertyValue[] }
    | { type: 'UPDATE_ITEM', id: string, aorValue: IPropertyValue[], itemsValue: IPropertyValue[] }
    | { type: 'REMOVE_ITEM', id: string }
    | { type: 'SET_TAGS', key: TagKeys, tags: ITag<IReferencial>[] }
    | { type: 'SET_UNCHANGED' };

class StateAdapter {
    init(totalAorValue: IPropertyValue[], totalItemsValue: IPropertyValue[]): IState {
        const itemsByAorIds = (totalItemsValue || []).reduce((byIds, item) => {
            byIds.set(item.parentId, byIds.has(item.parentId) ? [...byIds.get(item.parentId), item] : [item]);
            return byIds;
        }, new Map());
        return (totalAorValue || [])
            .reduce((state, value) => this.addItem(state, [value], itemsByAorIds.get(value.key)), { ...initialState });
    }

    addItem(state: IState, aorValue: IPropertyValue[] = [], itemsValue: IPropertyValue[] = []): IState {
        const newItem = { id: uuidv4(), aorValue, itemsValue };
        const newItems = [...state.scopeItems, newItem];

        return { ...state, scopeItems: newItems, ...this.totalizeScopeItems(newItems), changed: true };
    }

    updateItem(state: IState, id: string, aorValue: IPropertyValue[], itemsValue: IPropertyValue[]): IState {
        const updatedItems = state.scopeItems.map(item => {
            if (item.id === id) {
                const aorChanged = item.aorValue !== aorValue;
                return { ...item, aorValue, itemsValue: aorChanged ? [] : itemsValue.filter(elem => elem.value) };
            } else {
                return item;
            }
        });
        return { ...state, scopeItems: updatedItems, ...this.totalizeScopeItems(updatedItems), changed: true };
    }

    removeItem(state: IState, id: string): IState {
        const updatedItems = state.scopeItems.filter(item => item.id !== id);

        return { ...state, scopeItems: updatedItems, ...this.totalizeScopeItems(updatedItems), changed: true };
    }

    totalizeScopeItems(items: IScopeItem[]): Pick<IState, 'totalAorValue' | 'totalItemsValue'> {
        return items.reduce((total, item) => ({
            totalAorValue: [...total.totalAorValue, ...item.aorValue],
            totalItemsValue: [...total.totalItemsValue, ...item.itemsValue.map(child => ({ ...child, parentId: item.aorValue[0].key }))]
        }), { totalAorValue: [], totalItemsValue: [] });
    }
}

const adapter = new StateAdapter();

const reducer = (state: IState, action: Action): IState => {
    switch (action.type) {
        case 'INIT':
            return adapter.init(action.totalAorValue, action.totalItemsValue);
        case 'ADD_ITEM':
            return adapter.addItem(state, action.aorValue);
        case 'UPDATE_ITEM':
            return adapter.updateItem(state, action.id, action.aorValue, action.itemsValue);
        case 'REMOVE_ITEM':
            return adapter.removeItem(state, action.id);
        case 'SET_TAGS':
            return { ...state, [action.key]: action.tags };
        case 'SET_UNCHANGED':
            return { ...state, changed: false };
        default:
            return { ...initialState };
    }
};

// tslint:disable-next-line:no-null-keyword
const mapToPropertyValue = (items: IValue[]) => (items || []).map((item) => ({ key: item.key, value: item.data.name, parentId: null }));

const Field = styled.div`
    label {
        margin-bottom: 6px;
    }
`;

export const AreaOfResponsabilities: FC<React.PropsWithChildren<IStakeCoupleCollection>> = ({ errors, couple, editStake, setEditingData }) => {
    const [aorProperty, itemsProperty] = couple;
    const [searchHandler, , , searching] = useReferentialsPicker();
    const [state, dispatch] = useReducer(reducer, { ...initialState });

    useEffect(() => {
        dispatch({ type: 'INIT', totalAorValue: aorProperty.value, totalItemsValue: itemsProperty.value });

        if (editStake) {
            try {
                const keys: TagKeys[] = ['aorTags', 'itemsTags'];
                const promises = [aorProperty, itemsProperty].map(async (property, index) => {
                    const context = property.configuration?.referentialContext;
                    const type = property.configuration?.referentialType;

                    const tags = await searchHandler(type, context, undefined);
                    return dispatch({ type: 'SET_TAGS', key: keys[index], tags });
                });
                // SonarCloud wants us to use the result of the mapped promises, please leave the next line
                console.log('Promises for properties', promises);
            } catch (err) {
                console.log(`error: ${err}`);
            }
        }
    }, [editStake]);

    useEffect(() => {
        editStake && state?.totalAorValue?.length === 0 && addAorHandler();
    }, [editStake]);

    useEffect(() => {
        if (state.changed) {
            setEditingData([
                { ...aorProperty, value: state.totalAorValue },
                { ...itemsProperty, value: state.totalItemsValue }
            ]);

            dispatch({ type: 'SET_UNCHANGED' });
        }
    }, [state.changed]);

    const updateAorHandler = (id: string, aorValue: IPropertyValue[], itemsValue?: IPropertyValue[]) => {
        dispatch({ type: 'UPDATE_ITEM', id, aorValue, itemsValue });
    };

    const addAorHandler = useCallback(() => {
        dispatch({ type: 'ADD_ITEM' });
    }, []);

    const removeAorHandler = useCallback((id: string) => {
        dispatch({ type: 'REMOVE_ITEM', id });
    }, []);

    const aorError = (errors?.aggregatedErrors || [])?.find(elem => elem?.propertyId === aorProperty?.id)?.message;
    const itemsError = (errors?.aggregatedErrors || [])?.find(elem => elem?.propertyId === itemsError?.id)?.message;

    return (
        <SpliceList
            editMode={editStake}
            items={state.scopeItems}
            onAddItem={addAorHandler}
            onRemoveItem={removeAorHandler}
            addButtonText={
                <IntlMessage id="areaOfResponsability.addItem" />
            }
            itemRenderer={item => (
                <NewDesignStakeLayout stakeKey={aorProperty.key}>
                    <Field>
                        {editStake ? (
                            <>
                                <StaticDataPicker
                                    label={aorProperty.name}
                                    disabled={searching}
                                    value={item.aorValue.map(elem => ({
                                        text: elem.value,
                                        key: elem.key,
                                        data: { ...elem, name: elem.value }
                                    }))}
                                    staticData={state.aorTags.map(elem => ({
                                        text: elem.value,
                                        key: elem.id,
                                        data: elem.data
                                    }))}
                                    onSelectElement={tags => updateAorHandler(item.id, mapToPropertyValue(tags), item.itemsValue)}
                                />
                                {aorError && <ErrorText>{aorError}</ErrorText>}
                            </>
                        ) : (
                            <span>
                                <LabelWithEllipsis>{aorProperty.name}</LabelWithEllipsis>
                                {item.aorValue.length > 0 && (
                                    <Pill text={item.aorValue[0].value} id={item.aorValue[0].key} />
                                )}
                            </span>
                        )}
                    </Field>
                    <Field>
                        {editStake ? (
                            <>
                                <StaticDataPicker
                                    label={itemsProperty.name}
                                    disabled={searching || !item.aorValue.length}
                                    multiSelect
                                    value={item.itemsValue.map(elem => ({
                                        text: elem.value,
                                        key: elem.key,
                                        data: { ...elem, name: elem.value }
                                    }))}
                                    staticData={item.aorValue.length > 0 ?
                                        state.itemsTags.filter(tag => tag?.data?.parents?.includes(item?.aorValue[0]?.key)).map(elem => ({
                                            text: elem.value,
                                            key: elem.id,
                                            data: elem.data
                                        })) :
                                        state.itemsTags.map(elem => ({
                                            text: elem.value,
                                            key: elem.id,
                                            data: elem.data
                                        }))}
                                    onSelectElementMulti={tags => updateAorHandler(item.id, item.aorValue, mapToPropertyValue(tags))}
                                />
                                {itemsError && <ErrorText>{itemsError}</ErrorText>}
                            </>
                        ) : (
                            <span>
                                <LabelWithEllipsis>{itemsProperty.name}</LabelWithEllipsis>
                                <PillsBox>
                                    {item.itemsValue.map(child =>
                                        <Pill text={child.value} id={child.key} key={child.key} />
                                    )}
                                </PillsBox>
                            </span>
                        )}
                    </Field>
                </NewDesignStakeLayout>
            )}
        />
    );
};
