import { Dispatch } from 'redux';
import queryString from 'query-string-for-all';
import { DataManager, CustomDataAdaptor } from '@syncfusion/ej2-data';
import { extend } from '@syncfusion/ej2-base';

import * as actionTypes from './actionTypes/clustersTypes';
import * as organizationActionTypes from './actionTypes/organizationsTypes';
import { Http } from '../tools/http';
import { action, downloadFile, IDispatch } from '../tools/reduxTools';
import { delay } from '../tools/generalTools';
import { IState } from '../reducers';
import {
    ISingleCluster, IChannelMessages, ISingleChannelMessage, IRequestAccessUser,
    IClusterSingleMessage, IDocumentList, IClusterTags, EClusterAccessError, ECustomDatagidsKeys,
    IClustersListCluster, IEconomicStakes, IGetInvolvedPartiesResponse, IInvolvedPartyRequest, EClusterPrivacy, EClusterNature, ICLusterListPayload,
    IDataGridSettings, IClusterWorkplaceDetails, ISingleClusterChannelMessage, IClustersListPage, IClustersListSearch, SendMessageBody, ICreateLinks,
    IClusterTemplate, ITemplateDataGridSettings, ITimelineItemForm, ISingleClusterCore, ISingleClusterGift, IDatagridView, ISingleInvolvedPartie, ICreateCorporateCluster, ISingleLink, IClusterResources, ITeamWithChannels, IActivityFiles
} from '../entities/IClusters';
import { IOrganizationMember } from '../entities/IOrganization';
import { IDirectoryContent, IGIAttachmentUploadResponse } from '../entities/IClustersFilesystem';
import { ClusterPermissions, OrganizationPermissions, ERoleType } from '../entities/IPermissions';
import { IRoleManagerUser, IRoleManagerEmailInvitation } from '../entities/IRoleManager';
import { ELcid } from '../entities/ILanguage';
import { ISIngleLegalEntity, IPersonsInCharge } from '../entities/ILegalEntities';
import { ISearchWork } from '../entities/IWork';
import { IClusterDynamicStakes, IClusterSingleDynamicStake } from '../entities/IClusterStake';
import { EClusterStatus, EProfileType, ISearchResponse } from '../entities/IGlobal';
import { IFormData } from '../entities/IDeclaration';
import { uuidv4 } from '../tools/authTools';
import { ICOIPost, ECoiType } from '../entities/ActionPost/ICOIPost';
import { ISponsoringPost } from '../entities/ActionPost/ISponsoringPost';
import { IDates } from '../entities/IDates';
import { ISponsoringData } from '../entities/ActionData/ISponsoringData';
import { getMessageTranslations } from './languageActions';
import { ClusterPostData, IWizardValues } from '../entities/ILegalSupport';
import { getAllClusterReferentials } from './globalActions';
import { IGlobalTypes, SET_CURRENCY_RATIO } from './actionTypes/globalTypes';
import { IActivitySearchItem } from '../entities/Activity/global';
import { ITradeAssociationPost } from '../entities/ActionPost/ITradeAssociationPost';
import { IItem } from '../entities/IFilters';
import { IAccessRequestDataTable } from '../components/Organization/ClusterSettings/Tabs/AccessRequest';
import { EApprovalTypes, IEApprovalExpenses, IEApprovalFreeDeclaration, IEApprovalGrouping, IEApprovalSponsoring } from '../components/Organization/Wizards/EApprovalWizard';
import { IPOASendData } from '../components/Organization/Wizards/POAWizard';

export const getClusterList = (organizationId: string, data: ICLusterListPayload, page?: number): Promise<IClustersListPage> => ((dispatch: Dispatch, getState: () => IState, http: Http) => {
    return http.clustersApiPost(`/organizations/${organizationId}/clusters/list?page=${page || 0}&size=10`, data).then((response: IClustersListPage & { permissions: OrganizationPermissions[] }) => {
        dispatch({
            type: actionTypes.GET_CLUSTERS_LIST,
            clustersList: response.items as IClustersListCluster[]
        });
        dispatch<IDispatch<organizationActionTypes.IOrganizationsTypes['UPDATE_ORGANIZATION_PERMISSIONS']>>({
            type: organizationActionTypes.UPDATE_ORGANIZATION_PERMISSIONS,
            permissions: response.permissions,
            organizationId
        });
        return response as IClustersListPage;
    });
}) as any;

export const postGiftInvitationForm = (organizationId: string, data: IFormData, cumulated: ISingleCluster) => action<Promise<IOrganizationMember[]>>((dispatch: Dispatch, getState, http: Http) => {
    const body = !cumulated ? {
        declaration: data.formData.declaration,
        thirdParties: data.formData.thirdParties,
        information: data.formData.information,
        message: data.formData.message,
        declarationType: data.formData.declarationType
    } : cumulated;

    return http.clustersApiPost(
        `/organizations/${organizationId}/clusters/create-gift-invitation`,
        body
    );
});
export const createLegalSupportCluster = (organizationId: string, data: IWizardValues) => action<Promise<ISingleCluster>>((dispatch: Dispatch, getState, http: Http) => {
    const body: ClusterPostData = {
        requestAttachment: data.formData.attachment,
        templateKey: 'legal-support',
        legalSupportSubject: data.formData.subject,
        request: data.formData.question,
        answerDate: data.formData.answerDate,
        requestInformation: {
            creator: {
                firstName: data.formData.user.data.firstName,
                lastName: data.formData.user.data.lastName,
                jobTitle: data.formData.user.data.jobTitle,
                picture: data.formData.user.data.picture,
                // tslint:disable-next-line:no-null-keyword
                cabinet: data.formData.user.data.cabinet?.name || null,
                id: data.formData.user.data.id,
                emailContact: data.formData.user.data.emailContact,
                type: EProfileType.Personal
            },
            jobTitle: data.formData.jobTitle,
            legalEntity: data.formData.legalEntity.data,
            manager: data.formData.manager.data
        }
    };

    return http.clustersApiPost(
        `/organizations/${organizationId}/clusters/create-legal-support`,
        body
    );
});
export const createSponsoringCluster = (organizationId: string, data: ISponsoringData) => action<Promise<ISingleCluster>>((dispatch: Dispatch, getState, http: Http) => {
    const { language } = getState();
    const values = data.formData;
    const body: ISponsoringPost['formData'] = {
        templateKey: values.templateKey,
        declarationName: values.declarationName.trim(),
        description: values.description,
        // tslint:disable-next-line:no-null-keyword
        declarationType: null,
        information: {
            creator: {
                id: values.information.creator.id
            },
            jobTitle: values.information.jobTitle,
            legalEntity: {
                id: values.information.legalEntity.id
            },
            manager: {
                id: values.information.manager.id
            }
        }
    };
    return http.clustersApiPost(`/organizations/${organizationId}/clusters/create-sponsoring?lcid=${language.userLanguage || ELcid.En}`, body);
});

export const postCOIWizard = (organizationId: string, data: ICOIPost) => action<Promise<ICOIPost[]>>((dispatch: Dispatch, getState, http: Http) => {
    const { language } = getState();
    const values = data.formData;
    const body: ICOIPost['formData'] = {
        declarationType: ECoiType[values.coiDeclaration.type],
        coiInformation: {
            creator: {
                id: values.coiInformation.creator.id
            },
            jobTitle: values.coiInformation.jobTitle,
            legalEntity: {
                id: values.coiInformation.legalEntity.id
            },
            manager: {
                id: values.coiInformation.manager.id
            }
        },
        coiDeclaration: {
            type: values.coiDeclaration.type,
            description: values.coiDeclaration.description,
            linkType: {
                id: values.coiDeclaration.linkType?.id,
                name: values.coiDeclaration.linkType?.name,
                key: values.coiDeclaration.linkType?.key
            },
            thirdParty: {
                id: values.coiDeclaration.thirdParty?.id
            },
            startingDate: values.coiDeclaration.startingDate,
            endingDate: values.coiDeclaration?.endingDate,
            context: values.coiDeclaration?.context
        },
        coiCertification: {
            certificateClauses: values.coiCertification?.certificateClauses,
            certificationDate: values.coiCertification?.certificationDate,
            certificationSignee: {
                id: values.coiCertification?.certificationSignee?.id
            }
        }
    };

    return http.clustersApiPost(`/organizations/${organizationId}/clusters/create-conflict-of-interest?lcid=${language.userLanguage || ELcid.En}`, body);
});

export const postTradeAssociationWizard = (organizationId: string, data: ITradeAssociationPost) => action<Promise<ITradeAssociationPost[]>>((dispatch: Dispatch, getState, http: Http) => {
    const { language } = getState();
    const values = data.formData;
    const body: ITradeAssociationPost['formData'] = {
        tradeAssociationInformation: {
            creator: {
                id: values.tradeAssociationInformation.creator.id
            },
            jobTitle: values.tradeAssociationInformation.jobTitle,
            legalEntity: {
                id: values.tradeAssociationInformation.legalEntity.id
            },
            manager: {
                id: values.tradeAssociationInformation.manager.id
            }
        },
        tradeAssociationDeclaration: {
            description: values.tradeAssociationDeclaration.description,
            linkType: {
                id: values.tradeAssociationDeclaration.linkType?.id,
                name: values.tradeAssociationDeclaration.linkType?.name,
                key: values.tradeAssociationDeclaration.linkType?.key
            },
            thirdParty: {
                id: values.tradeAssociationDeclaration.thirdParty?.id
            },
            startingDate: values.tradeAssociationDeclaration.startingDate,
            endingDate: values.tradeAssociationDeclaration?.endingDate,
            context: values.tradeAssociationDeclaration?.context
        },
        tradeAssociationCertification: {
            certificateClauses: values.tradeAssociationCertification?.certificateClauses,
            certificationDate: values.tradeAssociationCertification?.certificationDate,
            certificationSignee: {
                id: values.tradeAssociationCertification?.certificationSignee?.id
            }
        }
    };
    return http.clustersApiPost(`/organizations/${organizationId}/clusters/create-trade-associations?lcid=${language.userLanguage || ELcid.En}`, body);
});

export const addNewInvolvedParty = (involvedPartiesGroupId: string, body: IInvolvedPartyRequest) => action<Promise<{}>>((dispatch: Dispatch, getState: () => IState, http: Http) => {
    const { clusters } = getState();
    return http.clustersApiPost(`/clusters/${clusters.currentClusterId}/involved-parties-groups/${involvedPartiesGroupId}`, body);
});

export const updateInvolvedParty = (involvedPartiesGroupId: string, body: IInvolvedPartyRequest, involvedPartyId: string) => action<Promise<{}>>((dispatch: Dispatch, getState: () => IState, http: Http) => {
    const { clusters } = getState();
    return http.clustersApiPut(`/clusters/${clusters.currentClusterId}/involved-parties-groups/${involvedPartiesGroupId}/${involvedPartyId}`, body);
});

export const getClustersSearchResult = (organizationId: string, isMineClusters: boolean, page: number = 0, size: number = 100, showOnlyNotHubs: boolean = false, query?: string, filters?: { [key: string]: string[] }, excludedClusterIds?: string[]): Promise<IClustersListSearch> => ((dispatch: Dispatch, getState: () => IState, http: Http) => {
    const { language } = getState();

    const body = {
        query: query || '',
        ...filters,
        isMineClusters,
        excludedClusterIds
    };

    return http.clustersApiPost(`/organizations/${organizationId}/clusters/search?page=${page}&size=${size}&showOnlyNotHubs=${showOnlyNotHubs}&lcid=${language.userLanguage || ELcid.En}`, body).then((response: IClustersListSearch) => {
        dispatch({
            type: actionTypes.GET_CLUSTERS_SEARCH_RESULT,
            clustersSearchResult: {
                items: response.items,
                facets: response.facets
            }
        });
        return response;
    });
}) as any;

export const downloadFileAsBlob = (documentId: string, isThumbnail: boolean, clusterId?: string): Promise<{}> => ((dispatch: Dispatch, getState, http: Http) => {
    const { context, config, clusters } = getState();
    return fetch(`${config.clustersApiUrl}/clusters/${clusterId || clusters.currentClusterId}/documents/${documentId}/download?isThumbnail=${isThumbnail}`, {
        method: 'POST',
        headers: {
            'Authorization': `Bearer ${context.token}`,
            'Ocp-Apim-Subscription-Key': config.subKey,
            'Content-Type': 'application/json'
        }
    }).then(res => res.blob());
}) as any;

export const downloadDocuments = (clusterId: string, name: string, path?: string): Promise<IDirectoryContent> => ((dispatch: Dispatch, getState, http: Http) => {
    const { clusters } = getState();
    const body = {
        name,
        path: path || 'Conversation files',
        includeFiles: true,
        recursive: true
    };
    return http.clustersApiPost(`/clusters/${clusterId || clusters.currentClusterId}/documents/metadata`, body);
}) as any;

export const getClusterMembers = (clusterId?: string, query: string = '', size: number = 9999): Promise<IOrganizationMember[]> => ((dispatch: Dispatch, getState, http: Http) => {
    const params = [
        ...[query ? [`query=${query}`] : []],
        `size=${size}`,
        `PrincipalType=Personal`
    ].filter(param => !!param).join('&');
    return http.permissionsApiGet(`/resources/${clusterId || getState().clusters.currentClusterId}/principals?resourceTypes=Cluster&${params}`).then((response) => {
        return (response && (response.items || response.principals)) || [];
    });
}) as any;

export const getClusterMembersDataManager = (clusterId?: string, clusterName?: string): Promise<IOrganizationMember[]> => ((dispatch: Dispatch, getState, http: Http) => {
    const { context, config } = getState();
    let result = new DataManager({
        adaptor: new CustomDataAdaptor({
            getData: function (option: any) {
                const query = JSON.parse(option.data)?.where?.[0]?.value || '';
                let request: any;
                fetch(`${config.permissionsApiUrl}/resources/${clusterId || getState().clusters.currentClusterId}/principals?resourceTypes=Cluster&PrincipalType=Personal&query=${query}`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${context.token}`,
                        'Ocp-Apim-Subscription-Key': config.subKey,
                        'Content-Type': 'application/json; charset=utf-8'
                    }
                }).then((response) => {
                    request = extend({}, option, { httpRequest: { ...response, getResponseHeader: () => 'application/json; charset=utf-8' } });
                    if (response.status >= 200 && response.status <= 299) {
                        return response.json();
                    }
                }).then((data) => {
                    const principalsMappedData = data.principals.map(member => ({
                        id: member.childId || member.id,
                        display: member?.childName || `${member?.firstName} ${member?.lastName}`,
                        picture: member.picture,
                        type: member?.principalType?.toLowerCase()
                    }));
                    if (clusterName?.includes(query.toLowerCase()) && clusterName) {
                        principalsMappedData.unshift({ id: clusterId, display: clusterName, picture: undefined, type: EProfileType.Cluster });
                    }
                    option.onSuccess(principalsMappedData, request);
                }).catch((error) => {
                    option.onFailure(request);
                });
            }
        })
    });
    return result;
}) as any;

export const requestAccess = (resourceId: string, resourceType: ERoleType) => action<Promise<void>>((dispatch, getState: () => IState, http: Http) => {
    const { profile } = getState();
    const body = {
        firstName: profile.currentUserProfile.firstName,
        lastName: profile.currentUserProfile.lastName,
        picture: profile.currentUserProfile.picture,
        id: profile.currentUserProfile.id
    };
    return http.permissionsApiPost(`/resources/${resourceId || getState().clusters.currentClusterId}/access-requests?resourceType=${resourceType}`, body).then((response) => {
        dispatch({
            type: actionTypes.GET_CURRENT_CLUSTER,
            currentClusterData: EClusterAccessError.NOT_MEMBER_REQ_ALREADY_SENT
        });
    });
});

export const updateChannel = (clusterChannelId: string, name: string) => ((dispatch, getState: () => IState, http: Http) => {
    const body = {
        name
    };
    return http.clustersApiPut(`/clusters/${getState().clusters.currentClusterId}/channels/${clusterChannelId}`, body);
}) as any;

export const archiveChannel = (clusterChannelId: string) => ((dispatch, getState: () => IState, http: Http) => {
    return http.clustersApiPut(`/clusters/${getState().clusters.currentClusterId}/channels/${clusterChannelId}/archive`);
}) as any;

export const addMemberToCluster = (accessRequestId: string, clusterId?: string) => action<Promise<undefined>>((dispatch, getState: () => IState, http: Http) => {
    return http.permissionsApiPost(`/resources/${clusterId || getState().clusters.currentClusterId}/access-requests/${accessRequestId}/accept`);
});

export const getRequestAccessUser = (clusterId?: string) => ((dispatch, getState: () => IState, http: Http) => {
    return http.permissionsApiGet(`/resources/${clusterId || getState().clusters.currentClusterId}/access-requests?resourceType=Cluster`).then((requestAccessUser: { items: IRequestAccessUser[] }) => {
        dispatch({
            type: actionTypes.GET_AREQUEST_ACCESS_USER,
            requestAccessUser: requestAccessUser.items
        });
    });
}) as any;

export const rejectRequestUser = (accessRequestId: string, resourceId?: string) => ((dispatch, getState: () => IState, http: Http) => {
    return http.permissionsApiPost(`/resources/${resourceId || getState().clusters.currentClusterId}/access-requests/${accessRequestId}/reject`).then(() => {
        dispatch(getRequestAccessUser());
    });
}) as any;

export const removeMemberFromCluster = (clusterId: string, memberId: string) => ((dispatch, getState: () => IState, http: Http) => {
    return http.permissionsApiDelete(`/resources/${clusterId}/principals/${memberId}?resourceType=Cluster`).catch((err) => {
        return err;
    });
}) as any;

export const getDatagridSetting = (organizationId: string) => action<Promise<IDataGridSettings>>(async (dispatch, getState, http) => {
    const { language } = getState();
    return http.clustersApiGet(`/organizations/${organizationId}/datagrid/settings?lcid=${language.userLanguage || ELcid.En}`);
});

export const getTemplateDatagridSetting = (organizationId: string, templateId: string, isCluster: boolean, isCustom?: boolean, customKey?: ECustomDatagidsKeys) => action<Promise<{ data: ITemplateDataGridSettings, customKey: ECustomDatagidsKeys }>, IState>(async (dispatch, getState, http) => {
    const { language } = getState();
    const query = queryString.stringify({ lcid: language.userLanguage || ELcid.En });
    let url = '';
    if (isCustom) {
        url = `/${isCluster ? 'clusters' : 'organizations'}/${organizationId}/custom-datagrid/${templateId}/settings`;
    } else if (customKey) {
        url = `/${isCluster ? 'clusters' : 'organizations'}/${organizationId}/custom-datagrid-by-key/${customKey}/settings`;
    } else {
        url = `/${isCluster ? 'clusters' : 'organizations'}/${organizationId}/datagrid/${templateId}/settings`;
    }
    return http.clustersApiGet(`${url}?${query}`).then(response => ({ data: response, customKey: customKey }));
});

export const setTemplateDatagridSetting = (organizationId: string, templateId: string, settings: ITemplateDataGridSettings, isCluster: boolean, isCustom?: boolean, customKey?: ECustomDatagidsKeys) => action<Promise<ITemplateDataGridSettings>, IState>(async (dispatch, getState, http) => {
    const { language } = getState();
    if (isCustom) {
        return http.clustersApiPost(`/${isCluster ? 'clusters' : 'organizations'}/${organizationId}/custom-datagrid/${templateId}/settings?lcid=${language.userLanguage || ELcid.En}`, settings);
    } else if (customKey) {
        return http.clustersApiPost(`/${isCluster ? 'clusters' : 'organizations'}/${organizationId}/custom-datagrid-by-key/${customKey}/settings?lcid=${language.userLanguage || ELcid.En}`, settings);
    } else {
        return http.clustersApiPost(`/${isCluster ? 'clusters' : 'organizations'}/${organizationId}/datagrid/${templateId}/settings?lcid=${language.userLanguage || ELcid.En}`, settings);
    }
});

export const setDatagridSetting = (organizationId: string, body: IDataGridSettings) => action<Promise<IDataGridSettings>>(async (dispatch, getState, http) => {
    const { language } = getState();
    return http.clustersApiPost(`/organizations/${organizationId}/datagrid/settings?lcid=${language.userLanguage || ELcid.En}`, body);
});

export const removeDocumentsFromCluster = (documentId: string) => ((dispatch, getState: () => IState, http: Http) => {
    return http.clustersApiDelete(`/clusters/${getState().clusters.currentClusterId}/documents/${documentId}`);
}) as any;

export const getAllTags = (organizationId: string) => action<Promise<IClusterTags>>((dispatch, getState: () => IState, http: Http) => {
    return http.clustersApiGet(`/clusters/organizations/${organizationId}/tags`).then((clusterTags: IClusterTags) => {
        dispatch({
            type: actionTypes.GET_ALL_TAGS,
            clusterTags
        });

        return clusterTags;
    });
});

export const getChannelNotifications = () => ((dispatch, getState: () => IState, http: Http) => {
    return http.notificationApiGet(`/clusters/${getState().clusters.currentClusterId}/channels/${getState().clusters.currentChannelId}/notifications-settings`).then((res: string) => {
        return res;
    });
}) as any;

export const setChannelNotifications = (messagesOptions: string) => ((dispatch, getState: () => IState, http: Http) => {
    const body = {
        messagesOptions
    };
    return http.notificationApiPost(`/clusters/${getState().clusters.currentClusterId}/channels/${getState().clusters.currentChannelId}/notifications-settings`, body);
}) as any;

export const getSingleCluster = (clusterId?: string) => action<Promise<ISingleCluster>>((dispatch: Dispatch, getState: () => IState, http: Http) => {
    const { clusters, language } = getState();
    if (clusterId || clusters.currentClusterId) {
        return http.clustersApiGet(`/Clusters/${clusterId || clusters.currentClusterId}?lcid=${language.userLanguage || ELcid.Fr}`).then((cluster: ISingleClusterCore) => {
            dispatch<IDispatch<actionTypes.IClusterTypes['GET_INITIAL_CLUSTER_DATA']>>({
                type: actionTypes.GET_INITIAL_CLUSTER_DATA,
                clusterData: cluster,
                permissions: cluster.permissions
            });
            dispatch<any>(getAllClusterReferentials(cluster.clusterTemplateId));
            return cluster;
        }).catch((err: any) => {
            if (err.status === 404) {
                dispatch({
                    type: actionTypes.GET_CURRENT_CLUSTER,
                    currentClusterData: err.data
                });
            }
        });
    }
});

export const updateClusterDetails = (clusterId: string, body: ISingleCluster) => action<Promise<ISingleCluster>, IState>(async (dispatch: Dispatch, getState, http) => {
    const { language } = getState();
    return http.clustersApiPut(`/organizations/${body.organizationId}/clusters/${clusterId}?lcid=${language.userLanguage}`, body).then((cluster: ISingleClusterCore) => {
        dispatch<IDispatch<actionTypes.IClusterTypes['GET_INITIAL_CLUSTER_DATA']>>({
            type: actionTypes.GET_INITIAL_CLUSTER_DATA,
            clusterData: cluster,
            permissions: cluster.permissions
        });
        return cluster;
    });
});

export const getClusterDataForRefreshApproval = (clusterId?: string) => action<Promise<ISingleCluster>>((dispatch: Dispatch, getState: () => IState, http: Http) => {
    const { clusters, language } = getState();
    return http.clustersApiGet(`/Clusters/${clusterId || clusters.currentClusterId}?lcid=${language.userLanguage || ELcid.Fr}`).then((cluster: ISingleClusterCore) => {
        dispatch<IDispatch<actionTypes.IClusterTypes['REFRESH_APPROVAL_INFO']>>({
            type: actionTypes.REFRESH_APPROVAL_INFO,
            clusterData: cluster
        });
        return cluster;
    });
});

export const deleteSingleCluster = (clusterId?: string) => action<Promise<void>>(async (dispatch, getState, http) => {
    const { clusters } = getState();
    return http.clustersApiDelete(`/Clusters/${clusterId || clusters.currentClusterId}`);
});

export const updateClusterPermissions = (permissions: ClusterPermissions[]): IDispatch<actionTypes.IClusterTypes['UPDATE_CLUSTER_PERMISSIONS']> => ({
    type: actionTypes.UPDATE_CLUSTER_PERMISSIONS,
    permissions
});

export const setCurrentTeam = (currentTeamId: string) => ({
    type: actionTypes.SET_CURRENT_TEAM,
    currentTeamId
});

export const setCurrentTeamChannelId = (currentTeamChannelId: string) => ({
    type: actionTypes.SET_CURRENT_TEAM_CHANNEL_ID,
    currentTeamChannelId
});

export const getChannelMessages = (page: number, size: number, resourceChildId: string, teamId: string, channelResourceId?: string) => action<Promise<IChannelMessages>>((dispatch: Dispatch, getState: () => IState, http: Http) => {
    const { language, clusters } = getState();
    return http.messagingApiGet(`/resources/${resourceChildId}/teams/${teamId}/channels/${channelResourceId || clusters.currentChannelId}/messages?size=${size}&page=${page}&lcid=${language.userLanguage || ELcid.En}`)
        .then((currentChannelMessages: IChannelMessages) => {
            dispatch({
                type: actionTypes.GET_CHANNEL_MESSAGES,
                currentChannelMessages
            });
            dispatch({
                type: actionTypes.GET_SINGLE_CHANNEL_MESSAGE,
                channelMessageError: undefined
            });
            dispatch(setCurrentTeam(currentChannelMessages.teamId));
            dispatch(setCurrentTeamChannelId(currentChannelMessages.teamChannelId));
            return currentChannelMessages;
        });
});

export const getSingleChannelMessage = (threadId: string, resourceChildId: string, teamId: string, teamChannelId: string) => action<Promise<ISingleChannelMessage>>((dispatch: Dispatch, getState: () => IState, http: Http) => {
    const { language } = getState();
    return http.messagingApiGet(`/resources/${resourceChildId}/teams/${teamId}/channels/${teamChannelId}/messages/${threadId}?lcid=${language.userLanguage}`)
        .then((currentChannelMessage: ISingleChannelMessage) => {
            dispatch({
                type: actionTypes.GET_SINGLE_CHANNEL_MESSAGE,
                currentChannelMessage
            });
            return currentChannelMessage;
        }).catch((err: any) => {
            if (err?.data?.ErrorCode === 1100) {
                dispatch({
                    type: actionTypes.GET_SINGLE_CHANNEL_MESSAGE,
                    channelMessageError: EClusterAccessError.NOT_EXIST_ANYMORE
                });
            }
        });
});

export const getTeamsWithChannels = (resourceChildId: string) => action<Promise<ITeamWithChannels[]>>((dispatch: Dispatch, getState: () => IState, http: Http) => {
    return http.messagingApiGet(`/resources/${resourceChildId}/teams-with-channels`).then((res: ITeamWithChannels) => {
        return res;
    });
});

export const getMessageViewByThreadId = (resourceChildId: string, teamId: string, teamChannelId: string, channelMessageId: string) => action<Promise<IActivitySearchItem[]>>((dispatch: Dispatch, getState: () => IState, http: Http) => {
    return http.messagingApiGet(`/resources/${resourceChildId}/teams/${teamId}/channels/${teamChannelId}/messages/channelMessageViews/${channelMessageId}`).then((res: ITeamWithChannels) => {
        return res;
    });
});

export const getClusterDocuments = (currentChannelId?: string, currentClusterId?: string) => action<Promise<void>>((dispatch: Dispatch, getState: () => IState, http: Http) => {
    return http.clustersApiGet(`/clusters/${currentClusterId || getState().clusters.currentClusterId}/channels/${currentChannelId || getState().clusters.currentChannelId}/documents?page=0&size=100`).then((documentList: IDocumentList) => {
        dispatch({
            type: actionTypes.GET_CLUSTER_DOCUMENTS,
            documentList: documentList.items
        });
    });
});

export const updateMessage = (body: SendMessageBody, resourceChildId: string, teamId: string, channelMessageId: string, channelId?: string) => action<Promise<void>>((dispatch: Dispatch, getState: () => IState, http: Http) => {
    return http.messagingApiPut(`/resources/${resourceChildId}/teams/${teamId}/channels/${channelId || getState().clusters.currentChannelId}/messages/${channelMessageId}`, body);
});

export const clearClusterDocuments = () => ({
    type: actionTypes.CLEAR_CLUSTER_DOCUMENTS
});

export const sendFiles = (files: FormData): Promise<IActivityFiles> => ((dispatch: Dispatch, getState: () => IState, http: Http) => {
    return http.clustersApiPost(`/clusters/${getState().clusters.currentClusterId}/documents/upload-activity`, files);
}) as any;

export const sendMessage = (body: SendMessageBody, resourceChildId: string, teamId: string, channelId?: string): Promise<any> => ((dispatch: Dispatch, getState: () => IState, http: Http) => {
    return http.messagingApiPost(`/resources/${resourceChildId}/teams/${teamId}/channels/${channelId || getState().clusters.currentChannelId}/messages`, body);
}) as any;

export const setCurrentChannelId = (currentChannelId: string) => ({
    type: actionTypes.SET_CURRENT_CHANNEL,
    currentChannelId
});

export const changeClusterPropertiesView = () => ({
    type: actionTypes.CHANGE_CLUSTER_PROPERTIES_VIEW
});

export const changeClusterDocumentsView = () => ({
    type: actionTypes.CHANGE_CLUSTER_DOCUMENTS_VIEW
});

export const changeClusterMembersView = () => ({
    type: actionTypes.CHANGE_CLUSTER_MEMBERS_VIEW
});

export const setCurrentChannelName = (currentChannelName: string) => ({
    type: actionTypes.SET_CURRENT_CHANNEL_NAME,
    currentChannelName
});

export const clearChannelMessages = () => ({
    type: actionTypes.CLEAR_CHANNEL_MESSAGES
});

export const setCurrentCluster = (currentClusterId: string) => (dispatch, getState, http: Http) => {
    dispatch({
        type: actionTypes.SET_CURRENT_CLUSTER,
        currentClusterId
    });
};

export const leaveClusters = (): Promise<any> => ((dispatch, getState: () => IState, http: Http) => {
    return http.clustersApiPut(`/Clusters/${getState().clusters.currentClusterId}/leave`);
}) as any;

export const getDatagridSettings = (clusterId: string) => action<Promise<IDataGridSettings>>((dispatch: Dispatch, getState: () => IState, http: Http) => {
    return http.clustersApiGet(`/clusters/${clusterId}/activities-datagrid/settings`);
});

export const setDatagridSettings = (clusterId: string, body: IDataGridSettings) => action<Promise<IDataGridSettings>>((dispatch: Dispatch, getState: () => IState, http: Http) => {
    return http.clustersApiPost(`/clusters/${clusterId}/activities-datagrid/settings`, body);
});

export const receiveClusterMessage = (message: ISingleChannelMessage) => ({
    type: actionTypes.RECEIVE_MESSAGE,
    message
});

export const updateSingleMessage = (message: ISingleChannelMessage) => ({
    type: actionTypes.UPDATE_MESSAGE,
    message
});

export const receiveThreadMessage = (message: IClusterSingleMessage) => ({
    type: actionTypes.RECEIVE_THREAD_MESSAGE,
    message
});

export const setMessagePage = () => ({
    type: actionTypes.SET_MESSAGE_PAGE
});

export const clearMessagePage = () => ({
    type: actionTypes.CLEAR_MESSAGE_PAGE
});

export const clearSearchResult = () => ({
    type: actionTypes.CLUSTERS_SEARCH_LOADING
});

export const getAvailableClusterPhotos = () => action<Promise<string[]>>((dispatch: Dispatch, getState: () => IState, http: Http) => {
    return http.clustersApiGet('/data/logos').then(clusterPhotos => {
        dispatch({
            type: actionTypes.GET_CLUSTER_PHOTOS,
            clusterPhotos
        });
        return clusterPhotos;
    });
});

export interface ICreateNewClusterPayload {
    organizationId: string;
    organizationUrlName: string;
    organizationName: string;
    name?: string;
    description?: string;
    privacy?: EClusterPrivacy;
    nature?: EClusterNature;
    workplaceDetails?: IClusterWorkplaceDetails;
    clusterTemplateId?: string;
    dynamicStakes?: IClusterDynamicStakes[];
}

export const createNewCluster = (clusterData: ICreateNewClusterPayload) => action<Promise<ISingleCluster>>(async (dispatch, getState: () => IState, http: Http) => {
    const { firstName, lastName, id, picture, jobTitle, department, cabinet } = getState().profile.currentUserProfile;
    const { organizationId, organizationUrlName, name, description, privacy, nature, workplaceDetails, clusterTemplateId, dynamicStakes, organizationName } = clusterData;
    const { language } = getState();
    const clusterPhotos = await dispatch(getAvailableClusterPhotos());

    const response: ISingleCluster = await http.clustersApiPost(`/organizations/${organizationId}/clusters?lcid=${language.userLanguage}`, {
        presentation: {
            name: name?.trim(),
            privacy,
            status: EClusterStatus.Active,
            nature: nature || EClusterNature.Project,
            description,
            photo: (clusterPhotos || [])[0],
            peopleInCharge: [],
            startDate: new Date()
        },
        owners: [{
            role: 'Owner',
            firstName,
            lastName,
            jobTitle,
            department,
            picture,
            cabinet: cabinet?.name,
            id
        }],
        organizationId,
        organizationName: organizationName,
        organizationUrlName: organizationUrlName,
        workplaceDetails,
        clusterTemplateId,
        dynamicStakes,
        // tslint:disable-next-line:no-null-keyword
        referencedItem: null
    });

    return response;
});

interface ICreateNewClusterLegalEntity {
    name: string;
    organizationId: string;
    organizationUrlName: string;
    description?: string;
    privacy: EClusterPrivacy;
    clusterTemplateId: string;
    peopleInCharge: IPersonsInCharge[];
    legalEntity: ISIngleLegalEntity;
}

export const createNewClusterLegalEntity = (newClusterData: ICreateNewClusterLegalEntity) => action<Promise<ISingleCluster>>(async (dispatch, getState: () => IState, http: Http) => {
    const { firstName, lastName, id, picture, jobTitle, department, cabinet } = getState().profile.currentUserProfile;
    const { name, organizationId, organizationUrlName, description, privacy, clusterTemplateId, peopleInCharge, legalEntity } = newClusterData;

    const clusterPhotos = await dispatch(getAvailableClusterPhotos());

    const response: ISingleCluster = await http.clustersApiPost(`/organizations/${organizationId}/clusters`, {
        presentation: {
            name: name.trim(),
            privacy,
            status: EClusterStatus.Active,
            nature: EClusterNature.Project,
            description,
            photo: (clusterPhotos || [])[0],
            peopleInCharge,
            startDate: new Date()
        },
        owners: [{
            role: 'Owner',
            firstName,
            lastName,
            jobTitle,
            department,
            picture,
            cabinet: cabinet?.name,
            id
        }],
        organizationId,
        organizationName: organizationUrlName,
        organizationUrlName,
        clusterTemplateId,
        referencedItem: {
            type: 'LegalEntity',
            referencedItem: {
                name: legalEntity.name,
                id: legalEntity.id
            }
        }
    });

    return response;
});

export const getAccessRequestDataTableData = (filters: any, clusterId?: string, page?: number) => action<Promise<IAccessRequestDataTable>>((dispatch: Dispatch, getState: () => IState, http: Http) => {
    return http.permissionsApiPost(`/resources/${clusterId || getState().clusters.currentClusterId}/access-requests/as-datatable?resourceType=Cluster&pageSize=50&page=${page || 0}`, { ...filters, query: filters.query?.[0] });
});

export const getClusterByChannel = (channelId: string) => action<Promise<ISingleCluster>>(async (dispatch, getState: () => IState, http: Http) => {
    const currentClusterData = await http.clustersApiGet(`/Clusters/by-workplace-channel/${channelId}`) as ISingleCluster;

    dispatch({
        type: actionTypes.GET_CURRENT_CLUSTER,
        currentClusterData
    });
    return currentClusterData;
});

export const deleteChannelMessage = (messageId: string, resourceChildId: string, teamId: string, teamChannelId: string) => ((dispatch: Dispatch, getState: () => IState, http: Http) => {
    return http.messagingApiDelete(`/resources/${resourceChildId}/teams/${teamId}/channels/${teamChannelId}/messages/${messageId}`);
}) as any;

export const createNegotiationsCluster = (organizationId: string, body: any) => action<Promise<ISingleCluster>>(async (dispatch, getState: () => IState, http: Http) => {
    return http.clustersApiPost(`/organizations/${organizationId}/clusters/create-negotiation`, body);
});

export const deleteChannelThread = (threadId: string, resourceChildId: string, teamId: string, teamChannelId: string) => action<Promise<undefined>>(async (dispatch: Dispatch, getState: () => IState, http: Http) => {
    await http.messagingApiDelete(`/resources/${resourceChildId}/teams/${teamId}/channels/${teamChannelId}/threads/${threadId}`);
});

export const deleteChannelThreadFromState = (threadId: string) => ({
    type: actionTypes.REMOVE_CLUSTER_CHANNEL_THREAD,
    threadId
});

export const inviteToClusterByEmail = (message: string, email: IRoleManagerEmailInvitation, clusterId?: string) => action<Promise<unknown>>((dispatch: Dispatch, getState: () => IState, http: Http) => {
    return http.permissionsApiPost(`/resources/${clusterId || getState().clusters.currentClusterId}/principals/invite?resourceType=Cluster`, { ...email, message });
});

export const archiveCluster = (clusterId: string) => action<Promise<unknown>>((dispatch: Dispatch, getState: () => IState, http: Http) => {
    return http.clustersApiPut(`/clusters/${clusterId}/change-status?status=Archived`);
});

export const joinCluster = (resourceId: string, member: IRoleManagerUser, resourceType: ERoleType) => action<Promise<void>>(async (dispatch, getState, http) => {
    await http.permissionsApiPost(`/resources/${resourceId}/join?resourceType=${resourceType}`, member);
    await delay(2000); // dirty hack until proper API created :(
    await dispatch(getSingleCluster());
    await dispatch(getMessageTranslations());
});

interface ILegalStakeInfo {
    exclusivity: boolean;
    confidentiality: boolean;
    changeOfControlClause: boolean;
}

interface ILegalStake {
    title: string;
    description: string;
    id?: string;
}

export const updateLegalStake = (legalStake: ILegalStake, legalStakeId: string) => action<Promise<void>>(async (dispatch, getState, http) => {
    const currentClusterData: ISingleCluster = await http.clustersApiPut(`/clusters/${getState().clusters.currentClusterId}/legalstakes/${legalStakeId}`, legalStake);
    dispatch({
        type: actionTypes.GET_CURRENT_CLUSTER,
        currentClusterData
    });
    return currentClusterData;
});

export const updateLegalStakeInfo = (legalStakeInfo: ILegalStakeInfo) => action<Promise<void>>(async (dispatch, getState, http) => {
    const currentClusterData: ISingleCluster = await http.clustersApiPut(`/clusters/${getState().clusters.currentClusterId}/legalstakes`, legalStakeInfo);
    dispatch({
        type: actionTypes.GET_CURRENT_CLUSTER,
        currentClusterData
    });
    return currentClusterData;
});

export const deleteLegalStake = (legalStakeId: string) => action<Promise<void>>(async (dispatch, getState, http) => {
    const currentClusterData: ISingleCluster = await http.clustersApiDelete(`/clusters/${getState().clusters.currentClusterId}/legalstakes/${legalStakeId}`);
    dispatch({
        type: actionTypes.GET_CURRENT_CLUSTER,
        currentClusterData
    });
    return currentClusterData;
});

export const setLegalStake = (legalStake: ILegalStake) => action<Promise<void>>(async (dispatch, getState, http) => {
    const currentClusterData: ISingleCluster = await http.clustersApiPost(`/clusters/${getState().clusters.currentClusterId}/legalstakes`, legalStake);
    dispatch({
        type: actionTypes.GET_CURRENT_CLUSTER,
        currentClusterData
    });
    return currentClusterData;
});

export const clearClusterData = () => ({
    type: actionTypes.CLEAR_CLUSTER_DATA
});

export const setEconomicStake = (economicStake: IEconomicStakes) => action<Promise<void>>(async (dispatch, getState, http) => {
    const currentClusterData: ISingleCluster = await http.clustersApiPost(`/clusters/${getState().clusters.currentClusterId}/economicstakes`, economicStake);
    dispatch({
        type: actionTypes.GET_CURRENT_CLUSTER,
        currentClusterData
    });
    return currentClusterData;
});

export const updateEconomicStake = (economicStake: IEconomicStakes, economicStakeId: string) => action<Promise<void>>(async (dispatch, getState, http) => {
    const currentClusterData: ISingleCluster = await http.clustersApiPut(`/clusters/${getState().clusters.currentClusterId}/economicstakes/${economicStakeId}`, economicStake);
    dispatch({
        type: actionTypes.GET_CURRENT_CLUSTER,
        currentClusterData
    });
    return currentClusterData;
});

export const deleteEconomicStake = (economicStakeId: string) => action<Promise<void>>(async (dispatch, getState, http) => {
    const currentClusterData: ISingleCluster = await http.clustersApiDelete(`/clusters/${getState().clusters.currentClusterId}/economicstakes/${economicStakeId}`);
    dispatch({
        type: actionTypes.GET_CURRENT_CLUSTER,
        currentClusterData
    });
    return currentClusterData;
});

export const createTimelineItem = (timelineItem: ITimelineItemForm) => action<Promise<ISingleCluster>>(async (dispatch, getState, http) => {
    const { startDate, endDate, ...timelineItemData } = timelineItem;
    const currentClusterData: ISingleCluster = await http.clustersApiPost(`/clusters/${getState().clusters.currentClusterId}/timeline`, timelineItemData);
    dispatch({
        type: actionTypes.GET_CURRENT_CLUSTER,
        currentClusterData
    });
    return currentClusterData;
});

export const updateTimelineItem = (timelineItem: ITimelineItemForm, id: string) => action<Promise<ISingleCluster>>(async (dispatch, getState, http) => {
    const { startDate, endDate, ...timelineItemData } = timelineItem;
    const currentClusterData: ISingleCluster = await http.clustersApiPut(`/clusters/${getState().clusters.currentClusterId}/timeline/${id}`, timelineItemData);
    dispatch({
        type: actionTypes.GET_CURRENT_CLUSTER,
        currentClusterData
    });
    return currentClusterData;
});

export const deleteTimeline = (timelineId: string) => action<Promise<void>>(async (dispatch, getState, http) => {
    const currentClusterData: ISingleCluster = await http.clustersApiDelete(`/clusters/${getState().clusters.currentClusterId}/timeline/${timelineId}`);
    dispatch({
        type: actionTypes.GET_CURRENT_CLUSTER,
        currentClusterData
    });
    return currentClusterData;
});

export const exportEvent = (timelineItemId: string, eventName: string) => action<Promise<void>, IState>((dispatch, getState) => {
    const { context, config, clusters } = getState();

    return downloadFile(`${config.clustersApiUrl}/clusters/${clusters.currentClusterId}/timeline/${timelineItemId}/export-event`, `${eventName}.ics`, context.token, config.subKey);
});

export const markOrUnmarkTimeline = (timelineId: string, isReferenceDate: boolean) => action<Promise<void>>(async (dispatch, getState, http) => {
    const currentClusterData: ISingleCluster = await http.clustersApiPut(`/clusters/${getState().clusters.currentClusterId}/timeline/${timelineId}/as-reference-date?asReferenceDate=${isReferenceDate}`);
    dispatch({
        type: actionTypes.GET_CURRENT_CLUSTER,
        currentClusterData
    });
    return currentClusterData;
});

export const getInvolvedParties = (query: string = '') => action<Promise<IGetInvolvedPartiesResponse>>(async (dispatch, getState, http) => {
    const involvedParties: IGetInvolvedPartiesResponse = await http.apiGet(`/involved-parties/search${query ? `?query=${query}` : ''}`);
    return involvedParties;
});

export const searchLegalEntities = (organizationId: string, query: string = '', size?: number) => action<Promise<ISearchResponse<ISingleInvolvedPartie>>>(async (dispatch, getState, http) => {
    return http.clustersApiGet(`/organizations/${organizationId}/clusters-search/clusters-legal-entities?searchQuery=${query}${size && `&size=${size}`}`, undefined, undefined, 15000);
});

export const getUnreadClustersMessages = (clustersIds: string[]) => action<Promise<ISingleClusterChannelMessage[]>>(async (dispatch, getState, http) => {
    const unreadClustersMessages: ISingleClusterChannelMessage[] = await http.messagingApiPost('/clusters-messages/unread', clustersIds);
    return unreadClustersMessages as ISingleClusterChannelMessage[];
});

export const getClusterTemplates = (organizationId: string, onlyActive: boolean = true) => action<Promise<IClusterTemplate[]>>(async (dispatch, getState, http) => {
    const { language } = getState();
    dispatch({
        type: actionTypes.GET_CURRENT_CLUSTER_TEMPLATES
    });
    const clusterTemplates: IClusterTemplate[] = await http.clustersApiGet(`/organizations/${organizationId}/cluster-templates?onlyActiveTemplates=${onlyActive}&lcid=${language.userLanguage || ELcid.En}`);
    dispatch({
        type: actionTypes.GET_CURRENT_CLUSTER_TEMPLATES,
        clusterTemplates
    });
    return clusterTemplates;
});

export const getClusterTemplateById = (organizationId: string, templateId: string, isCluster: boolean, isCustom?: boolean, customKey?: ECustomDatagidsKeys, orgsTarget?: boolean, activity?: boolean) => action<Promise<{ data: any, customKey: ECustomDatagidsKeys }>>(async (dispatch, getState, http) => {
    let url = '';
    const { language } = getState();
    if (activity) {
        return http.messagingApiGet(`/${isCluster ? 'clusters' : 'organizations'}/${organizationId}/custom-datagrid-by-key/${customKey}?lcid=${language.userLanguage}`).then(response => ({ data: response, customKey: customKey }));
    }
    if (isCustom && templateId) {
        url = `/${isCluster ? 'clusters' : 'organizations'}/${organizationId}/custom-datagrid/${templateId}?lcid=${language.userLanguage}`;
    } else if (customKey) {
        url = `/${isCluster ? 'clusters' : 'organizations'}/${organizationId}/custom-datagrid-by-key/${customKey}?lcid=${language.userLanguage}`;
    } else {
        url = `/${isCluster ? 'clusters' : 'organizations'}/${organizationId}/cluster-template/${templateId}?lcid=${language.userLanguage}`;
    }

    return orgsTarget ? http.organizationsApiGet(url).then(response => ({ data: response, customKey: customKey })) : http.clustersApiGet(url).then(response => ({ data: response, customKey: customKey }));
});

export const editClusterTemplate = (organizationId: string, templateId: string, body: IClusterTemplate) => action<Promise<any>>(async (dispatch, getState, http) => {
    const { language } = getState();
    return http.clustersApiPut(`/organizations/${organizationId}/cluster-templates/${templateId}?&lcid=${language.userLanguage || ELcid.En}`, body);
});

export const getClusterTemplatesWithStakes = (organizationId: string) => action<Promise<ISingleClusterGift[]>>(async (dispatch, getState, http) => {
    const { language } = getState();
    return http.clustersApiGet(`/organizations/${organizationId}/cluster-templates/full?lcid=${language.userLanguage || ELcid.En}`);
});

export const getAssociatedClusters = (clusterId: string, page: number, query: string = '', followed: string = 'All') => action<Promise<ISearchWork>>(async (dispatch, getState, http) => {
    const { language } = getState();
    return http.clustersApiPost(`/Clusters/${clusterId}/associated-clusters?page=${page}&lcid=${language.userLanguage || ELcid.En}`, {
        query,
        followed
    });
});

export const getDatagridViews = (organizationId: string) => action<Promise<IDatagridView[]>>((dispatch, getState, http) => {
    return http.clustersApiGet(`/organizations/${organizationId}/datagrid-views`);
});

export const getAllActivityFiles = (clusterId: string) => action<Promise<IDirectoryContent>>((dispatch, getState, http) => {
    return http.clustersApiPost(`/clusters/${clusterId}/documents/conversation-files`);
});

export const uploadAttachment = (body: FormData) => action<Promise<IGIAttachmentUploadResponse>>((dispatch, getState, http) => {
    return http.clustersApiPost(`/sessions/${uuidv4()}/attachments`, body);
});

export const getClustersAsPartyType = (query: string, organizationId: string) => action<Promise<ISearchResponse<IInvolvedPartyRequest>>>((dispatch, getState, http) => {
    const { language } = getState();

    return http.clustersApiGet(`/clusters/as-party-type/${organizationId}?${query ? `query=${query}&` : ``}page=0&size=20&lcid=${language.userLanguage || ELcid.En}`);
});

export const duplicateCluster = (clusterId: string, name: string, privacy: string) => action<Promise<ISingleCluster>>((dispatch, getState, http) => {
    const body = {
        name,
        privacy
    };

    return http.clustersApiPost(`/clusters/${clusterId}/duplicate`, body);
});

export const getRelatedCluster = (query: string, organizationId: string, clusterId: string) => action<Promise<ISearchResponse<IInvolvedPartyRequest>>>((dispatch, getState, http) => {
    const { language } = getState();

    return http.clustersApiPost(`/clusters-search/search?organizationId=${organizationId}&page=0&orderBy=40&size=20&lcid=${language.userLanguage || ELcid.En}`, {
        query: query,
        clusterTemplates: [],
        followed: 'Mine',
        clusterIdToBeHidden: clusterId
    });
});

export const checkResourceValidation = (contextId: string, type: string, resources: IClusterResources[]) => action<Promise<boolean>>((dispatch: Dispatch, getState: () => IState, http: Http) => {
    const body = {
        type,
        resources
    };
    return http.validationsApiPost(`/resources/${contextId}/validations/status/active`, body);
});

export const createCorporateCluster = (organizationId: string, body: ICreateCorporateCluster) => action<Promise<ISingleCluster>>((dispatch: Dispatch, getState: () => IState, http: Http) => {
    return http.clustersApiPost(`/organizations/${organizationId}/clusters/create-cle`, body);
});

export const postEApprovalForm = (organizationId: string, type: EApprovalTypes, body: IEApprovalSponsoring | IEApprovalExpenses | IEApprovalGrouping | IEApprovalFreeDeclaration) => action<Promise<ISingleCluster>>((dispatch: Dispatch, getState: () => IState, http: Http) => {
    const { language } = getState();
    return http.clustersApiPost(`/organizations/${organizationId}/clusters/create-${type}?lcid=${language.userLanguage || ELcid.En}`, body);
});

export const postPOAForm = (organizationId: string, body: IPOASendData) => action<Promise<ISingleCluster>>((_dispatch: Dispatch, _getState: () => IState, http: Http) => {
    return http.clustersApiPost(`/organizations/${organizationId}/clusters/create-poa`, body);
});

export const getCurrencyRatio = (first: string, second: string) => action<Promise<number>>((dispatch: Dispatch, getState: () => IState, http: Http) => {
    const { currencyRatio } = getState().global;
    let obj = currencyRatio || {};
    let isExistFirst = !!obj?.[first];
    let isExistSecond = !!obj?.[first]?.[second];
    if (!isExistFirst || !isExistSecond) {
        return http.clustersApiGet(`/currency-rates/${first}/to/${second}`).then((response: number) => {
            if (isExistFirst) {
                if (!isExistSecond) {
                    obj = { ...obj, [first]: { ...obj[first], [second]: response } };
                }
            } else {
                obj = { ...obj, [first]: { [second]: response } };
            }
            dispatch<IDispatch<IGlobalTypes['SET_CURRENCY_RATIO']>>({
                type: SET_CURRENCY_RATIO,
                currencyRatio: obj
            });
            return response;
        });
    }
    return new Promise((resolve) => {
        if (currencyRatio?.[first]?.[second]) {
            resolve(currencyRatio?.[first]?.[second]);
        } else {
            resolve(0);
        }
    });
});

export const getAllLinks = (organizationId: string, clusterId: string) => action<Promise<ISingleLink[]>>((dispatch: Dispatch, getState: () => IState, http: Http) => {
    return http.clustersApiGet(`/organizations/${organizationId}/clusters/${clusterId}/links?size=99999`).then(res => res.items);
});

export const createLink = (organizationId: string, clusterId: string, body: ICreateLinks) => action<Promise<ISingleLink[]>>((dispatch: Dispatch, getState: () => IState, http: Http) => {
    return http.clustersApiPost(`/organizations/${organizationId}/clusters/${clusterId}/links`, body);
});

export const updateLink = (organizationId: string, clusterId: string, linkId: string, body: ICreateLinks) => action<Promise<ISingleLink[]>>((dispatch: Dispatch, getState: () => IState, http: Http) => {
    return http.clustersApiPost(`/organizations/${organizationId}/clusters/${clusterId}/links/${linkId}`, body);
});

export const uploadLinkLogo = (organizationId: string, clusterId: string, body: FormData, callback: (e: ProgressEvent) => void) => action<Promise<{ fileId: string; fileName: string; uri: string; }>>((dispatch: Dispatch, getState: () => IState, http: Http) => {
    return http.clustersApiUpload(`/organizations/${organizationId}/clusters/${clusterId}/links/upload-picture`, body, callback);
});

export const markAsComplete = (clusterId: string, context: 'queries' | 'questions') => action<Promise<{ fileId: string; fileName: string; uri: string; }>>((dispatch: Dispatch, getState: () => IState, http: Http) => {
    return http.clustersApiPost(`/clusters/${clusterId}/${context}/complete`, {});
});

export const deleteLink = (organizationId: string, clusterId: string, linkId: string) => action<Promise<{ fileId: string; fileName: string; uri: string; }>>((dispatch: Dispatch, getState: () => IState, http: Http) => {
    return http.clustersApiDelete(`/organizations/${organizationId}/clusters/${clusterId}/links/${linkId}`);
});

export const reorderLinks = (organizationId: string, clusterId: string, body: { linkId: string, order: number }) => action<Promise<{ fileId: string; fileName: string; uri: string; }>>((dispatch: Dispatch, getState: () => IState, http: Http) => {
    return http.clustersApiPost(`/organizations/${organizationId}/clusters/${clusterId}/links/reorder`, body);
});

export const getLegalEntitiesDates = (legalEntityId: string) => action<Promise<IDates[]>>((dispatch: Dispatch, getState: () => IState, http: Http) => {
    return http.clustersApiGet(`/legal-entities/${legalEntityId}/dates`);
});

export const updateSingleStakeById = (clustedId: string, stakeId: string, body: IClusterSingleDynamicStake) => action<Promise<ISingleCluster>>((dispatch: Dispatch, getState: () => IState, http: Http) => {
    const { language } = getState();
    return http.clustersApiPut(`/clusters/${clustedId}/stakes/${stakeId}?lcid=${language.userLanguage || ELcid.Fr}`, body).then((currentClusterData: ISingleCluster) => {
        dispatch<IDispatch<actionTypes.IClusterTypes['GET_CURRENT_CLUSTER']>>({
            type: actionTypes.GET_CURRENT_CLUSTER,
            currentClusterData
        });
        return currentClusterData;
    }).catch((err: any) => {
        if (err.status === 404) {
            dispatch({
                type: actionTypes.GET_CURRENT_CLUSTER,
                currentClusterData: err.data
            });
        }
    });
});

export const generatePOA = (clusterId: string, lcid?: number) => action<Promise<ISingleCluster>>((dispatch: Dispatch, getState: () => IState, http: Http) => {
    return http.clustersApiPost(`/clusters/${clusterId}/poa/generate`);
});

interface ITableViewParentType {
    value: string;
}

export interface ITableViewSettings<T1 extends object, T2 extends object> {
    tableId: string;
    tableType: string;
    tableParams: ITableParams<T1, T2>;
    parentId: string,
    parentType: ITableViewParentType;
    dataSourceUrl: string;
    tableSettingsId?: string;
    id?: string;
    checksum?: string;
    createdBy?: string;
}

export interface ITableParams<T1 extends object, T2 extends object> {
    api: T2,
    ui: {
        filtersData: T1,
        staticSelected: IItem[]
    }
}
export interface ICreateTableViewSettingsInput<T1 extends object, T2 extends object> {
    dataSourceUrl: string;
    tableParams: ITableParams<T1, T2>,
    tableType: string;
    parentId: string;
    tableId?: string;
    tableSettingsId: string;
    parentTypeValue?: string;
}

export const createTableView = <T1 extends object, T2 extends object>(input: ICreateTableViewSettingsInput<T1, T2>): Promise<ITableViewSettings<T1, T2>> => action<Promise<ITableViewSettings<T1, T2>>>((dispatch: Dispatch, getState: () => IState, http: Http) => {
    const finalTableParams: ITableParams<T1, T2> = { ...input.tableParams };
    for (let key in finalTableParams) {
        if (finalTableParams[key]) {
            const field = finalTableParams[key];
            if (!field || (Array.isArray(field) && field.length === 0)) {
                delete finalTableParams[key];
            }
        }
    }

    const body: ITableViewSettings<T1, T2> = {
        dataSourceUrl: input.dataSourceUrl,
        tableParams: finalTableParams,
        tableType: input.tableType,
        parentId: input.parentId,
        parentType: {
            value: input.parentTypeValue
        },
        tableId: input.tableId,
        tableSettingsId: undefined // when the datagrid returns a real id, we will use input.tableSettingsId instead
    };

    return http.commonApiPost('/table-view', body);
});

export const getTableView = <T1 extends object, T2 extends object>(viewId: string) => action<Promise<ITableViewSettings<T1, T2>>>((dispatch: Dispatch, getState: () => IState, http: Http) => {
    return http.commonApiGet(`/table-view/${viewId}`);
});

export const getBeneficiaryTableWizard = (organizationId: string, data: IFormData) => action<Promise<ISingleCluster>>((dispatch: Dispatch, getState, http: Http) => {
    const body: IFormData['formData'] = {
        declaration: data.formData.declaration,
        thirdParties: data.formData.thirdParties,
        information: data.formData.information,
        message: data.formData.message,
        declarationType: data.formData.declarationType
    };

    return http.clustersApiPost(
        `/organizations/${organizationId}/cumulated-amounts/calculate-beneficiary-table`,
        body
    );
});
