import React from 'react';
import styled from 'styled-components';

import IntlMessage from '../../../Common/IntlMessage';

const Wrapper = styled.div`
    width: 100%;
`;

export const LastStep = () => {
    return (
        <Wrapper>
            <IntlMessage id="legalSupport.success"  />
        </Wrapper>
    );
};