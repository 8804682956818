import React, { FC, Fragment, useEffect, useMemo, useState } from 'react';
import { useFormikContext } from 'formik';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import { ISponsoringData } from '../../../../../entities/ActionData/ISponsoringData';
import { IClusterTemplate, ETemplateKeys } from '../../../../../entities/IClusters';
import { getClusterTemplates } from '../../../../../actions/clustersActions';
import { SelectCard } from '../../../../Common/SelectCard/SelectCard';
import IntlMessage from '../../../../Common/IntlMessage';

const Wrapper = styled.div`
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    grid-gap: 37px;
    > div {
        margin: 0 0 1rem 0;
    }
`;

type GetClusterTemplates = ReturnType<typeof getClusterTemplates>;

interface IProps {
    organizationId: string;
}

export const Step1: FC<React.PropsWithChildren<IProps>> = props => {
    const dispatch = useDispatch();
    const formikContext = useFormikContext<ISponsoringData>();
    const [clusterTemplates, setClusterTemplates] = useState<IClusterTemplate[]>([]);

    useEffect(() => {
        setClusterTemplates(undefined);
        formikContext.setFieldValue('step', 1);
        dispatch<GetClusterTemplates>(getClusterTemplates(props.organizationId))
            .then(response => {
                setClusterTemplates(response || []);
            });
    }, []);

    const areTemplatesAvailable = useMemo(() => { return clusterTemplates?.find((elem) => elem.templateKey === ETemplateKeys.Sponsoring || elem.templateKey === ETemplateKeys.Patronage); }, [clusterTemplates]);

    return (
        <Wrapper>
            {areTemplatesAvailable ?
                clusterTemplates?.map(elem =>
                    <Fragment key={elem.id}>
                        <SelectCard
                            field="formData.templateKey"
                            value={elem.templateKey}
                            imageKey={elem.templateKey}
                            name={`sponsoring.popup.step1.name.${elem.templateKey}`}
                            description={`sponsoring.popup.step1.description.${elem.templateKey}`}
                        />
                    </Fragment>
                )
            : <IntlMessage id="global.wizard.noTemplates"  />}
        </Wrapper>
    );
};
