import React, { FC, useRef, useMemo, useCallback, RefObject, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled, { css } from 'styled-components';
import { useNavigate, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTasks, faBooks, faFileSearch } from '@fortawesome/pro-light-svg-icons';

import { getSogeCollectionsUrl, getSogeSearchUrl, getNormsManagementUrl, getSogeDocUrl } from '../../../../tools/legalDocTools/sogeTools';
import { useOrganization } from '../../../../tools/organizationHooks';
import { useTocItemNumberingStyle, useNormsConfiguration } from '../../../../tools/legalDocTools/sogeHooks';
import { useSidebarTocContext } from '../../../../tools/legalDocTools/legalDocSidebarToc';
import { EMenuItem } from '../../../../entities/IMenu';
import { setCurrentVersionRealVersionId, setUniqueVersionId } from '../../../../actions/sogeActions';
import { IState } from '../../../../reducers';
import { ISogeReducer } from '../../../../reducers/sogeReducer';
import { colorStack } from '../../../../styleHelpers/colors';
import { fontSize } from '../../../../styleHelpers/fontSizes';
import { Separator } from './Separator';
import { MenuItem } from './MenuItem';
import { TreeView, ITreeExpandIcon, ITree, ITreePathItem } from '../../../Common/TreeView/TreeView';
import { EOrganizationPermissionsBase } from '../../../../entities/IPermissions';
import IntlMessage from '../../../Common/IntlMessage';

const TocItem = styled.span<{ isActive: boolean }>`
    font-size: ${fontSize[13]};
    color: ${colorStack.label};
    ${({ isActive }) => isActive && css`
        color: ${colorStack.middleBlue};
    `}
`;

const TreeWrapper = styled.div`
    overflow: auto;
    background: ${colorStack.white};
`;

const TOCTree = styled(TreeView)`
    > span {
        white-space: normal;
    }
    > div {
        margin-left: 0.1rem;
        margin-right: 0.5rem;
        > svg {
            color: ${colorStack.label};
        }
    }
`;

const OverflowWrapper = styled.div`
    display: inline-block;
`;

interface IProps {
    organizationUrlName: string;
    activeMenuItem: EMenuItem;
    withToc: boolean;
    bookCommonLevelId?: string;
}

export const NormsMenu: FC<React.PropsWithChildren<IProps>> = ({ organizationUrlName, activeMenuItem, withToc, bookCommonLevelId }) => {
    const dispatch = useDispatch();
    const history = useNavigate();
    const { currentOrganization, currentOrgPermissions } = useOrganization(organizationUrlName);
    const { sgTableOfContentsContainer, currentUniqueVersionId } = useSelector<IState, ISogeReducer>(state => state.soge);
    const sidebarTocContext = useSidebarTocContext();
    const { tocItemNumberingStyle } = useTocItemNumberingStyle();
    const treeScrollWrapper = useRef<HTMLDivElement>();
    const { organization, versionId } = useParams<{organization: string, versionId: string}>();
    const { doesLeftTocShowAllBooks, isSearchEnabled, isNormManagementEnabled } = useNormsConfiguration();

    const treeRoot = useMemo(() => {
        if (sgTableOfContentsContainer && currentUniqueVersionId) {
            if (sgTableOfContentsContainer?.[currentUniqueVersionId]?.tableOfContent) {
                return sgTableOfContentsContainer?.[currentUniqueVersionId]?.tableOfContent;
            } else {
                return sgTableOfContentsContainer?.[Object.keys(sgTableOfContentsContainer)[Object.keys(sgTableOfContentsContainer)?.length - 1]]?.tableOfContent;
            }
        }
    }, [sgTableOfContentsContainer, currentUniqueVersionId]);

    const currentBookTreeData: ITree[] = useMemo(() => {
        if (bookCommonLevelId) {
            return [(sgTableOfContentsContainer?.[currentUniqueVersionId]?.tableOfContent?.structureLevels || []).find(level => level.commonLevelId === bookCommonLevelId)];
        } else {
            return [];
        }
    }, [sgTableOfContentsContainer, currentUniqueVersionId, bookCommonLevelId]);

    const onSidebarItemClick = useCallback((commonLevelId: string) => {
        const element = document.querySelector(`[data-item-id="${commonLevelId}"]`) as HTMLElement;

        if (element) {
            const top = element.getBoundingClientRect().top + document.documentElement.scrollTop;
            typeof top === 'number' && window.scrollTo(0, top - 160);
        } else {
            history(getSogeDocUrl(organization, commonLevelId, versionId));
        }
    }, [organization, versionId]);

    useEffect(() => {
        dispatch(setUniqueVersionId(undefined));
        dispatch(setCurrentVersionRealVersionId(undefined));
    }, []);

    useEffect(() => {
        if (!withToc) {
            sidebarTocContext.expandPath = [];
            dispatch(setUniqueVersionId(currentUniqueVersionId));
        }
    }, [withToc]);

    const renderSidebarItem = useCallback((item: ITree, level: number, itemRef?: RefObject<any>, renderPath?: ITreePathItem[], isActive?: boolean) => {
        if (item?.name !== null) {
            return (
                <TocItem data-scrollto={item.commonLevelId} isActive={isActive} onClick={() => onSidebarItemClick(item.commonLevelId)}>
                    {tocItemNumberingStyle(item.name)}
                </TocItem>
            );
        }
    }, [onSidebarItemClick]);

    return (
        <>
            {isSearchEnabled && (
                <MenuItem dataLc="js-lc-button-morm-menu-search" to={getSogeSearchUrl(currentOrganization?.details?.urlName, 'current')} active={activeMenuItem === EMenuItem.searchInNorms}>
                    <IntlMessage id="menu.l3.searchInNorms"  /> <FontAwesomeIcon icon={faFileSearch} />
                </MenuItem>
            )}
            <MenuItem dataLc="js-lc-button-norm-menu-documentation" to={getSogeCollectionsUrl(currentOrganization?.details?.urlName, versionId || 'current')} active={activeMenuItem === EMenuItem.norms}>
                <IntlMessage id="menu.l3.norms"  /> <FontAwesomeIcon icon={faBooks} />
            </MenuItem>
            <Separator />
            {(withToc && treeRoot) && (
                <TreeWrapper ref={treeScrollWrapper}>
                    <OverflowWrapper>
                        <TOCTree
                            treeData={doesLeftTocShowAllBooks === true ? treeRoot?.structureLevels : (currentBookTreeData[0] !== undefined ? currentBookTreeData : [])}
                            expandIcon={ITreeExpandIcon.Arrow}
                            renderItem={renderSidebarItem}
                            forceDefaultExpandPath
                            defaultExpandPath={sidebarTocContext?.expandPath || []}
                            closeNodesOnDefaultExpandPathChange
                            levelPadding={.5}
                        />
                    </OverflowWrapper>
                </TreeWrapper>
            )}
            {(currentOrgPermissions?.[EOrganizationPermissionsBase.ManageOrganization] && isNormManagementEnabled ) && (
                <MenuItem dataLc="js-lc-button-norm-menu-management" to={getNormsManagementUrl(currentOrganization?.details?.urlName)} active={activeMenuItem === EMenuItem.normsManagement}>
                    <IntlMessage id="menu.l3.normsManagement"  /> <FontAwesomeIcon icon={faTasks} />
                </MenuItem>
            )}
        </>
    );
};
