import React, { ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import { faSearch } from '@fortawesome/pro-solid-svg-icons';

import _FilterList from '../../../Common/ColoredDatagrid/FilterList/FilterList';
import { ISingleFilter } from '../FilterList/SingleFilter';
import { getStatusColor } from '../../../../tools/statusHelper';
import { AllStatusEnums, EFilterType } from '../../../../entities/IGlobal';
import { reconcileListShallow } from '../../../../tools/generalTools';
import { FilterSelect } from './FilterSelect';
import { TextComponent } from '../../Inputs/TextComponent';
import { IItem } from '../../../../entities/IFilters';
import { removeDuplicatesByKey } from '../../../../tools/arrayTools';
import { useIntlMessage } from '../../IntlMessage';

const FilterWrapper = styled.div`
    display: flex;
    width: 100%;
    flex-direction: row;
    gap: 1rem;
    padding: 1rem 1rem 1rem 0;
    align-items: flex-start;
`;

const FilterList = styled(_FilterList)`
    margin-bottom: 1rem;
`;

const FiltersBox = styled.div`
    display: flex;
    gap: 0.5rem;
    flex-wrap: wrap;
`;

const FilterBarContainer = styled.div`
    &[hidden] { display: none; }
    display: flex;
    flex-direction: column;
`;

type Fn = (...args: any[]) => void;

const debounce = (fn: Fn, delay = 200): Fn => {
    let debouncer: ReturnType<typeof setTimeout>;
    return (...args) => {
        clearTimeout(debouncer);
        debouncer = setTimeout(() => fn(...args), delay);
    };
};

const mapFacetToFilterType: Record<string, EFilterType> = {
    UserDto: EFilterType.personal,
    ClusterSearchViewLegalEntityDto: EFilterType.company,
    date: EFilterType.date
};

export interface IMainDatatableFiltersData {
    text: string;
    id: string;
}

interface IFilterBarProps<T1, T2> {
    switch?: ReactNode;
    showFilters?: boolean;
    filteringFields?: string[];
    filtersData?: T1;
    filters?: T2;
    staticSelected: IItem[];
    blockAction: boolean;
    setStaticSelected(items: IItem[]);
    setFilterHeight?(height: number);
    setFilter?(field: string, id: string[]);
    resolveTopicFromField?(field: string): string | void;
}

export const FilterBar = <T1 extends object = Record<string, IMainDatatableFiltersData[]>, T2 = any>(props: IFilterBarProps<T1, T2>) => {
    const { intlFormatMessage } = useIntlMessage();
    const [query, setQuery] = useState<string>((props.filters as any)?.query);
    const [selectedFilter, setSelectedFilter] = useState<string | undefined>();
    const wrapperRef = useRef<HTMLDivElement>();
    const filterContainerRef = useRef<HTMLDivElement>();

    useEffect(() => {
        props.setFilterHeight && props.setFilterHeight(filterContainerRef?.current?.clientHeight);
    }, [props.showFilters, filterContainerRef?.current?.clientHeight]);

    useEffect(() => {
        const _query = (props.filters as any)?.query;
        if (_query !== query) {
            setQuery(_query);
        }
    }, [(props.filters as any)?.query]);

    const handleFilterListItemClick = (item: ISingleFilter) => {
        const topic = props.resolveTopicFromField(item.field);
        let newFilters = [];

        if (!topic) {
            return;
        }
        if (topic === 'dateRanges') {
            newFilters = props.filters[topic].filter((elem) => elem.id !== item.id);
        } else {
            newFilters = props.filters[topic].filter((id: string) => id !== item.id);
        }
        if (!props.blockAction) {
            props.setStaticSelected(props.staticSelected.filter(elem => elem.id !== item.id));
            props.setFilter(item.field, newFilters);
        }
    };

    const filterList = useMemo(() => {
        if (!props.filtersData || !props.filters) {
            return [];
        }
        const filterFields = Object.entries(props.filtersData)
            .flatMap(([key, list]) =>
                reconcileListShallow<ISingleFilter>(list).map(item => ({
                    ...item,
                    field: key,
                    bgColor: getStatusColor(item.id as AllStatusEnums)
                }))
            );

        const getProperFieldName = (field: string) => {
            if (props.resolveTopicFromField(field) !== undefined) {
                return props.resolveTopicFromField(field);
            }
            return field;
        };

        const transformedArray = [];
        for (const key in props.filters) {
            if (key) {
                const value = props.filters[key];
                if (Array.isArray(value)) {
                    value.forEach((elem) => {
                        if (typeof elem === 'object' && elem !== null && elem.id) {
                            transformedArray.push({
                                field: elem.id,
                                id: elem.id,
                                text: elem.text
                            });
                        } else {
                            transformedArray.push(transformedArray.push(filterFields.find(item => item.id === elem && getProperFieldName(item.field) === key)));
                        }
                    });
                }
            }
        }
        return transformedArray.filter(item => !!item?.id).filter(item => !!item?.text);
    }, [props.filters, props.filtersData, props.staticSelected]);

    const getSelectedItems = (fieldName) => {
        return props.filtersData?.[fieldName]?.filter?.(({ id }) => filterList.some(filter => id === filter.id && fieldName === filter.field))
            .map(filter => ({ ...filter, visible: true })) || [];
    };

    const handleClickOutside = (event) => {
        if (!wrapperRef.current?.contains(event.target)) {
            setSelectedFilter(undefined);
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, []);

    const searchQuery = useCallback(debounce((value: string) => props.setFilter('query', [value]), 800), [props.setFilter]);

    const staticSelectedElem = (item, fieldName) => {
        props.setStaticSelected(item === undefined ?
            []
            : removeDuplicatesByKey([...props.staticSelected, ...item.map(elem => ({ ...elem, field: fieldName, bgColor: getStatusColor(elem.id as AllStatusEnums) }))], staticElem => staticElem.id)
        );
    };

    return (
        <FilterBarContainer ref={filterContainerRef} hidden={!props.switch && props.showFilters === false}>
            {props.filteringFields?.length && props.filtersData ? (
                <FilterWrapper ref={wrapperRef}>
                    <FiltersBox>
                        {props.showFilters && props.filteringFields?.includes('query') && <TextComponent
                            value={query}
                            onChange={(value: string) => {
                                setQuery(value);
                                searchQuery(value);
                            }}
                            placeholder={intlFormatMessage({ id: 'filters.placeholder.search' })}
                            leftIco={faSearch}
                        />}
                        {props.showFilters && props.filteringFields?.filter(fieldName => props.filtersData?.[fieldName]?.length).map(fieldName => {
                            return <FilterSelect
                                key={fieldName}
                                type={mapFacetToFilterType[props.filtersData?.[fieldName]?.[0]?.data?.facetType] || EFilterType.default}
                                open={selectedFilter === fieldName}
                                label={intlFormatMessage({ id: `filters.map.${fieldName}` })}
                                values={props.filtersData?.[fieldName]}
                                selected={getSelectedItems(fieldName)}
                                setStaticSelected={item => staticSelectedElem(item, fieldName)}
                                onOpen={() => setSelectedFilter(fieldName)}
                                onClose={() => setSelectedFilter(undefined)}
                                onFilterChange={(ids: string[]) => props.setFilter(fieldName, ids)}
                            />;
                        })}
                    </FiltersBox>
                    {props.switch}
                </FilterWrapper>
            ) : undefined
            }
            {props.showFilters && props.staticSelected.length ? (
                <FilterList
                    disabled={props.blockAction}
                    list={props.staticSelected}
                    onClick={handleFilterListItemClick}
                />
            ) : undefined
            }
        </FilterBarContainer>
    );
};
