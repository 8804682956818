import { EAcceptanceStatus, IEnumObject, IReferencial, IUserBrief } from '../IGlobal';
import { ELcid } from '../ILanguage';
import { EMFN, ETypes, ICondition } from './global';

export interface ITextContent {
    textFormatted: string;
    textRaw: string;
}

export interface VersionDetailsComposite {
    creationDateUtc: Date;
    originalAuthorId: string;
    updateAuthorId: string;
    updateDateUtc: Date;
    versionNumber: number;
}

export interface ICreateQuestion extends ITextContent {
    teamChannelId: string;
    questionType: ETypes;
    mfnType?: EMFN;
    mainQuestionId?: string;
    questionAcceptanceStatus: EAcceptanceStatus;
    questionCategory: EQuestionCategory;
    templateType: ETemplateType;
    investorId?: string;
    threadId?: string;
    parents: {
        relatedItemId: string;
        relatedItemName: {
            label: string;
            lcid: ELcid;
        },
        relatedItemType: number;
        relatedItemSourceType: number;
        relatedItemUri: string;
    }[];
    relatedItems: IRelatedItem[];
    tagsIds: string[];
    investmentVehiclesIds?: string[];
}

export interface ICreateProvisionVersion {
    question: ICreateQuestion;
    answer: IAnswerDto;
}

export interface ISingleQuestion extends ITextContent {
    id: string;
    mainQuestionId: string;
    threadId: string;
    parentId?: string;
    versionDetails: VersionDetailsComposite;
    lastAnswerId: string;
    questionAcceptanceStatus: number;
    faqElementId: string;
    teamChannelId?: string;
    businessId?: IBusinessId;
}

export interface ICreateQueryClausier extends ITextContent {
    parents: {
        relatedItemId: string;
        relatedItemName: {
            label: string;
            lcid: ELcid;
        },
        relatedItemType: number;
        relatedItemSourceType: number;
        relatedItemUri: string;
    }[];
    questionType: ETypes;
    questionCategory: EQuestionCategory;
    templateType: ETemplateType;
    mfnType: EMFN;
    questionAcceptanceStatus: EAcceptanceStatus;
    relatedItems: IRelatedItem[];
    tagsIds: string[];
    investmentVehiclesIds?: string[];
    teamChannelId: string;
}

export interface IQueryClausier extends ITextContent {
    questionAcceptanceStatus: EAcceptanceStatus;
}

export interface ISingleQueryClausier extends ITextContent {
    id: string;
    mainQuestionId: string;
    threadId: string;
    versionDetails: string;
    lastAnswerId: string;
    questionAcceptanceStatus: number;
}

export interface ICreateRight {
    textRaw: string;
    textFormatted: string;
    investorId?: string;
    questionType?: ETypes;
    questionCategory?: EQuestionCategory;
    templateType?: ETemplateType;
    answerAcceptanceStatus?: EAcceptanceStatus;
    relatedItem?: IRelatedItem;
}

export interface IUpdateRight {
    rightAnswer: ICreateRight;
    rightMainQuestion: IQuestionDto;
}

export interface ICreateRightResponse {
    rightId: string;
    rightAnswer: IAnswerDto;
    rightMainQuestion: IQuestionDto;
}

export interface IUnsavedChangesBase {
    question: number;
    answer: number;
    subQuestions: number;
    subAnswers: number;
}

export interface IQueryDtoClausier extends IQuestionDtoBase, ITextContent {
    questionAcceptanceStatus: EAcceptanceStatus;
    conditions: ICondition[];
}

export interface IAnswerClausier extends ITextContent {
    answerAcceptanceStatus: EAcceptanceStatus;
    mainQuestionId?: string;
    conditionsIds: string[];
}

export interface IAnswerMainClausier extends IAnswerClausier {
    threadId: string | null;
    usedInIds: string[] | null;
    versionDetails: VersionDetailsComposite;
}

export interface IBusinessId {
    id: string;
    value: number;
    breadcrumb: string;
}

export interface IDataTableData {
    category: IEnumObject;
    comment: number;
    id: string;
    lastUpdatedOrCreationDate: string;
    question: string;
    references: {
        displayName: string;
        id: string;
        referenceType: 'Tag' | 'Article';
    }[];
    status: IEnumObject;
    thread: number;
}

export interface IAnswer extends ITextContent {
    mainQuestionId: string;
    conditionsIds?: string[];
    qualification?: ETypes;
    answerAcceptanceStatus?: EAcceptanceStatus;
    templateType?: ETemplateType;
    relatedItems?: IRelatedItem[];
}

export interface IAnswerDtoBase extends ITextContent {
    id: string;
    mainQuestionId: string;
    conditions: ICondition[];
    relatedItems?: IRelatedItem[];
    threadId: string;
    questionId: string;
    templateType: ETemplateType;
    previousAnswerId: string;
    versionDetails: VersionDetailsComposite;
    qualification?: ETypes;
    tags?: IReferencial[];
}

export interface IAnswerDto extends IAnswerDtoBase {
    answerAcceptanceStatus: EAcceptanceStatus;
}

export interface IQuestionDtoBase {
    id?: string;
    mainQuestionId?: string;
    threadId?: string;
    versionDetails?: VersionDetailsComposite;
    relatedItems?: IRelatedItem[];
    faqElementId?: string;
    lastAnswerId?: string;
    teamChannelId: string;
    parentId?: string;
    questionType: ETypes;
    questionCategory: EQuestionCategory;
    templateType: ETemplateType;
    tags?: IReferencial[];
    investmentVehicles?: IInvestmentVehicle[];
    mfnType?: EMFN;
    businessId?: IBusinessId;
}

export interface IRelatedItem {
    label: string;
    relatedItemIdOrCommonId: string;
    relatedItemSourceType: ERelatedItemSourceType;
    relatedItemType: ERelatedItemType;
    relatedItemUri: string;
}

export interface IQuestionDto extends ITextContent, IQuestionDtoBase {
    questionAcceptanceStatus: EAcceptanceStatus;
}

export interface IGetMainQuestion<Q, A> {
    mainAnswerDto: A;
    mainQuestionDto: Q;
    subQuestionsWithAnswers: ISubQuestionWithAnswer<Q, A>[];
}

export interface ISubQuestionWithAnswer<Q, A> {
    answerDto: A;
    orderNumber: number;
    questionDto: Q;
}

export interface IAnswerDtoClausier extends IAnswerDtoBase, ITextContent {
    answerAcceptanceStatus: EAcceptanceStatus;
    conditionsIds: string[];
    mainQuestionId: string;
}

export interface ISingleArticleElem {
    commonId: string;
    bookCommonId: string;
    documentElementType: number;
    fullName: string;
    versionId: string;
}

export interface IInvestmentVehicle {
    id?: string;
    Id?: string;
    name?: string;
    displayName?: string;
    picture: string;
}

export interface ISearchFaqToQnA {
    acceptanceStatusFilter: number[];
    direction: EDirection;
    lcid: ELcid;
    orderBy: EOrderBy;
    organizationId: string;
    parentIdFilter: string[];
    parentTypeFilter: number[];
    questionCategoryFilter: EQuestionCategory;
    questionTypeFilter: number[];
    relatedItemIdsFilter: string[];
    threadIdFilters?: string[];
    relatedItemSourceType: number[];
    relatedItemSourceUriFilter: string[];
    relatedItemTypesFilter: number[];
    searchPhrase: string;
}

export interface ISearchFaq {
  acceptanceStatusFilter?: EAcceptanceStatus[];
  comparisionConditionLhsOperand?: number;
  direction: EDirectionType;
  fromDateIncludingFilter?: string;
  hasFollowUpQuestions?: boolean;
  includeDeletedQuestions: boolean;
  investmentVehiclesIdsFilter?: string[];
  investorIdsFilter?: string[];
  lastAnswerTemplateTypeFilter?: string[];
  lastUpdatedByUserIdsFilters?: string[];
  lcid: ELcid;
  logicalOperatorForComparisionCondition?: string; // not sure
  mfnTypeFilter?: string[];
  onlyQuestionsWithAnswers?: boolean;
  orderBy: EOrderByType; // not sure
  organizationId: string;
  parentIdFilter?: string[];
  parentTypeFilter?: string[];
  questionCategoryFilter?: EQuestionCategory;
  questionTypeFilter?: string[];
  relatedItemIdsFilter?: string[];
  relatedItemSourceType?: ERelatedItemSourceType[]; // not sure
  relatedItemSourceUriFilter?: string[];
  relatedItemTypesFilter?: ERelatedItemType[]; // not sure
  scopeConditionFilters?: string[];
  searchPhrase?: string;
  templateTypeFilter?: string[];
  textConditionFilters?: string[];
  threadIdFilters?: string[];
  toDateIncludingFilter?: string;
}

export interface IGetSuggestedQuestions {
  direction: EDirectionType;
  orderBy: EOrderByType;
  includeDeletedQuestions: boolean;
  lcid: ELcid;
  onlyQuestionsWithAnswers?: boolean;
  organizationId: string;
  questionCategoryFilter?: EQuestionCategory;
  relatedItemIdsFilter?: string[];
  excludeQuestionsIds?: string[];
}

export enum EQuestionCategory {
    QuestionsAndAnswers = 'QuestionsAndAnswers',
    FrequentlyAskedQuestions = 'FrequentlyAskedQuestions',
    Rights = 'Rights',
    Clauses = 'Clauses'
}

export enum ETemplateType {
    GENERIC = 'Generic',
    LPA = 'LPA',
    SL = 'SideLetter'
}

export enum EOrderBy {
    NoOrdering = 'NoOrdering',
    AcceptanceStatus = 'AcceptanceStatus',
    QuestionType = 'QuestionType',
    UpdatedOrCreatedDate = 'UpdatedOrCreatedDate',
    Relevance = 'Relevance',
    BestUsed = 'BestUsed'
}

export enum EOrderByType {
    NoOrdering = 0,
    AcceptanceStatus = 1,
    QuestionType = 2,
    UpdatedOrCreatedDate = 3,
    Relevance = 4,
    BestUsed = 5,
    Mfn = 6,
    Theme = 7,
    Text = 8,
    Alternatives = 9,
    Investor = 10,
    CreatedDate = 11,
    ThreadId = 12,
    TemplateType = 13,
    LastAnswerTemplateType = 14,
    AnswerText = 15,
    LastEditedBy = 16,
    HasFollowUp = 17
}

const fieldOrderByMap: Record<string, keyof typeof EOrderByType> = {
    // NoOrdering // businessId ?
    status: 'AcceptanceStatus',
    category: 'QuestionType', // type ?
    lastUpdatedOrCreationDate: 'UpdatedOrCreatedDate',
    // Relevance
    bestUsed: 'BestUsed',
    mfn: 'Mfn',
    themes: 'Theme',
    relatedThemes: 'Theme',
    query: 'Text',
    question: 'Text',
    investor: 'Investor',
    // CreatedAt
    businessId: 'ThreadId',
    type: 'TemplateType',
    lastAnswerType: 'LastAnswerTemplateType',
    lastAnswer: 'AnswerText',
    hasAlternatives: 'Alternatives',
    lastEditedBy: 'LastEditedBy',
    hasFollowUp: 'HasFollowUp'
};

export const getOrderByType = (field: string) => EOrderByType[fieldOrderByMap[field]];

export const getFieldName = (order: EOrderByType) => Object.keys(fieldOrderByMap).find((field) => fieldOrderByMap[field] === EOrderByType[order]);

export enum EDirectionType {
    Ascending = 10,
    Descending = 20
}

export enum EDirection {
    Ascending = 'Ascending',
    Descending = 'Descending'
}

export interface IGetFaqForQnA {
    commentsCount: number;
    mainAnswerDto: IAnswerDto;
    mainQuestionDto: IQuestionDto;
    relevanceScore: number;
    subQuestionsCount: number;
    usedInCount: number;
}

export interface IRightsMiniDatatableEntry {
    acceptanceStatus: EAcceptanceStatus;
    businessId: number;
    id: string;
    lastAnswerText: string;
    lastEditedBy: {
        lastUpdatedOrCreationDate: string;
        lastEditedBy: IUserBrief[];
    }
    cat: {
        enumElement: string;
        translationKey: string;
        value: string;
    };
    provision: string;
    status: {
        enumElement: string;
        translationKey: string;
        value: EAcceptanceStatus;
    };
}

export enum ERelatedItemType {
    Attachment = 'Attachment',
    Article = 'Article',
    MainQuestion = 'MainQuestion'
}

export enum ERelatedItemSourceType {
    Cluster = 'Cluster',
    NormativeDocumentation = 'NormativeDocumentation',
    Provision = 'Provision'
}
