import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import { rgba } from 'polished';
import { useDispatch } from 'react-redux';

import { IRelatedRequest } from '../../../entities/ISoge';
import { useEscKey } from '../../../tools/keyboardTools';
import { colorStack } from '../../../styleHelpers/colors';
import { fontSize } from '../../../styleHelpers/fontSizes';
import { Button, EButtonTypeSchema } from '../../Common/Buttons/NewButton';
import { WhiteBox } from '../../Common/WhiteBox/WhiteBox';
import { SingleRequestor } from '../Branches/SingleRequestor';
import { getApprovalRequest } from '../../../actions/sogeActions';
import { removeDuplicatesByKey } from '../../../tools/arrayTools';
import { Loader } from '../../Common/Loader/Loader';
import IntlMessage from '../../Common/IntlMessage';

const CommitWrapper = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    background: ${rgba(colorStack.darkBlue, .1)};
    z-index: 999;
`;

const BranchSelectWrapper = styled.div`
    margin-bottom: 2rem;
`;

const ConfirmCommitModal = styled(WhiteBox)`
    max-width: 650px;
    width: 100%;
`;

const BranchesWrapper = styled.div`
    margin-bottom: 2rem;
    border: 1px solid ${colorStack.ligthGrey};
    border-radius: 4px;
    max-width: 600px;
    overflow: auto;
    box-shadow: inset 0px 1px 2px ${rgba(colorStack.black, 0.25)};
    margin-top: 0.7rem;
`;

const Buttons = styled.div`
    margin-top: -1rem;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    button + button {
        margin-left: 0.5rem;
    }
`;

const Header = styled.div`
    display: flex;
    justify-content: space-between;
    font-weight: 500;
    font-size: ${fontSize[13]};
    margin: .5rem 0 0 0;
    max-width: 590px;
    div:nth-of-type(2){
        margin: 0 0 0 2rem;
    }
`;

interface IRequestPickerProps {
    documentationId: string;
    versionId: string;
    branchPullRequestIds: string[];
    onCancel(): void;
    onRedirect?(requestId: string): void;
}

export const RelatedRequestPickerPopup: FC<React.PropsWithChildren<IRequestPickerProps>> = ({ branchPullRequestIds, documentationId, versionId, onRedirect, onCancel }) => {
    const [branchRelatedApprovals, setBranchRelatedApprovals] = useState<IRelatedRequest[]>(undefined);
    const dispatch = useDispatch();
    useEscKey(onCancel);

    useEffect(() => {
        setBranchRelatedApprovals(undefined);
        Promise.all(branchPullRequestIds.map((pullRequestId) => dispatch(getApprovalRequest(documentationId, versionId, pullRequestId))))
            .then(approvalRequest => {
                const relatedApprovalRequests = removeDuplicatesByKey(approvalRequest || [], approval => approval.id);
                setBranchRelatedApprovals((relatedApprovalRequests || []).map(approval => ({
                    name: approval.name,
                    status: approval.status,
                    branchIds: approval.sourceBranchIds,
                    createdByUser: approval.createdByUser,
                    creationDate: approval.creationDate,
                    id: approval.id
                })));
            });
    }, [branchPullRequestIds, documentationId, versionId]);

    return (
        <CommitWrapper>
            <ConfirmCommitModal>
                <Loader loading={!branchRelatedApprovals}>
                    <BranchSelectWrapper>
                        <div><IntlMessage id="tempid" /></div>
                        <Header>
                            <div><IntlMessage id="global.createdBy"/></div>
                            <div><IntlMessage id="global.requestStatus"/></div>
                            <div><IntlMessage id="global.publishedOn"/></div>
                            <div><IntlMessage id="global.action"/></div>
                        </Header>
                        <BranchesWrapper>
                            {(branchRelatedApprovals || []).map(approvalRequest => (
                                    <SingleRequestor
                                        key={approvalRequest.creationDate}
                                        requestor={approvalRequest}
                                        onRedirect={onRedirect}
                                    />
                            ))}
                        </BranchesWrapper>
                    </BranchSelectWrapper>
                    <Buttons>
                        <Button onClick={onCancel} typeSchema={EButtonTypeSchema.TERTIARY}>
                            <IntlMessage id="global.cancel" />
                        </Button>
                    </Buttons>
                </Loader>
            </ConfirmCommitModal>
        </CommitWrapper>
    );
};
