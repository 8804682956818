import moment from 'moment';
import React, { FC } from 'react';
import styled from 'styled-components';

import { colorStack } from '../../../../styleHelpers/colors';
import { useIntlMessage } from '../../IntlMessage';

const EllipsisMessage = styled.span`
    overflow: hidden;
    text-overflow: ellipsis;
`;

const DateContainer = styled.span`
    overflow: hidden;
    text-overflow: ellipsis;
    color: ${colorStack.disabled};
    font-weight: 500;
`;

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    color: ${colorStack.content};
`;

interface IProps {
    className?: string;
    translationKey: string;
    date?: string;
    text?: string;
}

export const EnumType: FC<React.PropsWithChildren<IProps>> = props => {
    const { intlFormatMessage } = useIntlMessage();
    const getText = () => {
        if (props.text) {
            return props.text;
        }
        return props.translationKey ? intlFormatMessage({ id: props.translationKey }) : '';
    };

    return (
        <EllipsisMessage className={props.className}>
            <Wrapper>
                {getText()}
                {props.date &&
                    <DateContainer>{moment(props.date).format('DD MMM YYYY HH:mm:ss')}</DateContainer>
                }
            </Wrapper>
        </EllipsisMessage>
    );
};