import React, { useEffect } from 'react';
import styled, { css } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faCaretUp } from '@fortawesome/pro-solid-svg-icons';

import { colorStack } from '../../../styleHelpers/colors';
import { fontSizeAndHeight } from '../../../styleHelpers/fontSizes';
import { MultilineTextWithEllipsis } from '../TextWithEllipsis/MultilineTextWithEllipsis';
import { useDropdown } from '../../../tools/hooks';
import { SearchMenu } from '../SearchMenu/SearchMenu';
import { IValue } from '../../../entities/IPickers';
import { EContextList, EReferentials } from '../../../entities/IGlobal';
import { ContextMenu, ISingleLink } from '../ContextMenu/ContextMenu';
import { StatusMenu } from '../StatusMenu/StatusMenu';
import { DefaultMenu } from './DefauleView';
import IntlMessage from '../IntlMessage';

const Wrapper = styled.div`
    cursor: pointer;
    position: relative;
`;

const SelectedBox = styled.div`
    display: flex;
    align-items: center;
    svg {
        color: ${colorStack.darkGrey};
        width: 1rem;
        margin-left: 20px;
    }
`;

const Selected = styled.span<{ textColor: string, withSeparator?: boolean }>`
    ${fontSizeAndHeight[16]};
    color: ${props => props.textColor};
    width: calc(100% - 28px);
    position: relative;
    ${props => props.withSeparator && css`
        &::after {
            position: absolute;
            width: 16px;
            height: 16px;
            top: 0;
            right: -20px;
            content: '|';
            color: ${colorStack.ligthGrey};
        }
    `}
    span {
        ${fontSizeAndHeight[16]};
    }
`;

const ListBox = styled.div<{ alignRight?: boolean }>`
    > div {
        top: 30px;
        left: -.5rem;
        ${props => props.alignRight && css`
            width: auto;
            top: 33px;
            left: calc(-200% + 28px);
        `}
    }
`;

export enum EDropDownType {
    SEARCH = 'SEARCH',
    MENU = 'MENU',
    STATUS = 'STATUS',
    DEFAULT = 'DEFAULT'
}

export interface IDropDownProps {
    type: EDropDownType,
    loading?: boolean;
    options?: IValue[];
    refsOptions?: {
        context: EContextList;
        type: EReferentials;
    };
    disabled?: boolean;
    links?: ISingleLink[];
    value: IValue[];
    alignRight?: boolean;
    withSeparator?: boolean;
    triggerOpenDropdown?: boolean;
    closeDropdown?: boolean;
    resetTrigger?: () => void;
    onChange?(item: IValue);
}

export const DropDown = (props: IDropDownProps) => {
    const [wrapperRef, dropdownOpen, toggleDropdown, closeDropdown] = useDropdown<HTMLDivElement>();

    useEffect(() => {
        if (props.closeDropdown) {
            closeDropdown();
        }
    }, [props.closeDropdown]);

    useEffect(() => {
        if (props.triggerOpenDropdown) {
            closeDropdown();
            setTimeout(() => {
                toggleDropdown();
                props.resetTrigger?.();
            }, 100);
        }

    }, [props.triggerOpenDropdown]);

    const changeHandler = (item: IValue) => {
        setTimeout(() => {
            toggleDropdown();
            props.resetTrigger?.();
            props.onChange(item);
        }, 100);
    };

    return (
        <Wrapper data-lc="js-lc-drop-down" ref={wrapperRef}>
            <SelectedBox onClick={props.disabled ? undefined : toggleDropdown}>
                <Selected textColor={props.value?.[0]?.data?.color || colorStack.content} withSeparator={props.withSeparator}>
                    <MultilineTextWithEllipsis numberOfLines={1} noTooltip>
                        {props.value?.[0]?.text ? (
                            <span>{props.value?.[0]?.text}</span>
                        ) : (
                            <IntlMessage id={props.value?.[0] ? (props.value?.[0]?.text || 'global.missingTranslation') : 'global.select'} />
                        )}
                    </MultilineTextWithEllipsis>
                </Selected>
                {/* tslint:disable-next-line:no-null-keyword */}
                {props.options?.length > 1 ? <FontAwesomeIcon icon={dropdownOpen ? faCaretUp : faCaretDown} /> : null}
            </SelectedBox>
            {dropdownOpen &&
                <ListBox alignRight={props.alignRight}>
                    {props.type === EDropDownType.SEARCH &&
                        <SearchMenu
                            loading={props.loading}
                            options={props.options}
                            refsOptions={props.refsOptions}
                            value={props.value}
                            onChange={changeHandler}
                        />
                    }
                    {(props.type === EDropDownType.MENU && props.links) &&
                        <ContextMenu
                            links={props.links}
                        />
                    }
                    {props.type === EDropDownType.STATUS &&
                        <StatusMenu
                            options={props.options}
                            value={props.value}
                            onChange={changeHandler}
                        />
                    }
                    {props.type === EDropDownType.DEFAULT &&
                        <DefaultMenu
                            options={props.options}
                            value={props.value}
                            onChange={changeHandler}
                        />
                    }
                </ListBox>
            }
        </Wrapper>
    );
};
