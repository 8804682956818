import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import jwtDecode from 'jwt-decode';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

import { GlobalLoader } from '../GlobalLoader';
import {
    AuthState,
    B2CError,
    B2CToken,
    EAuthConstants,
    EMsalErrorCode,
    getHashParameters
} from '../../tools/authTools';
import { getWithExpiry, localStorage } from '../../tools/storage';
import { setLoggedInUser } from '../../actions/contextActions';
import { IState } from '../../reducers';
import { IConfigReducer } from '../../reducers/configReducer';
import { fetchB2CToken } from '../../actions/actionTypes/authActions';
import { getSessionWithExpiry } from '../../tools/sessionStorage';

const Wrapper = styled.div`
    padding: 1rem;
`;

export const AuthEnd: FC<React.PropsWithChildren<unknown>> = () => {
    const dispatch = useDispatch();
    const history = useNavigate();
    const [errorMsg, setErrorMsg] = useState<string>(undefined);
    const { appConfig: { applicationConfig } } = useSelector<IState, IConfigReducer>(state => state.config);

    const authSuccessCallback = (accessToken: string, idToken: string, nextRequestUrl: string, refresh_token) => {
        localStorage.setItem(EAuthConstants.IDKey, idToken);

        const tokenData = jwtDecode(idToken);
        dispatch(setLoggedInUser(accessToken, tokenData.oid, tokenData.exp, {
            email: tokenData?.email,
            displayName: tokenData?.name,
            department: tokenData?.department,
            userOrigin: tokenData?.user_origin
        }, refresh_token));
        history(decodeURIComponent(nextRequestUrl || '') || '/');
    };

    useEffect(() => {
        const response = getHashParameters(window.location.hash);
        if (response.error) {
            const errorDesc = response.error_description;

            if (errorDesc.match(EMsalErrorCode.PasswordResetRequested)) {
                history('/reset-password');
            } else if (errorDesc.match(EMsalErrorCode.PasswordResetCancelled)) {
                history('/login');
            } else {
                setErrorMsg(errorDesc);
            }
        } else if (response.code) {
            const expectedStateCsrf = getWithExpiry(EAuthConstants.CSRFState, true);

            try {
                const state: AuthState = JSON.parse(atob(response.state));

                if (expectedStateCsrf !== state.csrf) {
                    setErrorMsg('We seem to have a minor issue. This often happens when opening too many tabs at the same time.  Going back and clicking again on the links should fix it.');
                } else {
                    fetchB2CToken(response.code, getSessionWithExpiry(EAuthConstants.CodeChallenge), applicationConfig).then((tokenResponse: Response) => {
                        if (tokenResponse.ok) {
                            tokenResponse.json().then((tokenData: B2CToken) => {
                                if (tokenData.id_token && tokenData.access_token) {
                                    authSuccessCallback(tokenData.access_token, tokenData.id_token, state.nextRequestUrl, tokenData.refresh_token);
                                } else {
                                    setErrorMsg('No id_token or access_token');
                                }
                            });
                        } else {
                            tokenResponse.json().then((tokenErrorData: B2CError) => {
                                setErrorMsg(`Unable to get token from B2C: ${tokenErrorData.error_description}`);
                            });
                        }
                    });
                }
            } catch (e) {
                setErrorMsg(`Unable to parse state: ${e}`);
            }
        } else {
            setErrorMsg('Unexpected failure');
        }
    }, []);

    return errorMsg ? (
        <Wrapper>
            <p>{errorMsg}</p>
        </Wrapper>
    ) : (
        <GlobalLoader isLoading />
    );
};