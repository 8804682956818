import React, { FC } from 'react';
import styled from 'styled-components';

import { colorStack } from '../../../styleHelpers/colors';
import { fontSizeAndHeight } from '../../../styleHelpers/fontSizes';

const Wrapper = styled.div`
    ${fontSizeAndHeight[13]}
    h1 {
        ${fontSizeAndHeight[25]}
        font-weight: 400;
        padding: 0;
        color: ${colorStack.MSOfficeHeadingBlue};
        font-family: Arial;
        margin: 16px 0 8px 0;
    }
    h2 {
        ${fontSizeAndHeight[20]}
        font-weight: 400;

        span::after {
            content: none;
        }
        color: ${colorStack.MSOfficeHeadingBlue};
        font-family: Arial;
        margin: 16px 0 8px 0;
    }
    h3 {
        ${fontSizeAndHeight[16]}
        font-weight: 500;
        color: ${colorStack.MSOfficeHeadingBlue};
        font-family: Arial;
        margin: 16px 0 8px 0;
    }
    p {
        text-align: left;
        font-weight: 400;
        font-size: inherit;
        line-height: 1;
        color: ${colorStack.content};
        word-wrap: break-word;
        margin-bottom: 10px;
    }
    ol, ul {
        padding-left: 3rem;
        font-size: inherit;
        line-height: 1;
    }
    ol {
        list-style: decimal;
        font-size: inherit;
        line-height: 1;
    }
    ul {
        list-style: disc;
        font-size: inherit;
        line-height: 1;
    }
    li {
        margin-bottom: 10px;
    }
    span {
        font-size: inherit;
        line-height: 1;
    }

    a {
        color: ${colorStack.middleBlue}!important;
        font-weight: 400;
        line-height: 1;
    }
`;

interface IProps {
    content: string;
}

export const NewRtView: FC<React.PropsWithChildren<IProps>> = props => {
    return (
        <Wrapper dangerouslySetInnerHTML={{ __html: props.content }} />
    );
};