import React, { useCallback, RefObject, ReactNode, FC } from 'react';
import styled, { css } from 'styled-components';
import { faList } from '@fortawesome/pro-light-svg-icons';

import { ITableOfContentsData, ITableOfContentsNode } from '../../../entities/ISoge';
import { SgAlignTo } from '../../../entities/LegalDoc/ISogeStyles';
import { useDropdown } from '../../../tools/hooks';
import { getSogeCollectionsUrl, getSogeDocUrl } from '../../../tools/legalDocTools/sogeTools';
import { media } from '../../../styleHelpers/breakpoint';
import { colorStack } from '../../../styleHelpers/colors';
import { Button, EButtonTypeSchema } from '../../Common/Buttons/NewButton';
import { Loader } from '../../Common/Loader/Loader';
import { TreeView, ITreeExpandIcon, ITreePathItem } from '../../Common/TreeView/TreeView';
import { SgTocItem } from './TocComponents';
import IntlMessage, { useIntlMessage } from '../../Common/IntlMessage';

const DropdownWrapper = styled.div`
    position: relative;
`;

const Dropdown = styled.div<{ alignTo?: SgAlignTo, maxWidthPx?: number }>`
    position: absolute;
    background: ${colorStack.white};
    border: 1px solid ${colorStack.ligthGrey};
    border-radius: 3px;
    ${({ maxWidthPx }) => maxWidthPx === undefined ? css`
        width: 90vw;
    ` : css`
        width: ${maxWidthPx}px;
        max-width: ${maxWidthPx}px;
    `}
    height: 40vh;
    z-index: 10;
    display: flex;
    flex-direction: column;
    ${media.desktop`
        width: 65vw;
    `}
    ${({ alignTo }) => alignTo === 'left' ? css`
        left: 0;
    ` : css`
        right: 0;
    `}
`;

const TocCloseDropdown = styled.button`
    outline: 0;
    cursor: pointer;
    margin: 0.5rem 0.5rem 0 auto;
    align-self: flex-start;
`;

const TocTreeWrapper = styled.div<{isLoading?: boolean}>`
    transition: all .3s ease;
    max-height: 75vh;
    overflow: auto;
    position: relative;
    ${props => props.isLoading && css`
        max-height: 30vh;
        >div {
            margin: 1.5rem 0 3rem 0;
        }
    `}
`;

const DropdownBtn = styled(Button)`
    border-width: 0;
    span {
        display: none;
    }
    svg {
        transform: translateX(5px);
    }
    ${media.tablet`
        border-width: 1px;
        svg {
            transform: none;
        }
        span {
            display: inline;
        }
    `}
`;

interface IProps {
    showLoader?: boolean;
    tableOfContents: ITableOfContentsData;
    versionId: string;
    currentStructureId?: string;
    organizationUrlName: string;
    className?: string;
    alignTo?: SgAlignTo;
    readElementLabel?: ReactNode;
    noRedirect?: boolean;
    buttonTranslationId?: string;
    maxWidthPx?: number;
    keepOpenOnSelect?: boolean;
    customDropdownButton?: ReactNode;
    onElementClick?(node: ITableOfContentsNode, renderPath: ITreePathItem[]);
}

export const TocDropdown: FC<React.PropsWithChildren<IProps>> = ({ showLoader, tableOfContents, versionId, currentStructureId, organizationUrlName, className, alignTo, readElementLabel, noRedirect, onElementClick, buttonTranslationId, maxWidthPx, keepOpenOnSelect, customDropdownButton }) => {
    const [tocWrapperRef, tocDropdownOpen, tocToggle, tocCloseDropdown] = useDropdown();
    const { intlFormatMessage } = useIntlMessage();
    const tocTreeData  = !!tableOfContents?.structureLevels?.length && [{
        name: intlFormatMessage({ id: 'norms.tableOfContents' }),
        id: 'root',
        children: tableOfContents.structureLevels
    }];

    const onClick = useCallback((item: ITableOfContentsNode, renderPath: ITreePathItem[]) => {
        onElementClick?.(item, renderPath);
        if (!keepOpenOnSelect) {
            tocCloseDropdown();
        }
    }, [onElementClick, tocCloseDropdown]);

    const renderTocItem = useCallback((item: ITableOfContentsNode, level: number, activeItemRef: RefObject<HTMLAnchorElement>, renderPath: ITreePathItem[], isActive?: boolean, expandChildren?: () => void, hasChildren?: boolean) => {
        const url = level === 0
            ? getSogeCollectionsUrl(organizationUrlName)
            : getSogeDocUrl(organizationUrlName, item.commonLevelId, tableOfContents.versionId);

        return (
            <SgTocItem
                hasChildren={hasChildren}
                item={item}
                url={url}
                renderPath={renderPath}
                expandChildren={expandChildren}
                onReadClick={() => onClick(item, renderPath)}
                readElementLabel={readElementLabel}
                noRedirect={noRedirect}
                disableReadButton={level === 0}
            />
        );
    }, [versionId, currentStructureId, organizationUrlName, tableOfContents, readElementLabel, noRedirect, tocCloseDropdown, onClick]);

    return (
        <DropdownWrapper ref={tocWrapperRef} className={className || ''}>
            {customDropdownButton ? (
                <div onClick={tocToggle}>{customDropdownButton}</div>
             ) : (
                <DropdownBtn data-lc="js-lc-button-select-element-contents" typeSchema={EButtonTypeSchema.TERTIARY} onClick={tocToggle} leftIco={faList} disabled={!tableOfContents}>
                    <IntlMessage id={buttonTranslationId || 'norms.button.contents'} />
                </DropdownBtn>
            )}
            {tocDropdownOpen && (
                <Dropdown alignTo={alignTo} maxWidthPx={maxWidthPx}>
                    <TocCloseDropdown onClick={tocCloseDropdown} type="button">
                        <img src={require('../../../../wwwroot/assets/images/svgicons/x.svg')} />
                    </TocCloseDropdown>
                    <TocTreeWrapper isLoading={showLoader}>
                        {showLoader ? (
                            <Loader loading={showLoader} label={<IntlMessage id="loaders.contents"/>}/>
                        ) : (
                            <TreeView
                                expandIcon={ITreeExpandIcon.Arrow}
                                treeData={tocTreeData || []}
                                renderItem={renderTocItem}
                                defaultExpandPath={['root']}
                            />
                        )}
                    </TocTreeWrapper>
                </Dropdown>
            )}
        </DropdownWrapper>
    );
};
