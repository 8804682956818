import { ISingleChannelMessage } from '../IClusters';
import { ELcid } from '../ILanguage';
import { IAnswerDto, IGetFaqForQnA, IGetMainQuestion, IQuestionDto, IUnsavedChangesBase } from './actions';
import { INegotiationData } from './negotiations';

export enum ETypes {
    New = 'New',
    Standard = 'Standard',
    StandardNew = 'StandardNew'
}

export enum EMFN {
    All = 'All',
    Conditional = 'Conditional',
    Specific = 'Specific'
}

export enum EPanelContextName {
    FAQ = 'FAQ',
    QNA = 'Q&A',
    SL = 'SL',
    OrganizationClausier = 'OrganizationClausier'
}

export enum EAssetManagementBaseType {
    MAIN_QUESTION = 'MainQuestion',
    SUB_QUESTION = 'SubQuestion',
    ANSWER = 'Answer',
    PROVISION = 'Provision'
}

export type OptionsCurrentTab = 'Q&A' | 'LPA' | 'FAQ' | 'Right' | 'Clausier' | 'OrganizationClausier';

export type TabName = 'Q&A' | 'FAQ' | 'OrganizationClausier' | 'LPA' | 'Right' | 'Clausier';
export type ContextName = EPanelContextName;
export type PanelContextStandard = IPanelContext<IQuestionDto, IAnswerDto, IUnsavedChangesBase>;
export type AnyPanelContext = PanelContextStandard;

export type PanelHeaderTopic = 'generic' | 'questionAcceptanceStatus' | 'questionType';

export type ExportType = 'ExportInvestorExchange' | 'ExportInternalExchange' | 'ExportAll';
export interface IPanelHeaderUpdateInfo {
    topic?: PanelHeaderTopic;
    shouldUpdate: boolean;
}

export interface ProxyHistoryParams {
    search: string;
    doneCallback?: () => void;
}

export interface IPanelContext<Q, A, U = void> {
    currentTab: OptionsCurrentTab;
    context: ContextName;
    mainData: IGetMainQuestion<Q, A>;
    negotiationData?: INegotiationData;
    showComments: boolean;
    showPanel: boolean;
    clusterId: string;
    loading?: boolean;
    unsavedChanges?: U;
    faqContent?: IGetFaqForQnA[];
    editorOpened: string[];
    messages: ISingleChannelMessage[];
    headerUpdate: IPanelHeaderUpdateInfo;
    setNegotiationData?: React.Dispatch<React.SetStateAction<INegotiationData>>;
    historyWithWarnings?(historyParams: ProxyHistoryParams);
    setHeaderUpdate(info: IPanelHeaderUpdateInfo);
    setMessages(messages: ISingleChannelMessage[]);
    setEditorOpened(state: string[]);
    setFaqContent?(faqContent: IGetFaqForQnA[]);
    setMainData(mainData: IGetMainQuestion<Q, A>);
    setLoading?(loading: boolean);
    setUnsavedChanges?(unsavedChanges: U);
    setShowPanel(state: boolean);
    setShowComments(state: boolean);
    setCurrentTab(item: OptionsCurrentTab);
    setClusterId(clusterId: string);
}

export enum ESortTypes {
    Ascending = 'Ascending',
    Descending = 'Descending',
    None = 'None'
}

export enum EConditionType {
    Text = 'Text',
    Comparision = 'Comparision',
    Scoped = 'Scoped'
}

export enum EConditionScopeType {
    Organization = 'Organization',
    Investor = 'Investor',
    InvestmentVehicle = 'InvestmentVehicle'
}

export interface ICondition {
    displayNames: {
        label: string;
        lcid: ELcid;
    }[];
    conditionType: EConditionType;
    id?: string;
    Id?: string;
    operator: string;
    rhsOperand: number;
    scope: {
        scopeId: string;
        scopeType: EConditionScopeType | string;
    };
}
