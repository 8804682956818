import React, { FC, useCallback, useEffect } from 'react';
import { useFormikContext } from 'formik';
import styled from 'styled-components';

import { IFormData } from '../../../../entities/IDeclaration';
import { fontSize } from '../../../../styleHelpers/fontSizes';
import { colorStack } from '../../../../styleHelpers/colors';
import { Checkbox } from '../../../Common/Inputs/Checkbox';
import IntlMessage, { useIntlMessage } from '../../../Common/IntlMessage';

const CBContainer = styled.div`
    margin-top: 1rem;
`;

const SWrapper = styled.div`
    color: ${colorStack.content};
    font-size: ${fontSize[16]};
    display: flex;
    flex-direction: column;
    margin: 0 2rem 0 2rem;
    ul {
        list-style-type: disc;
        li {
            margin: 1rem 1.6rem 0 1.6rem;

        }
        margin-bottom: 1rem;
    }
    span {
        margin: .5rem;
        & a {
            color: ${colorStack.middleBlue};
        }
        & span {
            margin: 0;
        }
    }
    ${CBContainer} > div > label > div {
        font-size: ${fontSize[16]};
        padding-top: 0;
    }
    img {
        width: 100%;
    }
`;

export const Principles: FC<React.PropsWithChildren<unknown>> = () => {
    const { intlFormatMessage } = useIntlMessage();
    const { setFieldValue, values } = useFormikContext<IFormData>();

    useEffect(() => {
        setFieldValue('step', 2);
    }, []);

    const changeCheckbox = useCallback((checked: boolean) => {
        setFieldValue('readPolicy', checked);
    }, []);

    return (
        <SWrapper>
            {values.formData.declarationType === 'gift' ? (
                <>
                    <span dangerouslySetInnerHTML={{
                        __html: intlFormatMessage({ id: 'giftinvitation.principles.gift.first' })
                    }} />
                    <span><IntlMessage id="giftinvitation.principles.gift.second" /></span>
                    <span dangerouslySetInnerHTML={{
                        __html: intlFormatMessage({ id: 'giftinvitation.principles.gift.third' })
                    }} />
                    <span dangerouslySetInnerHTML={{
                        __html: intlFormatMessage({ id: 'giftinvitation.principles.gift.lastPar' })
                    }} />
                    <CBContainer>
                        <Checkbox onChange={changeCheckbox} checked={values.readPolicy} label={intlFormatMessage({ id: 'giftinvitation.principles.gift.policyCheckbox' })} />
                    </CBContainer>
                </>
            ) : (
                <>
                    <span dangerouslySetInnerHTML={{
                        __html: intlFormatMessage({ id: 'giftinvitation.principles.invitation.first' })
                    }} />
                    <span><IntlMessage id="giftinvitation.principles.invitation.second" /></span>
                    <span dangerouslySetInnerHTML={{
                        __html: intlFormatMessage({ id: 'giftinvitation.principles.invitation.third' })
                    }} />
                    <span dangerouslySetInnerHTML={{
                        __html: intlFormatMessage({ id: 'giftinvitation.principles.invitation.lastPar' })
                    }} />
                    <CBContainer>
                        <Checkbox onChange={changeCheckbox} checked={values.readPolicy} label={intlFormatMessage({ id: 'giftinvitation.principles.gift.policyCheckbox' })} />
                    </CBContainer>
                </>
            )}
        </SWrapper>
    );
};
