import React, { FC, useCallback, useEffect } from 'react';
import styled from 'styled-components';
import { useFormikContext } from 'formik';

import { ISIngleLegalEntity } from '../../../../entities/ILegalEntities';
import { ICreateCorporateCluster } from '../../../../entities/IClusters';
import { LegalEntitiesPicker } from '../../../Common/Pickers/LegalEntitiesPicker';
import { useIntlMessage } from '../../../Common/IntlMessage';

const Wrapper = styled.div`
    width: 100%;
`;

export const Step1: FC<React.PropsWithChildren<unknown>> = () => {
    const { intlFormatMessage } = useIntlMessage();
    const formikContext = useFormikContext<ICreateCorporateCluster>();

    useEffect(() => {
        formikContext.setFieldValue('step', 1);
    }, []);

    const changeSearchValue = useCallback((value: { key: string; text: string; data: ISIngleLegalEntity; }[]) => {
        if (value.length > 0) {
            formikContext.setFieldValue('legalEntity', {
                name: value?.[0].data?.name,
                picture: value?.[0].data?.picture,
                presentation: value?.[0].data?.presentation,
                address: {
                    number: value?.[0].data?.address?.number,
                    street: value?.[0].data?.address?.street,
                    city: value?.[0].data?.address?.city,
                    state: value?.[0].data?.address?.state,
                    zipCode: value?.[0].data?.address?.zipCode,
                    country: value?.[0].data?.address?.country,
                    countryShort: value?.[0].data?.address?.CountryShort
                },
                id: value?.[0].data?.id,
                type: value?.[0].data.type
            });
        } else {
            formikContext.setFieldValue('legalEntity', undefined);
        }
    }, [formikContext]);

    return (
        <Wrapper>
            <LegalEntitiesPicker
                value={formikContext.values.legalEntity ? [{key: formikContext.values.legalEntity.id, text: formikContext.values.legalEntity.name, data: formikContext.values.legalEntity}] : undefined}
                label={intlFormatMessage({ id: 'corporate.popup.step1.label' })}
                required
                allCompanies
                onSelectElement={changeSearchValue}
            />
        </Wrapper>
    );
};