import React, { FC, useContext, useEffect } from 'react';
import styled from 'styled-components';

import { EApprovalContext, EApprovalTypes, IEApprovalContext, IMainStepProps } from '..';
import { Row } from '../../../../Common/Row/Row';
import { LegalEntitiesPicker } from '../../../../Common/Pickers/LegalEntitiesPicker';
import { EProfileType } from '../../../../../entities/IGlobal';
import { IValue } from '../../../../../entities/IPickers';
import { RadioButton } from '../../../../Common/Inputs/RadioButton';
import { LabelWithEllipsis } from '../../../../Common/Label/Label';
import { StaticDataPicker } from '../../../../Common/Pickers/StaticDataPicker';
import { colorStack } from '../../../../../styleHelpers/colors';
import { fontSizeAndHeight } from '../../../../../styleHelpers/fontSizes';
import IntlMessage, { useIntlMessage } from '../../../../Common/IntlMessage';

const Wrapper = styled.div`
    width: 100%;
    > div {
        margin-bottom: 1rem;
    }
    > :last-child {
        margin: 0;
    }
`;

const RadioInputSection = styled.div`
    display: flex;
    label {
        margin-right: 29px;
    }
`;

const LabelWithIconWrapper = styled.div`
    display: flex;
`;

const Paragraph = styled.p`
    margin: 0;
    color: ${colorStack.content};
    ${fontSizeAndHeight[16]};
    text-align: left;
`;

export const GroupingStep3: FC<IMainStepProps> = props => {
    const { intlFormatMessage } = useIntlMessage();
    const eApprovalContext = useContext<IEApprovalContext>(EApprovalContext);

    useEffect(() => {
        eApprovalContext.modifyData(undefined, undefined, props.context, props.stepName, props.stepNumber);
    }, []);

    const onLegalEntityMultiChange = (field: string, value: IValue[]) => {
        eApprovalContext.modifyData(value?.map(elem => elem.data), field, props.context, props.stepName, props.stepNumber);
    };

    const onBooleanChange = (field: string, value: boolean) => {
        eApprovalContext.modifyData(value, field, props.context, props.stepName, props.stepNumber);
    };

    return (
        <Wrapper>
            <Row numberOfElements={1} noBorder>
                <LegalEntitiesPicker
                    label={intlFormatMessage({ id: `eApproval.wizard.step3.${[EApprovalTypes.Grouping]}.companyPartners` })}
                    onSelectElementMulti={(value) => onLegalEntityMultiChange('companyPartners', value)}
                    value={(eApprovalContext.data?.[EApprovalTypes.Grouping]?.[props.stepName]?.companyPartners || [])?.map(legalEntity => ({
                        key: legalEntity?.id,
                        text: legalEntity?.name,
                        data: {...legalEntity, type: EProfileType.LegalEntity}
                    }))}
                    multiSelect
                    allCompanies
                    required
                    orgId={eApprovalContext.orgId}
                />
            </Row>
            <Row numberOfElements={1} noBorder>
                <div>
                    <LabelWithIconWrapper>
                        <LabelWithEllipsis required numberOfLines={3}>
                            <IntlMessage id={`eApproval.wizard.step3.${[EApprovalTypes.Grouping]}.inEconomicalGrouping`} />
                        </LabelWithEllipsis>
                    </LabelWithIconWrapper>
                    <RadioInputSection>
                        <RadioButton
                            label={intlFormatMessage({ id: 'global.yes' })}
                            checked={eApprovalContext.data?.[EApprovalTypes.Grouping]?.[props.stepName]?.inEconomicalGrouping === true}
                            onClick={() => onBooleanChange('inEconomicalGrouping', true)}
                        />
                        <RadioButton
                            label={intlFormatMessage({ id: 'global.no' })}
                            checked={eApprovalContext.data?.[EApprovalTypes.Grouping]?.[props.stepName]?.inEconomicalGrouping === false}
                            onClick={() => onBooleanChange('inEconomicalGrouping', false)}
                        />
                    </RadioInputSection>
                </div>
            </Row>
            {eApprovalContext.data?.[EApprovalTypes.Grouping]?.[props.stepName]?.inEconomicalGrouping === true &&
                <Row numberOfElements={1} noBorder>
                    <StaticDataPicker
                        label={intlFormatMessage({ id: `eApproval.wizard.step3.${[EApprovalTypes.Grouping]}.inEconomicalGroupingLegalEntites` })}
                        onSelectElementMulti={(value) => onLegalEntityMultiChange('inEconomicalGroupingLegalEntites', value)}
                        value={(eApprovalContext.data?.[EApprovalTypes.Grouping]?.[props.stepName]?.inEconomicalGroupingLegalEntites || [])?.map(legalEntity => ({
                            key: legalEntity?.id,
                            text: legalEntity?.name,
                            data: {...legalEntity, type: EProfileType.LegalEntity}
                        }))}
                        staticData={(eApprovalContext.data?.[EApprovalTypes.Grouping]?.[props.stepName]?.companyPartners || [])?.map(elem => ({
                            key: elem.id,
                            data: elem,
                            text: elem.name
                        }))}
                        multiSelect
                        required
                    />
                </Row>
            }
            <Row numberOfElements={1} noBorder>
                <div>
                    <LabelWithIconWrapper>
                        <LabelWithEllipsis required numberOfLines={3}>
                            <IntlMessage id={`eApproval.wizard.step3.${[EApprovalTypes.Grouping]}.isCompeting`} />
                        </LabelWithEllipsis>
                    </LabelWithIconWrapper>
                    <RadioInputSection>
                        <RadioButton
                            label={intlFormatMessage({ id: 'global.yes' })}
                            checked={eApprovalContext.data?.[EApprovalTypes.Grouping]?.[props.stepName]?.isCompeting === true}
                            onClick={() => onBooleanChange('isCompeting', true)}
                        />
                        <RadioButton
                            label={intlFormatMessage({ id: 'global.no' })}
                            checked={eApprovalContext.data?.[EApprovalTypes.Grouping]?.[props.stepName]?.isCompeting === false}
                            onClick={() => onBooleanChange('isCompeting', false)}
                        />
                    </RadioInputSection>
                </div>
            </Row>
            <Row numberOfElements={1} noBorder>
                <Paragraph>
                    <IntlMessage id={`eApproval.wizard.step3.${[EApprovalTypes.Grouping]}.isBreakdown`} />
                </Paragraph>
            </Row>
            <Row numberOfElements={1} noBorder>
                <div>
                    <LabelWithIconWrapper>
                        <LabelWithEllipsis required numberOfLines={3}>
                            <IntlMessage id={`eApproval.wizard.step3.${[EApprovalTypes.Grouping]}.byTechnicalLot`} />
                        </LabelWithEllipsis>
                    </LabelWithIconWrapper>
                    <RadioInputSection>
                        <RadioButton
                            label={intlFormatMessage({ id: 'global.yes' })}
                            checked={eApprovalContext.data?.[EApprovalTypes.Grouping]?.[props.stepName]?.isBreakdown === true}
                            onClick={() => onBooleanChange('isBreakdown', true)}
                        />
                        <RadioButton
                            label={intlFormatMessage({ id: 'global.no' })}
                            checked={eApprovalContext.data?.[EApprovalTypes.Grouping]?.[props.stepName]?.isBreakdown === false}
                            onClick={() => onBooleanChange('isBreakdown', false)}
                        />
                    </RadioInputSection>
                </div>
            </Row>
            <Row numberOfElements={1} noBorder>
                <div>
                    <LabelWithIconWrapper>
                        <LabelWithEllipsis required numberOfLines={3}>
                            <IntlMessage id={`eApproval.wizard.step3.${[EApprovalTypes.Grouping]}.byGeograficalPresence`} />
                        </LabelWithEllipsis>
                    </LabelWithIconWrapper>
                    <RadioInputSection>
                        <RadioButton
                            label={intlFormatMessage({ id: 'global.yes' })}
                            checked={eApprovalContext.data?.[EApprovalTypes.Grouping]?.[props.stepName]?.byGeograficalPresence === true}
                            onClick={() => onBooleanChange('byGeograficalPresence', true)}
                        />
                        <RadioButton
                            label={intlFormatMessage({ id: 'global.no' })}
                            checked={eApprovalContext.data?.[EApprovalTypes.Grouping]?.[props.stepName]?.byGeograficalPresence === false}
                            onClick={() => onBooleanChange('byGeograficalPresence', false)}
                        />
                    </RadioInputSection>
                </div>
            </Row>
        </Wrapper>
    );
};
