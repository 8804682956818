import { faEnvelope } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import styled, { css } from 'styled-components';

import { createUserWithoutInvitation } from '../../../../actions/permissionsActions';
import { EAlertType } from '../../../../entities/IAlert';
import { EProfileType } from '../../../../entities/IGlobal';
import { IValue } from '../../../../entities/IPickers';
import { EMAIL_INVITATION } from '../../../../entities/IRoleManager';
import { colorStack } from '../../../../styleHelpers/colors';
import { fontSize, fontSizeAndHeight } from '../../../../styleHelpers/fontSizes';
import { cardsShadow } from '../../../../styleHelpers/mixins/shadow';
import { uuidv4 } from '../../../../tools/authTools';
import { isValidEmail } from '../../../../tools/emailChecker';
import { useAlert } from '../../../../tools/hooks';
import { Button } from '../../Buttons/NewButton';
import { Delete } from '../../Icons';
import { TextComponent } from '../../Inputs/TextComponent';
import IntlMessage, { useIntlMessage } from '../../IntlMessage';

type CreateUserWithoutInvitation = ReturnType<typeof createUserWithoutInvitation>;

const Wrapper = styled.div<{outsideScreen: number, width: number}>`
    position: absolute;
    border-radius: 8px;
    background: ${colorStack.white};
    ${cardsShadow()};
    z-index: 9999;
    ${props => props.outsideScreen > 0 && css`
        bottom: 40px;
    `}
    ${props => props.width && css`
        width: ${`${props.width}px`};
    `}
`;

const CloseForm = styled(Delete)`
    position: absolute;
    right: -10px;
    top: -10px;
    cursor: pointer;
`;

const TopText = styled.div`
    font-size: ${fontSize[13]};
    color: ${colorStack.label};
    margin: 0 0 1rem 0;
    padding: 1rem 1rem 0 1rem;
`;

const FormRow = styled.div`
    display: flex;
    margin: 0 0 1rem 0;
    padding: 0 1rem;
    flex-direction: column;
`;

const AddElem = styled.div`
    padding: 12px 1rem;
    height: 56px;
    background: ${colorStack.bodyBg};
    cursor: pointer;
    display: flex;
    align-items: center;
    border-bottom: 1px solid ${colorStack.middleGrey};
    gap: 1rem;
    button {
        margin: 0 0 0 auto;
    }
`;

const Icon = styled.div`
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    border-radius: 100%;
    background: ${colorStack.lightBlue};
    font-size: 1rem;
`;

const Texts = styled.div`
    display: flex;
    flex-direction: column;
    span {
        ${fontSizeAndHeight[16]};
        &:first-child {
            font-weight: 600;
        }
    }
`;

interface IProps {
    isVisible: boolean;
    onlyDataFromForm?: boolean;
    initialData?: {
        firstName: string;
        lastName: string;
        email: string;
    }
    closeHandler();
    returnData(e: React.MouseEvent<HTMLButtonElement>, elem: IValue, isRemove?: boolean);
}

export const NewUserForm: FC<React.PropsWithChildren<IProps>> = props => {
    const dispatch = useDispatch();
    const addAlert = useAlert();
    const { intlFormatMessage } = useIntlMessage();
    const [firstName, setFirstName] = useState<string>('');
    const [lastName, setLastName] = useState<string>('');
    const [email, setEmail] = useState<string>('');
    const [emailErrMsg, setEmailErrMsg] = useState<string>(undefined);
    const [outsideScreen, setOutsideScreen] = useState<number>(0);
    // tslint:disable-next-line:no-null-keyword
    const wrapperRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if ((window.devicePixelRatio === 1 || window.location.pathname === '/registration-final-step') && props.isVisible && (window.innerHeight - wrapperRef.current?.getBoundingClientRect().height - wrapperRef.current?.previousElementSibling.getBoundingClientRect().height < wrapperRef.current?.getBoundingClientRect().top)) {
            setOutsideScreen(window.innerHeight - wrapperRef.current?.parentElement?.getBoundingClientRect().top);
        }
        if (!props.isVisible) {
            setFirstName('');
            setLastName('');
            setEmail('');
            setEmailErrMsg(undefined);
        }
    }, [props.isVisible, wrapperRef.current]);

    useEffect(() => {
        if (props.isVisible) {
            props.initialData.firstName?.length > 0 && setFirstName(props.initialData.firstName);
            props.initialData.lastName?.length > 0 && setLastName(props.initialData.lastName);
            props.initialData.email?.length > 0 && setEmail(props.initialData.email);
        }
    }, [props.initialData.firstName, props.initialData.lastName, props.isVisible]);

    const changeFirstName = (value: string) => {
        setFirstName(value);
    };

    const changeLstName = (value: string) => {
        setLastName(value);
    };

    const changeEmail = (value: string) => {
        setEmail(value);
    };

    const createNewUser = useCallback(() => {
        if (props.onlyDataFromForm) {
            props.returnData(undefined, {
                key: uuidv4(),
                text: `${firstName} ${lastName}`,
                data: { firstName, lastName, email, type: EProfileType.Personal, id: EMAIL_INVITATION }
            });
            setFirstName('');
            setLastName('');
            setEmail('');
            props.closeHandler();
            setEmailErrMsg(undefined);
        } else {
            dispatch<CreateUserWithoutInvitation>(createUserWithoutInvitation(email, firstName, lastName)).then(res => {
                props.returnData(undefined, {
                    key: res.id,
                    text: `${res.firstName} ${res.lastName}`,
                    data: { ...res, type: 'personal' }
                });
                addAlert(<IntlMessage id="searchInput.alert.person" />, EAlertType.SUCCESS);
                setFirstName('');
                setLastName('');
                setEmail('');
                props.closeHandler();
                setEmailErrMsg(undefined);
            }).catch(err => {
                setEmailErrMsg(err.data.message);
            });
        }
    }, [email, firstName, lastName, props.returnData]);

    const checkEmailFormat = () => {
        if (!isValidEmail(email)) {
            setEmailErrMsg(intlFormatMessage({ id: 'searchInput.error.email' }));
        } else {
            setEmailErrMsg(undefined);
        }
    };

    return (
        <>
            {props.isVisible &&
                <Wrapper ref={wrapperRef} outsideScreen={outsideScreen} width={wrapperRef.current?.previousElementSibling.getBoundingClientRect().width}>
                    <AddElem>
                        <Icon>
                            <FontAwesomeIcon icon={faEnvelope} />
                        </Icon>
                        <Texts>
                            <IntlMessage id="global.profileDoesntExist" tagName="span" />
                            <span>{firstName} {lastName}</span>
                        </Texts>
                        <Button onClick={createNewUser} disabled={!firstName || !email || !lastName || !!emailErrMsg} type="button"><IntlMessage id="global.save" /></Button>
                    </AddElem>
                    <CloseForm onClick={props.closeHandler} width={24} height={24} />
                    <TopText><IntlMessage id="searchinput.topText.user" /></TopText>
                    <FormRow>
                        <TextComponent
                            required
                            label={<IntlMessage id="searchInput.form.firstName" />}
                            value={firstName}
                            onChange={changeFirstName}
                        />
                    </FormRow>
                    <FormRow>
                        <TextComponent
                            required
                            label={<IntlMessage id="searchInput.form.lastName" />}
                            value={lastName}
                            onChange={changeLstName}
                        />
                    </FormRow>
                    <FormRow>
                        <TextComponent
                            required
                            label={<IntlMessage id="searchInput.form.emailAddress" />}
                            value={email}
                            onBlur={checkEmailFormat}
                            onChange={changeEmail}
                            error={!!emailErrMsg}
                            errorMsg={emailErrMsg}
                        />
                    </FormRow>
                </Wrapper>
            }
        </>
    );
};