import React, { FC } from 'react';
import styled, { css } from 'styled-components';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileAlt } from '@fortawesome/pro-duotone-svg-icons';

import { IReferenceText } from '../../../../entities/ISoge';
import { IDocumentVersion } from '../../../../entities/LegalDoc/INormativeDocumentationVersion';
import { IState } from '../../../../reducers';
import { ISogeReducer } from '../../../../reducers/sogeReducer';
import { useTocItemNumberingStyle } from '../../../../tools/legalDocTools/sogeHooks';
import { fontSize } from '../../../../styleHelpers/fontSizes';
import { colorStack } from '../../../../styleHelpers/colors';
import { DateStandardFormat } from '../../../Common/Date/DateStandardFormat';
import { Row, Col } from '../common/SharedComponents';
import { StructureDetailsDropdown } from '../StructureDetailsDropdown';
import { DropdownSpinnerWrapper } from '../../Components/Styles';
import { ESpinnerSize, Spinner } from '../../../Common/Spinner/Spinner';
import { RefTextTag } from '../../Components/RefTextTag';
import { LabelWithEllipsis } from '../../../Common/Label/Label';
import IntlMessage from '../../../Common/IntlMessage';

const Wrapper = styled.div`
    padding: 1rem;
    max-width: 860px;
    margin: auto;
`;

const Thematics = styled.div`
    margin-bottom: 1rem;
`;

const Header = styled.div`
    margin-bottom: 1rem;
    font-size: ${fontSize[13]};
    font-family: ${({ theme }) => theme.font?.primary}, Helvetica, sans-serif;
    > h3 {
        font-size: ${fontSize[25]};
        font-weight: 600;
        ${({ theme }) => theme.colors?.primary && css`
            color: ${theme.colors?.primary};
        `}
        > svg {
            margin-right: 0.5rem;
        }
    }
`;

interface ILevelReferenceTextProps {
    referenceText: IReferenceText;
    normativeDocumentationVersion: IDocumentVersion;
    structureLevelName: string;
    commonLevelId: string;
    currentDownloadingElementId: string;
    renderPath?: string;
    isAuthorized?: boolean;
    onStructureDownloadClick?(): void;
    onCopyUrlClick?(documentVersionName: string, commonElementId: string, levelId?: string): void;
    onMetadataClick?(commonElementId: string);
}

export const LevelReferenceText: FC<React.PropsWithChildren<ILevelReferenceTextProps>> = ({ referenceText, normativeDocumentationVersion, structureLevelName, commonLevelId, currentDownloadingElementId, renderPath, isAuthorized, onStructureDownloadClick, onCopyUrlClick, onMetadataClick }) => {
    const { normReferentials, documentationLcid } = useSelector<IState, ISogeReducer>(state => state.soge);
    const publicationDate = referenceText.version?.publicationDate;
    const updateDate = referenceText.version?.updateDate;
    const thematicReferentialIdentity = normReferentials?.identitiesByType?.Thematic?.[0];
    const { tocItemNumberingStyle } = useTocItemNumberingStyle();

    return (
        <Wrapper>
            {!!referenceText?.thematics?.length && (
                <Thematics>
                    {(referenceText?.thematics || []).map((thematic, index) => (
                        <RefTextTag key={thematic.id || index} >
                            {(normReferentials?.identitiesById?.[thematicReferentialIdentity?.id]?.itemById?.[thematic.id]?.name.find(name => name.lcid === documentationLcid)?.label) || thematic.name}
                        </RefTextTag>
                    ))}
                </Thematics>
            )}
            <Header
                className="structure-children-observer"
                data-item-id={commonLevelId}
                data-item-name={structureLevelName}
                data-render-path={renderPath}
            >
                <h3>
                    <FontAwesomeIcon icon={faFileAlt} color={colorStack.disabled} />
                    {tocItemNumberingStyle(structureLevelName)}
                    {(onStructureDownloadClick || onCopyUrlClick) && (
                        <StructureDetailsDropdown
                            commonElementId={commonLevelId}
                            isAuthorized={isAuthorized}
                            onDownloadClick={onStructureDownloadClick}
                            onCopyUrlClick={onCopyUrlClick}
                            onMetadataClick={onMetadataClick}
                            documentVersionName={normativeDocumentationVersion?.name}
                        />
                    )}
                    {currentDownloadingElementId === commonLevelId && (
                        <DropdownSpinnerWrapper>
                            <Spinner size={ESpinnerSize.SMALL} />
                        </DropdownSpinnerWrapper>
                    )}
                </h3>
            </Header>
            <Row center>
                <Col>
                    <LabelWithEllipsis>
                        {!!publicationDate && !String(publicationDate).match('0001') && (
                            <IntlMessage
                                id="norms.publishedBy"
                                values={{
                                    publicationDate: <DateStandardFormat date={publicationDate} />
                                }}
                            />
                        )}
                        {' '}
                        {!!updateDate && !String(updateDate).match('0001') && (
                            <IntlMessage
                                id="norms.lastModification"
                                values={{
                                    updateDate: <DateStandardFormat date={updateDate} />
                                }}
                            />
                        )}
                    </LabelWithEllipsis>
                </Col>
            </Row>
        </Wrapper>
    );
};
