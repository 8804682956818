
import { EClusterNature, EClusterPrivacy, EClusterVisibility } from '../entities/IClusters';
import { ECellHeight } from './dataGridTools';

export const clusterNatureTranslation = (nature: EClusterNature, intlFormatMessage: any) => {
    const clusterNature = {
        [EClusterNature.Project]: intlFormatMessage({ id: 'cluster.nature.project' }),
        [EClusterNature.Dispute]: intlFormatMessage({ id: 'cluster.nature.dispute' }),
        [EClusterNature.Contract]: intlFormatMessage({ id: 'cluster.nature.contract' }),
        [EClusterNature.Support]: intlFormatMessage({ id: 'cluster.nature.support' }),
        [EClusterNature.Other]: intlFormatMessage({ id: 'cluster.nature.other' }),
        [EClusterNature.SendingGift]: intlFormatMessage({ id: 'cluster.nature.sendingGift' }),
        [EClusterNature.ReceivingGift]: intlFormatMessage({ id: 'cluster.nature.receivingGift' })
    };
    return clusterNature[nature] || '';
};

export const clusterPrivacyTranslation = (privacy: EClusterPrivacy, intlFormatMessage: any) => {
    const clusterPrivacy = {
        [EClusterPrivacy.Private]: intlFormatMessage({ id: 'privacyType.private' }),
        [EClusterPrivacy.Public]: intlFormatMessage({ id: 'privacyType.public' }),
        [EClusterPrivacy.Secret]: intlFormatMessage({ id: 'privacyType.secret' })
    };
    return clusterPrivacy[privacy] || '';
};

export const clusterVisibilityTranslation = (clusterVisibility: EClusterVisibility, intlFormatMessage: any) => {
    const visibility = {
        [EClusterVisibility.Hidden]: intlFormatMessage({ id: 'clusterVisibility.hidden' }),
        [EClusterVisibility.Visible]: intlFormatMessage({ id: 'clusterVisibility.visible' })
    };
    return visibility[clusterVisibility] || '';
};

export const expandMenuOptionsTranslation = (intlFormatMessage: any) => {
    const expandMenuOptions = {
        [ECellHeight.Small]: intlFormatMessage({ id: 'clusterStatus.epandTool.small' }),
        [ECellHeight.Medium]: intlFormatMessage({ id: 'clusterStatus.epandTool.medium' }),
        [ECellHeight.Tall]: intlFormatMessage({ id: 'clusterStatus.epandTool.tall' }),
        [ECellHeight.ExtraTall]: intlFormatMessage({ id: 'clusterStatus.epandTool.extraTall' })
    };

    return {
        options: expandMenuOptions,
        getTranslation: (cellHeight: ECellHeight) => expandMenuOptions[cellHeight] || ''
    };
};

export const intlToString = (id: string, intl: any) => {
    if (id) {
        return (intl.formatMessage || intl)({ id: id });
    }

    return '';
};